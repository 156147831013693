import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const DivCardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 101%;

  margin-left: -1px;

  background: pink;

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: ${({ color }) => color};

  > svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.white.default};
  }
`;

export const Values = styled.div`
  display: flex;
  flex-direction: column;

  padding-left: 24px;

  h3 {
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.grafit.default};
  }

  span {
    font-size: 27px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    text-align: left;
    color: ${({ color }) => color};
  }
`;
