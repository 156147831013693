import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 100%;
  height: 100%;
  min-height: 780px;
  margin-top: 2%;

  .containerPot{
    box-shadow: unset !important;
    width: 100%;
    height:100%;
    align-self: flex-end;

  }
  & > div + div {

    margin-left: 1.5%;
  }
`;

export const ContainerTitleCard = styled.div`

  width: 50%;
  height:100%;
  div.header{

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px !important;
    height:5%;
    width: 100%;
    .select-date{
      /* border: solid 1px #3ba7ff; */
      width: 23%;
      max-height: auto;
      margin: unset;
      padding: unset;
      margin-bottom: 10px;

      div.custom__container{
        margin: unset;

      }
      div.custom__single-value{
        text-align: center;
        justify-content: center;
        font-size: 0.84vw;
        font-family: Nunito;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: 0.02px;
        text-align: left;
        color: #3ba7ff;
      }
    }
  }

`;

export const Header = styled.span`
  display: ${props => props.SpaceBetween ? 'inline-flex' : 'inline'};
  color: ${props => props.theme.grafit.hover};
  width: ${props => props.SpaceBetween ? '100%' : '50%'};
  justify-content: space-between;
  font-size: 1.3vw;
  font-weight: bold;
  line-height: 1.33;
  div.buttons{
    display: flex;
    flex-direction: row;
    gap: 10px;
    button.pot{
      font-size: medium;
    }
  }

`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  div.area-filter {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 20%;
    margin-top: 20px;

    div {
      & > div {
        margin-top: 0 !important;
        border-color: ${props => props.theme.info.default};

        div.custom__single-value {
          color: ${props => props.theme.info.default};
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: 0.02px;
          text-align: left;
        }

        div.custom__option:hover {
          background: ${props => props.theme.info.default};
        }
      }

      svg {
        fill: ${props => props.theme.grafit.default};
      }

      span {
        margin-left: 8px;
        color: ${props => props.theme.grafit.default};
        font-size: 14px;
        font-weight: bold;
        line-height: 1.36;
        letter-spacing: 0.02px;
        text-align: left;
      }
    }
  }
`;

export const Body = styled.div`
  display: flex;
  margin-top:15%;
  margin-bottom: 5%;
  height: 80%;
  &.modal-pot{
    margin-top: 30px;
  }
`;

export const AreaDetail = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-left: 3%;

  & > div + div {
    margin-top: auto;
  }


`;

export const DivDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height:100%;



  div.labels {
    display: flex;
    align-items: center;

    div.circle {
      width: 0.3vw;
      height: 0.3vw;
      border-radius: 50%;
      background: ${props => props.color};
      margin-right: 8px;
    }

    span {
      font-size: 0.76vw;
      line-height: 1.36;

      color: ${props => props.theme.grafit.hover};
      &.selected{
        text-decoration: line-through;
      }
    }
  }

  div.values {
    span {
      color: ${props => props.color};
      font-size: 1.1vw;
      font-weight: bold;
      line-height: 1.35;
    }
  }
`;

export const DivChart = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 11%;
  width: 29%;
  height: 95%;


`;

export const DivTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${props => props.theme.grafit.default};
  span.total {
    margin-left: 3%;
    font-size: 0.76vw;
    line-height: 1.36;
    letter-spacing: 0.24px;
    color: ${props => props.theme.grafit.hover};
  }

  span.value {
    color: ${props => props.theme.receipts.default};
    font-size: 1.56vw;
    font-weight: bold;
    line-height: 1.35;

  }
`;
