import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: ${props => props.height} ;
  width: ${props => props.width} ;

  padding: ${props => (props.notPadding ? '15px 20px' : '35px 30px')};

  position: relative;

  ${props =>
    props.withRow &&
    css`
      display: flex;
      flex-direction: row;
    `}

  background: ${props =>
    props.transparent === true ? 'transparent' : props.theme.white.default};

  border-radius: 10px;

  /**
    Quando a prop transparent é setada é necessário retirar o box-shadow
   */
  box-shadow: ${props =>
    props.transparent || props.notShadow
      ? ''
      : '0 3px 6px 0 rgba(0, 0, 0, 0.3)'};

  transition: ease 400ms;
`;
