import styled, { css, keyframes } from 'styled-components';

const showDivDropdown = keyframes`
  0% {
    opacity: 0;
    max-height: 0;

  }
  100% {
    opacity: 1;
    max-height: 100vh;

  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  height: 50px;
  margin-top: 25px;

  span {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: ${props => props.theme.darker.disabled};
  }
`;

export const DivOptions = styled.div`
  display: flex;
  width: 859px;
  height: 96px;
  position: relative;
  background: pink;
  border-radius: 8px;

  background-image: linear-gradient(
    to right,
    ${props => props.theme.darker.default},
    ${props => props.theme.darker.disabled}
  );

  img {
    position: absolute;
    bottom: 0;
    height: 133px;
  }

  div.options {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-left: 160px;

    span {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.35;
      letter-spacing: 0px;
      text-align: left;
      color: ${props => props.theme.white.default};
      height: 54px;

      width: 396px;
      span {
        color: ${props => props.theme.investments.default};
      }
    }

    div.buttons {
      display: flex;
      align-items: center;

      height: 40px;

      button {
        svg {
          margin-left: 16px;
          stroke-width: 30px;
        }
      }

      & > button + button {
        margin-left: 12px;
      }
    }
  }
`;

export const Title = styled.span`
  font-size: 32px;
  color: ${props => props.theme.grafit.hover};
  margin-bottom: 12px;
  line-height: 1.35;
  letter-spacing: 0.01px;
  text-align: left;

  ${props =>
    props.asSubTitle &&
    css`
      font-size: 16px;
      margin-bottom: 8px;
    `}
`;

export const InvestGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;

  margin-bottom: 40px;

  transition: 200ms ease;

  div.main {
    display: flex;
    align-items: center;
    width: 100%;
    height: 96px;
  }
`;

export const ButtonDropdown = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.active ? props.theme.info.default : props.theme.grafit.disabled};
  margin-left: 12px;

  width: 40px;
  height: ${props => (props.customHeight ? '52px' : '100%')};
  border-radius: 10px;
  box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.1);

  svg {
    fill: ${props =>
      props.active ? props.theme.white.default : props.theme.grafit.hover};
  }
`;

export const DivMoreActives = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  height: ${props => (props.visible ? '100%' : 0)};
  opacity: ${props => (props.visible ? 1 : 0)};
  flex-direction: column;

  width: 100%;
  margin-top: 24px;

  ${props =>
    props.visible &&
    css`
      animation: ${showDivDropdown} forwards 3000ms;
    `};

  > div + div {
    margin-top: 24px;
  }

  div.quotation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;

    div.line {
      display: flex;
      flex-direction: column;
      width: 55%;
      height: 100%;

      div.progress-bar {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        width: 100%;
        height: 100%;
        background: none;
      }
    }

    div.information {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 45%;
      height: 100%;

      > div + div {
        margin-left: 15px;
      }

      div.group {
        display: flex;
        flex-direction: column;

        width: 113px;
        height: 66px;

        span.title {
          font-size: 12px;
          line-height: 1.33;
          letter-spacing: 0px;
          text-align: left;
          color: ${props => props.theme.grafit.hover};
        }

        span.value {
          font-size: 20px;
          font-weight: 600;
          line-height: 1.35;
          letter-spacing: 0px;
          text-align: left;
          margin-top: 23px;
          color: ${props => props.theme.darker.disabled};
        }
      }

      div.options {
        display: flex;
        align-items: center;
        width: 92px;
        height: 100%;

        & > button {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 40px;
          height: 40px;
          border-radius: 10px;
          box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.15);
          background: ${props => props.theme.white.disabled};
        }

        & > button + button {
          margin-left: 12px;
        }
      }

      & > div.group + div.group {
        margin-left: 87px;
      }
    }
  }
`;

export const DivTableOperation = styled.div`
  display: ${props => (props.showTable ? 'flex' : 'none')};
  align-items: center;

  width: 100%;

  ${props =>
    props.showTable &&
    css`
      animation: ${showDivDropdown} forwards 3000ms;
    `}
`;

export const MessageInitial = styled.h1`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;



  width: 100%;
  height: 100%;
  /* color: ${({ theme }) => theme.receipts.default} */

  font-size: 48px;

  img {
    width: 500px;
    height: 500px;
  }
`;
