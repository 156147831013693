const home = {
  title: 'Dashboard',
  path: 'home',
  subPages: [
    {
      path: 'detail',
      title: 'Detalhamento Pote',
      hidden: true,
    },
  ],
};

const finances = {
  title: 'Mapear',
  path: 'finances',
  subPages: [
    {
      path: 'budget',
      title: 'Orçamento',
    },
    {
      path: 'patrimony',
      title: 'Patrimônio',
      subPages: [
        {
          path: 'active-patrimony',
          title: 'Ativo Patrimonial',
        },
        {
          path: 'passive-patrimony',
          title: 'Passivo Patrimonial',
        }
      ],
    },
    { path: 'investments/suitability',
      title: 'Suitability',
      absolutePath: true
    },
  ],
};
const acting = {
  title: 'Atuar',
  path: 'acting',
  subPages: [],
};

const goals = {
  title: 'Almejar',
  path: 'goals',
  subPages: [
    {
      path: 'goalPanel',
      title: 'Painel dos sonhos',
    },
    // {
    //   path: 'sealGoal',
    //   title: 'Selar Sonhos',
    // },
  ],
};
const planning = {
  title: 'Planejar',
  path: 'planning',
  subPages: [
    {
      path: 'wipe',
      title: 'Enxugar Gastos',
    },
  ],
};
const management = {
  title: 'Gestão',
  path: 'management',
  subPages: [
    {
      path: 'spendings',
      title: 'Gastos',
    },
    {
      path: 'extract',
      title: 'Extrato',
    },
    // Conquistas atingidas
    // Atas e reuniões
  ],
};

const investments = {
  title: 'Investimentos',
  path: 'investments',
  subPages: [
    {
      path: 'panelInvestments',
      title: 'Painel de Investimentos',
    },
    {
      path: 'progress',
      title: 'Progresso',
    },
    {
      path: 'extract',
      title: 'Extrato',
    },
    {
      path: 'suitability',
      title: 'Suitability',
    },
    {
      path: 'r1',
      title: 'Relatório de Gestão Orçamentária',
    },
  ],
};

const selfKnowledge = {
  title: 'Auto Conhecimento',
  path: 'selfKnowledge',
  subPages: [
    {
      path: 'lifeCompass',
      title: 'Bússola da vida',
    },
  ],
};

const routesInfo = {
  finances,
  home,
  acting,
  goals,
  management,
  planning,
  investments,
  selfKnowledge,
};

export default routesInfo;
