import React from 'react';
import { Container,
        ContainerColumn,
        SubTitle,
         PurpleBar,
         ContentWrapper,
         LogoAzul,
         Footer,
         Title } from './styles';

export default function IncomeTax({data}) {

  const { headerTitle, footerTitle } = data;

  return (
      <ContentWrapper id="printable" style={{minHeight: '1670px'}}>
        <Container style={{height: '100%'}} padding="0 78px 0px 78px">
          <div className="d-40w">
            <PurpleBar />
            <PurpleBar className="full-height" />
          </div>
          <div className="d-60w">
            <ContainerColumn padding="125px 0px 0px 0px">
              <Title dangerouslySetInnerHTML={{__html: headerTitle}}></Title>
              <p className="p-smaller">É importante esclarecermos que investir diretamente em <b>ativos
                                    de renda variável</b>, como ações e fundos imobiliários geram
                                    uma <b>demanda fiscal</b> de responsabilidade do investidor, que são
                                    elas:</p>
              <ul>
                <li>Todas as vendas precisam ser registradas e calculado os
                    ganhos ou prejuízos financeiros, no caso de <b>ganho acima de
                    R$20.000,00 (vinte mil reais)</b> para ações ou qualquer valor
                    para Fils deverá pagar o imposto de ganho de capital. No
                    caso de prejuízo, esse valor poderá ser abatido em futuros
                    ganhos de que capital que o cliente vier a ter.
                </li>
                <li>Na declaração de ajuste anual de imposto de renda é
                      necessário informar:</li>
                <ul>

                  <li>A posição de todos os ativos em 31/12. Precisa ter o preço
                      médio do ativo, CNPJ, nome do ativo e custodiante.</li>
                  <li>Os dividendos como renda não tributada.</li>
                  <li>Os JCP como renda tributada.</li>
                  <li>O ganho de capital como renda exclusiva.</li>
                </ul>
              </ul>
              <p className="p-smaller" style={{marginTop: '0px'}}>Essas informações não são enviadas pela corretora e <b>é de
                                    responsabilidade do investidor coletar essas informações.</b>
                                    Recomendamos fortemente que o cliente contrate algum
                                    sistema para controle de suas movimentações de renda
                                    variável.
              </p>
              <p className="p-smaller" style={{marginTop: '0px'}}><b>Caso o cliente não queira ter essa responsabilidade podemos
                                    fazer ajustes a carteira recomendada.</b>
              </p>
              <SubTitle style={{marginTop: '40px'}} className="bigger uppercase">Política de<br />Investimento</SubTitle>
              <p className="p-smaller">Qualquer alteração nos sonhos, aumento
                                    patrimonial ou insatisfação quanto a estratégia
                                    inicial, deverá ser comunicado para ajustarmos
                                    uma nova recomendação de alocação.
              </p>
            </ContainerColumn>
          </div>
        </Container>
        <Footer>
          <Container padding="0 78px">
              <p>{ footerTitle }</p>
              <LogoAzul />
          </Container>
        </Footer>
      </ContentWrapper>
  );
}
