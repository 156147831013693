import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { AiOutlineRise } from 'react-icons/ai';

import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { Form, SubTitle } from '../styles';

import Modal from 'components/Modal';
import CurrentyInput from 'components/masks/CurrencyInput';
import Input from 'components/Input';
import Select from 'components/Input/Dropdown';
import SelectAsync from 'components/Input/Dropdown/async';
import Button from 'components/Button';
import Loading from 'components/Loading';

import ModalSearchAssets from './ModalSearchAssets';

import api from 'services/api';

const defaultTypesAssets = [
  { label: 'Ações', value: 'asset' },
  { label: 'FII', value: 'fii' },
  { label: 'Títulos Públicos', value: 'tp' },
  { label: 'Outros', value: 'others' },
];

export default function CreateModal({
  setOperations,
  typeModal,
  setShowModal,
}) {
  const [symbol, setSymbol] = useState('');
  const [stockBroker, setStockBroker] = useState('');
  const [date, setDate] = useState('');
  const [quantity, setQuantity] = useState('');
  const [value, setValue] = useState('');
  const [typeAsset, setTypeAsset] = useState(defaultTypesAssets[0]);

  const [openModalSearchAsset, setOpenModalSearchAsset] = useState(false);

  const [loading, setLoading] = useState(false);

  const { id } = useSelector(state => state.user.profile);

  async function handleLoadAssets(inputValue) {
    if (inputValue.length < 1) return;

    if (typeModal === 'buy') {
      const { data } = await api.get(`assets/${inputValue}`);

      return data.map(dt => {
        return {
          value: dt.symbol,
          label: `${dt.symbol} | ${dt.name}`,
        };
      });
    }

    const { data } = await api.get(`users/${id}/operations`);

    return data.map(dt => {
      return {
        value: dt.symbol,
        label: dt.symbol,
      };
    });
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      let dateParam;

      if (date.length === 0) {
        dateParam = new Date();
      }

      setLoading(true);

      const { data } = await api.post(`users/${id}/operations`, {
        symbol: symbol.value,
        stock_broker: stockBroker,
        date: dateParam,
        value,
        quantity,
        type: typeModal,
        type_asset: typeAsset.value,
      });

      setOperations(assets => [...assets, data]);

      toast.success('Operação executada com sucesso');
    } catch (err) {
      if (err.response.data.error) {
        toast.error(err.response.data.error.message);
      }
      toast.error('Falha ao criar item! Verifique os dados e tente novamente');
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  }

  return (
    <>
      <Modal
        title={
          typeModal === 'sell'
            ? 'Cadastrar nova venda'
            : 'Cadastrar nova compra'
        }
        icon={<AiOutlineRise />}
        color={typeModal === 'sell' ? 'receipts' : 'investments'}
        setShowModal={setShowModal}
      >
        <Form onSubmit={handleSubmit}>
          <SubTitle>Qual Investimento?</SubTitle>
          <Select
            label="Em que produto você investiu?"
            value={typeAsset}
            options={defaultTypesAssets}
            onChange={selectedItem => setTypeAsset(selectedItem)}
          />
          <SelectAsync
            label={
              typeModal === 'sell'
                ? 'Qual título vendeu?'
                : 'Código de negociação'
            }
            small="Pesquisar por ativos"
            handleClickSmall={() => setOpenModalSearchAsset(true)}
            placeholder="Selecione um ativo"
            cacheOptions
            defaultOptions
            options={handleLoadAssets}
            onChange={selectedOption => setSymbol(selectedOption)}
            value={symbol}
          />
          <SubTitle>Onde você negociou?</SubTitle>
          <Input
            label={
              typeModal === 'sell'
                ? 'Em qual Instituição Financeira ou banco?'
                : 'Em qual Instituição Financeira?'
            }
            value={stockBroker}
            onChange={e => setStockBroker(e.target.value)}
          />
          <SubTitle>
            {typeModal === 'sell'
              ? 'Quando você resgatou?'
              : 'Quando você comprou?'}
          </SubTitle>
          <Input
            label={typeModal === 'sell' ? 'Data de venda?' : 'Data de compra'}
            type="date"
            value={date || format(new Date(), 'yyyy-MM-dd')}
            onChange={e => setDate(e.target.value)}
          />
          <SubTitle>Quanto você resgatou?</SubTitle>
          <div className="divGroup">
            <Input
              label="Quantidade?"
              width="45%"
              value={quantity}
              onChange={e => setQuantity(e.target.value)}
            />
            <CurrentyInput
              label={
                typeModal === 'sell' ? 'Preço de venda?' : 'Preço de compra?'
              }
              prefix="R$ "
              value={value}
              onChange={e => setValue(e)}
              width="45%"
            />
          </div>

          <div className="button">
            <Button>{loading ? <Loading /> : 'Salvar transação'}</Button>
          </div>
        </Form>
      </Modal>
      {openModalSearchAsset && (
        <ModalSearchAssets close={setOpenModalSearchAsset} />
      )}
    </>
  );
}

CreateModal.propTypes = {
  setOperations: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  typeModal: PropTypes.string,
};

CreateModal.defaultProps = {
  typeModal: 'sell',
};
