import React, { useMemo } from 'react';
import { Chart } from 'chart.js';
import { toCurrencyNoCents as formatReal } from 'utils/toCurrency';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import {
  Container,
  ContainerColumn,
  SubTitle,
  PurpleBar,
  ContentWrapper,
  LogoAzul,
  Footer,
  Title,
} from './styles';

const options = {
  responsive: true,
  indexAxis: 'y',
  elements: {
    bar: {
      borderRadius: 100,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          autoSkip: true,
          minTicksLimit: 2,
          maxTicksLimit: 5,
          callback: function(value) {
            return formatReal(value);
          },
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          autoSkip: false,
          maxTicksLimit: 7,
          callback: value => {
            const words = value.split(' ');
            const maxLineWidth = 20;
            let line = '';
            let lines = [];
            words.forEach(word => {
              if ((line + word).length > maxLineWidth) {
                lines.push(line);
                line = '';
              }
              line += (line ? ' ' : '') + word;
            });
            lines.push(line);
            return lines;
          },
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: true,
    callbacks: {
      label: function(tooltipItem, data) {
        var dataset = data.datasets[tooltipItem.datasetIndex];
        var currentValue = formatReal(dataset.data[tooltipItem.index]);
        return currentValue;
      },
      title: function(tooltipItem, data) {
        return data.labels[tooltipItem[0].index];
      },
    },
  },
  plugins: {
    datalabels: {
      display: true,
      enabled: false,
      formatter: value => {
        let total = formatReal(value);
        return total;
      },
      color: '#fff',
      backgroundColor: 'rgba(0,0,0,0.6)',
      borderRadius: 25,
      padding: 6,
      font: {
        family: 'Roboto Condensed',
        weight: 'bold',
        size: 13,
      },
      align: 'end',
      offset: 10,
    },
  },
};

Chart.plugins.register(ChartDataLabels);

export default function EquityComposition({ data }) {
  const { headerTitle, footerTitle } = data;
  const { active, activeTotal, passive, passivesTotal, liquidPatromony } = data;

  const activeData = useMemo(() => {
    if (!active) return {};
    const defaultColors = Array.from(
      { length: active.length },
      () => '#5960ff'
    );
    return {
      labels: active.map(item => item.name),
      datasets: [
        {
          label: '',
          data: active.map(item => item.value),
          backgroundColor: defaultColors,
          borderColor: defaultColors,
          color: '#FFFFFF',
          borderWidth: 2,
          borderRadius: [200, 100, 50, 20],
        },
      ],
    };
  }, [data]);

  const passiveData = useMemo(() => {
    if (!passive) return {};
    const defaultColors = Array.from(
      { length: passive.length },
      () => '#ff0043'
    );
    return {
      labels: passive.map(item => item.name),
      datasets: [
        {
          label: '',
          data: passive.map(item => item.value),
          backgroundColor: defaultColors,
          borderColor: defaultColors,
          color: '#FFFFFF',
          borderWidth: 2,
          borderRadius: [200, 100, 50, 20],
        },
      ],
    };
  }, [data]);

  const passivesActiveData = useMemo(() => {
    if (!active || !passive) return {};
    return {
      labels: ['Ativo', 'Passivo'],
      datasets: [
        {
          label: '',
          data: [liquidPatromony, passivesTotal],
          backgroundColor: ['#5960ff', '#ff0043'],
          borderColor: ['#5960ff', '#ff0043'],
          color: '#FFFFFF',
          borderWidth: 2,
          borderRadius: 100,
        },
      ],
    };
  }, [data]);

  return (
    <ContentWrapper id="printable" minHeight={1682}>
      <Container padding="0 78px 85px 78px">
        <PurpleBar />
      </Container>
      <Container padding="0 78px">
        <Title dangerouslySetInnerHTML={{ __html: headerTitle }} />
      </Container>
      <Container style={{ height: '580px' }} padding="50px 78px 19px 78px">
        <div className="half">
          <div className="header-title">
            <SubTitle>Ativos</SubTitle>
          </div>
          <div className="half-content">
            <Bar height={250} data={activeData} options={options} />
          </div>
        </div>
        <div className="half">
          <div className="header-title">
            <SubTitle>
              Passivos <sup>*</sup>
            </SubTitle>
            <small>
              *Esse é o saldo para quitação atual dos passivos. O valor exclui
              os juros futuros.
            </small>
          </div>
          <div className="half-content">
            <Bar height={250} data={passiveData} options={options} />
          </div>
        </div>
      </Container>
      <Container padding="0px 78px 0px 78px">
        <ContainerColumn>
          <div className="header-title">
            <SubTitle className="bigger uppercase">
              Patrimônio
              <br />
              Líquido
            </SubTitle>
            <p className="p-smaller">
              O Patrimônio Líquido (PL) representa a sua riqueza.
              <br />
              Se você vender tudo o que tem e pagar todas as
              <br />
              suas obrigações, quanto dinheiro sobraria?
            </p>
          </div>
        </ContainerColumn>
      </Container>
      <Container padding="0px 78px 19px 78px">
        <div className="half">
          <div className="half-content">
            <Bar height={250} data={passivesActiveData} options={options} />
          </div>
        </div>
        <div className="half">
          <div className="half-content flex-column">
            <div className="value">
              <span className="ativo">Ativo</span>
              <span> +{formatReal(activeTotal)}</span>
            </div>
            <div className="value">
              <span className="passivo">Passivo</span>
              <span> -{formatReal(passivesTotal)}</span>
            </div>
            <div className="value full">
              <span>
                Patrimonio
                <br />
                Líquido
              </span>
              <span>{formatReal(liquidPatromony)}</span>
            </div>
          </div>
        </div>
      </Container>
      <Footer>
        <Container padding="0 78px">
          <p>{footerTitle}</p>
          <LogoAzul />
        </Container>
      </Footer>
    </ContentWrapper>
  );
}
