import styled, { css } from 'styled-components';
import LogoAzulImage from 'assets/logo-azul.png'
import Assinatura from 'assets/assinatura.png'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170px;
  height: 845px;
  background: #FFF;
  -webkit-print-color-adjust: exact;
  margin: 0 auto;

  ${({ minHeight }) =>
      minHeight !== undefined &&
      css`
        min-height: ${ minHeight + 'px'} ;
      `
  }

  ${({ minHeight }) =>
        minHeight === undefined &&
        css`
          min-height: auto;
        `
    }

  h1, h2, h3, h4, h5, h6, p, span, ul, li, b {
    font-family: 'Poppins', sans-serif !important;
  }

`

export const ContainerColumn = styled.div`
  position: absolute;
  top: 230px;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: ${props => props.padding !== undefined ? props.padding: 0};
  height: auto;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.padding !== undefined ? props.padding: 0};
  height: ${props => props.height !== undefined ? props.height : 'auto'};

  > div.half {
    width: 50%;
  }

  > div.d-40w {
    width: 44%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  > div.d-60w {
    position: relative;
    width: 56%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .p-smaller {
    width: 760px;
    font-size: 26px;
    line-height: 37px;
    font-weight: 500;
    margin-top: 30px;
    text-align: right;
  }
`;

export const PurpleBar = styled.div`
  width: 378px;
  height: 40px;
  background: #5560ff;
`

export const LogoAzul = styled.div`
  width: 60px;
  height: 54px;
  background: url(${LogoAzulImage});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 15px;
`

export const AssinaturaDiretor = styled.div`
  width: 430px;
  height: 128px;
  background: url(${Assinatura});
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateX(25px);
  margin-left: auto;
`

export const Footer = styled.div`
  width: 100%;
  height: 130px;
  margin-top: auto;
  border-top: solid 6px #2d2d2d;

  > div {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 23px;
    font-weight: 600;
  }
`



