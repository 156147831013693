import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Loading from 'components/Loading';

import Progress from './Progress';
import PanelInvestments from './PanelInvestments';
import Extract from './Extract';
import R1 from './R1';
import Suitability from './Suitability';

import { checkSuitabilityUserAuth } from 'services/users';

export default function Planning() {

  const { loading, parcial, respondido } = useSelector(state => state.suitability);

  const [suitabilityOK, setSuitabilityOk ] = useState(null);

  const [ unlocked, setUnlocked] = useState(false);

  const [rendered, setRendered ] = useState(null)

  const [, , choosenSubPath] = window.location.pathname.split('/');

  const [ redirectToInvestiments, setRedirectToInvestments ] = useState(false);

  // Redirect to home when user === admin and choose suitability
  useEffect(() => {
    if(redirectToInvestiments && choosenSubPath !== "suitability") {
      setRedirectToInvestments(false);
      setUnlocked(true);
    }
  }, [redirectToInvestiments, choosenSubPath])

  const pages = {
    progress: <Progress />,
    panelInvestments: <PanelInvestments />,
    extract: <Extract />,
    r1: <R1 />,
    suitability: <Suitability />
  };

  const canRender = () => {
    if(!loading && unlocked) {
      return true
    }

    return false;
  }

  useEffect(() => {

    if(loading) return;

    // Se é cliente
    if(checkSuitabilityUserAuth()) {
      if(respondido && !parcial) {
        // O usuário respondeu tudo
        setSuitabilityOk(true);
        setUnlocked(true);
        setRendered(pages[choosenSubPath]);
      } else if(choosenSubPath === "suitability") {
        setSuitabilityOk(true);
        setUnlocked(true);
        setRendered(pages[choosenSubPath])
      } else {
        setUnlocked(true);
        setSuitabilityOk(false);
      }
    } else {
      if(choosenSubPath === "suitability") {
        setRedirectToInvestments(true);
      } else {
        setUnlocked(true);
        setRendered(pages[choosenSubPath])
      }
    }


      // eslint-disable-next-line
  }, [choosenSubPath, loading, redirectToInvestiments]);

  return (
    <>
    {loading ? (
        <Loading />
      ) : (
        <>
          { redirectToInvestiments && <Redirect to={{ pathname: '/investments/panelInvestments' }} />}
          { canRender() && rendered }
          { suitabilityOK === false && checkSuitabilityUserAuth() && <Redirect to={{ pathname: '/investments/suitability' }} />}
        </>
      )}
    </>
  ) ;
}
