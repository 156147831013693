import styled from 'styled-components';
import TotalTable from 'components/Tables/TotalTable';

export const Table = styled(TotalTable)`
  tbody tr td:first-child { width: auto !important;}
`;

export const DivSwitch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  svg{
    height: 25px;
    padding-top: 0 !important;
  }
`;