import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Container } from './styles';

export default function Dropdown({
  options,
  height,
  width,
  label,
  onChange,
  value,
  className,
  styles,
  defaultValue,
  placeholder,

  ...rest
}) {
  return (
    <Container className={className} width={width}>
      {label && <label className={`${rest.className}`}>{label}</label>}
      <Select
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        height={height}
        className="custom__container"
        classNamePrefix="custom"
        value={value}
        styles={styles}
        placeholder={placeholder}
        {...rest}
      />
    </Container>
  );
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  width: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number]),
  label: PropTypes.string,
};

Dropdown.defaultProps = {
  width: '100%',
  height: 35,
  label: undefined,
};
