import React, { useState } from 'react';

import { AiFillLock } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Form, InputGroup, WrapperGray, Wrapper } from './styles';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import newApi from 'services/newApi';

import history from 'services/history';

export default function ResetPassoword({ email, code }) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    if (password !== confirmPassword) {
      return toast.error('As senhas não conferem');
    }
    try {
      newApi.post('Usuario/redefine-slim', {
        novaSenha: confirmPassword,
        email,
        codigoRecuperacao: code,
      });

      toast.success('Senha alterada com sucesso');
      history.push('/');
    } catch (err) {
      toast.error('Ocorreu um erro ao alterar a sua senha');
    }
  }

  return (
    <WrapperGray>
      <Wrapper>
        <h1>Digite uma nova senha</h1>
        <Form onSubmit={handleSubmit}>
          <label htmlFor="inputPassword">Senha:</label>
          <InputGroup>
            <AiFillLock size={25} />
            <input
              type="password"
              name="password"
              id="inputPassword"
              placeholder="Digite sua nova senha"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </InputGroup>
          <label htmlFor="inputConfirmPassword">Confirme sua senha:</label>
          <InputGroup>
            <AiFillLock size={25} />
            <input
              type="password"
              name="password"
              id="inputConfirmPassword"
              placeholder="Confirme sua nova senha"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </InputGroup>

          <Link to="/">Voltar ao Login</Link>

          <Button type="submit" color="primary">
            Enviar
          </Button>
        </Form>
      </Wrapper>
    </ WrapperGray>
  );
}
ResetPassoword.propTypes = {
  email: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};
