import styled from 'styled-components';

export const Container = styled.div`
  div.titles {
    h2 {
      font-family: Nunito;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #4d4d70;
    }
  }
  div.valueHead {
    display: flex;
    align-items: left;
  }
  div.valueBody {
    display: flex;
    align-items: left;
    padding-left: 20px;
    padding-top: 0.5px;
  }

  .divButton {
    display: flex;
    margin-top: 12px;
    width: 100%;
    justify-content: flex-end;
  }

  .container-table {
    display: flex;
    width: 102%;
    height: 360px;
    overflow: auto;
    .container-titles {
      display: none;
    }
    table {
      width: 99% !important;
    }
  }
`;
export const Title = styled.h2`
  font-family: Nunito;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #4d4d70;
`;
