import styled, { css } from 'styled-components';

const styledStripped = css`
  background: ${props => props.theme.grafit.disabled};

  color: ${props => props.theme.grafit.hover};

  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.16);

  border-radius: 8px;

  &:hover {
    transform: scale(1.05);
  }
`;

export const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  max-height: 50px;
  height: 48px;
  padding-left: 10px;
  padding-right: 10px;

  border-radius: 8px;

  transition: 200ms ease;

  color: #fff;

  background: ${props => props.theme[props.color].default};

  &.inter {
    font-family: 'Inter', sans-serif !important;
    font-weight: 700;
  }

  &.higher {
    height: 48px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  ${props =>
    props.stripped
      ? styledStripped
      : css`
          &:not(:disabled)&:hover {
            background: ${props => props.theme[props.color].hover};
          }
        `};
`;
