import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UpperContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  div.icons {
    display: flex;

    & > * {
      margin-left: 12px;
    }

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 5px;
      transition: 200ms ease;

      &.icon {
        width: 41px;
        height: 40px;
        border: 1px solid ${props => props.theme.grafit.default};
      }

      &:first-child {
        &:hover {
        }
      }

      &:hover {
        border-color: ${props => props.theme.info.default};
        svg:hover {
          color: ${props => props.theme.info.default};
          transform: scale(1.2);
        }
      }
    }

    svg {
      color: #808099;
      font-size: 25px;
      transition: 400ms ease;
    }
  }

  div.titles {
    h2 {
      font-size: 34px;
      font-weight: bold;
      ${props =>
    props.isCustomColor
      ? css`
              color: ${props.color} !important;
            `
      : css`
              /* color: ${props => props.theme[props.color].default}; */
              color: #808099;
            `}

      margin-bottom: 5px;
    }

    h3 {
      font-size: 15px;
      font-weight: bold;
      color: ${props => props.theme.grafit.hover};
    }
  }
`;

const typeTotalValue = {
  spendings: css`
    margin-right: calc(100% - 99%);
    @media (max-width: 1700px){
      margin-right: -0.5%!important;
    }
    `,
  debts: css`
   margin-right: -1%;
   @media (max-width: 1700px){
      margin-right: -2%!important;
    };
  `,
  receipts: css`
    margin-right: calc(100% - 99%) !important;
    `,
  darker: css`
   margin-right: calc(100% - 99%) !important;
    @media (max-width: 1700px){
      margin-right: 0.1%!important;
    }
   `,
  investments: css`
    margin-right: 8%;
    @media (max-width: 1700px){
      margin-right: 8% !important;
    }
  `,
  'Passivos Patrimoniais': css`
    margin-right: 71.6% !important;
    @media (max-width: 1700px){
        margin-right: 71% !important;
    }
    &.patrimony{
      margin-right: 70.2% !important;
      @media (max-width: 1700px){
        margin-left: 23.3% !important;
      }
    }
    margin-right: 68% ;`,
  'Passivos Financeiros': css`
    margin-right: 71.5% ;
    @media (max-width: 1700px){
        margin-right: 71% !important;
    }
    &.patrimony{
    margin-right: 72.2% ;
    @media (max-width: 1700px){
      margin-right: 71.3% !important;
    }
  }
    `,
  'Ativos Materiais': css`
    margin-right: -0.7% ;
    @media (max-width: 1700px){
      margin-right: 1.5% !important;
    }
  `,
  'Ativos Financeiros': css`
    margin-right: 15.5% ;
    @media (max-width: 1700px){
      margin-right: 14% !important;
    }
    `,
  'Passivos Não Patrimoniais': css`
    &.patrimony{

      margin-right: 70.5% ;
      @media (max-width: 1700px){
        margin-right: 66.5% !important;
    }
  }
  `,
};

export const DivTotalValue = styled.div.attrs({})
  `${(type) => {
    return [typeTotalValue[type.type.color], typeTotalValue[type.type.title]]
  }}`;


export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  table {
    width: 100%;
    text-align: left;
    border: none;
    border-spacing: 0 7px;
    border-collapse: separate;

    thead {
      ${props =>
    props.isCustomColor
      ? css`
              color: ${props.theme.white.default} !important;
            `
      : css`
              /* color: ${props.theme[props.color].default}; */
            `}
      font-size: 17px;
      transform: translateY(-5px);
      tr {
        line-height: 17px !important;
        th {
          padding: 20px;
          font-weight: normal;
          font-weight: 700;
          padding-bottom: 0;
          padding-top: 0;

          font-family: Nunito;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38 !important;
          letter-spacing: 0.15px;
          text-align: left;
          color: #808099;
          line-height: 12px;
          text-align: center;
          color: ${props => props.theme.grafit.hover};
          ${props =>
    props.isCustomColor
      ? css`
                  background: ${props.color} !important;
                `
      : css`
                /* background: ${props.theme[props.color].disabled}; */
              `}
        }
      }
      th:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        -webkit-border-top-left-radius: 10px;
        -webkit-border-bottom-left-radius: 10px;

        -moz-border-top-left-radius: 10px;
        -moz-border-bottom-left-radius: 10px;
      }

      th:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        -webkit-border-top-right-radius: 10px;
        -webkit-border-bottom-right-radius: 10px;

        -moz-border-top-right-radius: 10px;
        -moz-border-bottom-right-radius: 10px;
      }
    }

    tbody {
      color: #999999;
      font-size: 17px;

      tr {
        transition: 500ms ease;
      }

      td {
        text-align: center;
        align-items: center;
        padding-bottom: 12px;
        padding-top: 12px;
        transition: 200ms ease;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.38;
        letter-spacing: 0px;

        background: #fff;
        border-bottom: 1px solid ${props => props.theme.grafit.default};
        border-top: 1px solid ${props => props.theme.grafit.default};
        color: ${props => props.theme.grafit.hover};

        div.valueCurrency {
          display: flex;

          width: 9vw;
          justify-content: space-between;

          margin-left: auto;
          margin-right: auto;
          .currency {
            /*margin-left: 25%;*/
          }
          .value {
            /* margin-right: 25%;*/
          }
        }
        svg {
          color: #808099;
          padding-top: 7px;
        }
      }

      div.icons {
        display: flex;
        align-items: center;

        button {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 40px;
          height: 40px;

          background: ${props => props.theme.grafit.disabled};
          border-radius: 8px;

          & > svg {
            width: 25px;
            height: 25px;
            fill: ${props => props.theme.darker.disabled};
          }
        }

        & > button + button {
          margin-left: 6px;
        }
      }

      td:last-child {
        width: 1%;
        padding: 0;
        padding-right: 10px;
        text-align: right;
        font-size: ${props => (props.defaultFontSize ? 20 : 30)}px;

        svg {
          cursor: pointer;
          transition: 500ms ease;
        }

        svg:hover {
          fill: ${props => props.theme.info.default};
          transform: scale(1.2);
        }
      }

      td:first-child {

        width: 20vw;
        max-width: 20vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-left: 1px solid ${props => props.theme.grafit.default};
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        -webkit-border-top-left-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;

        -moz-border-top-left-radius: 5px;
        -moz-border-bottom-left-radius: 5px;
      }
      td:first-child + td {

        width: 20vw;
        max-width: 20vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

      }

      td:last-child {
        border-right: 1px solid ${props => props.theme.grafit.default};
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;

        -moz-border-top-right-radius: 5px;
        -moz-border-bottom-right-radius: 5px;
      }
    }
  }

  div.total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    font-weight: bold;
    padding: 30px ;
    padding-right: 5.6%;
    border-radius: 10px;

    ${props =>
    props.isCustomColor
      ? css`
            background: ${props.color} !important;
          `
      : css`
            /* background: ${props => props.theme[props.color].custom}; */
            background: #b3b3c24f !important;
          `}
    div.darker{

      margin-left: -0.8%;
      @media (max-width: 1700px){
        margin-left: -0.5% !important;
      }

    }
    div.investments{
      margin-left: -0.7%;
    }

    div.spendings{
      margin-left: 6%;
      @media (max-width: 1700px){
        margin-left: 5.5% !important;
      }
    }
    div.debts{
      margin-left: -0.6%;
    }

    div.labels {
      display: flex;
      flex-direction: column;
      line-height: 1;

      span {
        color: ${props => props.theme.darker.disabled};
        font-size: 20px;
        font-weight: 600;

        letter-spacing: 0px;
        text-align: left;
      }
      span.percent {
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 0px;
        text-align: left;

        ${props =>
    props.isCustomColor === false &&
    css`
            color: ${props => props.theme.grafit.hover};
          `}
      }
    }

    div.values {
      display: flex;
      flex-direction: column;


      span {
        color: ${props => props.theme.darker.disabled};
        font-size: 20px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0px;
        text-align: left;
      }
      span.percent {
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 0px;
        text-align: left;
        ${props =>
    props.isCustomColor === false &&
    css`
            color: ${props => props.theme[props.color].hover};
            color: ${props => props.theme.grafit.hover};
          `}
      }
    }
  }
`;
