import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { format, subMonths, addMonths } from 'date-fns';
import { pt } from 'date-fns/locale';
import api from 'services/api';
import { Wrapper, FormatValue, ContainerDate} from '../styles';
import capitalize from 'utils/capitalizeWords';
import ProgressBar from 'components/ProgressBar';
import Loading from 'components/Loading';
import CardProgress from '../CardProgress/index';
import {TableFlexibleMonth, ContainerBar } from './styles.js';

export default function FlexibleMonth({ icon, theme }) {
  const [flexMonth, setFlexMonth] = useState([]);
  const [items, setItems] = useState([]);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const { id } = useSelector(state => state.user.profile);
  const [estimated, setEstimated] = useState(0);
  const [accomplished, setAccomplished] = useState(0);

  const head = [
    'Item',
    'Categoria',
    'Estimado',
    'Realizado',
    '',
    'Saldo para gastar',
  ];

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await api.get(
        `/users/${id}/spending_classification_report`,
        {
          params: {
            classification: 'Mensal Flexível',
            date,
          },
        }
      );
      let totalEstimated = data.reduce((total, current) => total + current.value, 0)
      setEstimated(totalEstimated)
      let total =  data.reduce((total, current) => total + current.total, 0)
      setAccomplished(totalEstimated-total);
      setItems(data);
      setLoading(false);
    }
    fetchData();
  }, [date, id]);

  useEffect(() => {
    setFlexMonth(
      items.map(item => [
        item.name,
        item.category.name,
        {
          value: item.value.toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          currency: 'R$'
        },
        {
          value: item.total.toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          currency: 'R$'
        },

        <ContainerBar>
          <ProgressBar
            className="bar"
            color={item.value - item.total < 0 ? 'danger' : 'spendings'}
            fontSize="14px"
            left={handlePorcentageValue(item.total, item.value, true)}
            fill={handlePorcentageValue(item.total, item.value)}
          />
        </ContainerBar>,
        <FormatValue
          color="spendings"
          variant="default"
          isNegative={item.value - item.total < 0}
        >
          <div>
            <span>R$</span>
            <span>
            {Math.abs(item.value - item.total).toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
            </span>
          </div>
        </FormatValue>,
      ])
    );
  }, [items]);

  function handlePorcentageValue(entry, estimated, withPercentage) {
    let result = (entry / estimated) * 100;

    if (isNaN(result)) {
      result = 0;
    }

    if (withPercentage) {
      if (!isFinite(result)) {
        result = 100;
      }
      return `${result.toLocaleString('pt-br', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}%`;
    }

    return result;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Wrapper>
          <TableFlexibleMonth
            title="Mensal Flexível"
            className="flexibleMonth"
            color={theme.darker.hover}
            head={head}
            body={flexMonth}
            upperCenterContent={
              <ContainerDate color={theme.darker.default} key={Math.random()}>
                <button
                  onClick={() => setDate(date => subMonths(new Date(date), 1))}
                >
                  <AiOutlineLeft size={18} />
                </button>
                <div className="date">
                  <span>
                    {capitalize(format(date, 'MMMM, yyyy', { locale: pt }))}
                  </span>
                </div>
                <button
                  onClick={() => setDate(date => addMonths(new Date(date), 1))}
                >
                  <AiOutlineRight size={18} />
                </button>
              </ContainerDate>
            }
            upperRightContent={
              <CardProgress
                left={
                  estimated.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  })
                }
                right={
                  accomplished.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  })
                }
                fill={
                  (100-(100 * accomplished)/estimated)
                }
              />
            }
          />
        </Wrapper>
      )}
    </>
  );
}

FlexibleMonth.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]).isRequired,
  theme: PropTypes.shape({
    darker: PropTypes.shape({
      hover: PropTypes.string,
      default: PropTypes.string,
    }),
  }).isRequired,
};
