import React from 'react';
import PropTypes from 'prop-types';

import { AiOutlineWallet } from 'react-icons/ai';

import { Container, Info, Title, MainInfo, Icon } from './styles';

export default function InfoCard({

  color,
  CardIcon,
  title,
  mainInfo,
  onClick,
  shake,
  active,
  subInfo,
  noSubtitle,
  subtitle

}) {
  return (
    <Container
      className={`custom-card-info ${active ? 'active' : ''} ${shake && 'shake'}`}
      onClick={onClick}
      color={color}
    >
      <Icon color={color}>
        <CardIcon size={40} />
      </Icon>

      <Info color={color}>

        <Title color={color}>{title}</Title>

        <MainInfo withSubInfo={subInfo ? true : false} color={color}>
          {!noSubtitle &&
            <span>{subtitle ? subtitle : "Estimado"}</span>
          }
          <strong>{mainInfo}</strong>
          {subInfo &&
            <div className="subInfo">
              <span>Limite da fatura do cartão:</span>
              <strong>{subInfo}</strong>
            </div>
          }
        </MainInfo>
      </Info>
    </Container>
  );
}

InfoCard.propTypes = {
  color: PropTypes.string,
  CardIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  title: PropTypes.string,
  mainInfo: PropTypes.string,
  subInfo: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  noSubtitle: PropTypes.bool
};

InfoCard.defaultProps = {
  color: 'receipts',
  CardIcon: AiOutlineWallet,
  title: 'Recebimentos',
  mainInfo: 'R$ 12.000,00',
  onClick: () => { },
  active: false,
  noSubtitle: false
};