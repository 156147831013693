import React, { useState, useLayoutEffect } from 'react';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { differenceInCalendarMonths } from 'date-fns';
import {
  CardMotion,
  Header,
  DivHeader,
} from '../styles';

import Card from 'components/Cards';
import Button from 'components/Button';
import GoalDefault from './Components/Goals/'
export default function Goals({ goals, setGoals }) {
  const [matchMedia, setMatchMedia] = useState(false);

  function handleCardMotionNext() {
    setGoals([...goals.slice(1, goals.length), goals[0]]);
  }

  function handleCardMotionBefore() {
    setGoals([goals[goals.length - 1], ...goals.slice(0, goals.length - 1)]);
  }

  function handlePercentProgress(savedMoney, totalValue, withCurrency) {
    let percent = (savedMoney / totalValue) * 100;

    if (isNaN(percent)) {
      percent = 0;
    }

    if (percent >= 100) {
      percent = 100;
    }

    if (withCurrency) {
      percent = percent?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    }

    return percent;
  }

  function handleremaining(ifp, end, saved_money, value) {
    const months = differenceInCalendarMonths(new Date(end), new Date());
    const result = ifp * months;
    if (months === 0 || isNaN(months)) {
      return (value - saved_money)?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    return result?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  function handleSavedMoney(saved_money) {
    if (isNaN(saved_money)) {
      return 0;
    }

    return saved_money?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  useLayoutEffect(() => {
    if (window.matchMedia('(max-width: 1400px)').matches) {
      return setMatchMedia(true);
    }
    return setMatchMedia(false);
  }, [matchMedia]);

  return (
    <Card
      className="card-goals"
      width="860px"
      height="357px"
      transparent
      notPadding
    >
      <DivHeader>
        <Header y="0" x="0">
          Sonhos
        </Header>
        <Link to="goals/goalPanel" className="showAll">VER TODOS</Link>
      </DivHeader>
      {goals.length >= 1 && (
        <CardMotion>
          <div className="goals-group">
            {goals.length > 3 && (
              <Button className="back" onClick={() => handleCardMotionBefore()}>
                <div className="circle">
                  <MdKeyboardArrowLeft />
                </div>
              </Button>
            )}
            {goals.slice(0, 3).map((goal, index) => (
              <GoalDefault goal={goal}
                key={`goal-def-${index}`}
                handleremaining={handleremaining}
                handlePercentProgress={handlePercentProgress}
                handleSavedMoney={handleSavedMoney}
              />


            ))}
            {goals.length > 3 && (
              <Button className="next" onClick={() => handleCardMotionNext()}>
                <div className="circle">
                  <MdKeyboardArrowRight />
                </div>
              </Button>
            )}
          </div>
        </CardMotion>
      )}
    </Card>
  );
}

Goals.propTypes = {
  goals: PropTypes.arrayOf(PropTypes.object).isRequired,
  setGoals: PropTypes.func.isRequired,
};
