import React, { useContext, useMemo } from 'react';
import Card from 'components/Cards';
import { CardContent } from '../../../styles';
import Can from 'components/Can';
import { ThemeContext } from 'styled-components';
import DropdownButton from 'components/Button/Dropdown'
import ProgressBar from 'components/ProgressBar';
import { differenceInCalendarMonths } from 'date-fns';
import default_goal_f_i from 'assets/icon_investments.svg';
import default_goal from 'assets/default_goal.svg';


const DefaultGoal = ({ goal, handleSavedMoney, setShowDeleteModal, setSelectedData, showDeleteModal, setShowUpdateModal, setSelectedGoal, handleDoneGoal }) => {

    const theme = useContext(ThemeContext);

    const getUrl = useMemo(() => {
        if (goal?.file?.url) return goal?.file?.url
        if (goal?.type === 'financial-independece') return default_goal_f_i
        return default_goal
    }, [goal])

    return (
        <Card className="card-goal" key={goal.id}>
            <CardContent done={goal.done_at}>
                <div
                    className={
                        goal.done_at ? 'background-finished' : 'background'
                    }
                >
                    <img
                        src={getUrl}
                        alt="Background goal"
                        style={
                            goal.done_at
                                ? { borderRadius: '8px' }
                                : {
                                    'border-top-left-radius': '8px',
                                    'border-top-right-radius': '8px',
                                }
                        }
                    />

                    <Can canRoles={['planner', 'adm', 'cs', 'clientPfg']}>
                        <DropdownButton
                            className="dropdown-button"
                            color={theme.primary.default}
                        >
                            <button
                                onMouseDown={() => {
                                    setSelectedGoal(goal);
                                    setShowUpdateModal(true);
                                }}
                            >
                                Editar
                            </button>
                            <button onMouseDown={() => handleDoneGoal(goal)}>
                                {goal.done_at ? 'Desconcluir' : 'Realizar'}
                            </button>
                            <button
                                onMouseDown={() => {
                                    setShowDeleteModal(!showDeleteModal);
                                    setSelectedData(goal.id);
                                }}
                            >
                                Excluir
                            </button>
                        </DropdownButton>
                    </Can>
                </div>
                <div className="content">
                    <div className="top-info">
                        {goal.done_at ? (
                            <span className="title-finished">{goal.name}</span>
                        ) : (
                            <span className="title">{goal.name}</span>
                        )}

                        <span className="value">
                            {goal.done_at
                                ? ''
                                : goal.value
                                    ? goal?.value?.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })
                                    : ''}
                        </span>
                        <small>
                            {goal.done_at
                                ? ''
                                : goal.end &&
                                    differenceInCalendarMonths(
                                        new Date(goal.end),
                                        new Date()
                                    ) >= 0
                                    ? `${differenceInCalendarMonths(
                                        new Date(goal.end),
                                        new Date()
                                    )} meses`
                                    : `Há ${differenceInCalendarMonths(
                                        new Date(),
                                        new Date(goal.end)
                                    )} meses atrás`}
                        </small>
                    </div>

                    {goal.value ? (
                        <ProgressBar
                            fill={
                                goal.done_at
                                    ? 100
                                    : handleSavedMoney(goal.saved_money, goal.value)
                            }
                            color={goal.done_at ? 'primary' : 'investments'}
                            left={
                                goal.done_at ? (
                                    <span className="goal-done-text-left">
                                        Realizado!
                                    </span>
                                ) : (
                                    'Total investido'
                                )
                            }
                            right={
                                goal.done_at
                                    ? ''
                                    : goal?.saved_money?.toLocaleString('pt-br', {
                                        style: 'currency',
                                        currency: 'BRL',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })
                            }
                            fontSize={12}
                        />
                    ) : null}
                </div>
            </CardContent>
        </Card>
    )
}
export default DefaultGoal;