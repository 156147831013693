import React, { useState, useEffect } from 'react';
import newApi from 'services/newApi';
import { toast } from 'react-toastify';
import getObjectDiff from 'utils/getObjectDiff';
import { loadInitialStateRequest } from 'store/modules/suitability/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Menu,
  Title,
  WelcomeContainer,
  StepperContainer,
  Steps,
  Step,
  PerguntasContainer,
  ButtonsStepsContainer,
  Pergunta,
  RespostasContainer,
  Resposta,
  EnviandoDados,
} from './styles';
import Button from 'components/Button';
import Loading from 'components/Loading';

export default function Suitability() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.profile);

  const [currentStep, setCurrentStep] = useState(null);
  const [totalAnsweredSteps, setTotalAnsweredSteps] = useState(null);
  const [showWelcome, setShowWelcome] = useState(true);

  const [perfilUsuario, setPerfilUsuario] = useState(null);
  const [isAnswering, setIsAnswering] = useState(false);
  const [startShowingPerfil, setStartShowingPerfil] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [steps, setSteps] = useState([]);
  const [originalSteps, setOriginalSteps] = useState([]);
  const { loading, perguntas, descricao, perfil } = useSelector(
    state => state.suitability
  );

  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const perguntasConvertidas = convertObjectPergunta(perguntas);

    setSteps(perguntasConvertidas);
    setOriginalSteps(perguntasConvertidas);
    setIsLoading(loading);

    if (descricao !== null && perfil !== null) {
      setPerfilUsuario({
        perfil,
        descricao,
      });
      setStartShowingPerfil(true);
      setIsAnswering(false);
    } else if (perguntasConvertidas[0]?.computado?.respondido) {
      setShowWelcome(false);
      setIsAnswering(true);
    }

    // eslint-disable-next-line
  }, [loading, perguntas]);

  const convertObjectPergunta = obj => {
    const perguntasConvertidas = obj.map(pergunta => {
      const indice = pergunta.respostas.findIndex(resposta => {
        return resposta.respondido === true;
      });

      const respondido = {
        ...pergunta,
        computado: {
          index: indice,
          respondido: indice >= 0,
        },
      };

      return respondido;
    });

    return perguntasConvertidas;
  };

  useEffect(() => {
    updateStepsAnswering();

    // eslint-disable-next-line
  }, [steps]);

  const updateStepsAnswering = () => {
    if (steps.length === 0) return;

    // Get first question not answered
    let firstQuestionNotAnswered = steps.findIndex(
      pergunta => !pergunta.computado.respondido
    );

    // If all the questions were answered
    if (firstQuestionNotAnswered < 0) firstQuestionNotAnswered = steps.length;

    setTotalAnsweredSteps(firstQuestionNotAnswered);
  };

  const startAnswering = () => {
    // Get first question not answered
    let firstQuestionNotAnswered = steps.findIndex(
      pergunta => !pergunta.computado.respondido
    );

    // If all the questions were answered
    if (firstQuestionNotAnswered < 0)
      firstQuestionNotAnswered = steps.length - 1;

    setTotalAnsweredSteps(firstQuestionNotAnswered);

    setCurrentStep(firstQuestionNotAnswered);

    setTimeout(() => {
      setShowWelcome(false);
      setIsAnswering(true);
    }, 300);
  };

  const getStepClassName = index => {
    if (index < currentStep) {
      return 'prev';
    } else if (index === currentStep && index === steps.length - 1) {
      return 'current-last';
    } else if (index === currentStep && currentStep < totalAnsweredSteps) {
      return 'current-color';
    } else if (index === currentStep) {
      return 'current';
    } else if (
      totalAnsweredSteps === steps.length &&
      index === steps.length - 1
    ) {
      return 'after-color-last-off';
    } else if (index > currentStep && index < totalAnsweredSteps) {
      return 'after-color';
    } else if (
      index > currentStep &&
      index === totalAnsweredSteps &&
      index === steps.length - 1
    ) {
      return 'after-color-last-off';
    } else if (index > currentStep && index === totalAnsweredSteps) {
      return 'after-color-last';
    } else if (index > currentStep && index < steps.length - 1) {
      return 'after';
    } else {
      return 'last';
    }
  };

  const updateStep = direction => {
    if (direction === 'decrease' && currentStep > 0) {
      setCurrentStep(() => currentStep - 1);
    }

    if (direction === 'increase' && currentStep < steps.length - 1) {
      setCurrentStep(() => currentStep + 1);
    }
  };

  const getSteps = distance => {
    const stepsRendered = steps.map((step, index) => {
      return (
        <Step
          className={getStepClassName(index)}
          key={`step-${step.id}`}
          distance={distance}
        >
          {index + 1}
        </Step>
      );
    });

    return stepsRendered;
  };

  const answerAll = () => {
    const difs = getObjectDiff(steps, originalSteps);

    if (difs.length === 0) {
      toast.error(
        'Não houve alteração nas respostas e não há mundanças para serem enviadas.'
      );
      return;
    }

    const respostas = steps.map(resposta => {
      const id = resposta.id;

      const indexResposta = resposta.respostas.findIndex(
        resp => resp.respondido === true
      );

      const resp = resposta.respostas[indexResposta].id;

      return {
        id_pergunta: id,
        id_resposta: resp,
      };
    });

    setIsUpdating(true);
    async function fetchData() {
      try {
        await newApi.post(`estrategia/${user.id}/listasuitability`, respostas);

        if (user.type === 'client') {
          setIsUpdating(false);
          dispatch(loadInitialStateRequest(user.id));
        }
        toast.success('Respostas gravadas com sucesso!');
      } catch (error) {
        toast.error('Houve um erro ao tentar gravar suas respostas.');
        setIsUpdating(false);
      }
    }
    fetchData();
  };

  const responderStep = resposta => {
    const newStateStep = steps.map(pergunta => {
      if (pergunta.id === steps[currentStep].id) {
        const newResposta = pergunta.respostas.map(p => {
          return { ...p, respondido: p.id === resposta ? true : false };
        });
        const updatedPergunta = {
          ...pergunta,
          computado: {
            ...pergunta.computado,
            respondido: true,
          },
          respostas: newResposta,
        };

        return updatedPergunta;
      }

      return pergunta;
    });

    setSteps(newStateStep);
  };

  const getRespostas = () => {
    const currentRespostas = steps[currentStep].respostas;

    const respostas = currentRespostas.map(resposta => {
      let _className = 'on';

      if (resposta.respondido === true) {
        _className = 'on active';
      }

      return (
        <Resposta
          onClick={() => responderStep(resposta.id)}
          key={`resposta-${resposta.id}`}
          className={_className}
        >
          {resposta.descricao}
        </Resposta>
      );
    });

    return respostas;
  };

  const checkNextDisabled = () => {
    if (currentStep < totalAnsweredSteps) {
      return false;
    }
    return true;
  };

  const getLabelPrev = () => {
    return 'Voltar';
  };

  const getLastStepActive = () => {
    return (
      currentStep === steps.length - 1 && totalAnsweredSteps === steps.length
    );
  };

  const getLabelNext = () => {
    if (getLastStepActive()) {
      return 'Finalizar';
    }
    return 'Continuar';
  };

  const restartAnswering = () => {
    setStartShowingPerfil(false);
    setTimeout(() => {
      setCurrentStep(0);
      setIsAnswering(true);
    }, 300);
  };

  return (
    <Container>
      <Menu>
        <Title>Suitability</Title>
      </Menu>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {perfilUsuario === null && showWelcome && (
            <WelcomeContainer className={currentStep === null ? 'on' : 'off'}>
              <h3>Bem-vindo(a) ao Questionário de Suitability!</h3>
              <p>
                Visando cumprir as exigências regulatórias, a Yuno ON possui um
                procedimento de Suitability para definir o perfil de
                investimento de seus clientes. Para continuar realizando suas
                operações, precisamos que preencha o questionário a seguir
              </p>
              <p className="destaque">
                É necessário responder todas as perguntas para que seu perfil
                seja gerado e os dados computados.
              </p>
              <Button disabled={currentStep !== null} onClick={startAnswering}>
                Começar
              </Button>
            </WelcomeContainer>
          )}
          {perfilUsuario !== null && !isAnswering && (
            <WelcomeContainer className={startShowingPerfil ? 'on' : 'off'}>
              <h3>O seu perfil é {perfilUsuario.perfil}</h3>
              <p>{perfilUsuario.descricao}</p>
              <Button onClick={restartAnswering}>Atualizar perfil</Button>
            </WelcomeContainer>
          )}
          {isAnswering && (
            <>
              {isUpdating && (
                <EnviandoDados>
                  <Loading />
                  <h2>Enviando respostas...</h2>
                </EnviandoDados>
              )}
              {!isUpdating && (
                <>
                  <StepperContainer>
                    <Steps gap={50}>
                      {currentStep !== null && getSteps(50)}
                    </Steps>
                  </StepperContainer>
                  <PerguntasContainer className="on">
                    <Pergunta>{steps[currentStep]?.descricao}</Pergunta>
                    <RespostasContainer className="on">
                      {currentStep !== null && getRespostas()}
                    </RespostasContainer>
                    <ButtonsStepsContainer>
                      <Button
                        disabled={currentStep === 0}
                        onClick={() => updateStep('decrease')}
                      >
                        {getLabelPrev()}
                      </Button>
                      <Button
                        color={getLastStepActive() ? 'success' : 'info'}
                        disabled={checkNextDisabled()}
                        onClick={() =>
                          getLastStepActive()
                            ? answerAll()
                            : updateStep('increase')
                        }
                      >
                        {getLabelNext()}
                      </Button>
                    </ButtonsStepsContainer>
                  </PerguntasContainer>
                </>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}
