import React from 'react';
import { Container,
        ContainerColumn,
         AssinaturaDiretor,
         PurpleBar,
         ContentWrapper,
         LogoAzul,
         Footer} from './styles';

export default function DirectorCustomerSuccess({data}) {

  const { footerTitle } = data;

  return (
      <ContentWrapper id="printable" style={{minHeight: '1684px'}}>
        <Container style={{height: '100%'}} padding="0 78px 0px 40px">
          <div className="d-40w">
            <PurpleBar />
          </div>
          <div className="d-60w">
            <ContainerColumn padding="64px 0px 0px 0px">
              <p className="p-smaller">Ninguém planeja falhar, mas muitos falham<br />
                                  por não se planejar. Por esse motivo estamos<br />
                                  te entregando esse plano claro, o SEU plano de<br />
                                  sucesso, para que você possa realizar seus<br />
                                  sonhos e objetivos.<br /><br />
                                  Mas lembre-se: Este é apenas o primeiro<br />
                                  passo! Nós estamos apenas começando.<br />
                                  Agora é hora de aprender o que é necessário<br />
                                  para executar corretamente e de forma<br />
                                  contínua tudo que aqui está.<br /><br />
                                  Um plano sem execução é um pedaço de<br />
                                  papel inútil, mas um plano associado à ação e<br />
                                  constância é a chave para mudar o seu futuro!<br /><br />
                                  Com Carinho,</p>
              <AssinaturaDiretor />
              <p style={{marginTop: "-26px", zIndex: 1}} className="p-smaller"><b>Ana Carolina Albernaz</b><br />Diretora de Customer Success</p>
            </ContainerColumn>
          </div>
        </Container>
        <Footer>
          <Container padding="0 78px">
              <p>{ footerTitle }</p>
              <LogoAzul />
          </Container>
        </Footer>
      </ContentWrapper>
  );
}
