import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: .5s ${fadeIn} forwards;

  svg {

    width: 230px !important;
    height: 230px !important;
    font-size: 250px;
  }
`;
