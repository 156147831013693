import styled from 'styled-components';

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  max-height: 660px;
  overflow-y: auto;
  

`;

export const Form = styled.form`
  width: 100%;
  height: auto;
  overflow: auto;
  padding-right: 20px;

  div.custom__container{
    margin-top: ${props => '0px'};
  }
  .frVONL div.custom__container {
    margin-top: 0px;
  }

  & > div + div {
    margin-top: 10px;
  }

  div.buttons {
    transform:translateY(-3px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    button{
      margin-left:7%;
    }
    & > button + button {
      margin-left: 5%;
    }
  }

  div.radio-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
    width: 50%;
    color: ${props => props.theme.grafit.hover};

    & > div {
      display: flex;
      align-items: center;
      & > input {
        margin-left: 5px;
        margin-bottom: 8px;
      }
    }
  }
`;

export const SubTitle = styled.h3`
  max-width: 432px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0px;
  text-align: left;

  > span {
    margin-left: 5px;

    color: ${props => props.theme[props.color].default};
  }
`;

export const DivGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  div.recurrent-value{
    width:100%;
    .date{
    width:100%;
    }
  }
  
`;

export const DivButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;

  button + button {
    margin-left: 25px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    border-radius: 10px;
    background: none;

    transition: 200ms ease;

    svg {
      transition: 200ms ease;
      width: 24px;
      height: 24px;
    }

    
    &.receipts {
      background: ${props => props.theme.receipts.disabled};
      svg {
        color: ${props => props.theme.receipts.default};
      }
    }

    &.spendings {
      background: ${props => props.theme.spendings.disabled};
      svg {
        color: ${props => props.theme.spendings.default};
      }
    }

    &.debts {
      background: ${props => props.theme.debts.disabled};
      svg {
        color: ${props => props.theme.debts.default};
      }
    }

    &.investments {
      background: ${props => props.theme.investments.disabled};
      svg {
        color: ${props => props.theme.investments.default};
      }
    }

    &.select {
      background: ${props => props.theme[props.typeEntry].default};

      svg {
        color: ${props => props.theme.white.default};
      }
    }

    &:hover {
      svg {
        transform: scale(1.3);
      }
    }
  }
`;
