import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  width: 100%;
  height: 100%;

  color: ${props => props.theme[props.color].default};

  svg {
    width: 35%;
    height: 35%;
  }
`;
