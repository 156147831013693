import React, { useState, useContext } from 'react';

import {
  AiOutlineWallet,
  AiOutlineShoppingCart,
  AiOutlineCreditCard,
} from 'react-icons/ai';
import { BsGraphUp } from 'react-icons/bs'

import { ContentModal, DivButtons, SubTitle } from './styles';

import ContextModal from '../ContextModal';

import Modal from 'components/Modal';

import SpendingsAndReceipts from './SpendingsAndReceipts';
import Installments from './Installments';
import Investments from './investments';
export default function ModalLaunch() {
  const [typeEntry, setTypeEntry] = useState('spendings');
  const { setShowModal } = useContext(ContextModal);
  const launchOptions = {
    receipts: 'Recebimento',
    spendings: 'Gasto',
    debts: 'Parcelado do cartão',
    investments: 'Investimento'
  }
  return (
    <Modal
      title="Novo Lançamento"
      color={typeEntry}
      setShowModal={setShowModal}
    >
      <ContentModal>
        <DivButtons typeEntry={typeEntry}>
          <button
            className={`receipts ${typeEntry === 'receipts' && 'select'}`}
            onClick={() => setTypeEntry('receipts')}
          >
            <AiOutlineWallet />
          </button>
          <button
            className={`spendings ${typeEntry === 'spendings' && 'select'}`}
            onClick={() => setTypeEntry('spendings')}
          >
            <AiOutlineShoppingCart />
          </button>
          <button
            className={`debts ${typeEntry === 'debts' && 'select'}`}
            onClick={() => setTypeEntry('debts')}
          >
            <AiOutlineCreditCard />
          </button>
          <button
            className={`investments ${typeEntry === 'investments' && 'select'}`}
            onClick={() => setTypeEntry('investments')}
          >
            <BsGraphUp />
          </button>
        </DivButtons>
        <SubTitle color={typeEntry}>
          Coloque os dados do seu
          <span>
            {launchOptions[typeEntry]}
          </span>
        </SubTitle>
        {(typeEntry === 'spendings' || typeEntry === 'receipts') && (
          <SpendingsAndReceipts typeEntry={typeEntry} />
        )}
        {typeEntry === 'debts' && <Installments />}
        {typeEntry === 'investments' && <Investments />}
      </ContentModal>
    </Modal>
  );
}
