import React, { useState, useContext, useEffect } from 'react';
import {
  ContainerScroll,
  CardsExtract,
  ContentCard,
  Item,
  ButtonDropdown,
  DivDropdown,
  ContentIcon,
  SavedMoney,
} from './styles';
import ProgressBar from './ProgressBar';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { MdCreditCard } from 'react-icons/md';
import ExtractTable from './ExtractTable/index';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';
import { GiLifeBar, GiHomeGarage } from 'react-icons/gi';
import { RiWallet2Line } from 'react-icons/ri';
import { FaPizzaSlice, FaVolleyballBall, FaTaxi } from 'react-icons/fa';
import { startOfMonth, lastDayOfMonth } from 'date-fns';
import api from 'services/api';
import { Context } from '../Context';
import Category from '../Empty/Category';
import { calcSpendingsBar } from 'utils/graphSpendings';
import { duplicateStatementItems } from 'utils/duplicateStatementItems';

export const Icon = ({ classification }) => {
  switch (classification) {
    case 'Pessoal':
      return <BsFillPersonFill size={28} />;
    case 'Dependente':
      return <AiOutlineUsergroupAdd size={28} />;
    case 'Alimentação Fora de Casa':
      return <FaPizzaSlice size={28} />;
    case 'Lazer':
      return <FaVolleyballBall size={28} />;
    case 'Vida e Saúde':
      return <GiLifeBar size={28} />;
    case 'Moradia':
      return <GiHomeGarage size={28} />;
    case 'Transporte':
      return <FaTaxi size={28} />;
    case 'Recebimento':
      return <RiWallet2Line size={28} />;
    default:
      return <MdCreditCard size={28} />;
  }
};

export default function GeneralExtract({
  color,
  categories,
  isLoad,
  setIsLoad,
  reloadItem,
  setReloadItem,
  reloadCategory,
  setReloadCategory,
}) {
  const [showDivDropdown, setShowDivDropdown] = useState(false);
  const [idActiveCategory, setIdActiveCategory] = useState('');
  const [showDropLine, setShowDropLine] = useState(false);
  const [idActiveItem, setIdActiveItem] = useState('');
  const [items, setItems] = useState([]);

  const {
    id,
    entries,
    setModalUpdate,
    setEntries,
    dateStart,
    dateEnd,
    toggleButton,
  } = useContext(Context);

  useEffect(() => {
    if (idActiveCategory && idActiveItem) {
      showItems(idActiveCategory);
      showEntries(idActiveItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd]);

  async function showItems(category_id) {
    setIsLoad(true);
    const start = startOfMonth(dateStart);
    const end = lastDayOfMonth(dateEnd);
    let type = color === 'debts' ? 'installments' : 'entries';

    try {
      const { data: params } = await api.get(
        `users/${id}/${category_id}/calc_parameters_items`,
        {
          params: {
            start,
            end,
            type: type,
          },
        }
      );

      setItems(params);
      return setIsLoad(false);
    } catch (error) { }
  }

  async function showEntries(item_id) {
    setIsLoad(true);

    const start = startOfMonth(dateStart);
    const end = lastDayOfMonth(dateEnd);
    if (toggleButton === 'spendings') {
      try {
        const { data: spendings } = await api.get(
          `users/${id}/${item_id}/spendings`,
          {
            params: {
              start,
              end,
            },
          }
        );
        const result = await duplicateStatementItems(start, end, spendings);
        setEntries(result);
        setIsLoad(false);
      } catch (error) { }
    }
    if (toggleButton === 'receipts') {
      try {
        const { data: entriesApi } = await api.get(
          `users/${id}/${item_id}/entries`,
          {
            params: {
              start,
              end,
            },
          }
        );
        const result = await duplicateStatementItems(start, end, entriesApi);
        setEntries(result);
        setIsLoad(false);
      } catch (error) { }
    }
  }

  useEffect(() => {
    async function load() {
      await showItems(idActiveCategory);
      await showEntries(idActiveItem);
      setIsLoad(false);
      setReloadItem(false);
    }
    if (idActiveCategory || idActiveItem || reloadItem) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadItem, dateStart, dateStart]);

  return (
    <ContainerScroll>
      <CardsExtract width={'100%'} height={'100%'}>
        {categories.length < 1 ? (
          <Category />
        ) : (
          <ContentCard>
            {categories?.map(category => (
              <React.Fragment key={category.id}>
                <Item>
                  <div className="progress">
                    <div className="details">
                      <div className="icon-name">
                        <ContentIcon className="icon" color={color}>
                          <Icon classification={category.classification}></Icon>
                        </ContentIcon>
                        <span className="name">{category.name}</span>
                      </div>
                      <span className="value">
                        {false ? (
                          <span className="done-at">Concluído</span>
                        ) : (
                          <>
                            <SavedMoney
                              color={
                                category.parcel + category.accomplished >
                                  category.estimated &&
                                  toggleButton === 'spendings'
                                  ? 'debts'
                                  : color
                              }
                            >
                              {!category.accomplished && !category.parcel
                                ? 'R$ 0'
                                : (
                                  category.parcel + category.accomplished
                                )?.toLocaleString('pt-br', {
                                  style: 'currency',
                                  currency: 'BRL',
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                            </SavedMoney>
                            <span className="total">
                              {` de ${category.estimated?.toLocaleString(
                                'pt-br',
                                {
                                  style: 'currency',
                                  currency: 'BRL',
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                }
                              )}`}
                            </span>
                          </>
                        )}
                      </span>
                    </div>
                    <div className="progress-buttom">
                      <ProgressBar
                        valueAccomplished={category.accomplished?.toLocaleString(
                          'pt-br',
                          {
                            style: 'currency',
                            currency: 'BRL',
                          }
                        )}
                        valueInstallments={category.parcel?.toLocaleString(
                          'pt-br',
                          {
                            style: 'currency',
                            currency: 'BRL',
                          }
                        )}
                        valueEstimated={category.estimated?.toLocaleString(
                          'pt-br',
                          {
                            style: 'currency',
                            currency: 'BRL',
                          }
                        )}
                        installments={
                          calcSpendingsBar(
                            category.estimated,
                            category.accomplished,
                            category.parcel
                          ).percentInstallment
                        }
                        accomplished={
                          calcSpendingsBar(
                            category.estimated,
                            category.accomplished,
                            category.parcel
                          ).percentAccomplished
                        }
                        surplus={(
                          category.accomplished +
                          category.parcel -
                          category.estimated
                        )?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                        color={color}
                        height="2.3vw"
                      />

                      <ButtonDropdown
                        active={
                          showDivDropdown && idActiveCategory === category.id
                        }
                        onClick={() => {
                          if (idActiveCategory === category.id) {
                            setShowDivDropdown(false);
                            setItems([]);
                          } else {
                            setShowDivDropdown(true);

                            showItems(category.id);
                            return setIdActiveCategory(category.id);
                          }
                          setIdActiveCategory('');
                        }}
                      >
                        {showDivDropdown && idActiveCategory === category.id ? (
                          <AiOutlineUp />
                        ) : (
                          <AiOutlineDown />
                        )}
                      </ButtonDropdown>
                    </div>
                  </div>
                </Item>

                <DivDropdown
                  show={showDivDropdown && idActiveCategory === category.id}
                  color={color}
                >
                  {items &&
                    items?.map(item => (
                      <React.Fragment key={item.id}>
                        <div className="header">
                          <span>{item.name}</span>
                          <div>
                            <SavedMoney
                              color={
                                item.accomplished + item.parcel >
                                  item.estimated && toggleButton === 'spendings'
                                  ? 'debts'
                                  : item.accomplished > item.estimated &&
                                    toggleButton === !'spendings'
                                    ? 'investments'
                                    : color
                              }
                              className="accomplished"
                            >
                              {!item.accomplished && !item.parcel
                                ? 'R$ 0'
                                : (
                                  item.accomplished + item.parcel
                                )?.toLocaleString('pt-br', {
                                  style: 'currency',
                                  currency: 'BRL',
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                            </SavedMoney>
                            <span>
                              de{' '}
                              {item.estimated?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              })}
                            </span>
                          </div>
                        </div>
                        <div className="container-drop-table">
                          <div className="drop-line">
                            <ProgressBar
                              className={
                                toggleButton === 'receipts' ? 'darker' : ''
                              }
                              valueAccomplished={item.accomplished?.toLocaleString(
                                'pt-br',
                                {
                                  style: 'currency',
                                  currency: 'BRL',
                                }
                              )}
                              valueInstallments={item.parcel?.toLocaleString(
                                'pt-br',
                                {
                                  style: 'currency',
                                  currency: 'BRL',
                                }
                              )}
                              valueEstimated={item.estimated?.toLocaleString(
                                'pt-br',
                                {
                                  style: 'currency',
                                  currency: 'BRL',
                                }
                              )}
                              installments={
                                calcSpendingsBar(
                                  item.estimated,
                                  item.accomplished,
                                  item.parcel
                                ).percentInstallment
                              }
                              accomplished={
                                calcSpendingsBar(
                                  item.estimated,
                                  item.accomplished,
                                  item.parcel
                                ).percentAccomplished
                              }
                              surplus={(
                                item.accomplished +
                                item.parcel -
                                item.estimated
                              )?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                              height="2vw"
                              color={color}
                            // secundLabel="R$ 200,00"
                            />

                            <ButtonDropdown
                              active={showDropLine && idActiveItem === item.id}
                              onClick={() => {
                                if (idActiveItem === item.id) {
                                  setShowDropLine(false);
                                } else {
                                  setShowDropLine(true);
                                  showEntries(item.id);

                                  return setIdActiveItem(item.id);
                                }
                                setIdActiveItem('');
                                if (showDropLine && idActiveItem === item.id) {
                                  setShowDropLine(false);
                                }
                              }}
                            >
                              {showDropLine && idActiveItem === item.id ? (
                                <AiOutlineUp />
                              ) : (
                                <AiOutlineDown />
                              )}
                            </ButtonDropdown>
                          </div>
                          {isLoad ? (
                            <></>
                          ) : (
                            <ExtractTable
                              show={showDropLine && idActiveItem === item.id}
                              entries={entries}
                              setModalUpdate={setModalUpdate}
                              setEntries={setEntries}
                              idCategory={category.id}
                              reloadItem={reloadItem}
                              setReloadItem={setReloadItem}
                              reloadCategory={reloadCategory}
                              setReloadCategory={setReloadCategory}
                            />
                          )}
                        </div>
                      </React.Fragment>
                    ))}
                </DivDropdown>
              </React.Fragment>
            ))}
          </ContentCard>
        )}
      </CardsExtract>
    </ContainerScroll>
  );
}
