import styled, { keyframes, css } from 'styled-components';
import Cards from 'components/Cards/index';

export const ContainerScroll = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 102%;
  max-height: 100%;
`;

export const CardsExtract = styled(Cards)`
  display: grid;
  border: solid 1px ${props => props.theme.grafit.hover};
  box-shadow: unset !important;
  width: 99%;
  min-height: 100%;
  height: auto;
`;

const showDivDropdown = keyframes`
  0% {
    opacity: 0;
    max-height: 0;
  }
  70% {
    max-height: 100vh;
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  width: 99%;
  margin-bottom: 26px;

  div.labels {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      font-size: 1.3vw;
      font-weight: bold;
      line-height: 1.33;
      color: ${props => props.theme.grafit.hover};
    }

    & > span + span {
      margin-top: 32px;
    }
  }

  div.buttons {
    display: flex;
    align-items: center;

    & > button + button {
      margin-left: 12px;
    }
  }
`;

export const Title = styled.span`
  font-size: 1.3vw;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: ${props => props.theme.grafit.hover};
`;

export const ContentCard = styled.div`
  display: flex;
  flex-direction: column;

  & > div + div {
    margin-top: 1vw;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  img {
    width: 92px;
    height: 92px;
    backdrop-filter: blur(30px);
    border-radius: 8px;
  }

  div.progress {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 6vw;
    background: unset !important;
    .progress-buttom {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .bar {
        border-radius: 0.8vw !important;
      }
    }

    div.details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      div.icon-name {
        display: flex;
        align-items: center;
      }

      span.name {
        font-size: 1.3vw;
        font-weight: bold;
        line-height: 1.33;
        text-align: left;
        color: ${props => props.theme.darker.disabled};
      }

      span.value {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.33;
        letter-spacing: 0px;
        color: ${props => props.theme.grafit.default};

        span.total {
          font-size: 0.65vw;
        }

        span.done-at {
          font-size: 20px;
          font-weight: bold;
          line-height: 1.35;
          letter-spacing: 0px;
          color: ${props => props.theme.investments.default};
        }
      }
    }
  }
`;
export const SavedMoney = styled.span`
  font-size: 1vw;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0px;
  color: ${props => props.theme[props.color].default};
  &.accomplished {
    font-weight: 800 !important;
    font-size: 0.9vw !important;
    color: ${props => props.theme[props.color]?.default} !important;
    margin-right: 5px;
  }
`;

export const ContentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${props => props.theme[props.color].default};
  border-radius: 8px;
  width: 4vh;
  height: 4vh;
  padding: 3%;
  margin-right: 0.5vw;
  background: ${props => props.theme[props.color].disabled};
  svg {
    fill: ${props => props.theme[props.color].default};
  }
`;

export const ButtonDropdown = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5vw;
  height: 1.5vw;
  transition: 200ms ease;
  background: ${props =>
    props.active ? props.theme.info.default : props.theme.grafit.disabled};
  margin-left: 0.4vw;
  border-radius: 0.5vw;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.16);

  svg {
    fill: ${props =>
      props.active ? props.theme.white.default : props.theme.grafit.hover};
    width: 0.8vw;
    height: 0.8vw;
  }
`;

export const DivDropdown = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  ${props =>
    props.show &&
    css`
      animation: ${showDivDropdown} forwards 3000ms;
    `};
  & > div + div {
    margin-top: 10px;
  }
  div.header {
    display: flex;
    width: 96%;
    justify-content: space-between;

    & span {
      font-size: 0.7vw;
      font-weight: 600;
      line-height: 1.38;
      letter-spacing: 0px;
      color: ${props => props.theme.grafit.hover};
    }
  }

  div.drop-line {
    display: flex;
    align-items: center;
  }
  div.container-drop-table {
    display: flex;
    height: auto;
    width: 100%;
    flex-direction: column;
  }
`;
