import styled from 'styled-components';
import DatePicker from 'react-datepicker';


export const DatePickerCustom = styled(DatePicker)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 1.1vh;
  border: solid 1px ${props => props.theme.grafit.hover};
  .indicator{
    font-size: 1vh !important;
  }
  
  span{
    font-family: Nunito;
    /* font-size: 20px; */
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: 0.26px;
    color: ${props => props.theme.grafit.hover};
    }
    
    svg{
        fill:${props => props.theme.grafit.hover} ; 
    }

`;