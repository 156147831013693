import React, { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';

import { AiOutlineFileSearch } from 'react-icons/ai';
import { FiSearch } from 'react-icons/fi';
import { toast } from 'react-toastify';

import { Container, Form, DivTitle, Asset } from './styles';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';
import Loading from 'components/Loading';

import api from 'services/api';

export default function ModalSearchAssets({ close }) {
  const inputRef = useRef(null);

  const [nameAsset, setNameAsset] = useState('');
  const [findedAssets, setFindedAssets] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  async function handleSubmit(e) {
    try {
      setLoading(true);
      e.preventDefault();
      const { data } = await api.get(`/assets/${nameAsset}`);

      setFindedAssets(data);
    } catch (err) {
      toast.error('Falha ao buscar ativo');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }
  return (
    <Modal
      title="Pesquisar por ativos"
      color="info"
      width="700"
      setShowModal={close}
      icon={<AiOutlineFileSearch />}
    >
      <Container>
        <Form onSubmit={handleSubmit}>
          <Input
            ref={inputRef}
            label="Digite pelo menos 3 dígitos do ativo"
            value={nameAsset}
            onChange={e => setNameAsset(e.target.value.toUpperCase())}
          />
          {nameAsset.length >= 3 && (
            <Button color="info">
              <FiSearch />
              Procurar
            </Button>
          )}
        </Form>
        {loading ? (
          <Loading />
        ) : (
          <>
            {findedAssets.length > 0 && (
              <>
                <DivTitle>
                  <span>Simbolo</span>
                  <span>Nome</span>
                  <span>Região</span>
                </DivTitle>
                {findedAssets.map(asset => {
                  return (
                    <Asset>
                      <span>{asset.symbol}</span>
                      <span>{asset.name}</span>
                      <span>{asset.region}</span>
                    </Asset>
                  );
                })}
              </>
            )}
          </>
        )}
      </Container>
    </Modal>
  );
}

ModalSearchAssets.propTypes = {
  close: PropTypes.func.isRequired,
};
