import styled from 'styled-components';

export const DivText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 16px;
  margin-top: 4px;


  & > p{
    font-family: Nunito;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0px;
    text-align: left;
    color: #808099;
  }
`;
