import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  transform: translateY(-10px);
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: 24px;

  width: 95%;
`;



export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.darker.disabled};
`;

export const Cards = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > div + div {
    margin-left: 20px;
  }

  div.card-menu {
    padding: 0 !important;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: 1900px) {
    width: 100%;

    div.card-menu {
      width: 100%;
    }
  }
`;

export const EnviandoDados = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: center;
  gap: 20px;
  width: 600px;
  opacity: 0;
  margin: 0 auto;
  height: 400px;
  animation: 0.5s ease ${fadeIn} forwards .3s;

  h2 {
    position: absolute;
    color: #3ba7ff;
    top: 300px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
    white-space: nowrap;
  }
`

export const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: center;
  gap: 20px;
  width: 600px;
  opacity: 0;
  margin: 0 auto;
  margin-top: 100px;
  border: solid 2px #3ba7ff;
  padding: 30px;
  border-radius: 14px;

  .destaque {
    width: 70%;
    text-align: center;
    color: ${props => props.theme.danger.hover};
  }

  &.on {
    animation: 0.5s ease ${fadeIn} forwards .3s;
  }

  &.off {
    animation: 0.3s ease ${fadeOut} forwards 0s;
  }

`;
export const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: center;
  gap: 20px;
  width: 600px;
  opacity: 0;
  margin: 0 auto;

  animation: 0.5s ease ${fadeIn} forwards ;

  &.off {
    animation: 0.5s ease ${fadeOut} forwards 0s;
  }

`;

export const Steps = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  gap: ${(props) => props.gap ? props.gap + 'px' : '30px'};
`

export const Step = styled.li`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  width: 40px;
  height: 40px;
  background: #00f9b5;
  border-radius: 20px;
  transition: all .5s ease;
  font-weight: bold;

  &.current {
    background: #00c791;
    color: #FFFFFF;
    pointer-events: none;
  }

  &.current-color {
    background: #00c791;
    color: #FFFFFF;
    pointer-events: none;
  }

  &.current-last {
    background: #00c791;
    color: #FFFFFF;
    pointer-events: none;
  }

  &.after {
    background: #b3b3c2;
    pointer-events: none;
  }

  &.last {
    background: #b3b3c2;
    pointer-events: none;

  }

  &:before {
    position: absolute;
    content: "";
  }

  &.prev {
    &:before {
      width: ${(props) => props.distance ? props.distance + 'px' : '50px'};;
      height: 2px;
      top: 50%;
      left: 100%;
      z-index: -1;
      background: #00f9b5;
    }
  }

  &.after-color {
    &:before {
      width: ${(props) => props.distance ? props.distance + 'px' : '50px'};;
      height: 2px;
      top: 50%;
      left: 100%;
      z-index: -1;
      background: #00f9b5;
    }
  }

  &.current {
    &:before {
      width: ${(props) => props.distance ? props.distance + 'px' : '50px'};;
      height: 2px;
      top: 50%;
      left: 100%;
      z-index: -1;
      background: #b3b3c2;
    }
  }

  &.current-color {
    &:before {
      width: ${(props) => props.distance ? props.distance + 'px' : '50px'};;
      height: 2px;
      top: 50%;
      left: 100%;
      z-index: -1;
      background: #00f9b5;
    }
  }

  &.after {
    &:before {
      width: ${(props) => props.distance ? props.distance + 'px' : '50px'};;
      height: 2px;
      top: 50%;
      left: 100%;
      z-index: -1;
      background: #b3b3c2;
    }
  }

  &.after-color {
    &:before {
      width: ${(props) => props.distance ? props.distance + 'px' : '50px'};;
      height: 2px;
      top: 50%;
      left: 100%;
      z-index: -1;
      background: #00f9b5;
    }
  }

  &.after-color-last {
    &:before {
      width: ${(props) => props.distance ? props.distance + 'px' : '50px'};;
      height: 2px;
      top: 50%;
      left: 100%;
      z-index: -1;
      background: #b3b3c2;
    }
  }

  &.current-last {
    &:before {
    }
  }

  &.last {
    &:before {
      width: 0 !important;
    }
  }

  /* &:hover {
    background: #00c791;
  } */
`

export const PerguntasContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: center;
  gap: 20px;
  width: 1000px;
  opacity: 0;
  margin: 0 auto;

  &.on {
    animation: 0.5s ease ${fadeIn} forwards 0s;
  }

  &.off {
    animation: 0.3s ease ${fadeOut} forwards 0s;
  }
`;

export const Pergunta = styled.h3`
  padding: 20px 0 10px 0;
  color: #2a8038;
`

export const RespostasContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 1000px;
  opacity: 0;
  margin: 0 auto;
  list-style: none;
  padding-bottom: 20px;

  &.on {
    animation: 0.5s ease ${fadeIn} forwards 0s;
  }

  &.off {
    animation: 0.3s ease ${fadeOut} forwards 0s;
  }
`;

export const Resposta = styled.li`
  opacity: 0;
  font-size: 20px;
  background: #ccffd4;
  width: 100%;
  padding: 10px 15px;
  border-radius: 15px;
  cursor: pointer;
  color: #808099;
  background: #f0f1f7;
  border: solid 2px #808099;
  transition: all .5s ease;

  &:hover:not(.active) {
    background: #e6e6eb;
  }

  &.active {
    color: #2a8038;
    background: #ccffd4;
    border: solid 2px #4be364;
    pointer-events: none;
  }

  &.on {
    animation: 0.5s ease ${fadeIn} forwards 0s;
  }
`

export const ButtonsStepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
`

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    padding-right: 10px;

    > button {
      font-size: 13px;

      color: ${({ theme }) => theme.info.default};

      transition: 200ms ease-out;

      &:hover {
        transform: scale(1.02);
      }
    }
  }

  @media (max-width: 1542px) {
    &.card-price {
      margin-left: 0 !important;
    }
    div.container-cards-price {
      display: flex;
      align-items: flex-start;

      div.container-card-price {
        flex-shrink: 3;
      }

      & > div.container-card-price + div.container-card-price {
        margin-left: 14px;
      }
    }
  }

  div.container-cards-price {
    width: 100%;

    > div {
      margin-top: 14px;
    }
  }

  &.card-chart-EP {
    width: 33%;
  }

  &.card-chart-AC {
    width: 33%;
  }

  &.card-price {
    width: auto;
  }

  @media (max-width: 1598px) {
    &.card-chart-EP {
      flex-grow: 3;
    }

    &.card-chart-AC {
      flex-grow: 3;
      margin-left: 15px;
    }

    &.card-price {
      flex-grow: 5;

      margin-left: 15px;
    }
  }
`;
