export function buildBudget(selected, receipts, investments, spendings, passivePatrimonies) {

    const data = {
        receipt: selected.includes("Recebimentos") ? 0 : receipts.estimated,
        investment: selected.includes("Investimentos") ? 0 : investments.estimated,
        pec:selected.includes("PGE-C") ? 0 : spendings['Eventual Comprometido'],
        committed_monthly:selected.includes("Mensais Comprometidos") ? 0 : spendings['Mensal Comprometido'],
        installments: 0,
        debt:selected.includes("Dívidas") ? 0 : passivePatrimonies.entries,
        flexible_monthly: selected.includes("Mensais Flexíveis") ? 0 : spendings['Mensal Flexível'],
        eventual_flexible: selected.includes("Eventuais Flexíveis") ? 0 : spendings['Eventual Flexível'],   
        
    };

    return data;
}