import React, { useRef, useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signOut } from 'store/modules/auth/actions';
import { useResize } from 'hooks';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdKeyboardArrowDown } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import ContextColorHeader from 'utils/contexts/ColorHeaderContext';
import Context from './ContextModal';
import ModalLaunch from './ModalLaunch';
import {
  Container,
  MenuHeaderClosed,
  MenuHeaderOpened,
  MenuActionBottom,
  ButtonOpen,
  ButtonClose,
  SideNav,
  Avatar,
  Separator,
  BackgrondMenu,
  ButtonToolTip,
  IconButton,
  TooltipMenu,
  SideMenu,
  HeaderMenu,
  ContainerLancamento,
  WrapperMenuToolTip,
} from './styles';
import LogoClosed from 'assets/sidebarIcons/logo-menu-closed.png';
import LogoOpened from 'assets/sidebarIcons/logo-menu-opened.png';
import Can from 'components/Can';

import {
  User,
  BudgetManagement,
  WealthManagement,
  // RetirementPlans,
  Sair,
  RiskManagement,
  TaxManagement,
  EstatePlanning,
  Pin,
  Planejar,
  RetirementPlans as Aposentadoria,
  Dashboard,
  Cloud,
  Atuar,
} from '../../../../assets/sidebarIcons';

export default function SidebarFixed({ isOpen, handleOpen }) {
  const [showModal, setShowModal] = useState(false);
  const [ulHeight, setUlHeight] = useState(0);

  const dispatch = useDispatch();

  const { height } = useResize();

  const { name: fullName } = useSelector(state => state.user.profile);

  const spaceY = 32;

  const { setColorHeader } = useContext(ContextColorHeader);

  const history = useHistory();

  useEffect(() => {
    setUlHeight(0);
    setTimeout(() => {
      if (ulRef.current) {
        let extra = 0;
        if (ulRef.current.scrollHeight !== ulRef.current.clientHeight) {
          extra = 80;
        }
        const ulHeight = ulRef.current.scrollHeight + extra;
        setUlHeight(ulHeight);
      }
    }, 10);
  }, [height]);

  const ulRef = useRef(null);

  const initialState = {
    dashboard: false,
    lifeProject: false,
    budgetManagement: false,
    wealthManagement: false,
    retirementPlans: false,
    riskManagement: false,
    taxManagement: false,
    estatePlanning: false,
    lancamento: false,
    userName: false,
  };

  const [hoverState, setHoverState] = useState(initialState);
  const [activeState, setActiveState] = useState(initialState);

  const handleOpenMenu = action => {
    handleOpen(action);

    if (!action) {
      setActiveState(initialState);
    }
  };

  const changeColorHeader = color => {
    setColorHeader(color);
  };

  const navigateTo = url => () => {
    changeColorHeader('layout');
    history.push(url);
  };

  function checkActive(pathname) {
    let active = '';

    const [, choosenPath] = window.location.pathname.split('/');

    if (choosenPath === pathname) {
      active = 'active';
    }

    return active;
  }

  const handleToggleTooltip = e => {
    if (isOpen) return;

    const trueOrFalse = e.type === 'mouseenter';
    const choosedItem = String(e.currentTarget.dataset.name);

    // Force all others to be false
    const newItemState = { ...initialState };
    newItemState[choosedItem] = trueOrFalse;

    setHoverState(newItemState);
  };

  const handleToggleMenu = e => {
    const choosedItem = String(e.currentTarget.dataset.name);

    // Force all others to be false
    const newItemState = { ...initialState };
    newItemState[choosedItem] = !activeState[choosedItem];

    setActiveState(newItemState);
  };

  // const { type } = useSelector(state => state.auth.user);

  const canShowTooltip = name => {
    return hoverState[name] && !isOpen;
  };

  const canShowMenu = () => {
    return isOpen;
  };

  const getLiClassName = name => {
    if (isOpen) return 'on';
    return hoverState[name] ? 'on' : 'off';
  };

  const getFullName = () => {
    /*if(newName.length > 18) {
      newName = newName.substring(0, 18) + "...";
    }*/

    return fullName;
  };

  const logout = () => {
    dispatch(signOut());
  };

  return (
    <>
      <Container>
        {!isOpen && (
          <MenuHeaderClosed>
            <Avatar src={LogoClosed} alt="Avatar" />
            <ButtonOpen onClick={() => handleOpenMenu(true)}>
              <MdKeyboardArrowDown />
            </ButtonOpen>
          </MenuHeaderClosed>
        )}
        {isOpen && (
          <MenuHeaderOpened>
            <div></div>
            <Avatar className="opened" src={LogoOpened} alt="Avatar" />
            <ButtonClose onClick={() => handleOpenMenu(false)}>
              <MdKeyboardArrowDown />
            </ButtonClose>
          </MenuHeaderOpened>
        )}
        <SideNav
          onWheel={() => setHoverState(initialState)}
          ref={ulRef}
          open={isOpen}
          currentHeight={ulHeight}
        >
          <Can canRoles={['planner', 'adm', 'clientPfg', 'client']}>
            <li
              data-name="dashboard"
              className={`${getLiClassName('dashboard')}`}
              onMouseEnter={handleToggleTooltip}
              onMouseLeave={handleToggleTooltip}
            >
              <IconButton
                className={`${isOpen ? 'disabled' : ''} ${checkActive('home')}`}
              >
                <Link to="/home" onClick={() => changeColorHeader('layout')}>
                  <Dashboard />
                </Link>
              </IconButton>
              {canShowTooltip('dashboard') && (
                <TooltipMenu totalButtons={1} spaceY={spaceY}>
                  <WrapperMenuToolTip width={86}>
                    <ButtonToolTip
                      clickable={true}
                      onClick={navigateTo('/home')}
                    >
                      Dashboard
                    </ButtonToolTip>
                  </WrapperMenuToolTip>
                </TooltipMenu>
              )}
              {canShowMenu() && (
                <SideMenu className="open">
                  <HeaderMenu
                    className="simple-button"
                    clickable={true}
                    onClick={navigateTo('/home')}
                  >
                    Dashboard
                  </HeaderMenu>
                </SideMenu>
              )}
            </li>

            <li
              data-name="lifeProject"
              className={`${getLiClassName('lifeProject')}`}
              onMouseEnter={handleToggleTooltip}
              onMouseLeave={handleToggleTooltip}
            >
              <IconButton
                className={`${isOpen ? 'disabled' : ''} ${checkActive(
                  'goals'
                )}`}
              >
                <Link
                  to="/goals/goalPanel"
                  onClick={() => changeColorHeader('layout')}
                >
                  <Cloud />
                </Link>
              </IconButton>
              {canShowTooltip('lifeProject') && (
                <TooltipMenu totalButtons={2} spaceY={spaceY}>
                  <WrapperMenuToolTip width={124}>
                    <ButtonToolTip
                      clickable={true}
                      onClick={navigateTo('/goals/goalPanel')}
                    >
                      Projeto de Vida
                    </ButtonToolTip>
                    <ButtonToolTip
                      clickable={true}
                      onClick={navigateTo('/goals/goalPanel')}
                      type="subbutton"
                      posX="0"
                    >
                      Painel dos sonhos
                    </ButtonToolTip>
                  </WrapperMenuToolTip>
                </TooltipMenu>
              )}
              {canShowMenu() && (
                <SideMenu className="open">
                  <HeaderMenu
                    data-name="lifeProject"
                    onClick={handleToggleMenu}
                    className={`has-sub ${
                      activeState['lifeProject'] ? 'sub-open' : ''
                    }`}
                  >
                    Projeto de Vida <MdKeyboardArrowDown />
                  </HeaderMenu>
                  {activeState['lifeProject'] && (
                    <ButtonToolTip
                      className="sub-open"
                      clickable={true}
                      onClick={navigateTo('/goals/goalPanel')}
                      type="subbutton"
                      posX="0"
                    >
                      Painel dos sonhos
                    </ButtonToolTip>
                  )}
                </SideMenu>
              )}
            </li>
          </Can>
          <li
            data-name="budgetManagement"
            className={`${checkActive('home')} ${getLiClassName(
              'budgetManagement'
            )}`}
            onMouseEnter={handleToggleTooltip}
            onMouseLeave={handleToggleTooltip}
          >
            <IconButton
              className={`${isOpen ? 'disabled' : ''} ${checkActive(
                'finances'
              )} ${checkActive('management')}`}
            >
              <Link
                to="/finances/budget"
                onClick={() => changeColorHeader('layout')}
              >
                <BudgetManagement />
              </Link>
            </IconButton>
            {canShowTooltip('budgetManagement') && (
              <TooltipMenu totalButtons={4} spaceY={spaceY}>
                <WrapperMenuToolTip width={142}>
                  <ButtonToolTip>Gestão Orçamentária</ButtonToolTip>
                  <ButtonToolTip
                    clickable={true}
                    onClick={navigateTo('/finances/budget')}
                    type="subbutton"
                  >
                    <Pin />
                    Mapear
                  </ButtonToolTip>
                  <ButtonToolTip
                    clickable={true}
                    onClick={navigateTo('/management/spendings')}
                    type="subbutton"
                  >
                    <Planejar />
                    Planejar
                  </ButtonToolTip>
                  <ButtonToolTip
                    clickable={true}
                    onClick={navigateTo('/investments/r1')}
                    type="subbutton"
                  >
                    <Atuar />
                    Atuar
                  </ButtonToolTip>
                </WrapperMenuToolTip>
              </TooltipMenu>
            )}
            {canShowMenu() && (
              <SideMenu className="open">
                <HeaderMenu
                  data-name="budgetManagement"
                  onClick={handleToggleMenu}
                  className={`has-sub ${
                    activeState['budgetManagement'] ? 'sub-open' : ''
                  }`}
                >
                  Gestão Orçamentária <MdKeyboardArrowDown />
                </HeaderMenu>
                {activeState['budgetManagement'] && (
                  <WrapperMenuToolTip>
                    <ButtonToolTip
                      className="sub-open"
                      clickable={true}
                      onClick={navigateTo('/finances/budget')}
                      type="subbutton"
                    >
                      <Pin />
                      Mapear
                    </ButtonToolTip>
                    <ButtonToolTip
                      className="sub-open"
                      clickable={true}
                      onClick={navigateTo('/management/spendings')}
                      type="subbutton"
                    >
                      <Planejar />
                      Planejar
                    </ButtonToolTip>
                    <ButtonToolTip
                      className="sub-open"
                      clickable={true}
                      onClick={navigateTo('/investments/r1')}
                      type="subbutton"
                    >
                      <Atuar />
                      Atuar
                    </ButtonToolTip>
                  </WrapperMenuToolTip>
                )}
              </SideMenu>
            )}
          </li>
          <Can canRoles={['planner', 'adm', 'clientPfg', 'client']}>
            <li
              data-name="wealthManagement"
              className={`${checkActive('finances')} ${getLiClassName(
                'wealthManagement'
              )}`}
              onMouseEnter={handleToggleTooltip}
              onMouseLeave={handleToggleTooltip}
            >
              <IconButton className={`${isOpen ? 'disabled' : ''}`}>
                <Link
                  to="/finances/patrimony"
                  onClick={() => changeColorHeader('layout')}
                >
                  <WealthManagement />
                </Link>
              </IconButton>
              {canShowTooltip('wealthManagement') && (
                <TooltipMenu totalButtons={4} spaceY={spaceY}>
                  <WrapperMenuToolTip width={132}>
                    <ButtonToolTip>Gestão Patrimonial</ButtonToolTip>
                    <ButtonToolTip
                      clickable={true}
                      onClick={navigateTo('/finances/patrimony')}
                      type="subbutton"
                    >
                      <Pin />
                      Mapear
                    </ButtonToolTip>
                    <ButtonToolTip
                      clickable={true}
                      onClick={navigateTo('/investments/panelInvestments')}
                      type="subbutton"
                    >
                      <Planejar />
                      Planejar
                    </ButtonToolTip>
                    <ButtonToolTip
                      clickable={true}
                      onClick={navigateTo('/acting/r1')}
                      type="subbutton"
                    >
                      <Atuar />
                      Atuar
                    </ButtonToolTip>
                  </WrapperMenuToolTip>
                </TooltipMenu>
              )}
              {canShowMenu() && (
                <SideMenu className="open">
                  <HeaderMenu
                    data-name="wealthManagement"
                    onClick={handleToggleMenu}
                    className={`has-sub ${
                      activeState['wealthManagement'] ? 'sub-open' : ''
                    }`}
                  >
                    Gestão Patrimonial <MdKeyboardArrowDown />
                  </HeaderMenu>
                  {activeState['wealthManagement'] && (
                    <WrapperMenuToolTip>
                      <ButtonToolTip
                        className="sub-open"
                        clickable={true}
                        onClick={navigateTo('/finances/patrimony')}
                        type="subbutton"
                      >
                        <Pin />
                        Mapear
                      </ButtonToolTip>
                      <ButtonToolTip
                        className="sub-open"
                        clickable={true}
                        onClick={navigateTo('/investments/panelInvestments')}
                        type="subbutton"
                      >
                        <Planejar />
                        Planejar
                      </ButtonToolTip>
                      <ButtonToolTip
                        className="sub-open"
                        clickable={true}
                        onClick={navigateTo('/acting/r1')}
                        type="subbutton"
                      >
                        <Atuar />
                        Atuar
                      </ButtonToolTip>
                    </WrapperMenuToolTip>
                  )}
                </SideMenu>
              )}
            </li>

            <li
              data-name="retirementPlans"
              className={`${getLiClassName('retirementPlans')}`}
              onMouseEnter={handleToggleTooltip}
              onMouseLeave={handleToggleTooltip}
            >
              <IconButton className={`${isOpen ? 'disabled' : ''}`}>
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    changeColorHeader('layout');
                  }}
                >
                  <Aposentadoria />
                </a>
              </IconButton>
              {canShowTooltip('retirementPlans') && (
                <TooltipMenu totalButtons={4} spaceY={spaceY}>
                  <WrapperMenuToolTip width={164}>
                    <ButtonToolTip>Planos de Aposentadoria</ButtonToolTip>
                    <ButtonToolTip type="subbutton">
                      <Pin />
                      Mapear
                    </ButtonToolTip>
                    <ButtonToolTip type="subbutton">
                      <Planejar />
                      Planejar
                    </ButtonToolTip>
                    <ButtonToolTip type="subbutton">
                      <Atuar />
                      Atuar
                    </ButtonToolTip>
                  </WrapperMenuToolTip>
                </TooltipMenu>
              )}
              {canShowMenu() && (
                <SideMenu className="open">
                  <HeaderMenu
                    data-name="retirementPlans"
                    onClick={handleToggleMenu}
                    className={`has-sub ${
                      activeState['retirementPlans'] ? 'sub-open' : ''
                    }`}
                  >
                    Planos de Aposentadoria <MdKeyboardArrowDown />
                  </HeaderMenu>
                  {activeState['retirementPlans'] && (
                    <WrapperMenuToolTip>
                      <ButtonToolTip className="sub-open" type="subbutton">
                        <Pin />
                        Mapear
                      </ButtonToolTip>
                      <ButtonToolTip className="sub-open" type="subbutton">
                        <Planejar />
                        Planejar
                      </ButtonToolTip>
                      <ButtonToolTip className="sub-open" type="subbutton">
                        <Atuar />
                        Atuar
                      </ButtonToolTip>
                    </WrapperMenuToolTip>
                  )}
                </SideMenu>
              )}
            </li>

            <li
              data-name="riskManagement"
              className={`${getLiClassName('riskManagement')}`}
              onMouseEnter={handleToggleTooltip}
              onMouseLeave={handleToggleTooltip}
            >
              <IconButton className={`${isOpen ? 'disabled' : ''}`}>
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    changeColorHeader('layout');
                  }}
                >
                  <RiskManagement />
                </a>
              </IconButton>
              {canShowTooltip('riskManagement') && (
                <TooltipMenu totalButtons={4} spaceY={spaceY}>
                  <WrapperMenuToolTip width={116}>
                    <ButtonToolTip>Gestão de Risco</ButtonToolTip>
                    <ButtonToolTip type="subbutton">
                      <Pin />
                      Mapear
                    </ButtonToolTip>
                    <ButtonToolTip type="subbutton">
                      <Planejar />
                      Planejar
                    </ButtonToolTip>
                    <ButtonToolTip type="subbutton">
                      <Atuar />
                      Atuar
                    </ButtonToolTip>
                  </WrapperMenuToolTip>
                </TooltipMenu>
              )}
              {canShowMenu() && (
                <SideMenu className="open">
                  <HeaderMenu
                    data-name="riskManagement"
                    onClick={handleToggleMenu}
                    className={`has-sub ${
                      activeState['riskManagement'] ? 'sub-open' : ''
                    }`}
                  >
                    Gestão de Risco <MdKeyboardArrowDown />
                  </HeaderMenu>
                  {activeState['riskManagement'] && (
                    <WrapperMenuToolTip>
                      <ButtonToolTip className="sub-open" type="subbutton">
                        <Pin />
                        Mapear
                      </ButtonToolTip>
                      <ButtonToolTip className="sub-open" type="subbutton">
                        <Planejar />
                        Planejar
                      </ButtonToolTip>
                      <ButtonToolTip className="sub-open" type="subbutton">
                        <Atuar />
                        Atuar
                      </ButtonToolTip>
                    </WrapperMenuToolTip>
                  )}
                </SideMenu>
              )}
            </li>

            <li
              data-name="taxManagement"
              className={`${getLiClassName('taxManagement')}`}
              onMouseEnter={handleToggleTooltip}
              onMouseLeave={handleToggleTooltip}
            >
              <IconButton className={`${isOpen ? 'disabled' : ''}`}>
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    changeColorHeader('layout');
                  }}
                >
                  <TaxManagement />
                </a>
              </IconButton>
              {canShowTooltip('taxManagement') && (
                <TooltipMenu totalButtons={4} spaceY={spaceY}>
                  <WrapperMenuToolTip width={120}>
                    <ButtonToolTip>Gestão Tributária</ButtonToolTip>
                    <ButtonToolTip type="subbutton">
                      <Pin />
                      Mapear
                    </ButtonToolTip>
                    <ButtonToolTip type="subbutton">
                      <Planejar />
                      Planejar
                    </ButtonToolTip>
                    <ButtonToolTip type="subbutton">
                      <Atuar />
                      Atuar
                    </ButtonToolTip>
                  </WrapperMenuToolTip>
                </TooltipMenu>
              )}
              {canShowMenu() && (
                <SideMenu className="open">
                  <HeaderMenu
                    data-name="taxManagement"
                    onClick={handleToggleMenu}
                    className={`has-sub ${
                      activeState['taxManagement'] ? 'sub-open' : ''
                    }`}
                  >
                    Gestão Tributária <MdKeyboardArrowDown />
                  </HeaderMenu>
                  {activeState['taxManagement'] && (
                    <WrapperMenuToolTip>
                      <ButtonToolTip className="sub-open" type="subbutton">
                        <Pin />
                        Mapear
                      </ButtonToolTip>
                      <ButtonToolTip className="sub-open" type="subbutton">
                        <Planejar />
                        Planejar
                      </ButtonToolTip>
                      <ButtonToolTip className="sub-open" type="subbutton">
                        <Atuar />
                        Atuar
                      </ButtonToolTip>
                    </WrapperMenuToolTip>
                  )}
                </SideMenu>
              )}
            </li>

            <li
              data-name="estatePlanning"
              className={`${checkActive('home')} ${getLiClassName(
                'estatePlanning'
              )}`}
              onMouseEnter={handleToggleTooltip}
              onMouseLeave={handleToggleTooltip}
            >
              <IconButton className={`${isOpen ? 'disabled' : ''}`}>
                {/* <Link to="/home" onClick={() => changeColorHeader('layout')}> */}
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    changeColorHeader('layout');
                  }}
                >
                  <EstatePlanning />
                </a>
              </IconButton>
              {canShowTooltip('estatePlanning') && (
                <TooltipMenu totalButtons={4} spaceY={spaceY}>
                  <WrapperMenuToolTip width={160}>
                    <ButtonToolTip>Planejamento Sucessório</ButtonToolTip>
                    <ButtonToolTip type="subbutton">
                      <Pin />
                      Mapear
                    </ButtonToolTip>
                    <ButtonToolTip type="subbutton">
                      <Planejar />
                      Planejar
                    </ButtonToolTip>
                    <ButtonToolTip type="subbutton">
                      <Atuar />
                      Atuar
                    </ButtonToolTip>
                  </WrapperMenuToolTip>
                </TooltipMenu>
              )}
              {canShowMenu() && (
                <SideMenu className="open">
                  <HeaderMenu
                    data-name="estatePlanning"
                    onClick={handleToggleMenu}
                    className={`has-sub ${
                      activeState['estatePlanning'] ? 'sub-open' : ''
                    }`}
                  >
                    Planejamento Sucessório <MdKeyboardArrowDown />
                  </HeaderMenu>
                  {activeState['estatePlanning'] && (
                    <WrapperMenuToolTip>
                      <ButtonToolTip className="sub-open" type="subbutton">
                        <Pin />
                        Mapear
                      </ButtonToolTip>
                      <ButtonToolTip className="sub-open" type="subbutton">
                        <Planejar />
                        Planejar
                      </ButtonToolTip>
                      <ButtonToolTip className="sub-open" type="subbutton">
                        <Atuar />
                        Atuar
                      </ButtonToolTip>
                    </WrapperMenuToolTip>
                  )}
                </SideMenu>
              )}
            </li>
          </Can>
          {/* <li className={checkActive('home')}>
            <Link to="/home" onClick={() => changeColorHeader('layout')}>
              <AiOutlineHome type="home" />
              <span className="link-text">Dashboard</span>
            </Link>
          </li>
          <li className={checkActive('finances')}>
            <Link
              to="/finances/budget"
              // data-tip="Mapear"
              onClick={() => changeColorHeader('layout')}
            >
              <AiOutlineEnvironment type="environment" />
              <span className="link-text">Mapear</span>
            </Link>
          </li>
          <li className={checkActive('goals')}>
            <Link
              to="/goals/goalPanel"
              // data-tip="Almejar"
              onClick={() => changeColorHeader('layout')}
            >
              <MdCloudDone type="compass" />
              <span className="link-text">Almejar</span>

            </Link>
          </li>
          <li className={checkActive('planning')}>
            <Link
              to="/planning/wipe"
              // data-tip="Planejar"
              onClick={() => changeColorHeader('layout')}
            >
              <MdShowChart type="trophy" />
              <span className="link-text">Planejar</span>

            </Link>
          </li>
          <li className={checkActive('acting')}>
            <Link
              to="/acting"
              // data-tip="Atuar"
              onClick={() => changeColorHeader('layout')}
            >
              <AiOutlineAudit type="idcard" />
              <span className="link-text">Atuar</span>

            </Link>
          </li>
          <li className={checkActive('management')}>
            <Link
              to="/management/spendings"
              // data-tip="Gestão"
              onClick={() => changeColorHeader('layout')}
            >
              <AiOutlineBarChart type="idcard" />
              <span className="link-text">Gestão</span>

            </Link>
          </li>
          <li className={checkActive('investments')}>
            <Link
              to="/investments/panelInvestments"
              // data-tip="Investimentos"
              onClick={() => changeColorHeader('layout')}
            >
              <MdTrendingUp />
              <span className="link-text">Investimentos</span>

            </Link>
          </li>
          <li className={checkActive('selfKnowledge')}>
            <Link
              to="/selfKnowledge/lifeCompass"
              // data-tip="Autoconhecimento"
              onClick={() => changeColorHeader('layout')}
            >
              <GoSmiley />
              <span className="link-text">Autoconhecimento</span>

            </Link>
          </li> */}
        </SideNav>
        <MenuActionBottom>
          <Can
            canRoles={[
              'planner',
              'coordinator-planner',
              'assistant-planner',
              'instructor-planner',
              'mentor-planner',
              'cs',
              'cs-assistant',
              'adm',
              'clientPfg',
            ]}
          >
            <ContainerLancamento
              onMouseEnter={handleToggleTooltip}
              onMouseLeave={handleToggleTooltip}
              data-name="lancamento"
              className={`container-lancamento ${getLiClassName('lancamento')}`}
            >
              <div style={{ width: '32px' }}>
                <button
                  className="launchModal"
                  onClick={() => setShowModal(true)}
                >
                  <AiOutlinePlus />
                </button>
              </div>
              {canShowTooltip('lancamento') && (
                <TooltipMenu totalButtons={1} spaceY={spaceY}>
                  <WrapperMenuToolTip width={124}>
                    <ButtonToolTip
                      clickable={true}
                      onClick={() => setShowModal(true)}
                    >
                      Novo Lançamento
                    </ButtonToolTip>
                  </WrapperMenuToolTip>
                </TooltipMenu>
              )}
              {canShowMenu() && (
                <SideMenu className="open">
                  <HeaderMenu
                    className="simple-button"
                    clickable={true}
                    onClick={() => setShowModal(true)}
                  >
                    Novo Lançamento
                  </HeaderMenu>
                </SideMenu>
              )}
            </ContainerLancamento>
            <Separator />
          </Can>
          <ContainerLancamento
            paddingLeft={2}
            onMouseEnter={handleToggleTooltip}
            onMouseLeave={handleToggleTooltip}
            data-name="userName"
            className={`container-lancamento ${getLiClassName('userName')}`}
          >
            <IconButton>
              <User />
            </IconButton>
            {canShowTooltip('userName') && (
              <TooltipMenu totalButtons={1} spaceY={spaceY}>
                <WrapperMenuToolTip width={200}>
                  <ButtonToolTip className="justify-between no-mg">
                    <span style={{ width: '150px' }} className="client-name">
                      {getFullName()}
                    </span>
                    <Sair onClick={logout} />
                  </ButtonToolTip>
                </WrapperMenuToolTip>
              </TooltipMenu>
            )}
            {canShowMenu() && (
              <SideMenu className="open">
                <HeaderMenu className="simple-button">
                  <span style={{ width: '144px' }} className="client-name">
                    {getFullName()}
                  </span>{' '}
                  <Sair onClick={logout} />
                </HeaderMenu>
              </SideMenu>
            )}
          </ContainerLancamento>
        </MenuActionBottom>
        <ReactTooltip />
      </Container>
      <BackgrondMenu open={isOpen} />
      <Context.Provider value={{ showModal, setShowModal }}>
        {showModal && <ModalLaunch />}
      </Context.Provider>
    </>
  );
}
