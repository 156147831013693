import React, { useContext } from 'react';
import Card from 'components/Cards';
import { CardContent } from './styles';
import Can from 'components/Can';
import { ThemeContext } from 'styled-components';
import DropdownButton from 'components/Button/Dropdown'
import ProgressBar from 'components/ProgressBar';
import { differenceInCalendarMonths } from 'date-fns'
import default_goal from 'assets/icon_investments.svg';
import { useMemo } from 'react';
const FreedomFinancial = ({ goal, showUpdateModal, setShowUpdateModal, setSelectedGoal, handleDoneGoal, handleSavedMoney }) => {

    const theme = useContext(ThemeContext);

    const getUrl = useMemo(() => {
        if (goal?.file?.url) return goal?.file?.url
        return default_goal
    }, [goal])

    return (
        <Card className="card-goal" key={goal.id}>
            <CardContent done={new Date()}>
                <div className='freedom-financial'>
                    <img
                        src={getUrl}
                        alt="Background goal"
                        className='financial-independence'
                    />

                    <Can canRoles={['planner', 'adm', 'cs', 'clientPfg']}>
                        <DropdownButton
                            className="dropdown-button"
                            color={theme.primary.default}
                        >
                            <button
                                onMouseDown={() => {
                                    setSelectedGoal(goal);
                                    setShowUpdateModal(!showUpdateModal);
                                }}
                            >
                                Editar
                            </button>
                            {goal.id !== -1 &&
                                < button onMouseDown={() => handleDoneGoal(goal)}>
                                    {goal.done_at ? 'Desconcluir' : "Concluir"}
                                </button>
                            }
                        </DropdownButton>
                    </Can>
                </div>
                <div className="content">
                    <div className="top-info">
                        <span className="title">{goal.name}</span>
                        <span className="value">
                            {goal.done_at ? "Realizado!" : goal.value.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}
                        </span>
                        <small>
                            {goal.done_at
                                ? ''
                                : goal.end &&
                                    differenceInCalendarMonths(
                                        new Date(goal.end),
                                        new Date()
                                    ) >= 0
                                    ? `${differenceInCalendarMonths(
                                        new Date(goal.end),
                                        new Date()
                                    )} meses`
                                    : `Há ${differenceInCalendarMonths(
                                        new Date(),
                                        new Date(goal.end)
                                    )} meses atrás`}
                        </small>
                    </div>
                    <ProgressBar
                        fill={goal.done_at ? 100 : handleSavedMoney(goal.financial_active_value_bpl, goal.bpl)}
                        color={'primary'}
                        left={'Base de Plena Liquidez'}
                        right={(goal.financial_active_value_bpl || 0)?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })
                        }
                        fontSize={12}
                    />
                    <ProgressBar
                        fill={goal.done_at ? 100 : handleSavedMoney(goal.saved_money, goal.value)}
                        color={'primary'}
                        left={'Independência Financeira Total'}
                        right={goal.saved_money?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })
                        }
                        fontSize={12}
                    />
                </div>

            </CardContent>
        </Card >)
}
export default FreedomFinancial;