import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { format } from 'date-fns';

import Input from 'components/Input';
import Dropdown from 'components/Input/Dropdown';
import Button from 'components/Button';
import { CurrencyInput } from 'components/masks';

import { Form, DivGroup } from './styles';

import dateUTC from 'utils/dateUTC';

import api from 'services/api';

import debounce from 'utils/debounce';

export default function SpendingsAndReceipts({ typeEntry }) {
  const [categories, setCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState({});
  const [selectItem, setSelectItem] = useState({});

  const [items, setItems] = useState([]);
  const [inputValueItem, setInputValueItem] = useState('');
  const [categoryWithItem, setCategoryWithItem] = useState([]);

  const [start, setStart] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [end, setEnd] = useState(null);
  const [description, setDescription] = useState('');
  const [recurrent, setRecurrent] = useState(false);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const { id } = useSelector(state => state.user.profile);

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`/users/${id}/categories`, {
        params: {
          type: typeEntry === 'receipts' ? 'receipts' : 'spendings',
          items: true,
        },
      });

      setCategories(
        data.map(category => ({
          value: category?.name,
          label: category?.name.toUpperCase(),
          id: category?.id,
          ...category,
        }))
      );
    }
    fetchData();
  }, [id, typeEntry]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`/users/${id}/items`, {
        params: {
          name: inputValueItem,
          category: true,
        },
      });

      setItems(data.data);

      setCategoryWithItem(
        data.data.map(item => [
          {
            value: item.category.name,
            label: item.category.name.toUpperCase(),
            ...item.category,
          },
        ])
      );
    }

    if (inputValueItem.length > 0) {
      debounce(fetchData, 300);
    }
  }, [id, inputValueItem]);

  function loadItems() {
    if (items.length > 0) {
      return items.map(item => ({
        value: item.name,
        label: item.name.toUpperCase(),
        ...item,
      }));
    }
    if (!selectCategory.items) return [];

    return selectCategory?.items.map(item => ({
      value: item.name,
      label: item.name.toUpperCase(),
      ...item,
    }));
  }

  function loadCategoryWithItem() {
    if (categoryWithItem.length < 1) {
      return categories;
    }

    return categoryWithItem[0];
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);

      if (!selectCategory || !selectItem) {
        toast.error('Escolha a categoria e item');
      }

      await api.post(`/users/${id}/items/${selectItem.id}/entries`, {
        description,
        start: dateUTC(start),
        end: dateUTC(end),
        value,
        recurrent,
      });

      setRecurrent(false);
      toast.success('Lançamento realizado com sucesso');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error('Falha ao criar lançamento');
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Dropdown
        label="Nome da categoria"
        placeholder="Categoria..."
        width="100%"
        defaultValue={categoryWithItem[0]}
        options={loadCategoryWithItem()}
        onChange={selected => setSelectCategory(selected)}
      />

      <Dropdown
        label="Nome do item"
        placeholder="Item..."
        width="100%"
        options={loadItems()}
        onInputChange={e => setInputValueItem(e)}
        onChange={select => setSelectItem(select)}
      />
      <Input
        label="Data de inicio"
        type="date"
        value={start}
        onChange={e => setStart(e.target.value)}
      />

      <Input
        label="Descrição"
        value={description}
        onChange={e => setDescription(e.target.value)}
      />
      <DivGroup>
        <div className="radio-buttons">
          <label htmlFor="">É recorrente?</label>
          <div>
            <label htmlFor="true">Sim</label>
            <input
              type="radio"
              name="recurrent"
              id="true"
              checked={recurrent}
              onChange={() => {
                setRecurrent(true);
              }}
            />
          </div>
          <div>
            <label htmlFor="false">Não</label>
            <input
              type="radio"
              name="recurrent"
              id="false"
              checked={!recurrent}
              onChange={() => {
                setRecurrent(false);
              }}
            />
          </div>
        </div>
        <div className="div-inputs">
          {recurrent && (
            <Input
              label="Data de término"
              type="date"

              value={end}
              onChange={e => setEnd(e.target.value)}
            />
          )}
          <CurrencyInput
            label="Valor"
            prefix="R$ "
            value={value}
            onChange={cleanValue => setValue(cleanValue)}
          />
        </div>
      </DivGroup>

      <div className="buttons">
        <Button type="submit" stripped color="grafit">
          {loading ? 'Carregando...' : 'Salvar transação'}
        </Button>
      </div>
    </Form>
  );
}

SpendingsAndReceipts.propTypes = {
  typeEntry: PropTypes.string.isRequired,
};
