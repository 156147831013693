import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  width: 100%;

  margin-bottom: 10px;

  > button {
    margin-left: 20px;

    svg {
      margin-right: 5px;
    }
  }
`;

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  margin-top: 10px;

  color: ${({ theme }) => theme.grafit.default};

  > span {
    width: calc(100% / 4);
    max-width: calc(100% / 4);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Asset = styled.table`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  border-radius: 8px;
  margin-top: 10px;
  padding: 5px 8px;

  color: ${({ theme }) => theme.spendings.default};
  border: 1px solid ${({ theme }) => theme.spendings.custom};

  span {
    width: calc(100% / 4);
    max-width: calc(100% / 4);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
