import React, { useEffect, useContext } from 'react';

import { ThemeContext } from 'styled-components';

import { useSelector } from 'react-redux';

// import { Container } from './styles';

// import Calendar from './Calendar';

// import Loading from 'components/Loading';
import UnderConstruction from 'components/UnderConstruction';

// import Modal from './Modal';
// import UpdateModal from './Modal/UpdateModal';

// import api from 'services/api';

export default function Acting() {
  // const [activities, setActivities] = useState([]);

  // const [selectedEvent, setSelectEvent] = useState({});

  // const [showModal, setShowModal] = useState(false);

  // const [showUpdateModal, setShowUpdateModal] = useState(false);

  // const [propsEvent, setPropsEvent] = useState({});

  // const [loading, setLoading] = useState(false);

  const { id } = useSelector(state => state.user.profile);

  const theme = useContext(ThemeContext);

  useEffect(() => {
    async function fetchActivities() {
      // setLoading(true);
      // const { data: meetings } = await api.get(`/users/${id}/meetings`);
      // const { data } = await api.get(`/users/${id}/activities`);
      // const dataMeetings = meetings.map(meet => {
      //   let backgroundColor = theme.receipts.custom;
      //   if (meet.done) {
      //     backgroundColor = theme.success.disabled;
      //   }
      //   if (new Date(meet.start) < new Date() && !meet.done) {
      //     backgroundColor = theme.danger.default;
      //   }
      //   return {
      //     id: meet?.id,
      //     _id: meet?.id,
      //     title: meet?.name,
      //     editable: true,
      //     backgroundColor,
      //     color: 'white',
      //     date_start: new Date(meet?.start),
      //     date_end: new Date(meet?.end),
      //     ...meet,
      //   };
      // });
      // const dataActivities = data.map(act => {
      //   let backgroundColor = theme.receipts.custom;
      //   if (act.done) {
      //     backgroundColor = theme.success.disabled;
      //   }
      //   if (new Date(act.start) < new Date() && !act.done) {
      //     backgroundColor = theme.danger.default;
      //   }
      //   return {
      //     id: act?.id,
      //     _id: act?.id,
      //     title: act?.name,
      //     editable: true,
      //     backgroundColor,
      //     color: 'white',
      //     date_start: new Date(act?.start),
      //     date_end: new Date(act?.end),
      //     who: act?.who,
      //     where: act?.where,
      //     how: act?.how,
      //     ...act,
      //   };
      // });
      // const events = [dataMeetings, dataActivities].flat();
      // setActivities(events);
      // return setLoading(false);
    }
    fetchActivities();
  }, [id, theme.danger.default, theme.receipts.custom, theme.success.disabled]);

  return (
    <>
      <UnderConstruction />
      {/* {loading ? (
        <Loading />
      ) : (
        <Container>
          <Calendar
            events={activities}
            setEvents={setActivities}
            showModal={showModal}
            setShowModal={setShowModal}
            setPropsEvent={setPropsEvent}
            showUpdateModal={showUpdateModal}
            setShowUpdateModal={setShowUpdateModal}
            setSelectEvent={setSelectEvent}
          />
        </Container>
      )}
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          propsEvent={propsEvent}
          setEvents={setActivities}
          events={activities}
        />
      )}

      {showUpdateModal && !selectedEvent.meeting_type && (
        <UpdateModal
          setShowUpdateModal={setShowUpdateModal}
          selectedEvent={selectedEvent}
          setEvents={setActivities}
        />
      )} */}
    </>
  );
}
