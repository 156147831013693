import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RiWallet2Line } from 'react-icons/ri';
import {
  AiOutlineShoppingCart,
  AiOutlineSearch,
  AiOutlineDelete,
} from 'react-icons/ai';
import { BsGraphUp } from 'react-icons/bs';
import GeneralExtract from './Extract/index';
import { startOfMonth, lastDayOfMonth } from 'date-fns';
import {
  Container,
  Header,
  ContainerLeft,
  ContainerRight,
  Title,
  DivButton,
  DivInput,
} from './styles';
import api from 'services/api';
import DetailedExtract from './DetailedExtract';
import DatePicker from './DatePickerCustom/index';
import { Context } from './Context';
import Loading from 'components/Loading';
import UpdateModal from './ModalUpdate';
import ReactTooltip from 'react-tooltip';
import ModalExclude from 'components/Modal';
import Button from 'components/Button';
import { toast } from 'react-toastify';
import Investments from './Investments';

export default function Extract() {
  const [entries, setEntries] = useState([]);
  const [allEntries, setAllEntries] = useState([]);
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [toggleButton, setToggleButton] = useState('receipts');
  const { id } = useSelector(state => state.user.profile);
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef();
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(0);

  const [isLoad, setIsLoad] = useState();
  const [search, setSearch] = useState('');
  const [modalUpdate, setModalUpdate] = useState(false);
  const [selectEntry, setSelectEntry] = useState({});
  const [loading, setLoading] = useState(false);
  const [reloadCategory, setReloadCategory] = useState(false);
  const [reloadItem, setReloadItem] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showInvestments, setShowInvestments] = useState(false);
  async function handleDeleteSpending(item) {
    try {
      setLoading(true);
      if (item.payment === 'Parcelado') {
        await api.delete(`/installments/${item.id}`);
      } else {
        await api.delete(`/entries/${item.id}`);
      }
      setEntries(entries => entries.filter(entry => entry.id !== id));
      setAllEntries(entries => entries.filter(entry => entry.id !== id));
      setLoading(false);
      setShowModalDelete(false);
      toast.success('Excluido com sucesso');
      setReloadItem(true);
      setReloadCategory(true);
    } catch (err) {
      toast.error('Falha ao excluir item');
    }
  }

  async function fetchCategories(start, end) {
    setIsLoad(true);

    try {
      const { data: params } = await api.get(
        `users/${id}/calc_parameters_category`,
        {
          params: {
            start,
            end,
            type: toggleButton,
          },
        }
      );
      setCategories(params);

      return setIsLoad(false);
    } catch (error) {}
  }

  async function fetchValueEntries(start, end, type, search) {
    try {
      if (toggleButton === 'receipts') {
        const { data: total } = await api.get(`users/${id}/receipts/value`, {
          params: {
            start,
            end,
            type,
            search,
          },
        });

        return setTotalEntries(total);
      }

      const { data: total } = await api.get(`users/${id}/spendings/value`, {
        params: {
          start,
          end,
          type,
          search,
        },
      });
      setTotalEntries(total);
    } catch (error) {}
  }

  //busca todos os itens do extrato
  async function fetchEntries(start, end) {
    setLoading(true);
    if (toggleButton === 'receipts') {
      const { data: entriesApi } = await api.get(`users/${id}/receipts`, {
        params: {
          start,
          end,
          type: toggleButton,
          page,
          limit: 12,
          search,
        },
      });
      await fetchValueEntries(start, end, toggleButton, search);
      if (page < 2) {
        setAllEntries(entriesApi);
        return setLoading(false);
      }

      setAllEntries([...allEntries, ...entriesApi]);
      setLoading(false);
    } else {
      const { data: entriesApi } = await api.get(`users/${id}/spendings`, {
        params: {
          search,
          start,
          end,
          type: toggleButton,
          page,
          limit: 12,
        },
      });
      await fetchValueEntries(start, end, toggleButton, search);

      if (page < 2) {
        setAllEntries(entriesApi);

        return setLoading(false);
      }
      setAllEntries([...allEntries, ...entriesApi]);
      setLoading(false);
    }
  }

  async function handleSearch() {
    setPage(1);
    const start = startOfMonth(dateStart);
    const end = lastDayOfMonth(dateEnd);

    await fetchEntries(start, end, search);
  }

  //Se o usuário mudar dateStart, dateEnd, toggleButton
  useEffect(() => {
    async function fetchData() {
      setPage(1);
      const start = startOfMonth(dateStart);
      const end = lastDayOfMonth(dateEnd);

      await fetchCategories(start, end);
      await fetchEntries(start, end);
      // await fetchEntries(start, end);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd, toggleButton]);

  //Se o usuário mudar a page
  useEffect(() => {
    async function fetchData() {
      const start = startOfMonth(dateStart);
      const end = lastDayOfMonth(dateEnd);
      await fetchEntries(start, end, search);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    const start = startOfMonth(dateStart);
    const end = lastDayOfMonth(dateEnd);
    async function fetchData() {
      if (!search) {
        setPage(1);
        setEntries([]);
        return await fetchEntries(start, end);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  //se o usuário excluir ou editar algum item
  useEffect(() => {
    async function fetchReloadCategories() {
      const start = startOfMonth(dateStart);
      const end = lastDayOfMonth(dateEnd);

      if (reloadCategory && reloadItem) {
        await fetchCategories(start, end);
        await fetchValueEntries(start, end, toggleButton, search);
        setReloadCategory(false);
      }
    }
    fetchReloadCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCategory, reloadItem]);

  return (
    <Context.Provider
      value={{
        id,
        toggleButton,
        loading,
        setLoading,
        setEntries,
        selectEntry,
        modalUpdate,
        setModalUpdate,
        showModalDelete,
        setShowModalDelete,
        setSelectEntry,
        entries,
        dateStart,
        dateEnd,
        allEntries,
        setAllEntries,
        setIsLoad,
      }}
    >
      <Container>
        <ContainerLeft>
          <Header>
            <Title>Extrato</Title>
            {isLoad ? <Loading /> : ''}
            <div className="inputLeft">
              <DivButton
                data-tip="Recebimentos"
                selected={toggleButton === 'receipts'}
                color={toggleButton === 'receipts' ? 'receipts' : ''}
                onClick={() => {
                  setPage(1);
                  setAllEntries([]);
                  setCategories([]);
                  setSearch('');
                  setToggleButton('receipts');
                }}
              >
                <RiWallet2Line size={28} />
              </DivButton>

              <DivButton
                data-tip="Gastos"
                selected={toggleButton === 'spendings'}
                color={toggleButton === 'spendings' ? 'spendings' : ''}
                onClick={() => {
                  setPage(1);
                  setAllEntries([]);
                  setCategories([]);
                  setSearch('');
                  setEntries([]);

                  setToggleButton('spendings');
                }}
              >
                <AiOutlineShoppingCart size={28} />
              </DivButton>

              <DivButton
                data-tip="Investimentos"
                selected={toggleButton === 'investments'}
                onClick={() => {
                  setShowInvestments(true);
                }}
              >
                <BsGraphUp size={28} />
              </DivButton>
              <div className="container-date-picker">
                <DatePicker
                  selected={dateStart}
                  onChange={date => setDateStart(date)}
                  className="date-picker"
                  locale="pt-br"
                  dateFormat="MMMM"
                  showMonthYearPicker
                  text="De"
                />
              </div>
              <div className="container-date-picker">
                <DatePicker
                  selected={dateEnd}
                  onChange={date => setDateEnd(date)}
                  className="date-picker"
                  locale="pt-br"
                  dateFormat="MMMM"
                  showMonthYearPicker
                  text="Até"
                />
              </div>
            </div>
          </Header>
          {toggleButton !== 'investments' && (
            <GeneralExtract
              categories={categories}
              isLoad={isLoad}
              setIsLoad={setIsLoad}
              setReloadItem={setReloadItem}
              reloadItem={reloadItem}
              reloadCategory={reloadCategory}
              setReloadCategory={setReloadCategory}
              color={toggleButton}
            />
          )}
        </ContainerLeft>
        {toggleButton !== 'investments' && (
          <ContainerRight>
            <>
              <Header>
                <Title>Extrato Detalhado</Title>
                {loading ? <Loading /> : ''}
                <DivInput className={showInput} color={toggleButton}>
                  {!showInput ? (
                    <button
                      onClick={() => {
                        setShowInput(!showInput);
                        inputRef.current.focus();
                      }}
                      type="submit"
                    >
                      <AiOutlineSearch size={28} />
                    </button>
                  ) : (
                    <></>
                  )}
                  <input
                    ref={inputRef}
                    className={showInput ? 'show' : ''}
                    type="text"
                    placeholder="Nome, valor..."
                    onChange={e => setSearch(e.target.value)}
                  />
                  {showInput ? (
                    <button className="search" onClick={handleSearch}>
                      Buscar
                    </button>
                  ) : (
                    <></>
                  )}
                </DivInput>
              </Header>
              <DetailedExtract
                totalEntries={totalEntries}
                color={toggleButton}
                page={page}
                search={search}
                setPage={setPage}
                setReloadCategory={setReloadCategory}
                setReloadItem={setReloadItem}
              />
            </>
          </ContainerRight>
        )}
        {showInvestments && (
          <Investments setShowInvestments={setShowInvestments} />
        )}
        {modalUpdate && (
          <UpdateModal
            reloadCategory={reloadCategory}
            setReloadCategory={setReloadCategory}
            setReloadItem={setReloadItem}
          />
        )}
        {showModalDelete && (
          <ModalExclude
            color="danger"
            setShowModal={setShowModalDelete}
            title="Confirmar Exclusão? "
            icon={<AiOutlineDelete />}
          >
            <Button
              color="danger"
              onClick={() => {
                handleDeleteSpending(selectEntry);
              }}
            >
              {loading ? <Loading /> : 'Confirmar'}
            </Button>
          </ModalExclude>
        )}
        <ReactTooltip />
      </Container>
    </Context.Provider>
  );
}
