import React, { useContext, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { Bar } from 'react-chartjs-2';
import Button from 'components/Button';
import { optionsBarDisplayOff } from 'utils/optionsBar';
import { ParamsContext } from '../Provider';
import List from '../List'
import {

  ContainerTitleCard,
  CardContent,
  Body,
  Header,
  AreaDetail,
  DivDetail,
  DivChart,
  DivTotal

} from '../styles';

import Card from 'components/Cards';
import useBudget from 'hooks/useBudget';
import { buildBudget } from 'services/buildBudget';

export default function Estimated({ max }) {
  const [balance, setBalance] = useState();
  const theme = useContext(ThemeContext);
  const [selected, setSelected] = useState([]);
  const [showPots, setShowPots] = useState(false);
  const { postData } = useBudget();

  const {
    receipts,
    investments,
    spendings,
    passivePatrimonies,
  } = useContext(ParamsContext);

  const difference = useMemo(() => {

    const inside =
      passivePatrimonies.entries +
      spendings['Mensal Flexível'] +
      spendings['Mensal Comprometido'] +
      spendings['Eventual Flexível'] +
      investments.estimated +
      spendings['Eventual Comprometido'];

    const outside = receipts.estimated;

    return (Number(outside) - Number(inside))
  }, [

    investments.estimated,
    passivePatrimonies.entries,
    receipts.estimated,
    spendings,
  ]);

  const dataChart = {
    labels: [''],
    datasets: [
      {
        label: 'Receitas',
        data: [
          selected.includes('Recebimentos') ? 0
            : receipts?.estimated
        ],
        backgroundColor: 'transparent',
        borderColor: theme.receipts.default,
        borderSkipped: 'none',
        borderWidth: 3,
        yAxisID: 'right',
      },
      {
        label: 'Dívidas',
        data: [
          selected.includes('Dívidas') ? 0
            : passivePatrimonies?.entries],
        backgroundColor: theme.debts.default,
      },
      {
        label: 'Patrimônio pássivo',
        data: [0],
        backgroundColor: theme.debts.hover,
        borderWidth: 0,
      },
      {
        label: 'Gastos mensais comprometidos',
        data: [
          selected.includes('Mensais Comprometidos') ? 0 :
            spendings['Mensal Comprometido']
        ],
        backgroundColor: theme.spendings.hover,
        borderWidth: 0,
      },
      {
        label: 'Gastos mensais flexíveis',
        data: [
          selected.includes('Mensais Flexíveis') ? 0 :
            spendings['Mensal Flexível']
        ],
        backgroundColor: theme.spendings.default,
        borderWidth: 0,
      },
      {
        label: 'Gastos eventuais flexíveis',
        data: [
          selected.includes('Eventuais Flexíveis') ? 0 :
            spendings['Eventual Flexível']
        ],
        backgroundColor: theme.spendings.custom,
        borderWidth: 0,
      },
      {
        label: 'PGE-C',
        data: [
          selected.includes('PGE-C') ? 0 :
            spendings['Eventual Comprometido']
        ],
        backgroundColor: theme.pgec.default,
        borderWidth: 0,
      },
      {
        label: 'Investimentos',
        data: [
          selected.includes('Investimentos') ? 0 :
            investments.estimated
        ],
        backgroundColor: theme.investments.default,
        borderWidth: 0,
      },
    ],
  };


  function onOff(label) {
    let index;
    if (selected.includes(label)) {
      index = selected.indexOf(label);

      setSelected(selected.filter((_, i) => i !== index));

      switch (label) {
        case "PGE-C": setBalance(balance - spendings['Eventual Comprometido']);
          break;

        case "Recebimentos": setBalance(balance + receipts.estimated);
          break;

        case "Investimentos": setBalance(balance - investments.estimated);
          break;

        case "Eventuais Flexíveis": setBalance(balance - spendings['Eventual Flexível']);
          break;

        case "Mensais Flexíveis": setBalance(balance - spendings['Mensal Flexível']);
          break;

        case "Mensais Comprometidos": setBalance(balance - spendings['Mensal Comprometido']);
          break;

        case "Dívidas": setBalance(balance - passivePatrimonies.entries);
          break;

        default:
          break;
      }
    }
    else {
      setSelected([...selected, label]);

      switch (label) {
        case "PGE-C": setBalance(balance + spendings['Eventual Comprometido']);
          break;

        case "Recebimentos": setBalance(balance - receipts.estimated);
          break;

        case "Investimentos": setBalance(balance + investments.estimated);
          break;

        case "Eventuais Flexíveis": setBalance(balance + spendings['Eventual Flexível']);
          break;

        case "Mensais Flexíveis": setBalance(balance + spendings['Mensal Flexível']);
          break;

        case "Mensais Comprometidos": setBalance(balance + spendings['Mensal Comprometido']);
          break;

        case "Dívidas": setBalance(balance + passivePatrimonies.entries);
          break;
        default:
          break;
      }
    }
  }

  useEffect(() => {
    if (selected.length === 0) return setBalance(difference);

  }, [selected, difference]);

  async function Budget(){
    const data = buildBudget(selected, receipts, investments, spendings, passivePatrimonies) 
    
    await postData(data);
  }


  return (
    <ContainerTitleCard>
      {showPots && <List setShowPots={setShowPots}>
      </List>}
      <div className="header">
        <Header SpaceBetween>Detalhamento Pote Estimado
          <div className='buttons'>
            <Button
              color="investments"
              className="pot"
              onClick={() => { setShowPots(true) }}>Consultar Pote
            </Button>
            <Button
              color="investments"
              className="pot"
              onClick={Budget}>Gravar Pote
            </Button>
          </div>
        </Header>

      </div>
      <Card className="containerPot" notPadding>
        <CardContent>
          <Body>
            <AreaDetail>
              {/* Recebimentos */}
              <DivDetail color={theme.receipts.default} onClick={() => onOff("Recebimentos")}
              >
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes("Recebimentos") ? 'selected' : ''} >Recebimentos</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("Recebimentos") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        receipts.estimated.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </span>
                </div>
              </DivDetail>
              {/* Investimentos */}
              <DivDetail color={theme.investments.default} onClick={() => onOff("Investimentos")} >
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes("Investimentos") ? 'selected' : ''}>Investimentos</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("Investimentos") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        investments.estimated.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </span>
                </div>
              </DivDetail>
              {/* PGE-C */}
              <DivDetail color={theme.pgec.default} onClick={() => onOff("PGE-C")} >
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes("PGE-C") ? 'selected' : ''}>PEC</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("PGE-C") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        spendings['Eventual Comprometido'].toLocaleString(
                          'pt-br',
                          {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }
                        )}
                  </span>
                </div>
              </DivDetail>
              {/* Eventual Flexível */}
              <DivDetail color={theme.spendings.custom} onClick={() => onOff("Eventuais Flexíveis")} >
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes("Eventuais Flexíveis") ? 'selected' : ''}>Eventuais Flexíveis</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("Eventuais Flexíveis") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        spendings['Eventual Flexível'].toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </span>
                </div>
              </DivDetail>
              {/* Mensal Flexível */}
              <DivDetail color={theme.spendings.default} onClick={() => onOff("Mensais Flexíveis")}>
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes("Mensais Flexíveis") ? 'selected' : ''} >Mensais Flexíveis</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("Mensais Flexíveis") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        spendings['Mensal Flexível'].toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </span>
                </div>
              </DivDetail>
              {/* Mensal Comprometido */}
              <DivDetail color={theme.spendings.hover} onClick={() => onOff("Mensais Comprometidos")}>
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes("Mensais Comprometidos") ? 'selected' : ''}>Mensais Comprometidos</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("Mensais Comprometidos") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        spendings['Mensal Comprometido'].toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </span>
                </div>
              </DivDetail>
              {/* Compras Parceladas */}
              <DivDetail color={theme.debts.hover}>
                <div className="labels">
                  <div className="circle" />
                  <span className={'selected'}>Compras Parceladas</span>
                </div>
                <div className="values">
                  <span>
                    R$ 0
                  </span>
                </div>
              </DivDetail>
              {/* Dívidas */}
              <DivDetail color={theme.debts.default} onClick={() => onOff("Dívidas")}>
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes("Dívidas") ? 'selected' : ''}>Dívidas</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("Dívidas") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        passivePatrimonies.entries.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </span>
                </div>
              </DivDetail>
              <DivTotal>
                <span className="total">Saldo</span>
                <span className="value">{balance?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
                </span>
              </DivTotal>
            </AreaDetail>
            <DivChart >
              <Bar data={dataChart} options={optionsBarDisplayOff(max)} />
            </DivChart>
          </Body>
        </CardContent>
      </Card>
    </ContainerTitleCard>
  );
}

Estimated.propTypes = {
  max: PropTypes.number.isRequired,
};
