import styled from 'styled-components';

export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: 200ms ease;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.16);
  width: ${props => (props.width ? props.width : '440px')};
  height: ${props => (props.height ? props.height : 'auto')};

  div.head {
    display: flex;
    justify-content: space-between;

    /* background: ${props =>
      props.variant
        ? props.theme[props.color][props.variant]
        : props.theme[props.color].default}; */

    background: ${props => props.theme.white.default};

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 78px;
    align-items: center;
    color: #fff;
    font-size: 20px;
    padding: 30px 20px 0px 20px;
    transition: 200ms ease;
    div.titleIcon{


      display: flex;
      justify-content: space-between;
      align-items:center;
    }

    div.icon {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 48px;
      height: 48px;
      border-radius: 10px;

      background: ${props => props.theme[props.color].disabled};

      svg {
        fill: ${props => props.theme[props.color].default};
        width: 30px;
        height: 30px;
      }
    }

    span {
      margin-left: 8px;
      font-size: 24px;
      line-height: 1.33;
      text-align: left;

      color: ${props => props.theme[props.color].default};
    }

    div.close {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 10%;
      margin-left: 10px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 10px;

        background: ${props => props.theme.grafit.custom};

       svg {
         fill: ${props => props.theme.darker.disabled};
       }
      }

    }
  }

  div.body {
    display: flex;
    background: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 30px;
    height: ${props => (props.height ? props.height : 'auto')};

  }
`;

export const DivBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  background: rgba(167, 167, 167, 0.6);
  backdrop-filter: blur(10px);
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
`;
