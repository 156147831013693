/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import ReactTooltip from 'react-tooltip';

import { MdModeEdit } from 'react-icons/md';

import { Container, Title, Table, THead, TBody, TdHover } from './styles';

import Card from 'components/Cards';

export default function TableComponent({
  title,
  head,
  body,
  category,
  setShowModal,
  setSelectedItem,
}) {


  const [toggleEdit, setToggleEdit] = useState(false);

  const [toggleButton, setToggleButton] = useState(null);

  // Nome e valor estimado
  const firstHead = head.slice(0, 2);
  let firstBody = body.map(item => item.slice(0, 2));

  // 6 meses
  const secondHead = head.slice(2, 8);
  let secondBody = body.map(item => item.slice(2, 8));

  // Média e soma
  const thirdHead = head.slice(8);
  let thirdBody = body.map(item => item.slice(8, 10));


  if (body.length === 0) {
    firstBody = [[]];
    secondBody = [[]];
    thirdBody = [[]];
  }

  const totalEstimated = useMemo(() => {
    let total;
    if (title === "Investimentos") {
      total = body.map(item => item[10]);
      return total.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    }
    total = category.items
      .reduce((total, current) => {
        if (current.classification.includes('Eventual')) {
          return total + current.value / 12;
        }
        return total + current.value;
      }, 0)
    return total.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }, [body, category, title])


  const totalSecundBody = (row, index) => {
    let total;
    if (title === "Investimentos") {
      total = secondBody[0][index]
    } else {
      total = category?.items
        .reduce((total, current) => {
          const report = Object.values(current.report)[
            index + 1
          ];
          return total + report?.total;
        }, 0)

    }

    return total?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }




  return (
    <>
      <Card width="100%" height="auto">
        <Title>
          <span>{title}</span>
          <span className="accompliched">Realizado</span>
          <small>* Para editar passe o mouse por cima do nome do item.</small>
        </Title>
        <Container>
          {/* Primeira tabela -> nome e valor do item */}
          <Table width={20}>
            <THead>
              <tr>
                {firstHead.map(text => (
                  <th key={text}>{text}</th>
                ))}
              </tr>
            </THead>
            <TBody>
              {firstBody.map((row, index) => (
                <tr key={Math.random()}>
                  {row.map(column => {
                    if (typeof column === 'object') {
                      return (
                        <td key={Math.random()} className={column.className}>
                          {column.value}
                        </td>
                      );
                    }
                    
                    return (

                      <TdHover
                        key={Math.random()}
                        onMouseEnter={() => {
                          setToggleEdit(true);
                          setToggleButton(column);
                        }}
                        onMouseLeave={() => {
                          setToggleEdit(false);
                          setToggleButton(null);
                        }}
                        data-tip={column}
                      >
                        {toggleEdit && toggleButton === column && (
                          <button
                            onClick={() => {
                              setShowModal(true);
                              setSelectedItem(body[index]);
                              setToggleEdit(false);
                              setToggleButton(null);
                            }}
                          >
                            <MdModeEdit size={23} />
                          </button>
                        )}
                        <span>{column}</span>
                      </TdHover>
                    );
                  })}
                </tr>
              ))}
              <tr className="total">
                <td>Total:</td>
                <td>
                  {totalEstimated}
                </td>
              </tr>
            </TBody>
          </Table>

          {/* Segunda tabela -> meses do ano */}
          <Table width={65}>
            <THead>
              <tr className="date-picker">
                {secondHead.map((text, index) => {
                  if (index === 0) {
                    return (
                      <th key={text}>
                        {/* <button
                        className="previous"
                        onClick={() => handleMonths('previous')}
                      >
                        <AiOutlineCaretLeft size={28} />
                      </button> */}
                        {text}
                      </th>
                    );
                  }
                  if (secondHead.length - 1 === index) {
                    return (
                      <th key={text}>
                        {text}
                        {/* <button
                        className="next"
                        onClick={() => handleMonths('next')}
                      >
                        <AiOutlineCaretRight size={28} />
                      </button> */}
                      </th>
                    );
                  }
                  return <th key={text}>{text}</th>;
                })}
              </tr>
            </THead>
            <TBody>
              {secondBody.map(row => {
                return (
                  <tr key={Math.random()}>
                    {row.map(column => {
                      return <td key={Math.random()}>{column}</td>;
                    })}
                  </tr>
                );
              })}
              <tr className="total">
                {secondBody[0].map((row, index) => {
                  return (
                    <td key={Math.random()}>
                      {totalSecundBody(row, index)}
                    </td>
                  );
                })}
              </tr>
            </TBody>
          </Table>

          {/* Terceita tabela -> valores do item */}
          <Table width={15}>
            <THead>
              <tr>
                {thirdHead.map(text => (
                  <th key={text}>{text}</th>
                ))}
              </tr>
            </THead>
            <TBody>
              {thirdBody.map(row => {
                return (
                  <tr key={Math.random()}>
                    {row.map(column => {
                      return <td key={Math.random()}>{column}</td>;
                    })}
                  </tr>
                );
              })}
            </TBody>
          </Table>
        </Container>
      </Card>
      <ReactTooltip />
    </>
  );
}

TableComponent.propTypes = {
  title: PropTypes.string.isRequired,
  head: PropTypes.arrayOf(PropTypes.string).isRequired,
  body: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.array), PropTypes.array]).isRequired,
  category: PropTypes.shape(),
  setShowModal: PropTypes.func,
  setSelectedItem: PropTypes.func,
};
