import React from 'react';

import { Container, Title, LogoConstruction } from './styles';

import underConstructionSvg from 'assets/construction.svg';

export default function UnderConstruction() {
  return (
    <Container>
      <Title>Em Construção</Title>
      <LogoConstruction src={underConstructionSvg} />
    </Container>
  );
}
