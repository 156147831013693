import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { AiOutlineMore, AiFillCheckCircle } from 'react-icons/ai';
import { RiAddLine } from 'react-icons/ri';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { Form, Table } from './styles';
import { Content, DivButtons } from '../styles';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Input from 'components/Input';
import Dropdown from 'components/Button/Dropdown';
import ModalUpdate from './UpdateModal';
import { CurrencyInput } from 'components/masks';
import api from 'services/api';
import Can from 'components/Can';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css'


export default function PassivePatrimony({ dataPassive, setDataPassive }) {
  // Dados do formulário
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [institution, setInstitution] = useState(''); // Instituição
  const [installment, setInstallment] = useState(0); // Valor da parcela
  const [remainingInstallments, setRemainingInstallments] = useState(0); // Parcelas restantes
  const [rate, setRate] = useState(0); // Taxa
  const [inCash, setInCash] = useState(0); // Valor à vista

  // Patrimônio selecionado
  const [selectedPatrimony, setSelectedPatrimony] = useState({});

  // Toggle para mostrar modal
  const [showModal, setShowModal] = useState(false);

  // Toggle para modal de opções
  const [showModalOptions, setShowModalOptions] = useState(false);

  // Toggle para modal de update
  const [showModalUpdate, setShowModalUpdate] = useState(false);

  // Toggle para modal de delete
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const { id } = useSelector(state => state.user.profile);

  const colors = useContext(ThemeContext);

  const headTable = [
    <div style={{ textAlign: 'start' }}>Passivo</div>,
    'Instituição',
    'PMT',
    'Nº Parcelas',
    'Taxa',
    'Valor À Vista',
    'Valor Total',
    'Descontos',
    'Quitados',
    '',
  ];

  useEffect(() => {
    const j = Number(rate) / 100;
    const n = Number(remainingInstallments);
    const p = Number(installment);
    const result = ((1 - (1 + j) ** -n) / j) * p;

    if (isNaN(result)) {
      setInCash(0);
    } else {
      setInCash(result);
    }
  }, [installment, rate, remainingInstallments]);

  function handleShowModal(type_passive) {
    setType(type_passive);
    setShowModal(true);
  }

  function calcDiscount(inCash, remainingInstallments, installment) {
    const result = (1 - inCash / (remainingInstallments * installment)) * 100;

    // eslint-disable-next-line
    if (isNaN(result)) return '0%';

    return `${result.toLocaleString('pt-br')}%`;
  }

  function calcTotalValue(installment, remainingInstallments) {
    return (installment * remainingInstallments).toLocaleString('pt-br', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  async function handleSettled_at(patrimony) {
    try {
      setLoading(true);
      const {
        name,
        type,
        institution,
        installment,
        remainingInstallments,
        rate,
        inCash,
      } = patrimony;

      let settled_at = new Date();

      if (patrimony.settled_at) settled_at = null;

      const { data } = await api.put(`/passive_patrimony/${patrimony.id}`, {
        name,
        type,
        institution,
        installment,
        remainingInstallments,
        rate,
        inCash,
        settled_at,
      });

      setDataPassive(pass =>
        pass.map(patr => {
          if (patr.id !== patrimony.id) return patr;
          return data;
        })
      );
      setShowModal(false);
      setLoading(false);
      setShowModalOptions(!showModalOptions);
      return toast.success('Menos uma');
    } catch (err) {
      setLoading(false);
      setShowModalOptions(!showModalOptions);
      return toast.error('Erro ao quitar passivo');
    }
  }

  async function handleDeletePatrimony(patrimony_id) {
    try {
      setLoading(true);
      api.delete(`/passive_patrimony/${patrimony_id}`);
      setDataPassive(
        dataPassive.filter(patrimony => patrimony.id !== patrimony_id)
      );

      setLoading(false);
      toast.success('Passivo excluido com sucesso');
    } catch (err) {
      setLoading(false);
      toast.error('Falha ao excluir passivo');
    }
    setShowModalOptions(!showModalOptions);
  }

  function tableBody(type_patrimony) {
    return dataPassive
      .filter(patrimony => patrimony.type === type_patrimony)
      .map(patrimony => [
        <Tippy content={patrimony?.name}>
          <div
            style={{
              textAlign: 'start',
              marginLeft: '20px',
            }}
          >
            {' '}
            {patrimony?.name}
          </div>
        </Tippy>,
        patrimony?.institution,

        {
          value: (patrimony?.installment).toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          currency: 'R$',
        },

        patrimony?.remainingInstallments,

        `${(patrimony?.rate).toLocaleString('pt-br')}%`,

        {
          value: (patrimony?.inCash).toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          currency: 'R$',
        },

        {
          value: calcTotalValue(
            patrimony?.installment,
            patrimony?.remainingInstallments
          ),
          currency: 'R$',
        },

        calcDiscount(
          patrimony?.inCash,
          patrimony?.remainingInstallments,
          patrimony?.installment
        ),

        patrimony?.settled_at && (
          <div className="settled_at">
            {format(new Date(patrimony?.settled_at), 'dd/MM/yyyy')}
            <AiFillCheckCircle size={28} color={colors.success.default} />
          </div>
        ),

        <Can canRoles={['planner', 'adm', 'cs', 'clientPfg']}>
          <Dropdown icon={<AiOutlineMore />}>
            {patrimony?.settled_at ? (
              <button onMouseDown={() => handleSettled_at(patrimony)}>
                Desquitar
              </button>
            ) : (
              <>
                <button onMouseDown={() => handleSettled_at(patrimony)}>
                  Quitar
                </button>
                <button
                  onMouseDown={() => {
                    setShowModalUpdate(!showModalUpdate);
                    setSelectedPatrimony(patrimony);
                  }}
                >
                  Editar
                </button>
                <button
                  onMouseDown={() => {
                    setShowDeleteModal(!showDeleteModal);
                    setSelectedPatrimony(patrimony);
                  }}
                >
                  Excluir
                </button>
              </>
            )}
          </Dropdown>
        </Can>,
      ])
      .sort((befValue, aftValue) => {
        if (befValue[8] === null && aftValue[8] !== null) return -1;
        if (befValue[8] !== null && aftValue[8] === null) return 1;
        return 0;
      });
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);
      const response = await api.post(`/users/${id}/passive_patrimony`, {
        name,
        type,
        institution,
        installment,
        remainingInstallments,
        rate,
        inCash,
      });

      setDataPassive([...dataPassive, response.data]);

      setName('');
      setType('');
      setInstitution('');
      setInstallment('');
      setRemainingInstallments('');
      setRate('');
      setInCash('');

      setLoading(false);
      setShowModal(false);

      toast.success('Criado com sucesso');
    } catch (err) {
      setLoading(false);
      toast.error('Falha ao criar patrimônio');
    }
  }

  return (
    <Content column>
      <Table
        title="Passivos Patrimoniais"
        color="debts"
        head={headTable}
        body={tableBody('patrimonial-passive')}
        total={dataPassive
          .filter(pp => pp.type === 'patrimonial-passive')
          .reduce((total, currentValue) => {
            if (currentValue.settled_at) return total;
            return total + currentValue.installment;
          }, 0)}
        buttonIcons={[
          <Can
            key={Math.random()}
            canRoles={['planner', 'adm', 'cs', 'clientPfg']}
          >
            <Button
              color="debts"
              onClick={() => handleShowModal('patrimonial-passive')}
            >
              Novo
            </Button>
          </Can>,
        ]}
      />
      <Table
        total={dataPassive
          .filter(pp => pp.type === 'passive-financial')
          .reduce((total, currentValue) => {
            if (currentValue.settled_at) return total;
            return total + currentValue.installment;
          }, 0)}
        title="Passivos Não Patrimoniais"
        color="debts"
        head={headTable}
        body={tableBody('passive-financial')}
        buttonIcons={[
          <Can
            key={Math.random()}
            canRoles={['planner', 'adm', 'cs', 'clientPfg']}
          >
            <Button
              color="debts"
              onClick={() => handleShowModal('passive-financial')}
            >
              Novo
            </Button>
          </Can>,
        ]}
      />
      {showModalUpdate && (
        <ModalUpdate
          selectedPatrimony={selectedPatrimony}
          setPassivePatrimony={setDataPassive}
          calcDiscount={calcDiscount}
          calcTotalValue={calcTotalValue}
          setShowModalUpdate={setShowModalUpdate}
        />
      )}
      {showDeleteModal && (
        <Modal
          color="danger"
          setShowModal={setShowDeleteModal}
          title="Confirmar Exclusão? "
          icon={null}
        >
          <DivButtons>
            <Button
              color="danger"
              onClick={() => {
                handleDeletePatrimony(selectedPatrimony.id);
                setShowDeleteModal(!showDeleteModal);
              }}
            >
              {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
            </Button>
          </DivButtons>
        </Modal>
      )}
      {showModal && (
        <Modal
          color="debts"
          setShowModal={setShowModal}
          title="Cadastrar Novo Passivo"
          icon={<RiAddLine/>}
        >
          <Form onSubmit={handleSubmit}>
            <Input
              label="Nome do Passivo"
              width="100%"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <Input
              label="Instituição"
              width="100%"
              value={institution}
              onChange={e => setInstitution(e.target.value)}
            />

            <div className="divGroup">
              <Input
                label="Parcelas restantes"
                width="50%"
                value={remainingInstallments}
                onChange={e => setRemainingInstallments(e.target.value)}
                type="number"
                min="0"
              />
              <CurrencyInput
                suffix="% a.m."
                label="Taxa"
                width="50%"
                value={rate}
                onChange={setRate}
              />
            </div>

            <div className="divGroup">
              <CurrencyInput
                label="Valor da Parcela:"
                prefix="R$ "
                width="70%"
                value={installment}
                onChange={cleanValue => setInstallment(cleanValue)}
              />
              <CurrencyInput
                label="Valor à vista:"
                prefix="R$ "
                width="70%"
                value={inCash}
                onChange={cleanValue => setInCash(cleanValue)}
              />
            </div>

            <div className="divGroup">
              <Input
                label="Saldo Atual:"
                width="50%"
                value={calcTotalValue(remainingInstallments, installment)}
                disabled
                style={{'background' : 'rgb(233, 233, 233)'}}
              />
              <Input
                label="Desconto:"
                width="50%"
                value={calcDiscount(inCash, remainingInstallments, installment)}
                disabled
                style={{'background' : 'rgb(233, 233, 233)'}}
              />
            </div>

            <div className="buttons">
              <Button type="submit">
                {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
              </Button>
            </div>
          </Form>
        </Modal>
      )}
    </Content>
  );
}

PassivePatrimony.propTypes = {
  dataPassive: PropTypes.arrayOf(PropTypes.object).isRequired,
  setDataPassive: PropTypes.func.isRequired,
};
