import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { format, subYears, addYears } from 'date-fns';
import { pt } from 'date-fns/locale';
import api from 'services/api';
import capitalize from 'utils/capitalizeWords';
import { Wrapper, FormatValue, ContainerDate} from '../styles';
import ProgressBar from 'components/ProgressBar';
import Loading from 'components/Loading';
import Table from '../Table';
import {ContainerBar} from './styles';
import CardProgress from '../CardProgress/index';

export default function FlexibleEventual({ icon, theme }) {

  const [flexEventual, setFlexEventual] = useState([]);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const { id } = useSelector(state => state.user.profile);
  const [estimated, setEstimated] = useState(0);
  const [accomplished, setAccomplished] = useState(0);

  const head = [
    'Item',
    'Categoria',
    'Estimado',
    'Realizado',
    '',
    'Saldo para gastar',
  ];

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await api.get(
        `/users/${id}/spending_classification_report`,
        {
          params: {
            classification: 'Eventual Flexível',
            date,
          },
        }
      );
      let totalEstimated = data.reduce((total, current)=> total + current.value, 0);
      setEstimated(totalEstimated);
      let totalAccomplished = data.reduce((total, current)=> total + current.total, 0);
      setAccomplished(totalEstimated-totalAccomplished);


      if (data.length >= 1) {
        setFlexEventual(
          data.map(item => [
            item.name,
            item.category.name,
            {
              value: item.value.toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              }),
              currency: 'R$'
           },
           {
             value: item.total.toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
              currency: 'R$'
           },

            <ContainerBar>
              <ProgressBar
                className="bar"
                color={item.value - item.total < 0 ? 'danger' : 'spendings'}
                fontSize="14px"
                left={handlePorcentageValue(item.total, item.value, true)}
                fill={handlePorcentageValue(item.total, item.value)}
              />
            </ContainerBar>,
            <FormatValue
              color="spendings"
              variant="hover"
              isNegative={item.value - item.total < 0}
            >
              <div>
                <span>R$</span>
                <span>
                {Math.abs(item.value - item.total).toLocaleString('pt-br', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
                </span>
              </div>
            </FormatValue>,
          ])
        );
        setLoading(false);
      }
    }
    fetchData();
  }, [date, id]);

  function handlePorcentageValue(entry, estimated, withPercentage) {
    let result = (entry / estimated) * 100;

    if (isNaN(result)) {
      result = 0;
    }

    if (withPercentage) {
      return `${result.toLocaleString('pt-br', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}%`;
    }

    return result;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Wrapper>
          <Table
            title="Eventual Flexível"
            className="flexibleEventual"
            color={theme.darker.hover}
            head={head}
            body={flexEventual}
            upperCenterContent={
              <ContainerDate color={theme.darker.default} key={Math.random()}>
                <button
                  onClick={() => setDate(date => subYears(new Date(date), 1))}
                >
                  <AiOutlineLeft size={32} />
                </button>
                <div className="date">
                  <span>
                    {capitalize(format(date, 'yyyy', { locale: pt }))}
                  </span>
                </div>
                <button
                  onClick={() => setDate(date => addYears(new Date(date), 1))}
                >
                  <AiOutlineRight size={32} />
                </button>
              </ContainerDate>
            }
            upperRightContent={[
              <CardProgress
                left={
                  estimated.toLocaleString('pt-br', {
                    style:'currency',
                    currency:'BRL',
                    minimumFractionDigits:0,
                    maximumFractionDigits:0,
                  })
                }
                right={
                  accomplished.toLocaleString('pt-br', {
                    style:'currency',
                    currency:'BRL',
                    minimumFractionDigits:0,
                    maximumFractionDigits:0,
                  })
                }
                fill={(100-(100 * accomplished)/estimated)}
              />
              // <TotalValue key={Math.random()}>
              //   <div className="icon">{icon}</div>
              //   <div className="values">
              //     <span className="label">Total Eventual Flexível</span>
              //     <span className="total">
              //       {apiData
              //         .reduce((total, current) => total + current.total, 0)
              //         .toLocaleString('pt-br', {
              //           style: 'currency',
              //           currency: 'BRL',
              //         })}
              //     </span>
              //   </div>
              // </TotalValue>,
            ]}
          />
        </Wrapper>
      )}
    </>
  );
}

FlexibleEventual.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  theme: PropTypes.shape({
    darker: PropTypes.shape({
      hover: PropTypes.string,
      default: PropTypes.string,
    }),
  }).isRequired,
};
