import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 350px;
  padding: 50px 0;

  svg {
    font-size: 50px;
    color: ${props => props.theme.spendings.default};
  }

  h2 {
    font-size: 34px;
    font-weight: bold;
    color: ${props => props.theme.grafit.hover};
    margin-bottom: 45px;
  }

  div.info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 200px;
    height: 200px;
    border: 5px solid ${props => props.theme.spendings.default};
    border-radius: 50%;
    margin-bottom: 45px;

    span {
      font-size: 60px;
      font-weight: bold;
      color: ${props => props.theme.spendings.default};
      margin-bottom: 5px;
    }

    small {
      font-size: 20px;
      color: ${props => props.theme.spendings.default};
    }
  }

  span.text {
    font-size: 20px;
    width: 244px;
    margin-bottom: 45px;
    text-align: center;
    line-height: 1.35;
    letter-spacing: 0.19px;
    color: ${props => props.theme.grafit.hover};

    small.blue {
      font-weight: bold;
      color: ${props => props.theme.spendings.default};
    }
  }
`;
