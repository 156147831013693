import styled from 'styled-components';
import TotalTable from 'components/Tables/TotalTable';

export const Table = styled(TotalTable)`
  tbody tr td:first-child {

    width: 14vw !important;
  }
  tbody tr td:nth-child(2){

    width: 14vw !important;
  }
`;
