import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { produce } from 'immer';

import {
  AiOutlineShoppingCart,
  AiOutlineCreditCard,
  AiOutlineLineChart,
  AiOutlineWallet,
} from 'react-icons/ai';

import { dayOptions, monthsOfYear } from 'utils/dates';

import Modal from 'components/Modal';
import Input from 'components/Input';
import CurrencyInput from 'components/masks/CurrencyInput';
import Select from 'components/Input/Dropdown';
import Button from 'components/Button';
import Loading from 'components/Loading';

import api from 'services/api';

import { Body } from './styles';

export default function CreateItemModal({
  type,
  category,
  categories,
  items,
  setCategories,
  setItems,
  setCreateItemModal,
}) {
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [classification, setClassification] = useState('Mensal Flexível');
  const [when, setWhen] = useState('');
  const [loading, setLoading] = useState(false);

  const user = useSelector(state => state.user.profile);

  let icon = null;

  switch (type) {
    case 'receipts':
      icon = <AiOutlineWallet />;
      break;
    case 'spendings':
      icon = <AiOutlineShoppingCart />;
      break;
    case 'debts':
      icon = <AiOutlineCreditCard />;
      break;
    case 'investments':
      icon = <AiOutlineLineChart />;
      break;
    default:
  }

  const options = [
    { value: 'Mensal Flexível', label: 'Mensal Flexível' },
    { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
    { value: 'Eventual Flexível', label: 'Eventual Flexível' },
    { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
  ];

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setLoading(true);
      const { data } = await api.post(
        `users/${user.id}/categories/${category.id}/items`,
        {
          user_id: user.id,
          category_id: category.id,
          name,
          value,
          classification,
          when:
            classification === 'Eventual Comprometido' ||
            classification === 'Mensal Comprometido'
              ? when
              : null,
        }
      );

      const newCategories = produce(categories, draft => {
        draft[category.id].items.push(data.id);
      });

      const newItems = produce(items, draft => {
        draft[data.id] = data;
      });

      setItems(newItems);
      setCategories(newCategories);

      toast.success('Item criado com sucesso!');

      setCreateItemModal(false);
      return setLoading(false);
    } catch (err) {
      setLoading(false);
      return toast.error('Ops, tivemos um erro!');
    }
  }

  function checkWhen(e) {
    const { value: whenValue } = e.target;

    if (whenValue > 31) {
      return toast.error('O valor de "Quando" deve ser um dia do mês válido');
    }

    return setWhen(whenValue);
  }

  return (
    <Modal
      title="Novo item"
      setShowModal={setCreateItemModal}
      color={type}
      icon={icon}
    >
      {loading ? (
        <Loading />
      ) : (
        <Body>
          <form onSubmit={handleSubmit}>
            <Input
              value={name}
              onChange={e => setName(e.target.value)}
              label="Nome do item"
            />
            <Select
              onChange={e => setClassification(e.value)}
              options={options}
              placeholder=""
              label="Classificação"
            />

            {!classification === 'Mensal Flexível' ||
              (!classification === 'Eventual Flexível' && (
                <Input
                  value={when}
                  onChange={checkWhen}
                  type="number"
                  label="Quando"
                />
              ))}

            {classification.includes('Mensal Comprometido') && (
              <Select
                label="Dia do Mês"
                options={dayOptions()}
                onChange={selected => setWhen(selected.value)}
              />
            )}

            {classification.includes('Eventual Comprometido') && (
              <Select
                label="Mês do Ano"
                options={monthsOfYear()}
                onChange={selected => setWhen(selected.value)}
              />
            )}

            <CurrencyInput
              value={value}
              prefix="R$ "
              onChange={e => setValue(e)}
              label="Valor"
            />

            <div className="buttons">
              <Button type="submit">Confirmar</Button>
            </div>
          </form>
        </Body>
      )}
    </Modal>
  );
}

CreateItemModal.propTypes = {
  type: PropTypes.string.isRequired,
  setCreateItemModal: PropTypes.func.isRequired,
  category: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  categories: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  items: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  setCategories: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired,
};
