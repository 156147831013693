import React from 'react';

import PropTypes from 'prop-types';

import { Container } from './styles';

export default function CheckBox({ value, ...rest }) {
  return (
    <Container>
      <input type="checkbox" value={value} {...rest} />
    </Container>
  );
}

CheckBox.propTypes = {
  value: PropTypes.string.isRequired,
  // checked: PropTypes.bool,
};

CheckBox.defaultProps = {
  // checked: false,
};
