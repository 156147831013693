import styled from 'styled-components';

export const Container = styled.div`

  & > h2 {
    margin-top:25px;
    margin-bottom: 20px;
    &.title{
      font-family: Nunito;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #4d4d70;
    }
  }

  div.goal {
    height: 100px;
    margin-bottom: 34px;
    border-radius: 10px;
    border: solid 1px #b3b3c2;
    background-color: #ffffff;
    box-shadow: unset;
     padding-top: 16px !important;
     /* padding-bottom: 0 !important; */
    .info{
      display: flex;
      align-items: center;
      flex-direction: unset;
      padding-top: 8px;
      margin-bottom: 8px;

      & > div.left{
        margin-top: 8px !important;
       & > p{
        font-family: Nunito;
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: 0px;
        text-align: left;
        color: #808099;
      }
    }
      & > div.right{
        margin-top: 8px !important;
        font-family: Nunito;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: 0px;
        text-align: right;
        color: #3ba7ff;
      }
    }
  }
`;

