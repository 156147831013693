import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { AiFillCheckCircle } from 'react-icons/ai';
import TotalTable from 'components/Tables/TotalTable';

export default function Investments({ goals }) {
  const theme = useContext(ThemeContext);
  const [totalFinancialIndependence, setTotalFinancialIndependence] = useState(0);
  const [totalDefaultGoals, setTotalDefaultGoals] = useState(0)
  useEffect(() => {
    const financialIndependence = goals.find(goal => goal.type === 'financial-independence');
    const goalsWithoutFinancialIndependence = goals.filter(goal => goal.type !== 'financial-independence')
    const total = goalsWithoutFinancialIndependence.reduce((total, currentValue) => {
      if (currentValue.done_at) return total;
      return total + currentValue.ifp;
    }, 0)

    setTotalFinancialIndependence(financialIndependence.ifp)
    setTotalDefaultGoals(total);
  }, [goals])
  const tableBodyFinancialIndependence = () => {
    const financialIndependence = goals.filter(goal => goal.type === 'financial-independence');

    const goalsFinancialIndependence = [[
      <div
        style={{
          textAlign: 'start',
          marginLeft: '20px',
        }}
      >
        Base de Plena Liquidez
      </div>,
      {
        value: financialIndependence[0].bpl?.toLocaleString('pt-br', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        currency: 'R$'
      },
      '-',
      {
        value: financialIndependence[0].financial_active_value_bpl?.toLocaleString('pt-br', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        currency: 'R$'
      },
      financialIndependence[0].when >= 0
        ? `${financialIndependence[0].when} Meses`
        : `Há ${financialIndependence[0].when * -1} Meses atrás`,
      financialIndependence[0].strategic_allocation_bpl,
      {
        value: financialIndependence[0]?.ifp?.toLocaleString('pt-br', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        currency: 'R$',
      } || '-',
      financialIndependence[0].done_at ? (
        <AiFillCheckCircle size={28} color={theme.success.default} />
      ) :
        ''
    ],
    [
      <div
        style={{
          textAlign: 'start',
          marginLeft: '20px',
        }}
      >
        PLF - Portifólio da Liberdade Financeira

      </div>,
      {
        value:
          financialIndependence[0].value?.toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        currency: 'R$'
      },
      {
        value:
          financialIndependence[0].patrimonial_active_value?.toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        currency: 'R$'
      },
      {
        value:
          financialIndependence[0].financial_active_value?.toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        currency: 'R$'
      },
      financialIndependence[0].when >= 0
        ? `${financialIndependence[0].when} Meses`
        : `Há ${financialIndependence[0].when * -1} Meses atrás`,
      financialIndependence[0].strategic_allocation,
      {
        value: financialIndependence[0]?.ifp?.toLocaleString('pt-br', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        currency: 'R$',
      } || '-',
      financialIndependence[0].done_at ? (
        <AiFillCheckCircle size={28} color={theme.success.default} />
      ) :
        ''
    ]
    ]

    return goalsFinancialIndependence

  }

  const tableBodyGoalsDefault = () => {
    const body = goals.filter(goal => goal.type !== 'financial-independence').map(goal => [
      <div
        style={{
          textAlign: 'start',
          marginLeft: '20px',
        }}
      >
        {goal.name}
      </div>,

      {
        value: goal?.value?.toLocaleString('pt-br', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        currency: 'R$',
      } || '-',
      !goal?.patrimonial_active_value
        ? '-'
        : {
          value: goal?.patrimonial_active_value?.toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          currency: 'R$',
        },
      {
        value:
          goal?.financial_active_value?.toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
        currency: 'R$'
      },

      goal.when >= 0
        ? `${goal.when} Meses`
        : `Há ${goal.when * -1} Meses atrás`,
      goal.strategic_allocation,
      {
        value: goal?.ifp?.toLocaleString('pt-br', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        currency: 'R$',
      } || '-',

      goal.done_at ? (
        <AiFillCheckCircle size={28} color={theme.success.default} />
      ) : (
        ''
      ),

    ]);

    return body;
  }

  function tableHead() {
    return [
      <div style={{ textAlign: 'start' }}>
        Sonho/Objetivo
      </div>,
      'Valor Total',
      'Patrimônio Imobilizado Atual',
      'Patrimônio Financeiro Atual',
      'Prazo',
      'Estratégia de alocação',

      'Investimento Mensal',

      'Concluído',

    ];
  }

  return (
    <>
      <TotalTable
        color="investments"
        title="Independência Financeira"
        head={tableHead()}
        body={tableBodyFinancialIndependence()}
        total={totalFinancialIndependence}
      />
      <TotalTable
        color="investments"
        title="Sonhos"
        head={tableHead()}
        body={tableBodyGoalsDefault()}
        total={totalDefaultGoals}

      />
    </>
  );
}

Investments.propTypes = {
  goals: PropTypes.arrayOf(PropTypes.object).isRequired,
};
