import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { AiOutlineMore } from 'react-icons/ai';

import { Container } from './styles';

export default function Dropdown({
  icon,
  children,
  className,
  color,
  variant,
}) {
  const [show, setShow] = useState(false);
  return (
    <Container className={className} color={color} variant={variant}>
      <button
        type="button"
        onClick={() => setShow(true)}
        onBlur={() => setTimeout(() => setShow(false), 250)}
      >
        {icon}
      </button>
      {show && <div className="options">{children}</div>}
    </Container>
  );
}

Dropdown.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

Dropdown.defaultProps = {
  icon: <AiOutlineMore />,
  className: '',
  color: 'grafit',
  variant: false,
};
