export default function capitalizeWords(string) {
  const words = string.split(' ');

  if (words.length > 0) {
    return words
      .map(word => {
        if (word) return `${word[0].toUpperCase()}${word.slice(1)}`;
        return '';
      })
      .join(' ');
  }
}
