import styled from 'styled-components';

export const measures = {
  sidebarWidth: 48,
  sidebarOpenWidth: 250,
  sidebarIconsSize: 40,
  sidebarHomeIconSize: 40,
  avatarSize: 60,
  headerHeight: 60,
  headerIconsSize: 25,
  searchIconSize: 30,
  wrapperSize: 40,
  scrollMargin: 10,
};

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background: #f0f1f7;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: ${ props => props.isOpen ? measures.sidebarOpenWidth : measures.sidebarWidth}px;
  /* margin-left: ${measures.sidebarWidth}px; */
  padding: 0px
    ${measures.wrapperSize - measures.scrollMargin}px ${measures.wrapperSize}px
    ${measures.wrapperSize}px;
  /* overflow-y: auto; */
  transition: all 300ms ease;

  @media print {
    margin-left: 30px;
  }
`;

export const DivICon = styled.div``;

export const BreadCrumb = styled.div`
  /* margin-bottom: 35px; */
  display: flex;
  align-items: center;
  width: 50%;
  transform: translateY(-22px);
  .divIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

    width: 31px !important;
    height: 31px;
    border-radius: 6px;
    border: solid 1px #808099 !important;
    svg {
      width: 20px;
      height: 20px;
      margin-top: 1.3px;
    }
  }
  a {
    color: ${props => props.theme.grafit.hover};
    transition: 200ms ease;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.09px;
    text-align: left;
    text-decoration: none;
  }
  a.home {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 31px;
    height: 31px;
    padding: 5px 5px;
    border-radius: 6px;
    border: 1.5px solid ${props => props.theme.grafit.default};
    margin-right: 12px;
    > svg {
      width: 18.7px;
      height: 17.7px;
      stroke-width: 50px;
    }
  }
  a.backhome{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px;
    > svg {
      width: 18.7px;
      height: 17.7px;
      stroke-width: 50px;
    }
  }
  svg {
    font-size: 25px;
  }
  span {
    font-size: 25px;
    color: #999999;
  }
  svg.breadcrumb-separate {
    margin: 0 5px;
    width: 15px;
    height: 30px;
  }
  a:hover {
    color: ${props => props.theme.info.default};
  }
  a.active {
    color: ${props => props.theme.info.default};
  }
`;
