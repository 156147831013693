import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  width: 100%;
  & > div + div {
    margin-top: 8px;
  }
`;

export const DivGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 40px !important;

  & > button + button {
    margin-left: 50px;
  }

  width: 100%;
`;
