export function loadInitialStateRequest(idUser) {
  return {
    type: '@suitability/LOAD_INITIAL_DATA_REQUEST',
    payload: idUser,
  };
}

export function loadInitialStateSuccess({ parcial, perguntas, respondido, descricao, perfil }) {
  return {
    type: '@suitability/LOAD_INITIAL_DATA_SUCCESS',
    payload: { parcial, perguntas, respondido, descricao, perfil },
  };
}

export function loadInitialStateFailure() {
  return {
    type: '@suitability/LOAD_INITIAL_DATA_FAILURE',
  };
}

