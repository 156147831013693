import styled from 'styled-components';

export const Container = styled.div`
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-between;
    margin:auto;
    width: 8rem;
    height: 9rem;
    svg{
        margin-bottom: 10%;
        width: 4rem;
        height: 4rem;
        fill: ${props => props.theme[props.color].default};
    }

    p{
        
        font-family: Nunito;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #1a1a47;
    }

    span{
        font-family: Nunito;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: 0px;
        color: #808099;
    }
`;