import React from 'react';
import {ContainerProgress} from './styles';
import PropTypes from 'prop-types';
import ProgressBar from 'components/ProgressBar';
import Card from 'components/Cards/index';

export default function CardProgress({
  left,
  right,
  fill
}) {

  return (
    <ContainerProgress>
      <Card className="card-progress">
        <div className="values">
          <p>Estimado</p>
          <p>Pode Gastar</p>
        </div>
      <div className="div-bar">
        <ProgressBar
          className="bar-management"
          rightColor="receipts"
          color="grafit"
          left={left}
          right={right}
          bg="spendings"
          fill={fill}
        />
      </div>
    </Card>
  </ContainerProgress>
  );
}
CardProgress.propTypes = {
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.number,
}
