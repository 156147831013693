import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Sidebar from './SidebarFixed';
import { Container, Wrapper } from './styles';
export default function DashboardLayout({ children }) {

  const [ isMenuOpen, setIsMenuOpen ] = useState(false);

  return (
    <Container>
      <Sidebar isOpen={isMenuOpen} handleOpen={setIsMenuOpen}/>
      <Wrapper isOpen={isMenuOpen}>
        <Header />
        {children}
      </Wrapper>
    </Container>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};
