import styled, { css } from 'styled-components';
import LogoAzulImage from 'assets/logo-azul.png'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170px;
  height: auto;
  background: #FFF;
  -webkit-print-color-adjust: exact;
  margin: 0 auto;

  ${({ minHeight }) =>
      minHeight !== undefined &&
      css`
        min-height: ${ minHeight + 'px'} ;
      `
  }

  ${({ minHeight }) =>
        minHeight === undefined &&
        css`
          min-height: auto;
        `
    }

  h1, h2, h3, h4, h5, h6, p, span, table, tr, th {
    font-family: 'Poppins', sans-serif !important;
  }

`

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.padding !== undefined ? props.padding: 0};
  height: ${props => props.height !== undefined ? props.height : 'auto'};

  > div.half {
    width: 50%;
  }

  > div.d-40w {
    width: 44%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  > div.d-60w {
    width: 56%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-title {
    height: auto;
    min-height: 60px;

    small {
      font-size: 7px !important;
      font-style: italic;
    }
  }

  .p-smaller {
    font-size: 19px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 58px;
  }

  .p-smaller-no-mg {
    font-size: 19px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 19px;
    margin-bottom: 0;
  }

  .half-content {
    height: 100%;
  }

  .full-content {
    height: auto;
    min-height: 350px;
    width: 100%;
  }

  &.split {
    gap: 10px;
  }

  &.split-2 {
    margin-top: auto;
    gap: 7px;
    justify-content: space-between;

    > div {
      width: 100%;

    }
  }

  .d-purple {
    font-size: 19px;
    padding: 4px;
    font-weight: bold;
    text-align: center;
    background: #5560ff;
    color: #FFFFFF;
  }
  .d-gray {
    font-size: 19px;
    padding: 4px;
    font-weight: bold;
    text-align: center;
    background: #f1f1f1;
    border-left: solid 3px #5560ff;
    color: #FFFFFF;
    color: #2d2d2d;
  }
`;

export const PurpleBar = styled.div`
  width: 378px;
  height: 40px;
  background: #5560ff;
`

export const Title = styled.h1`
  text-transform: uppercase;
  font-size: 58px;
  font-weight: 800;
  line-height: 66px;
  margin-bottom: 0;
  padding: 0;
  margin-top: -2px;
`

export const SubTitle = styled.h2`
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 0;
  padding: 0;

  &.bigger {
    font-size: 40px;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  sup {
    font-size: 20px;
  }
`

export const Labels = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`

export const Label = styled.li`
  display: inline-flex;
  align-items: center;
  width: 50%;
  gap: 14px;
  height: 30px;
  svg {
    width: 20px;
    height: auto;

    ${({ rotateSvg }) =>
      rotateSvg &&
      css`
        transform: rotate(22.5deg);
      `}

    path {
      color: #5560ff;
    }
  }
`
export const LogoAzul = styled.div`
  width: 60px;
  height: 54px;
  background: url(${LogoAzulImage});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 15px;
`

export const Footer = styled.div`
  width: 100%;
  height: 130px;
  margin-top: auto;
  border-top: solid 6px #2d2d2d;

  > div {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 23px;
    font-weight: 600;
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse:separate;
  font-family: 'Poppins', sans-serif !important;

  &:not(.sub-table) {
    border-radius: 7px;
  }
  &.sub-table {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  thead th, tbody td {
    font-family: 'Poppins', sans-serif !important;
    font-size: 13px;
    padding: 7px 7px;
    font-weight: 500;

    &.center {
      text-align: center;
    }
  }

  tbody tr td {
    font-size: 13px;
    font-weight: 600;
    padding: 7px 7px;

    &:not(.h-0) {
      height: 45px;
      padding: 0 4px;
    }

    &.title {
      border-left: solid 7px #7386ff;
      text-align: center;
    }
  }

  tbody tr.blue td.title {
    text-align: left;
  }

  tbody tr:nth-child(odd) td {
    background: ${props => props.theme.receipts.disabled};
    color: ${props => props.theme.black.default};
  }

  tbody tr:nth-child(even) td {
    background: ${props => props.theme.grafit.disabled};
    color: ${props => props.theme.black.default};
  }

  thead {
    background: #7386ff;
    color: ${props => props.theme.white.default};
    font-size: 7px;
  }

  tbody tr.blue td {
    position: relative;
    background: #7386ff;
    color: #FFFFFF;
    font-weight: 400;
    padding: 4px;
  }

  tbody tr.blue:not(:last-child) td {
    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -2px;
        width: 2px;
        height: 100%;
        background: #7386ff;
      }
    }
  }

  thead th {
    position: relative;
    background: #7386ff;

    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -2px;
        width: 2px;
        height: 100%;
        background: #7386ff;
      }
    }
  }
`
