import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { toCurrencyNoCents as formatReal } from 'utils/toCurrency';
import {
  Container,
  ContainerColumn,
  PurpleBar,
  ContentWrapper,
  LogoAzul,
  PoteContainer,
  Footer,
  Title
} from './styles';

const maxValue = 100;
const initialDataChart = {
  labels: [''],
  datasets: [
    {
      label: "Recebimentos",
      data: [maxValue],
      backgroundColor: "transparent",
      borderColor: "#6342ee",
      borderSkipped: "none",
      borderWidth: 3,
      yAxisID: "right"
    },
    {
      label: "Dívidas",
      data: [0],
      backgroundColor: "#f7192f"
    },
    {
      label: "Compras Parceladas",
      data: [0],
      backgroundColor: "#c4143e",
      borderWidth: 0
    },
    {
      label: "Gastos mensais comprometidos",
      data: [0],
      backgroundColor: "#0f7acc",
      borderWidth: 0
    },
    {
      label: "Gastos mensais flexíveis",
      data: [0],
      backgroundColor: "#1399ff",
      borderWidth: 0
    },
    {
      label: "Gastos eventuais flexíveis",
      data: [0],
      backgroundColor: "#89ccff",
      borderWidth: 0
    },
    {
      label: "PGE-C",
      data: [0],
      backgroundColor: "#17ba92",
      borderWidth: 0
    },
    {
      label: "Investimentos",
      data: [0],
      backgroundColor: "#1de9b6",
      borderWidth: 0
    },
  ]
}

export default function MyBudgetPot({ data }) {

  const { headerTitle, footerTitle, budgetPot } = data;
  const [dataChart, setDataChart] = useState(initialDataChart);
  const [totalValue, setTotalValue] = useState(maxValue)

  const pot = budgetPot.pot || []
  const getValorFromNome = (name) => {
    try {
      const { value } = pot.find(item => item.name === name);
      return value;
    } catch (error) {
      return 0
    }
  }

  const getColorFromName = (name) => {
    switch (name) {
      case "Eventuais Flexíveis - LEF":
        return "#89ccff";
      case "Mensais Flexíveis":
        return "#1399ff";
      case "Mensais Comprometidos":
        return "#0f7acc";
      case "Eventuais Comprometidos - PEC":
        return "#17ba92";
      case "Investimentos":
        return "#1de9b6";
      case "Compras Parceladas":
        return "#c4143e";
      case "Dívidas":
        return "#f7192f";
      default:
        return "#6342ee";
    }
  }

  const getFormatedName = (name) => {
    if (name === 'Eventuais Comprometidos - PEC') {
      return 'Eventuais Comp. - PEC'
    }
    return name
  }

  useEffect(() => {
    if (pot.length > 0) {
      const receipts = "Recebimentos";
      const flexibleEventual = "Eventuais Flexíveis - LEF";
      const flexibleMonthly = "Mensais Flexíveis";
      const committedMonthly = "Mensais Comprometidos";
      const pec = "Eventuais Comprometidos - PEC";
      const investments = "Investimentos";
      const cashPurchases = "Compras Parceladas";
      const debts = "Dívidas";
      setTotalValue(budgetPot.max_value_proportion || 0)
      setDataChart({
        labels: [''],
        datasets: [
          {
            label: receipts,
            data: [
              getValorFromNome(receipts)
            ],
            backgroundColor: "transparent",
            borderColor: getColorFromName(receipts),
            borderSkipped: "none",
            borderWidth: 3,
            yAxisID: "right"
          },
          {
            label: debts,
            data: [
              getValorFromNome(debts)
            ],
            backgroundColor: getColorFromName(debts),
          },
          {
            label: cashPurchases,
            data: [
              getValorFromNome(cashPurchases)
            ],
            backgroundColor: getColorFromName(cashPurchases),
            borderWidth: 0
          },
          {
            label: investments,
            data: [
              getValorFromNome(investments)
            ],
            backgroundColor: getColorFromName(investments),
            borderWidth: 0
          },
          {
            label: pec,
            data: [
              getValorFromNome(pec)
            ],
            backgroundColor: getColorFromName(pec),
            borderWidth: 0
          },
          {
            label: committedMonthly,
            data: [
              getValorFromNome(committedMonthly)
            ],
            backgroundColor: getColorFromName(committedMonthly),
            borderWidth: 0
          },
          {
            label: flexibleMonthly,
            data: [
              getValorFromNome(flexibleMonthly)
            ],
            backgroundColor: getColorFromName(flexibleMonthly),
            borderWidth: 0
          },
          {
            label: flexibleEventual,
            data: [
              getValorFromNome(flexibleEventual)
            ],
            backgroundColor: getColorFromName(flexibleEventual),
            borderWidth: 0
          },
        ]
      })
    }
  }, [budgetPot])

  const getOptions = () => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      cornerRadius: 10,
      legend: {
        display: false
      },
      tooltips: {
        showAllTooltips: false,
        enabled: false,
        mode: "index",
        bodySpacing: 12,
        bodyFontSize: 15
      },
      plugins: {
        datalabels: {
          color: "rgba(0,0,0,0)",
        }
      },
      scales: {
        xAxes: [
          {
            display: false,
            stacked: true
          }
        ],
        yAxes: [
          {
            display: false,
            stacked: true,
            ticks: {
              beginAtZero: true,
              max: totalValue
            }
          },
          {
            id: "right",
            display: false,
            gridLines: {
              display: false
            },
            ticks: {
              beginAtZero: true,
              max: totalValue
            }
          }
        ]
      }
    }
  }

  const renderList = () => {
    const listaPote = pot.map((item, index) => {
      return (
        <div key={item.name} className="list-item">
          <span className="title">{getFormatedName(item.name)}</span>
          <span style={{ color: getColorFromName(item.name), marginLeft: '12px' }} className="value">{formatReal(item.value)}</span>
        </div>
      )
    })

    return listaPote;
  }

  return (
    <ContentWrapper id="printable" minHeight={1682}>
      <Container style={{ height: '100%' }} padding="0 78px 0px 78px">
        <div className="d-60w">
          <ContainerColumn style={{ padding: "30px 19px 0 0" }}>
            <Title dangerouslySetInnerHTML={{ __html: headerTitle }}></Title>
            <div style={{ margin: "108px 19px 0 0" }} className="d-purple">Receitas - Pagamentos = ZERO</div>
            <p style={{ marginTop: "38px" }} className="p-smaller">A situação de suas receitas e seus <br />
              pagamentos sempre deve dar <b>ZERO</b>.</p>
            <p className="p-smaller">O objetivo do planejamento é usufruir <br />
              100% do dinheiro. Não é necessário lucro. <br />
              O objetivo é ter <b>qualidade de vida</b>.</p>
            <div style={{ marginTop: "180px" }} className="half-content">
              <PoteContainer>
                <div className="pote-atual-title">
                  <h2>Pote Atual</h2>
                  <div className="lista">
                    {pot.length && renderList()}

                  </div>
                </div>
                <div className="pote-atual-bar">
                  <Bar height={800} data={dataChart} options={getOptions()} />
                </div>
              </PoteContainer>
            </div>
          </ContainerColumn>
        </div>
        <div className="d-40w">
          <PurpleBar />
          <PurpleBar className="full-height">
            <h1 style={{ marginBottom: '50px' }}>Entenda seu<br />orçamento</h1>
            <h2>Recebimentos</h2>
            <p>Esse é o limite do seu pote<br />orçamentário</p>
            <h2>Dívidas</h2>
            <p>As parcelas que você paga<br />dos empréstimos e<br />financiamentos que possui</p>
            <h2>Investimentos (MIM)</h2>
            <p>Sua Meta de Investimento<br />Mensal que garantirá a<br />realização dos seus<br />objetivos e sonhos.</p>
            <h2>Eventuais<br />Comprometidos (PEC)</h2>
            <p>Sua Provisão mensal para<br />gastos que você tem ao<br />longo do ano, mas com<br />data para acontecer, como<br />IPVA, IPTU, etc.</p>
            <h2>Mensais<br />Comprometidos</h2>
            <p>São os gastos que você<br />tem todo mês com data<br />fixa para acontecer.</p>
            <h2>Mensais Flexíveis</h2>
            <p>Esses são aqueles gastos<br />que acontecem todos os<br />meses, mas não têm uma<br />data fixa.</p>
            <h2>Eventuais Flexíveis</h2>
            <p>Esse é o valor anual dos<br />
              seus gastos que não<br />
              acontecem todos os meses<br />
              e nem têm data para<br />
              acontecer, como roupas,<br />
              presentes, etc, tudo dividido<br />
              por 12. O limite da sua fatura<br />
              de cartão será tudo que<br />
              você paga todos os meses<br />
              + esse valor de Eventual<br />
              Flexivel</p>
          </PurpleBar>
        </div>
      </Container>
      <Footer>
        <Container padding="0 78px">
          <p>{footerTitle}</p>
          <LogoAzul />
        </Container>
      </Footer>
    </ContentWrapper>
  );
}
