import styled, { css, keyframes } from 'styled-components';

export const openInput = keyframes`
  0% {
    width:0%;
    opacity: 0;
  }
  100%{
    width: 60%;
    opacity: 1;

  }

`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  
`;
export const ContainerLeft = styled.div`
  display: flex;
  margin-right: 2%;
  width: 70%;
  height: 100%;
  
  flex-direction: column;
`;
export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;

`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 4.5vh;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  .inputLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70%;
    width: 70%;
    justify-content: flex-end;
  }

  div.container-date-picker {
    display: flex;

    .date-picker {
      width: 9vw;
      height: 4.5vh;

      svg {
        height: 1.5vw;
        width: 1.5vw;
      }
      span {
        font-size: 1vw;
      }
    }
  }
  div:nth-child(5) {
    margin-left: 1%;
  }
`;
export const Title = styled.h1`
  font-family: Nunito;
  font-size: clamp(0.5rem, -0.875rem + 2.9vw, 1.2rem);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin: unset !important;
  color: #4d4d70;
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${props =>
      props.selected
        ? props.theme[props.color].default
        : props.theme.grafit.hover};
  border-radius: 1.1vh;
  width: 4.5vh;
  height: 4.5vh;
  padding: 1%;
  margin-right: 1%;

  ${props =>
    props.selected
      ? css`
          & > svg {
            fill: ${props =>
              props.selected
                ? props.theme[props.color].default
                : props.theme.grafit.hover};
          }
        `
      : css`
          & > svg {
            fill: ${props => props.theme.grafit.hover};
          }
        `}

  &:hover {
    cursor: pointer;
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 4.5vh;
  height: 4.5vh;
  border-radius: 1vh;
  border: solid 1px #808099;

  &.true {
    width: 40%;
    justify-content: flex-start;
    button {
      margin-left: 10%;
    }
  }
  button.search {
    background: ${props => props.theme[props.color].default};
    margin-right: 3%;
    font-size: 0.9vw;
    width: 32%;
    height: 80%;
    color: ${props => props.theme.white.default};
    border-radius: 0.5vh;
    font-weight: bold;
  }

  input {
    width: 0%;
    border: 0;
    outline: 0;
    background: transparent;
    font-size: 0.9vw;
    justify-content: center;

    border-left: 0;
    border-right: 0;
    border-top: 0;

    &.show {
      animation: ${openInput} forwards 500ms;
      margin-left: 5%;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3%;
    width: 4vh;
    height: 4vh;

    svg {
      color: ${props => props.theme.grafit.hover};

      &:hover {
        color: ${props => props.theme.grafit.hover};
        transition: color 0.2s ease;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
