import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  div.legends {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    width: 100%;

    margin: 0;

    label,
    button {
      margin: 0;
    }

    button {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      font-size: 13px;

      color: ${({ theme }) => theme.grafit.default};

      transition: 250ms ease;

      svg {
        margin-right: 2px;
        margin-top: -2px;
      }

      &:hover {
        color: ${({ theme }) => theme.info.default};
        cursor: pointer;
      }
    }
  }

  /* margin-top: 18px; */
  width: ${props => props.width};
  label {
    font-size: 14px;
    color: ${({ theme }) => theme.grafit.default};
  }

  div.custom__container {
    margin-top: 10px;
  }

  div.custom__value-container {
    font-size: 15px;
  }

  div.custom__control {
    border: 1px solid ${props => props.theme.grafit.default};
    border-radius: 10px;

    height: ${props => props.height};
    width: 100%;
  }

  div.custom__control--is-focused {
    border: 0.1px solid ${props => props.theme.info.default};
  }

  div.custom__menu {
    padding: 10px;
  }

  div.custom__option {
    margin-bottom: 5px;
    height: 48px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: ${props => props.theme.grafit.hover};

    &:last-child {
      margin-bottom: 0;
    }
  }

  div.custom__option:hover {
    background: ${props => props.theme.info.disabled};
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }

  div.custom__option--is-focused {
    background: ${props => props.theme.info.disabled};
    font-weight: bold;
    color: #fff;
  }

  div.custom__option--is-selected {
    background: ${props => props.theme.info.default};
    font-weight: bold;
    color: #fff;
  }
`;
