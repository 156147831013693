import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import Switch from 'react-switch';
import { produce } from 'immer';
import Tippy from '@tippy.js/react';
import { Container, Title } from './styles';
import 'tippy.js/dist/tippy.css';
import Button from 'components/Button';
import Table from 'components/Tables/TotalTable';
import Can from 'components/Can';

export default function Organize({
  firstPageItem,
  lastPageItem,
  totalItems,
  handlePageNext,
  handlePageBefore,
  pageItems,
  setItems,
  items,
  setPage,
  setWipeContainer,
  setOrganize,
}) {
  const theme = useContext(ThemeContext);

  function handleOrganize() {
    const organizeQuadrants = items.map(item => {
      let quadrant = 1;
      if (!item.priority && item.replaceable && !item.belowAverage) {
        quadrant = 1;
      }
      if (!item.priority && item.replaceable && item.belowAverage) {
        quadrant = 2;
      }
      if (!item.priority && !item.replaceable && !item.belowAverage) {
        quadrant = 3;
      }
      if (item.priority && item.replaceable && !item.belowAverage) {
        quadrant = 4;
      }
      if (item.priority && item.replaceable && item.belowAverage) {
        quadrant = 5;
      }
      if (!item.priority && !item.replaceable && item.belowAverage) {
        quadrant = 6;
      }
      if (item.priority && !item.replaceable && !item.belowAverage) {
        quadrant = 7;
      }
      if (item.priority && !item.replaceable && item.belowAverage) {
        quadrant = 8;
      }
      return {
        ...item,
        quadrant,
      };
    });

    const newItems = organizeQuadrants.sort((a, b) => {
      if (a.quadrant > b.quadrant) return 1;
      if (a.quadrant < b.quadrant) return -1;
      return 0;
    });
    setPage(1);
    setItems(newItems);
    setWipeContainer(true);
    setOrganize(false);
  }

  return (
    <Container>
      <Title class="title">Organizar</Title>
      <div className="container-table">
        <Table
          title={null}
          color="spendings"
          head={[
            <div className="valueHead">Gasto</div>,
            'Categoria',
            'Tipo',
            'Valor',
            'Prioridade',
            'Substituível',
          ]}
          // buttonIcons={[
          //   `${firstPageItem}-${lastPageItem} de ${totalItems}`,
          //   <button type="button" onClick={handlePageNext} key={Math.random()}>
          //     <AiOutlineArrowLeft />
          //   </button>,
          //   <button onClick={handlePageBefore} type="button" key={Math.random()}>
          //     <AiOutlineArrowRight />
          //   </button>,
          // ]}
          body={items.map((item, index) => [
            <Tippy content={item.name}>
              <div className="valueBody">{item.name}</div>
            </Tippy>,
            item.category.name,
            item.classification,
            {
              value: item.value.toLocaleString('pt-br', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              currency: 'R$',
            },

            <Switch
              onChange={() => {
                const newItems = produce(items, draft => {
                  draft[index + firstPageItem - 1].priority = !draft[
                    index + firstPageItem - 1
                  ].priority;
                });
                setItems(newItems);
              }}
              checked={item.priority}
              offColor={theme.grafit.default}
              onColor={theme.info.default}
              onHandleColor={theme.info.hover}
              handleDiameter={25}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className="switch-priority"
            />,
            <Switch
              onChange={() => {
                const newItems = produce(items, draft => {
                  draft[index + firstPageItem - 1].replaceable = !draft[
                    index + firstPageItem - 1
                  ].replaceable;
                });
                setItems(newItems);
              }}
              checked={item.replaceable}
              offColor={theme.grafit.default}
              onColor={theme.info.default}
              onHandleColor={theme.info.hover}
              handleDiameter={21}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className="switch-replaceable"
            />,
            '',
            // item.belowAverage ? <AiOutlineArrowDown /> : <AiOutlineArrowUp />,
          ])}
        />
      </div>
      <Can canRoles={['planner', 'adm', 'cs', 'clientPfg']}>
        <div className="divButton">
          <Button onClick={handleOrganize} className="cta">
            Organizar
          </Button>
        </div>
      </Can>
    </Container>
  );
}

Organize.propTypes = {
  firstPageItem: PropTypes.number.isRequired,
  lastPageItem: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  handlePageNext: PropTypes.func.isRequired,
  handlePageBefore: PropTypes.func.isRequired,
  pageItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  setItems: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  setPage: PropTypes.func.isRequired,
  setWipeContainer: PropTypes.func.isRequired,
  setOrganize: PropTypes.func.isRequired,
};
