
import React, { useContext } from 'react';
import { Container } from './styles';
import { BsListCheck } from 'react-icons/bs';
import { Context } from '../../Context';
export default function Category() {

    const { toggleButton } = useContext(
        Context
    );

    return (
        <Container color={toggleButton === 'installments'? 'debts' : toggleButton} >
            <BsListCheck />
            <p>Nada aqui</p>
            <span>Nenhum item</span>
            <span>foi encontrado</span>
        </Container>
    );

}
