import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 1605px;
  margin-top: -20px;
  margin-left: 20px;
  

  .custom__control {
    background: transparent;
    border: solid 2.5px #808099 !important;
  }
  div.card-goals {
    padding: 0;
    width: 802px;
    
    a.showAll {
      font-family: Nunito;
      font-size: 10px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: 0.33px;
      text-align: left;
      color: #3ba7ff;
    }
    @media (max-width: 1700px) {
      a.showAll {
        font-size: 9.7px;
      }
    }
    @media (max-width: 1650px) {
      a.showAll {
        font-size: 9.5px;
      }
    }
    @media (max-width: 1600px) {
      a.showAll {
        font-size: 9.25px;
      }
    }
    @media (max-width: 1500px) {
      a.showAll {
        font-size: 8.7px;
      }
    }
    @media (max-width: 1450px) {
      a.showAll {
        font-size: 8.5px;
      }
    }
  }

  @media (max-width: 1700px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1464px;


    span {
      font-size: 24px;
    }

    div.card-goals {
      width: 716px;
      height: 350px;
      display: flex;
      flex-direction: column;
    }
    div.circle {
      width: 27px !important;
      height: 27px !important;
    }
  }

  @media (max-width: 1650px) {
    width: 1384px !important;
    span {
      font-size: 23px;
    }
    div.card-goals {
      width: 676px;
      height: 330px;
      display: flex;
      flex-direction: column;
    }
  }
  @media (max-width: 1600px) {
    width: 1324px !important;
    span {
      font-size: 22px;
    }
    div.card-goals {
      width: 646px;
      height: 315px;
      display: flex;
      flex-direction: column;
    }
    div.circle {
      width: 25px !important;
      height: 25px !important;
    }
  }
  @media (max-width: 1500px) {
    width: 1264px !important;
    span {
      font-size: 21px;
    }
    div.card-goals {
      width: 616px;
      height: 300px;
      display: flex;
      flex-direction: column;
    }
    div.circle {
      width: 20px !important;
      height: 20px !important;
    }
  }
  @media (max-width: 1450px) {
    width: 1184px !important;
    span {
      font-size: 20px;
    }
    div.card-goals {
      width: 576px;
      height: 280px;
      display: flex;
      flex-direction: column;
    }
    div.circle {
      width: 20px !important;
      height: 20px !important;
    }
  }
`;

export const DivHeader = styled.div`

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  width: 100%;
`;

export const TitleCard = styled.div`
  width: 123px;
  height: 32px;
  font-family: Nunito;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #808099;
  
`;

export const DivTitleMonths = styled.div`
  display: flex;
  grid-column-start: 1;
  grid-column-end: 5;
  width: 1605px;
  height: 46px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
  .divData{

    width:200px;
  }
  .dropMonths {
    width:100%;
    margin-top: unset !important;
    .custom__container {
      margin-top: 0 !important;
      width: 100%;
      .css-1okebmr-indicatorSeparator {
        display: none;
      }
      .custom__single-value {
        width: 100%;
        height: auto;
        font-family: Nunito;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;

        letter-spacing: 0.26px;
        text-align: center;
        color: #808099;
      }
    }
  }
  @media (max-width:1700px){
    width: 100% !important;
    div.divData{
      margin-right: 1%;
      width: 190px;
      .custom__single-value {
        text-align:center;
        font-size: 17px !important;
      }
    }

  }

  @media (max-width:1650px){

    div.divData{
      width: 185px;
      .custom__single-value {
        font-size: 16px !important;
      }
    }

  }
`;
export const ContentTitle = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  height: 46px;
`;

export const ContainerCards = styled.div`
  width: 1605px !important;
  display: grid;
  grid-template-columns: 390px 390px 390px 390px;
  grid-template-rows: 392.9px 351.9px;
  grid-gap: 16px;
  align-items: end;
  div.card-resize {
    &.graphic{
      box-shadow: 0 0 black;
    }
    box-shadow: ${props => ' 2px 2px 3px 0 rgba(0, 0, 0, 0.16)'};
    width: 100%;
    div.detail-button-center {
      display: flex;
      margin-top: 15px;
      justify-content: flex-end;
      height: 16px;

      color: ${props => props.theme.info.default};
      font-weight: bold;
      text-decoration: none;
      .link {
        width: 54px;
        height: 14px;
        font-family: Nunito;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: 0.33px;
        text-align: left;
        color: #3ba7ff;
      }
    }
  }

  @media (max-width: 1700px) {
    width: 100% !important;
    grid-template-columns: 350px 350px 350px 350px;
    grid-template-rows: 391px 350px;
    div.card-resize {
      width: 350px;
      height: 350px;
      div.icon {
        width: 49px;
        height: 49px;
      }
      span.title {
        font-size: 24px;
      }
      a {
        font-size: 13.7px;
      }
      div.BarAndValues {
      }
      div.detail-button-center {
        transform: translateY(12px);
        .link {
          font-size: 13.7px;
        }
      }
    }
  }

  @media (max-width: 1650px) {
    grid-template-columns: 330px 330px 330px 330px;
    grid-template-rows: 371px 330px;
    div.card-resize {
      width: 330px !important;
      height: 330px !important;
      div.icon {
        width: 48px;
        height: 48px;
      }
      span.title {
        font-size: 23px;
      }
      a {
        font-size: 13.5px;
      }
      div.detail-button-center {
        transform: translateY(1px);
        .link {
          font-size: 13.5px;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    grid-template-columns: 315px 315px 315px 315px;
    grid-template-rows: 356px 315px;
    div.card-resize {
      width: 315px !important;
      height: 315px !important;
      div.icon {
        width: 47px;
        height: 47px;
      }
      span.title {
        font-size: 22px;
      }
      a {
        font-size: 13.25px;
      }
      div.detail-button-center {
        transform: translateY(3px);
        .link {
          font-size: 13.25px;
        }
      }
    }
  }

  @media (max-width: 1500px) {
    grid-template-columns: 300px 300px 300px 300px;
    grid-template-rows: 341px 300px;

    div.card-resize {
      width: 300px !important;
      height: 300px !important;
      div.icon {
        width: 46px;
        height: 46px;
      }
      span.title {
        font-size: 21px;
      }
      a {
        font-size: 12.7px;
      }
      div.detail-button-center {
        .link {
          font-size: 12.7px;
        }
      }
    }
  }
  @media (max-width: 1450px) {
    grid-template-columns: 280px 280px 280px 280px;
    grid-template-rows: 321px 280px;
    div.card-resize {
      width: 280px !important;
      height: 280px !important;
      div.icon {
        width: 45px;
        height: 45px;
      }
      span.title {
        font-size: 20px;
      }
      a {
        font-size: 12.5px;
      }
      div.detail-button-center {
        transform: translateY(4px) !important;
        .link {
          font-size: 12.5px;
        }
      }
    }
  }
`;

export const DivBar = styled.div`
  margin-top: 13%;
  width: calc(100% - 22.6%);

  p {
    font-size: 12px;
  }

  div.progress-bar {
    height: 14px !important;
  }

  @media (max-width: 1700px) {
    width: calc(100% - 22.6%);
    height: 35px;

    p {
      font-size: 11px !important;
    }
    div.progress-bar {
      height: 13px !important;
    }
    @media (max-width: 1650px) {
      width: calc(100% - 22.6%);
      div.progress-bar {
        height: 12.5px !important;
      }
    }
    @media (max-width: 1600px) {
      width: calc(100% - 22.6%);
      div.progress-bar {
        height: 12px !important;
      }
    }
    @media (max-width: 1500px) {
      transform: translateY(-10px);
      width: calc(100% - 22.6%);
      div.progress-bar {
        height: 11.5px !important;
      }
    }
    @media (max-width: 1450px) {
      width: calc(100% - 22.6%);
      div.progress-bar {
        height: 11px !important;
      }
      transform: translateY(-18px);
    }
  }
`;

export const LoadingScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75vh;
  svg {
    color: ${props => props.theme.info.default};
    font-size: 250px;
  }
`;

export const Body = styled.div`
  height: auto;

  width: auto;

  flex-direction: column;

  div.rowCard {
    margin-top: 1.5%;

    display: flex;
    align-items: left;

    @media (max-width: 1700px) {
      display: flex;

      margin-top: 24px;
    }

    & > div + div {
      margin-left: 16.8px;
    }
  }
  @media (max-width: 1700px) {
    width: 1464px;
    height: 850px;
  }

  @media (max-width: 1650px) {

      width: 1384px !important;
      height: 810px;

  }

  @media (max-width: 1600px) {


      width: 1324px !important;
      height: 780px;
    }
  }

  @media (max-width: 1500px) {

      width: 1264px !important;
      height: 750px;


  }
  @media (max-width: 1450px) {


      width: 1184px !important;
      height: 710px;

  }
`;

export const ContentCard = styled.div`

  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.span`
  color: ${props => props.theme.grafit.hover};
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  top: 30px;
  ${({ budget }) =>
    budget &&
    css`
      position: absolute;
      transform: translateX(-25px) translateY(-70px);
    `}
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: ${props => props.theme[props.color].disabled};
    border-radius: 10px;

    width: 50px;
    height: 50px;

    margin-right: 4%;

    svg {
      width: 62%;
      height: 62%;
      stroke-width: 20px;
      color: ${props => props.theme[props.color].default};
    }
  }
  span {
    color: ${props => props.theme[props.color].default};
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    text-align: left;
  }

  @media (width: 1700px) {
    div {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      svg {
        width: 19px;
        height: 18px;
      }
    }
  }
`;

export const Title = styled.h4`
  width: 174px;
  height: 46px;
  font-family: Nunito;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.24px;
  color: #4d4d70;

  @media (max-width: 1700px) {
    font-size: 33px;
  }
  @media (max-width: 1650px) {
    font-size: 32px;
  }
  @media (max-width: 1600px) {
    font-size: 31px;
  }
  @media (max-width: 1500px) {
    font-size: 30px;
  }
  @media (max-width: 1450px) {
    font-size: 29px;
  }
`;

export const CardBody = styled.div`
  margin-top: 25px;

  small {
    font-size: 13px !important;
    color: ${props => props.theme.grafit.hover};
  }
  div.realized {
    div {
      margin-top: 1%;
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        color: ${props => props.theme.grafit.hover};
        font-size: 20px;
        font-weight: bold;
        line-height: 1.35;
        letter-spacing: 0px;
        text-align: left;
      }

      svg {
        margin-left: 12%;
        height: 5%;
        width: 5%;
        color: ${props => props.theme.grafit.default};
        cursor: pointer;
      }
    }
  }

  div.estimated {
    transform: translateY(40px);

    h4 {
      margin-top: 1%;
      font-size: 34px;
      font-weight: bold;
      letter-spacing: 0.24px;
      text-align: left;
      color: ${props => props.theme[props.color].default};
    }
  }

  div.countProgress {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    & >span {

      color: ${props => props.theme.grafit.hover};
      margin-top: 14px !important;
      font-size:12px;
    }
  }

  @media (max-width: 1700px) {
    margin-top: 22px;
    div.realized{
      small{
        font-size: 12px !important;
      }
      span{
        font-size: 18px !important;
      }
    }
    div.estimated {
      small{
        font-size: 12px !important;
      }
      h4 {
        font-size: 33px !important;
      }
    }
  }
  @media (max-width: 1650px) {

    small {
      font-size: 13px !important;
    }
    span.value {
      font-size: 24px !important;
    }
    div.realized {
    }

    div.estimated {
      h4 {
        font-size: 30px !important;
      }
    }

    .countProgress {
      margin-bottom: 0 !important;
      span {
        font-size: 11px;
      }
    }
  }
  @media (max-width: 1600px) {
    small {
      font-size: 12.5px !important;
    }
    span.value {
      font-size: 23px !important;
    }
    div.realized {
    }

    div.estimated {
      margin-top: -15px !important;
      h4 {
        font-size: 29px !important;
      }
    }

    div.countProgress {
    }
  }
  @media (max-width: 1500px) {

    small {
      font-size: 12px !important;
    }

    span.value {
      font-size: 16px !important;

    }

    div.realized {
    }

    div.estimated {
      margin-top: -25px !important;
      h4 {
        font-size: 28px !important;
      }
    }

    div.countProgress {
    }
  }
  @media (max-width: 1450px) {
    small {

      font-size: 10px !important;
    }
    div.realized {
      transform: translateY(-18px);
    }

    div.estimated {
      margin-top: -45px !important;
      h4 {
        font-size: 26px !important;
      }
    }

    div.countProgress {
    }
  }
`;

export const ProgressBar = styled.div`
  background: ${props => props.theme.grafit.disabled};
  width: 100%;
  height: 14px !important;
  height: ${props => props.height || '15'}px;
  border-radius: 10px;
  bottom: 30%;
  div.filler {
    background: ${props => props.theme[props.color].default};
    width: ${props => props.width}%;
    height: 100%;
    border-radius: 10px;
  }
  @media (max-width: 1700px) {
    height: 13px !important;
  }
  @media (max-width: 1650px) {
    height: 12px !important;
  }
  @media (max-width: 1600px) {
    height: 11px !important;
  }
  @media (max-width: 1500px) {
  }
  @media (max-width: 1450px) {
  }
`;

export const Chart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  button {
    background: none;
  }

  p {
    color: ${props => props.theme.grafit.hover};
    font-size: 20px;
    font-weight: 550;
  }

  svg {
    cursor: pointer;
    color: ${props => props.theme.grafit.hover};
  }
`;

export const ChartBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 90%;

  div.canvas {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 35%;
    height: 100%;
  }
`;

export const ListDetailts = styled.div`
  display: flex;
  flex-direction: column;
  small {
    font-size: 12px;
    color: ${props => props.theme.grafit.hover};
  }
  span {
    margin-top: 5%;
    font-size: 15px;
    color: ${props => props.theme[props.color][props.hue]};
    font-weight: 700;
  }
`;

export const CardMotion = styled.div`

  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  div.goals-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    grid-column-start: 3;
    grid-column-end: 5;

    width: 100%;

    position: relative;

    > div + div {
      margin-left: 16px;
    }

    > button {
      z-index: 1;
      position: absolute;
      background: transparent;
      padding: 0;
      div.circle {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.16);
        background-color: #f0f1f7;
        width: 32px;
        height: 32px;
      }
      &.back {
        left: 0.9%;
      }
      &.next {
        right: 0.9%;
      }
      &:hover {
        background: transparent !important;
      }
      svg {
        height: 50px;
        width: 50px;
        fill: #b3b3c2;
      }
    }

    button::-moz-focus-inner {
      border: 0;
    }
  }

  @media (max-width: 1700px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    div.goals-group {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 319px !important;
    }
  }
  @media (max-width: 1650px) {
    div.goals-group {
      height: 300px !important;
    }
  }
  @media (max-width: 1625px) {
    div.goals-group {
      height: 297px !important;
    }
  }
  @media (max-width: 1600px) {
    div.goals-group {
      height: 285px !important;
    }
  }
  @media (max-width: 1500px) {
    div.goals-group {
      height: 273px !important;
    }
  }
  @media (max-width: 1450px) {
    div.goals-group {
      height: 253px !important;
    }
  }
`;

export const CardMotionDetail = styled.div`
  z-index: 2;
  position: absolute;
  top: 8%;
  color: ${props => props.theme.white.default};
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 90%;
  width: 48%;
  p {
    font-weight: 700;
  }

  & > div {
    margin-top: 42%;
  }

  div {
    display: flex;
    flex-direction: column;

    p {
      text-align: center;
      font-weight: 600;
      font-size: 13px;
      overflow: hidden;
      max-width: 230px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    h4 {
      font-size: 22px;
      margin-top: 5%;
      margin-bottom: 2px;
    }
    small {
      font-size: 13px;
      font-weight: 700;
    }
  }

  div.progressDetail {

    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 85%;
    height: 40%;
    margin-top: 8% !important;

    & > div + div {
      margin-top: 1%;
    }
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      width: 85%;
      p {
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
`;

export const DivDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 65%;
  height: 100%;

  & > div + div {
    margin-top: 5px;
  }
`;

export const DivValuesChart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  font-size: 13px;

  ${props =>
    props.withBorder &&
    css`
      padding: 8px;
      border-top: 1px solid ${props.theme.grafit.default};
    `}

  div.value {
    display: flex;
    justify-content: flex-end;

    width: 30%;
    & > span {
      color: ${props => props.color};
      font-weight: 600;
    }
  }

  div.receipts {
    font-size: 18px;
    letter-spacing: 0.26px;
  }

  div.label {
    display: flex;
    justify-content: flex-start;
    position: relative;

    width: 60%;

    & > div::after {
      content: '';
      position: absolute;
      left: -8%;
      top: 30%;
      width: 4px;
      height: 4px;
      background: ${props => props.color};
      border-radius: 50%;
    }

    & > span {
      color: ${props => props.theme.grafit.hover};
      font-weight: 600;
    }
  }
`;

export const ContainerDate = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 35px;
  width: 220px;

  div.date {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid ${props => props.color};
    padding: 5px 10px;
    border-radius: 11px;
    width: 190px;
    span {
      color: ${props => props.color};
      font-weight: 700;
      font-size: 18px;
    }
  }

  button {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;

    &:first-child {
      margin-right: 20px;
    }

    &:last-child {
      margin-left: 20px;
    }

    svg {
      fill: ${props => props.color};
    }
  }
`;

export const Goal = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 257px;
  height: 327px;

  background: ${props => props.theme.white.default};

  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  img {
    width: 100%;
    height: 144px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  span.name {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    letter-spacing: 0px;
    text-align: center;
    color: ${props => props.theme.grafit.hover};
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 16px;
  }

  span.ifp {
    font-size: 24px;
    line-height: 1.33;
    text-align: center;
    color: ${props => props.theme.primary.default};
    margin-top: 4px;
  }

  span.when {
    margin-top: 1.2%;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0.02px;
    text-align: center;
    color: ${props => props.theme.primary.hover};
  }

  @media (max-width: 1700px) {
    height: 100%;
    div.info{
      margin-top: -12px;
    }
    img {
      width: 100%;
      max-height: 45%;
    }

    span.name {
      font-size: 14px;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span.ifp {
      font-size: 20px;
    }

    span.when {
      font-size: 9px !important;
    }
  }

  @media (max-width: 1550px) {
    height: 100%;
    div.info{
      margin-top: -5px;
      p {
        font-size: 10px !important;
      }
    }
    span.when {
      font-size: 10px !important;
    }
  }
`;
