const allocationDefault = [
    { value: 'R. Fixa Pós', label: 'R. Fixa Pós' },
    { value: 'R. Fixa Pré', label: 'R. Fixa Pré' },
    { value: 'R. Fixa IPCA', label: 'R. Fixa IPCA' },
    { value: 'Multimercado', label: 'Multimercado' },
    { value: 'FIIs', label: 'FIIs' },
    { value: 'Ações BR', label: 'Ações BR' },
    { value: 'R.V. Exterior', label: 'R.V. Exterior' },
    { value: 'Alternativo', label: 'Alternativo' },

];

const goalsAllocationDefault = [...allocationDefault, { value: 'ELP', label: 'ELP' }]

const liquidityOptions = [
    { value: 'DO', label: 'DO' },
    { value: 'Até 30 dias', label: 'Até 30 dias' },
    { value: 'De 1 à 6 meses', label: 'De 1 à 6 meses' },
    { value: 'De 6 à 12 meses', label: 'De 6 à 12 meses' },
    { value: 'De 12 à 24 meses', label: 'De 12 à 24 meses' },
    { value: 'Acima de 24 meses', label: 'Acima de 24 meses' },
];

export { allocationDefault, liquidityOptions, goalsAllocationDefault };