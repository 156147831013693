import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { format, startOfDay, addHours } from 'date-fns';
import { toast } from 'react-toastify';
import Tippy from '@tippy.js/react';
import Button from 'components/Button';
import Table from 'components/Tables/TotalTable';
import api from 'services/api';
import DropdownButton from 'components/Button/Dropdown';
import 'tippy.js/dist/tippy.css';
import { Container, ContainerTable } from './styles';

export default function WipeContainer({
  firstPageItem,
  lastPageItem,
  totalItems,
  handlePageNext,
  handlePageBefore,
  pageItems,
  setWipeModal,
  setSelectedItem,
  wiped,
  setResultModal,
  setLoading,
  setSuccess,
  // eslint-disable-next-line react/prop-types
  items,
}) {
  const user = useSelector(state => state.user.profile);
  async function handleWipe() {
    if (!wiped.length) return toast.error('Enxugue pelo menos um gasto');
    try {
      setLoading(true);
      setResultModal(true);
      const promises = wiped.map(data => {
        const { item_id, value, oldValue, date, action } = data;

        const start = startOfDay(new Date(date));

        const promise1 = api.put(`items/${item_id}`, {
          value,
        });

        const promise2 = api.post(`users/${user.id}/items/${item_id}/wipes`, {
          value,
          oldValue,
        });

        const promise3 = api.post(`users/${user.id}/activities`, {
          name: action,
          description: action,
          start: addHours(start, 10),
          end: addHours(start, 11),
        });

        return Promise.all([promise1, promise2, promise3]);
      });

      await Promise.all(promises);
      setLoading(false);
      return setSuccess(true);
    } catch (err) {
      setLoading(false);
      setResultModal(false);
      return toast.error('Tivemos um erro!');
    }
  }

  return (
    <Container>
      <ContainerTable>
        <Table
          title="Enxugar"
          color="spendings"
          head={[
            <div className="valueHead">Gasto</div>,
            'Categoria',
            'Tipo',
            'Valor',
            'Novo Valor',
            'Ação',
            'Data',
            '',
          ]}
          // eslint-disable-next-line react/prop-types
          body={items.map(item => [
            <Tippy content={item.name}>
              <div className="valueBody">{item.name}</div>
            </Tippy>,
            item.category.name,
            item.classification,
            {
              value: item.value.toLocaleString('pt-br', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              currency: 'R$',
            },
            {
              value: item.newValue.toLocaleString('pt-br', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              currency: 'R$',
            },

            item.action || '-',
            !item.date ? '-' : format(new Date(item.date), 'dd/MM/yyyy'),
            <DropdownButton>
              <button
                onMouseDown={() => {
                  setSelectedItem(item);
                  setWipeModal(true);
                }}
              >
                Enxugar
              </button>
            </DropdownButton>,
          ])}
        />
      </ContainerTable>
      <div className="divButton">
        <Button onClick={handleWipe} className="cta">
          Enxugar
        </Button>
      </div>
    </Container>
  );
}

WipeContainer.propTypes = {
  firstPageItem: PropTypes.number.isRequired,
  lastPageItem: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  handlePageNext: PropTypes.func.isRequired,
  handlePageBefore: PropTypes.func.isRequired,
  pageItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  setWipeModal: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  wiped: PropTypes.arrayOf(PropTypes.object).isRequired,
  setResultModal: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
};
