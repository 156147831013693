import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -40px;
  width: 100%;
  height: 100%;
`;

export const DivOptions = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  transform: translateY(-59px);
  align-self: flex-end;
  & div + div {
    margin-left: 15px;
  }
`;
export const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 95%;
  padding: 10px;
  border-radius: 5px;
  border: solid 1px ${props => props.theme[props.status_color].darker};
  background: ${props => props.theme[props.status_color].disabled};
  color: ${props => props.theme[props.status_color].default};
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.grafit.hover};
  border-radius: 10px;
  padding: 1%;
  svg{
    width: 25px;
    height: 25px;
  }

  ${props =>
    props.selected
      ? css`
          background: ${props.theme.info.default};
          border: 0;
          & > svg {
            fill: ${props.theme.white.default};

          }
        `
      : css`
          & > svg {
            fill: ${props => props.theme.grafit.hover};
          }
        `}

  &:hover {
    cursor: pointer;
  }
`;



export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  ${props =>
    props.withChart &&
    css`
      justify-content: space-between;
    `}
  width: 100%;
  height: 100%;
`;


export const ContentCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;




export const DivPercent = styled.div`
  position: absolute;
  bottom: 0%;

  width: 100%;
  height: ${props => props.height}%;

  div.percent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    transform: translateY(-50px);
    span {
      font-weight: 700;
      font-size: 18px;
      color: ${props => props.theme.grafit.hover};
      &.value {
        color: ${props => props.theme.primary.hover};
        margin-bottom: 4px;
      }
    }

    hr.marker {
      width: 90%;
      transform: translateX(-160px) translateY(-26px);
      border: 1px dashed ${props => props.theme.primary.hover};

      &::before {
        content: '';
        position: absolute;
        right: -1%;
        transform: translateY(-5px);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${props => props.theme.primary.hover};
      }
    }
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 50%;
  background: pink;
`;

export const FormatValue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${props =>
    props.isNegative ? props.theme.danger.default : props.theme.info.default};
  border-radius: 8px;
  padding: 15px 5px;

  div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 89%;
  }

  div, span {
    font-size: 1.3vw;
    font-weight: 700;
    color: ${props => props.theme.white.default};
  }
`;

export const ContainerDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 35px;

  div.date {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #808099;
    padding: 5px 10px;
    border-radius: 11px;

    width: 190px;
    span {
      color: #808099;
      font-weight: 700;
      font-size: 18px;
    }
  }

  button {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      margin-right: 15px;
    }
    &:last-child {
      margin-left: 15px;
    }
    svg {
      fill: #808099;
      height: 20px !important;
    }
  }
`;



export const Recurrent = styled.div``;

export const TotalValue = styled.div`
  display: flex;
  align-items: center;
  width: 290px;
  height: 90px;

  border: 1px solid ${props => props.theme.grafit.default};
  border-radius: 10px;

  div.icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 25%;
    height: 100%;

    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    background: ${props => props.theme.info.default};

    & > svg {
      fill: ${props => props.theme.white.default};
      font-size: 32px;
    }
  }

  div.values {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    width: 85%;
    height: 100%;
    padding-right: 15px;

    span.label {
      font-size: 16px;
      color: ${props => props.theme.grafit.hover};
    }

    span.total {
      color: ${props => props.theme.info.default};
      font-size: 18px;
      font-weight: 700;
    }
  }
`;
