import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FaRegCopy } from 'react-icons/fa';
import {
  AiOutlineFileText,
  AiOutlineCalendar,
  AiOutlineCar,
  AiOutlineShopping,
} from 'react-icons/ai';
import { IoMdRestaurant } from 'react-icons/io';
import { Container, DivOptions, DivButton } from './styles';
import CommitedMonth from './CommitedMonth';
import FlexibleMonth from './FlexibleMonth';
import CommitedEventual from './CommitedEventual';
import FlexibleEventual from './FlexibleEventual';
import SixMonth from './SixMonth';

export default function Spendings() {
  const [toggleButton, setToggleButton] = useState('mensal-comprometido');
  const theme = useContext(ThemeContext);

  return (
    <>
        <DivOptions>
          <DivButton
            data-tip="Mensal Comprometido"
            selected={toggleButton === 'mensal-comprometido'}
            onClick={() => setToggleButton('mensal-comprometido')}
          >
            <AiOutlineFileText size={28} />
          </DivButton>

          <DivButton
            data-tip="Mensal Flexível"
            selected={toggleButton === 'mensal-flexivel'}
            onClick={() => setToggleButton('mensal-flexivel')}
          >
            <IoMdRestaurant size={28} />
          </DivButton>

          <DivButton
            data-tip="Eventual Comprometido"
            selected={toggleButton === 'eventual-comprometido'}
            onClick={() => setToggleButton('eventual-comprometido')}
          >
            <AiOutlineCar size={28} />
          </DivButton>

          <DivButton
            data-tip="Eventual Flexível"
            selected={toggleButton === 'eventual-flexivel'}
            onClick={() => setToggleButton('eventual-flexivel')}
          >
            <AiOutlineShopping size={28} />
          </DivButton>

          <DivButton
            data-tip="6 Meses"
            selected={toggleButton === 'six-month'}
            onClick={() => setToggleButton('six-month')}
          >
            <AiOutlineCalendar size={28} />
          </DivButton>
        </DivOptions>
        <Container>
        {toggleButton === 'mensal-comprometido' && (
          <CommitedMonth icon={<FaRegCopy />} theme={theme} />
        )}

        {toggleButton === 'mensal-flexivel' && (
          <FlexibleMonth icon={<IoMdRestaurant />} theme={theme} />
        )}

        {toggleButton === 'eventual-comprometido' && (
          <CommitedEventual icon={<AiOutlineCar />} theme={theme} />
        )}

        {toggleButton === 'eventual-flexivel' && (
          <FlexibleEventual icon={<AiOutlineShopping />} theme={theme} />
        )}

        {toggleButton === 'six-month' && (
          <SixMonth icon={<AiOutlineCalendar />} />
        )}

      </Container>
      <ReactTooltip />
    </>
  );
}
