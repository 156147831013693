import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { produce } from 'immer';

import {
  AiOutlineShoppingCart,
  AiOutlineCreditCard,
  AiOutlineLineChart,
  AiOutlineWallet,
} from 'react-icons/ai';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Dropdown from 'components/Input/Dropdown';
import Button from 'components/Button';
import Loading from 'components/Loading';

import api from 'services/api';

import { Body } from './styles';

const options = [
  { value: 'Recebimento', label: 'Recebimento', type: 'receipts' },
  { value: 'Dívida', label: 'Dívida', type: 'debts' },
  { value: 'Parcelados', label: 'Parcelados', type: 'debts' },
  { value: 'Sonho', label: 'Sonho', type: 'investments' },
  { value: 'Pessoal', label: 'Pessoal', type: 'spendings' },
  {
    value: 'Alimentação Fora de Casa',
    label: 'Alimentação Fora de Casa',
    type: 'spendings',
  },
  { value: 'Lazer', label: 'Lazer', type: 'spendings' },
  { value: 'Pessoa Jurídica', label: 'Pessoa Jurídica', type: 'spendings' },
  { value: 'Vida e Saúde', label: 'Vida e Saúde', type: 'spendings' },
  { value: 'Moradia', label: 'Moradia', type: 'spendings' },
  { value: 'Transporte', label: 'Transporte', type: 'spendings' },
  { value: 'Dependente', label: 'Dependente', type: 'spendings' },
  { value: 'Outro', label: 'Outro', type: 'spendings' },
];

export default function CreateCategoryModal({
  type,
  items,
  categories,
  setItems,
  setCategories,
  setCreateCategoryModal,
}) {
  const [name, setName] = useState('');
  const [classification, setClassification] = useState(
    type === 'receipts' ? 'Recebimento' : 'Pessoal'
  );
  const [loading, setLoading] = useState(false);

  const user = useSelector(state => state.user.profile);

  let icon = null;

  switch (type) {
    case 'receipts':
      icon = <AiOutlineWallet />;
      break;
    case 'spendings':
      icon = <AiOutlineShoppingCart />;
      break;
    case 'debts':
      icon = <AiOutlineCreditCard />;
      break;
    case 'investments':
      icon = <AiOutlineLineChart />;
      break;
    default:
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault();
      setLoading(true);

      if (!name || !classification) {
        setLoading(false);
        return toast.error('Preencha todos os dados');
      }

      const { data } = await api.post(`users/${user.id}/categories`, {
        name,
        type,
        classification,
      });

      const newCategories = produce(categories, draft => {
        draft[data.id] = {
          ...data,
          items: data.items.map(item => item.id),
        };
      });

      const newItems = produce(items, draft => {
        data.items.forEach(item => {
          draft[item.id] = item;
        });
      });

      setItems(newItems);
      setCategories(newCategories);
      setLoading(false);
      setCreateCategoryModal(false);
      return toast.success('Categoria Criado com sucesso');
    } catch (err) {
      setLoading(false);
      return toast.error('Ops, tivemos um erro!');
    }
  }

  return (
    <Modal
      title="Nova Categoria"
      setShowModal={setCreateCategoryModal}
      color={type}
      icon={icon}
    >
      {loading ? (
        <Loading />
      ) : (
        <Body>
          <form onSubmit={handleSubmit}>
            <Input
              label="Nome da Categoria"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <Dropdown
              label="Tipo"
              placeholder="Selecione o tipo..."
              options={options.filter(option => option.type === type)}
              onChange={e => setClassification(e.value)}
            />
            <div className="buttons">
              <Button type="submit">Confirmar</Button>
            </div>
          </form>
        </Body>
      )}
    </Modal>
  );
}

CreateCategoryModal.propTypes = {
  type: PropTypes.string,
  setCreateCategoryModal: PropTypes.func.isRequired,
  setCategories: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired,
  categories: PropTypes.objectOf(PropTypes.object),
  items: PropTypes.objectOf(PropTypes.object),
};

CreateCategoryModal.defaultProps = {
  type: 'receipts',
  categories: {},
  items: {},
};
