import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import ChartAccomplished from './ChartBack/ChartAccomplished';
import ChartEstimated from './ChartBack/ChartEstimated';

import { Context } from './ContextChart';

import Card from 'components/Cards';

export default function ChartBack({
  installments,
  passivePatrimonies,
  spendings,
  investments,
  receipts,
  accomplished,
}) {
  const maxEntries = useMemo(() => {
    const inside =
      installments?.entries +
      passivePatrimonies?.entries +
      spendings['Mensal Flexível'] +
      spendings['Mensal Comprometido'] +
      spendings['Eventual Flexível'];

    const outside = receipts.entries;

    if (inside > outside) return inside;

    return outside;
  }, [installments, passivePatrimonies, receipts.entries, spendings]);

  const maxEstimated = useMemo(() => {
    const inside =
      installments.entries +
      passivePatrimonies.entries +
      spendings['Mensal Flexível'] +
      spendings['Mensal Comprometido'] +
      spendings['Eventual Flexível'] +
      investments.estimated +
      spendings['Eventual Comprometido'];

    const outside = receipts.estimated;

    if (inside > outside) return inside;

    return outside;
  }, [
    installments.entries,
    investments.estimated,
    passivePatrimonies.entries,
    receipts.estimated,
    spendings,
  ]);

  return (
    <Card
      className="card-resize graphic"
      width="424px"
      height="351px"
      notPadding
    >
      <Context.Provider
        value={{
          installments,
          passivePatrimonies,
          spendings,
          investments,
          receipts,
        }}
      >
        {accomplished ? (
          /* Realizado */
          <ChartAccomplished
            max={maxEstimated > maxEntries ? maxEstimated : maxEntries}
          />
        ) : (
          /* Estimado */
          <ChartEstimated
            max={maxEstimated > maxEntries ? maxEstimated : maxEntries}
          />
        )}
      </Context.Provider>
    </Card>
  );
}

ChartBack.propTypes = {
  accomplished: PropTypes.bool,
  receipts: PropTypes.shape({
    entries: PropTypes.number,
    estimated: PropTypes.number,
    'Mensal Comprometido': PropTypes.number,
    'Eventual Comprometido': PropTypes.number,
    'Mensal Flexível': PropTypes.number,
    'Eventual Flexível': PropTypes.number,
  }).isRequired,
  spendings: PropTypes.shape({
    estimated: PropTypes.number,
    'Mensal Comprometido': PropTypes.number,
    'Eventual Comprometido': PropTypes.number,
    'Mensal Flexível': PropTypes.number,
    'Eventual Flexível': PropTypes.number,
  }).isRequired,
  investments: PropTypes.shape({
    estimated: PropTypes.number,
  }).isRequired,
  installments: PropTypes.shape({
    entries: PropTypes.number,
  }).isRequired,
  passivePatrimonies: PropTypes.shape({
    entries: PropTypes.number,
  }).isRequired,
};

ChartBack.defaultProps = {
  accomplished: false,
};
