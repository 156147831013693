export default function dateUTC(value) {
  let date;

  if (value) {
    if (value.toString().match(/\d{1,4}-\d{1,4}-\d{1,4}$/g)) {
      date = new Date(value.toString().replace('-', ','));
    } else {
      date = new Date(value);
    }
  } else {
    date = new Date();
  }

  const dateUTC = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return new Date(dateUTC);
}
