import React from 'react';

import Budget from './Budget';
import Patrimony from './Patrimony';

export default function Finances() {
  const [, , choosenSubPath] = window.location.pathname.split('/');

  if (choosenSubPath === 'budget') {
    return <Budget />;
  }

  if (choosenSubPath === 'patrimony') {
    return <Patrimony />;
  }
}
