import styled from 'styled-components';

import { measures } from '../styles';

const {
  sidebarWidth,
  headerHeight,
  headerIconsSize,
  searchIconSize,
  wrapperSize,
} = measures;

export const Container = styled.nav`
  height: ${headerHeight}px;
  min-height: ${headerHeight}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-left: 0;
  transition: background-image 200ms ease;
`;

export const Search = styled.form`
  display: flex;
  align-items: center;

  input {
    margin-left: 21px;
    outline: 0;
    border: 0;
    font-family: 'Nunito', sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: 0.26px;
    color: #999999;

    &::placeholder {
      color: #cccccc;
    }
  }

  img {
    height: ${searchIconSize}px;
    width: ${searchIconSize}px;
    color: #cccccc;
  }
`;

export const SubPagesNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  a {
    font-family: 'Inter', sans-serif !important;
    background: none;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
    white-space: nowrap;
    transition: color .3s ease;
    margin: 0 16px 0 0;
    color: #888888;

    /* &.patrimony{
      color: #140361;
    }
    &.budget{
      color: #140361;
    }
    &.sealGoal{
      color: #140361;
    }
    &.goalPanel{
      color: #140361;
    } */
    &.active {
      color: #140361;
      border-bottom: solid 2px #140361;
    }

    &:hover {
      color: #0f0248;
    }
  }
`;

export const HeaderNav = styled.ul`
  list-style: none;
    display: flex;
    gap: 50px;
    margin-bottom: 0;
    padding-right: 50px;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
    cursor: pointer;

    svg {
      transition: 200ms ease;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      border: 1.2px solid ${props => props.theme.white.default};
      padding: 2px;
      svg {
        width: 22px;
        height: 22px;
        fill: ${props => props.theme.white.default};
      }
    }

    svg {
      height: ${headerIconsSize}px;
      width: ${headerIconsSize}px;
      color: #fff;
    }

    /* &:nth-child(2):after {
      position: absolute;
      right: 0;
      top: 0;
      width: 45%;
      height: 45%;
      background: #f7194e;
      content: '';
      border-radius: 50%;
    } */

    & + li {
      margin-left: 8px;
    }
  }
`;

export const DropDownMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 160px;
  background: #fff;
  right: 0;
  top: 0;
  transform: translateY(50px);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &.show {
    opacity: 1;
    transform: scale(1);
    transform: translateY(70px);
  }

  button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 38px;
    padding: 0 25px;
    font-size: 13px;
    font-weight: bold;
    background: #fff;
    border-radius: 7px;
    color: #999999;

    &:hover {
      background: ${props => props.theme.info.default};
      color: #fff;
    }
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  padding: ${headerHeight + wrapperSize}px ${wrapperSize}px ${wrapperSize}px
    ${sidebarWidth + wrapperSize}px;
`;

export const Avatar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  margin-right: -21px;

  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 9px;
    margin-left: 4px;
  }

  span {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    letter-spacing: 0.4px;
    width: auto;
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${props => props.theme.white.default};
  }
`;
