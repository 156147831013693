const addMonthsToDate = (date, months) => {
    return new Date(new Date(date).setMonth(new Date(date).getMonth() + months));
}

const calculateInstallmentDifference = (purchaseDate, currentInstallmentDate) => {
    const startDate = new Date(purchaseDate);
    const currentParcelDate = new Date(currentInstallmentDate);
    return (currentParcelDate.getFullYear() - startDate.getFullYear()) * 12 + (currentParcelDate.getMonth() - startDate.getMonth()) + 1;
}

const duplicateSpendingItems = (spending, dateSearchStart, dateSearchEnd, duplicatedItems) => {
    let counter = -1;
    let installment = 0;
    let canEdit = true;

    while (true) {
        counter++;

        const newDataItem = addMonthsToDate(spending.start, counter);

        if (newDataItem < dateSearchStart) {
            continue;
        }

        if (newDataItem > dateSearchEnd || new Date(spending.end) < newDataItem) {
            break;
        }

        installment = calculateInstallmentDifference(spending.start, newDataItem);

        duplicatedItems.push({
            category_id: spending.category_id,
            created_at: newDataItem.toISOString(),
            description: spending.description,
            end: spending.end,
            id: spending.id,
            item: spending.item_id,
            payment: spending.payment,
            start: spending.start,
            value: spending.value,
            item_id: spending.item_id,
            installment: installment,
            userCanEdit: canEdit,
            processedFrontendValue: true
        });

        canEdit = false;
    }
}

export const duplicateStatementItems = async (dateSearchStart, dateSearchEnd, spendings) => {
    const duplicatedItems = [];

    for (const spending of spendings) {
        duplicateSpendingItems(spending, dateSearchStart, dateSearchEnd, duplicatedItems);
    }

    return duplicatedItems;
};