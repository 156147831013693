import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  UpperContainer,
  Table,
  Thead,
  Tbody,
  ContainerTotal,
  ContainerTable,
} from './styles';

export default function TableComponent({
  total,
  upperCenterContent,
  upperRightContent,
  title,
  head,
  body,
  color,
  className
}) {
  return (
    <Container  title={title || ''}>
      <UpperContainer color={color}>
        {title && <span className="title">{title}</span>}
        {upperCenterContent && upperCenterContent}
        {upperRightContent && upperRightContent}
      </UpperContainer>
      <ContainerTable className="container-table-total">
      <Table className={className}>
        <Thead>
          <tr>
            {head.map(content => (
              <th key={Math.random()}>{content}</th>
            ))}
          </tr>
        </Thead>
        <Tbody>
          {body.map(row => (
            <tr key={Math.random()}>
              {row.map(column => {
                  if (
                    typeof column === 'object' &&
                    column !== null &&
                    Object.keys(column).length === 2 &&
                    column.currency !== undefined &&
                    column.currency !== null
                  ) {
                    return (
                      <td key={Math.random()}>
                        <div className="valueCurrency">
                          <div className="currency">{column.currency}</div>
                          <div className="value">{column.value}</div>
                        </div>
                      </td>
                    );
                  }
                  return <td key={Math.random()}>{column}</td>;
                })}
            </tr>
          ))}
        </Tbody>
      </Table>
      {total !== 0 && (
        <ContainerTotal className="container-total" color={color}>
          <span>Total</span>
          <span>
            {total.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </span>
        </ContainerTotal>
      )}
      </ContainerTable>
    </Container>
  );
}

TableComponent.propTypes = {
  total: PropTypes.number,
  upperCenterContent: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  upperRightContent: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  head: PropTypes.arrayOf(PropTypes.string),
  body: PropTypes.arrayOf(PropTypes.array),
  color: PropTypes.string,
};
TableComponent.defaultProps = {
  total: 0,
  upperCenterContent: false,
  upperRightContent: false,
  title: false,
  head: ['head', 'head', 'head'],
  body: [
    ['linha', 'linha', 'linha'],
    ['linha', 'linha', 'linha'],
  ],
  color: 'pink',
};
