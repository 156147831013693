import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { AiOutlineCreditCard } from 'react-icons/ai';

import Modal from 'components/Modal';
import Input from 'components/Input';
import CurrencyInput from 'components/masks/CurrencyInput';
import DropdownAsync from 'components/Input/Dropdown/async';
import Button from 'components/Button';
import Loading from 'components/Loading';

import api from 'services/api';

import { Body } from './styles';

export default function CreateInstallmentModal({
  categories,
  setInstallments,
  setCreateInstallmentModal,
}) {
  const [name, setName] = useState('');
  const [value, setValue] = useState(0);
  const [currentInstallment, setCurrentInstallment] = useState(0);
  const [totalInstallments, setTotalInstallments] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [loading, setLoading] = useState(false);

  const user = useSelector(state => state.user.profile);

  async function handleSubmit(event) {
    try {
      event.preventDefault();
      setLoading(true);
      if (
        !name ||
        !value ||
        !currentInstallment ||
        !totalInstallments ||
        !selectedItem ||
        !selectedCategory
      ) {
        setLoading(false);
        return toast.error('Preencha todos os dados');
      }

      if (Number(currentInstallment) > Number(totalInstallments)) {
        setLoading(false);
        return toast.error(
          'A parcela atual não pode ser maior que o total de parcelas'
        );
      }

      if (Number(currentInstallment) <= 0 || Number(totalInstallments) <= 0) {
        setLoading(false);
        return toast.error('As parcelas devem ser maior que zero');
      }

      const { data } = await api.post(`users/${user.id}/installments`, {
        name,
        value,
        category_id: selectedCategory.id,
        item_id: selectedItem.id,
        currentInstallment,
        totalInstallments,
      });

      const { data: installments } = await api.get(
        `/users/${user.id}/installments`,
        {
          params: {
            withCategory: true,
            withItem: true,
            all: true,
          },
        }
      );

      setInstallments(installments);
      setLoading(false);
      setCreateInstallmentModal(false);
      return toast.success('Parcelado criado com sucesso');
    } catch (err) {
      setLoading(false);
      return toast.error('Ops, tivemos um erro!');
    }
  }

  async function handleCategoriesInputChange(newValue) {
    const { data } = await api.get(`users/${user.id}/categories`, {
      params: {
        type: 'spendings',
        name: newValue,
        items: true,
      },
    });

    return data.map(category => ({
      value: category.name,
      label: category.name,
      ...category,
    }));
  }

  return (
    <Modal
      title="Novo Parcelado"
      setShowModal={setCreateInstallmentModal}
      color="debts"
      icon={<AiOutlineCreditCard />}
    >
      {loading ? (
        <Loading />
      ) : (
        <Body>
          <form onSubmit={handleSubmit}>
            <DropdownAsync
              cacheOptions
              loadOptions={handleCategoriesInputChange}
              onChange={selectedOption => setSelectedCategory(selectedOption)}
              placeholder="Categoria..."
              defaultOptions={categories.map(c => ({
                value: c.name,
                label: c.name,
                ...c,
              }))}
            />
            <DropdownAsync
              onChange={selectedOption => setSelectedItem(selectedOption)}
              isSearchable={false}
              placeholder="Item..."
              defaultOptions={
                selectedCategory.items
                  ? selectedCategory.items.map(item => ({
                    value: item.name,
                    label: item.name,
                    ...item,
                  }))
                  : []
              }
            />
            <Input
              label="Nome da Compra"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <CurrencyInput
              label="Valor da parcela"
              prefix="R$ "
              value={value}
              onChange={e => setValue(e)}
            />
            <Input
              type="number"
              label="Parcela Atual"
              value={currentInstallment}
              onChange={e => setCurrentInstallment(e.target.value)}
            />
            <Input
              type="number"
              label="Total de parcelas"
              value={totalInstallments}
              onChange={e => setTotalInstallments(e.target.value)}
            />
            <div className="buttons">
              <Button type="submit">Confirmar</Button>
            </div>
          </form>
        </Body>
      )}
    </Modal>
  );
}

CreateInstallmentModal.propTypes = {
  setCreateInstallmentModal: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  setInstallments: PropTypes.func.isRequired,
};
