import React, { useState, useEffect, useMemo } from 'react';
import { toCurrencyNoCents as formatReal } from 'utils/toCurrency';
import CardPie from './CardPie';
import {
  Container,
  ContainerColumn,
  SubTitle,
  PurpleBar,
  Table,
  ContentWrapper,
  LogoAzul,
  Footer,
  Title,
} from './styles';

export default function AllocationRecommendation({ data }) {
  const { headerTitle, footerTitle } = data;

  const currentAllocationData = useMemo(() => {
    const allocationRecommendations = [
      ...(data?.allocationRecommendations || []),
    ];
    const currentPercentAlocation = allocationRecommendations
      .filter(allocation => allocation.currentPercentAlocation !== 100)
      .map(allocation => ({
        titulo: allocation.fund,
        valor: allocation.currentPercentAlocation,
      }));
    return currentPercentAlocation;
  }, [data]);
  const recommendationsAllocationData = useMemo(() => {
    const allocationRecommendations = [
      ...(data?.allocationRecommendations || []),
    ];
    const allocations = allocationRecommendations
      .filter(allocation => allocation.fullAlocationPercent !== 100)
      .map(allocation => ({
        titulo: allocation.fund,
        valor: allocation.fullAlocationPercent,
      }));
    return allocations;
  }, [data]);
  const allocationTable = useMemo(() => {
    const allocationRecommendations = [
      ...(data?.allocationRecommendations || []),
      ...(data?.overviewAlocation ? [data.overviewAlocation] : []),
    ];

    const contentTable = allocationRecommendations.map((row, index) => {
      if (index < allocationRecommendations.length - 1) {
        return (
          <tr key={`tr-j-${index}`}>
            <td className="title h-0">{row.fund}</td>
            <td className="center h-0">{formatReal(row.currentValue)}</td>
            <td className="center h-0">{row.currentPercentAlocation + '%'}</td>
            <td className="center h-0">
              {row.longTermStrategiesValueDefined + '%'}
            </td>
            <td className="center h-0">{row.fullAlocationPercent + '%'}</td>
            <td className="center h-0">{formatReal(row.finalValue)}</td>
            <td className="center h-0">
              {formatReal(row.nextMovimentationValue)}
            </td>
          </tr>
        );
      } else {
        return (
          <React.Fragment key={`tr-${index}`}>
            <tr className="blue">
              <td className="center h-0">{row.fund}</td>
              <td className="center h-0">{formatReal(row.currentValue)}</td>
              <td className="center h-0">
                {row.currentPercentAlocation + '%'}
              </td>
              <td className="center h-0">
                {row.longTermStrategiesValueDefined + '%'}
              </td>
              <td className="center h-0">{row.fullAlocationPercent + '%'}</td>
              <td className="center h-0">{formatReal(row.finalValue)}</td>
              <td className="center h-0">
                {formatReal(row.nextMovimentationValue)}
              </td>
            </tr>
          </React.Fragment>
        );
      }
    });

    return contentTable;
  }, [data]);

  return (
    <ContentWrapper id="printable" style={{ minHeight: '1682px' }}>
      <Container padding="0 78px 58px 78px">
        <PurpleBar />
      </Container>
      <Container padding="30px 78px 0px 78px">
        <ContainerColumn>
          <Title dangerouslySetInnerHTML={{ __html: headerTitle }} />
          <p className="p-smaller">
            Unindo a estratégia para os sonhos de <b>curto prazo</b>
            <br />
            com a estratégia do portfólio para os sonhos de
            <br />
            <b>longo prazo,</b> temos a seguinte alocação (Full <br />
            Allocation) sugerida especialmente para você.
          </p>
        </ContainerColumn>
      </Container>
      <Container padding="10px 78px 19px 78px" className="table-container">
        <ContainerColumn className="full-content">
          <Table>
            <thead>
              <tr>
                <th className="center">Estratégia</th>
                <th className="center">Valores Atuais</th>
                <th className="center">
                  Participação
                  <br />
                  Atual
                </th>
                <th className="center">
                  Participação
                  <br />
                  Estratégica
                </th>
                <th className="center">
                  <i>
                    Participação
                    <br />
                    Estratégica
                  </i>
                </th>
                <th className="center">
                  Valores
                  <br />
                  Finais
                </th>
                <th className="center">
                  Próximas
                  <br />
                  Movimentações
                </th>
              </tr>
            </thead>
            <tbody>{allocationTable}</tbody>
          </Table>
        </ContainerColumn>
      </Container>
      <Container padding="80px 78px 78px 78px" className="split">
        <div className="half">
          <ContainerColumn>
            <SubTitle style={{ padding: '0 0 38px 0' }}>
              Alocação Atual
            </SubTitle>
            <div className="half-content">
              <CardPie
                height={290}
                title="Alocação Atual"
                values={currentAllocationData}
              />
            </div>
          </ContainerColumn>
        </div>
        <div className="half">
          <ContainerColumn>
            <SubTitle style={{ padding: '0 0 38px 0' }}>
              Alocação Recomendada
            </SubTitle>
            <div className="half-content">
              <CardPie
                height={290}
                title="Alocação Recomendada"
                values={recommendationsAllocationData}
              />
            </div>
          </ContainerColumn>
        </div>
      </Container>
      <Footer>
        <Container padding="0 78px">
          <p>{footerTitle}</p>
          <LogoAzul />
        </Container>
      </Footer>
    </ContentWrapper>
  );
}
