/* eslint-disable import/no-cycle */
import axios from 'axios';
// import { store } from 'store';
// import { signOut } from 'store/modules/auth/actions';
// import history from 'services/history';

const newApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_NEW_API || 'https://realteste.eastus.cloudapp.azure.com/TESTE_RealeasyAPI/api',
});

newApi.interceptors.response.use(
  (response) => {
    return response;
  },
  error => {
    const access_token = localStorage.getItem("persist:realeasy/client");
    if (error.response.status === 401 && access_token) {
      localStorage.removeItem("persist:realeasy/client");
      // history.push('/sign_out');
      // store.dispatch(signOut());
    }
    return Promise.reject(error);
  }
);

export default newApi;
