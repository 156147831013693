import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import suitability from './suitability/reducer';

export default combineReducers({
  auth,
  user,
  suitability,
});
