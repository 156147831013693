import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { ThemeContext } from 'styled-components';

import {
  MdTrendingUp,
  MdAttachMoney,
  MdEqualizer,
  MdInsertDriveFile,
} from 'react-icons/md';

import { FaPercent, FaRegMoneyBillAlt } from 'react-icons/fa';

import { Container, DivCardIcon, Values } from './styles';

import Card from 'components/Cards';

export default function CardComponent({ withPercentage, value, type }) {
  const theme = useContext(ThemeContext);

  const types = {
    'Total Investido': {
      color: theme.investments.default,
      icon: <MdTrendingUp />,
    },
    'Total da Carteira de Investimentos': {
      color: theme.investments.default,
      icon: <MdTrendingUp />,
    },
    'Valor Atual': {
      color: theme.spendings.default,
      icon: <FaRegMoneyBillAlt />,
    },
    'Desenquadramento': {
      color: theme.spendings.default,
      icon: <FaPercent />,
    },
    'Patrimônio Financeiro Atual': {
      color: theme.investments.default,
      icon: <MdTrendingUp />,
    },
    'Rentabilidade da Carteira': {
      color: theme.receipts.default,
      icon: <MdEqualizer />,
    },
    'Proventos Mensais': {
      color: theme.investments.default,
      icon: <MdAttachMoney />,
    },
    'Total de Ativos': {
      color: theme.receipts.default,
      icon: <MdInsertDriveFile />,
    },
  };

  return (
    <Card className="card-menu" width="312px" height="112px">
      <Container>
        <DivCardIcon color={types[type].color}>{types[type].icon}</DivCardIcon>
        <Values color={types[type].color}>
          <h3>{type}</h3>
          <span>
            {withPercentage
              ? `${parseFloat(Number(value).toFixed(2))} %`
              : value.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
          </span>
        </Values>
      </Container>
    </Card>
  );
}

CardComponent.propTypes = {
  withPercentage: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string.isRequired,
};
CardComponent.defaultProps = {
  withPercentage: false,
  value: '-',
};
