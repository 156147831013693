import React, { useContext, useMemo, useEffect } from 'react';

import PropTypes from 'prop-types';

import { ThemeContext } from 'styled-components';
import { Bar } from 'react-chartjs-2';
import { format, getYear } from 'date-fns';
import { pt } from 'date-fns/locale';
import capitalizeWords from 'utils/capitalizeWords';
import { monthsOfYears } from 'utils/dates';
import { optionsBarDisplayOff } from 'utils/optionsBar';
import { ParamsContext } from '../Provider';

import {

  ContainerTitleCard,
  CardContent,
  Body,
  Header,
  AreaDetail,
  DivDetail,
  DivChart,
  DivTotal,

} from '../styles';

import Card from 'components/Cards';

import Select from 'components/Input/Dropdown';

import { useState } from 'react';

export default function Accomplished({ max }) {
  const [balance, setBalance] = useState();
  const theme = useContext(ThemeContext);
  const [selected, setSelected] = useState([]);

  const {
    receipts,
    investments,
    spendingsAccomplished,
    installments,
    passivePatrimonies,
    date,
    setDate,
  } = useContext(ParamsContext);

  function formatDate() {
    return {
      value: capitalizeWords(
        format(date, 'MMMM, yyyy', {
          locale: pt,
        })
      ),
      label: capitalizeWords(
        format(date, 'MMMM, yyyy', {
          locale: pt,
        })
      ),
      data: date,
    };
  }

  const total = useMemo(() => {
    const debts = passivePatrimonies.entries + installments.entries;

    const totalSpendingsAccomplished = Object.keys(
      spendingsAccomplished
    ).reduce((total, currentValue) => {
      return total + spendingsAccomplished[currentValue];
    }, 0);

    const result =
      receipts.entries -
      investments.entries -
      totalSpendingsAccomplished -
      debts;

    return result;
  }, [
    installments.entries,
    investments.entries,
    passivePatrimonies.entries,
    receipts.entries,
    spendingsAccomplished,
  ]);


  const dataChart = {
    labels: [''],
    datasets: [
      {
        label: 'Receitas',
        data: [
          selected.includes('Recebimentos') ? 0
            : receipts?.entries
        ],
        backgroundColor: 'transparent',
        borderColor: theme.receipts.default,
        borderSkipped: 'none',
        borderWidth: 3,
        yAxisID: 'right',

      },
      {
        label: 'Dívidas',
        data: [
          selected.includes('Dívidas') ? 0
            : passivePatrimonies?.entries],
        backgroundColor: theme.debts.default,
      },
      {
        label: 'Patrimônio passivo',
        data: [
          selected.includes('Compras parceladas') ? 0 :
            installments?.entries
        ],
        backgroundColor: theme.debts.hover,
        borderWidth: 0,
      },
      {
        label: 'Gastos mensais comprometidos',
        data: [
          selected.includes('Mensais Comprometidos') ? 0 :
            spendingsAccomplished['Mensal Comprometido']
        ],
        backgroundColor: theme.spendings.hover,
        borderWidth: 0,
      },
      {
        label: 'Gastos mensais flexíveis',
        data: [
          selected.includes('Mensais Flexíveis') ? 0 :
            spendingsAccomplished['Mensal Flexível']
        ],
        backgroundColor: theme.spendings.default,
        borderWidth: 0,
      },
      {
        label: 'Gastos eventuais flexíveis',
        data: [
          selected.includes('Eventuais Flexíveis') ? 0 :
            spendingsAccomplished['Eventual Flexível']
        ],
        backgroundColor: theme.spendings.custom,
        borderWidth: 0,
      },
      {
        label: 'PGE-C',
        data: [
          selected.includes('PGE-C') ? 0 :
            spendingsAccomplished['Eventual Comprometido']
        ],
        backgroundColor: theme.pgec.default,
        borderWidth: 0,
      },
      {
        label: 'Investimentos',
        data: [
          selected.includes('Investimentos') ? 0 :
            investments.entries
        ],
        backgroundColor: theme.investments.default,
        borderWidth: 0,
      },
    ],
  };

  function onOff(label) {
    let index;
    if (selected.includes(label)) {
      index = selected.indexOf(label);

      setSelected(selected.filter((_, i) => i !== index));

      switch (label) {
        case "PGE-C": setBalance(balance - spendingsAccomplished['Eventual Comprometido']);
          break;

        case "Recebimentos": setBalance(balance + receipts?.entries);
          break;

        case "Investimentos": setBalance(balance - investments.entries);
          break;

        case "Eventuais Flexíveis": setBalance(balance - spendingsAccomplished['Eventual Flexível']);
          break;

        case "Mensais Flexíveis": setBalance(balance - spendingsAccomplished['Mensal Flexível']);
          break;

        case "Mensais Comprometidos": setBalance(balance - spendingsAccomplished['Mensal Comprometido']);
          break;

        case "Compras parceladas": setBalance(balance - installments?.entries);
          break;

        case "Dívidas": setBalance(balance - passivePatrimonies.entries);
          break;

        default:
          break;
      }

    }
    else {
      setSelected([...selected, label]);

      switch (label) {

        case "PGE-C": setBalance(balance + spendingsAccomplished['Eventual Comprometido']);
          break;

        case "Recebimentos": setBalance(balance - receipts?.entries);
          break;

        case "Investimentos": setBalance(balance + investments.entries);
          break;

        case "Eventuais Flexíveis": setBalance(balance + spendingsAccomplished['Eventual Flexível']);
          break;

        case "Mensais Flexíveis": setBalance(balance + spendingsAccomplished['Mensal Flexível']);
          break;

        case "Mensais Comprometidos": setBalance(balance + spendingsAccomplished['Mensal Comprometido']);
          break;

        case "Compras parceladas": setBalance(balance + installments?.entries);
          break;

        case "Dívidas": setBalance(balance + passivePatrimonies.entries);
          break;

        default:
          break;
      }
    }
  }

  useEffect(() => {
    if (selected.length === 0) return setBalance(total);

  }, [selected, date, total]);


  return (
    <ContainerTitleCard>
      <div className="header">
        <Header>Detalhamento Pote Realizado</Header>
        <Select
          className="select-date"
          value={formatDate()}
          options={monthsOfYears()}
          onChange={selected => {
            const today = new Date();
            setDate(new Date(getYear(today.setFullYear(today.getFullYear() - 1)), selected.value - 1))
          }
          }
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      <Card className="containerPot" notPadding>
        <CardContent>
          <Body>
            <AreaDetail className="with-select">
              <DivDetail color={theme.receipts.default} onClick={() => onOff("Recebimentos")}>
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes("Recebimentos") ? 'selected' : ''}>Recebimentos</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("Recebimentos") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        receipts.entries.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </span>
                </div>
              </DivDetail>
              {/* Investimentos */}
              <DivDetail color={theme.investments.default} onClick={() => onOff("Investimentos")}>
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes("Investimentos") ? 'selected' : ''}>Investimentos</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("Investimentos") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        investments.entries.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </span>
                </div>
              </DivDetail>
              {/* PGE-C */}
              <DivDetail color={theme.pgec.default} onClick={() => onOff("PGE-C")}>
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes("PGE-C") ? 'selected' : ''}>PEC</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("PGE-C") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        spendingsAccomplished[
                          'Eventual Comprometido'
                        ].toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </span>
                </div>
              </DivDetail>
              {/* Eventual Flexível */}
              <DivDetail color={theme.spendings.custom} onClick={() => onOff("Eventuais Flexíveis")}>
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes('Eventuais Flexíveis') ? 'selected' : ''}>Eventual Flexível</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes('Eventuais Flexíveis') ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        spendingsAccomplished['Eventual Flexível'].toLocaleString(
                          'pt-br',
                          {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }
                        )}
                  </span>
                </div>
              </DivDetail>
              {/* Mensal Flexível */}
              <DivDetail color={theme.spendings.default} onClick={() => onOff("Mensais Flexíveis")}>
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes("Mensais Flexíveis") ? 'selected' : ''} >Mensais Flexíveis</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("Mensais Flexíveis") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        spendingsAccomplished['Mensal Flexível'].toLocaleString(
                          'pt-br',
                          {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }
                        )}
                  </span>
                </div>
              </DivDetail>
              {/* Mensal Comprometido */}
              <DivDetail color={theme.spendings.hover} onClick={() => onOff("Mensais Comprometidos")}>
                <div className="labels" >
                  <div className="circle" />
                  <span className={selected.includes("Mensais Comprometidos") ? 'selected' : ''}>Mensais Comprometidos</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("Mensais Comprometidos") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        spendingsAccomplished[
                          'Mensal Comprometido'
                        ].toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </span>
                </div>
              </DivDetail>
              {/* Compras Parceladas */}
              <DivDetail color={theme.debts.hover} onClick={() => onOff("Compras parceladas")}>
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes("Compras parceladas") ? 'selected' : ''}>Compras parceladas</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("Compras parceladas") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        installments.entries.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </span>
                </div>
              </DivDetail>
              {/* Dívidas */}
              <DivDetail color={theme.debts.default} onClick={() => onOff("Dívidas")}>
                <div className="labels">
                  <div className="circle" />
                  <span className={selected.includes("Dívidas") ? 'selected' : ''}>Dívidas</span>
                </div>
                <div className="values">
                  <span>
                    {
                      selected.includes("Dívidas") ? Number(0).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      ) :
                        passivePatrimonies.entries.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                  </span>
                </div>
              </DivDetail>
              <DivTotal>
                <span className="total">Saldo</span>
                <span className="value">{balance?.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}</span>
              </DivTotal>
            </AreaDetail>
            <DivChart>
              <Bar data={dataChart} options={optionsBarDisplayOff(max)} />
            </DivChart>
          </Body>
        </CardContent>
      </Card>
    </ContainerTitleCard>
  );
}

Accomplished.propTypes = {
  max: PropTypes.number.isRequired,
};
