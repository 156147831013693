import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */

  div.select-patrimony {
    display: flex;
    align-items: center;
    button{
      width:160px;
      padding-left: unset;
      padding-right: unset;
      align-items:center;
      justify-content:center;
    }
    button + button {
      margin-left: 1.5%;
    }
  }

`;

export const Content = styled.div`

  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  /* align-items: center; */
  width: 100%;
  /* flex-direction: column; */
  margin-top: 1.5%;

  & > div + div {
    ${props =>
    props.column
      ? css`
            margin-top: 3%;

          `
      : css`
            margin-left: 2.5%;
          `}
  }

  div.settled_at {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > svg {
      margin-left: 2%;
    }
  }
`;

export const TotalContainer = styled.div`
  padding: 0.5%;
  padding-left: 0%;
  &.patrimonial-active div.total div.patrimony{
    margin-right: 10% !important;
  }
  &.financial-active div.total div.patrimony{
    margin-right: 3% !important;
  }
`;

export const WrapperChart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 1%;
  padding-left: 0%;

`;

export const DivOptions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: relative;

  button {
    width: 100%;
    height: 100%;

    background: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  div.modal-options {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    width: 500%;
    height: 300%;
    background: ${props => props.theme.white.default};
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    z-index: 1;
    transform: translateX(-70px) translateY(40px);

    padding: 15%;

    & > button + button {
      margin-top: 2%;
    }
    button {
      border-radius: 8px;
      color: ${props => props.theme.grafit.hover};
      transition: 100ms ease;

      &:hover {
        background: ${props => props.theme.info.default};
        font-size: 15px;
        color: ${props => props.theme.white.default};
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: ${props => props.showModal === false && 'none'};
`;

export const WrapperCard = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 1%;
  padding-left: 0%;
  margin: 2% 0 1% 0;

  & > div + div {
    margin-left: 5%;
  }
`;

export const DivButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;
