import React, { useMemo } from 'react'
import { DivBar, Goal } from '../../../styles'
import ProgressBar from 'components/ProgressBar';
import default_goal from 'assets/default_goal.svg';
import default_goal_f_i from 'assets/icon_investments.svg';
export default function GoalDefault({ goal, handleremaining, handleSavedMoney, handlePercentProgress }) {

    const getUrl = useMemo(() => {
        if (goal?.file?.url) return goal?.file?.url
        if (goal?.type === 'financial-independence') return default_goal_f_i
        return default_goal
    }, [goal])
    return (
        <Goal key={goal.id}>
            <img
                alt={goal.name}
                src={getUrl}
            />
            <span className="name">{goal.name}</span>
            <span className="ifp">
                {goal.value === null ? '' : goal.value.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })}
            </span>
            <span className="when">
                {goal.done_at ? `Realizado` : `${goal.when} MESES`}
            </span>
            <DivBar>
                <ProgressBar
                    color={goal.done_at ? 'primary' : 'investments'}
                    bg="darker"
                    marginTop={goal.value === null ? '31' : ''}
                    fontSize={matchMedia ? 10 : 12}
                    rightColor="grafit"
                    leftColor="grafit"
                    className="ProgressBar"
                    left={`Falta ${handleremaining(
                        goal.ifp,
                        goal.end,
                        goal.saved_money,
                        goal.value
                    )}`}
                    right={handleSavedMoney(goal.saved_money, goal.ifp)}
                    fill={
                        goal.done_at
                            ? 100
                            : handlePercentProgress(goal.saved_money, goal.value)
                    }
                />
            </DivBar>
        </Goal>

    )
}