import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { AiOutlineEdit } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import { Form } from '../styles';
import { Wrapper } from '../../styles';

import capitalize from 'utils/capitalizeWords';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Input/Dropdown';
import { goalsAllocationDefault } from 'utils/defaultsDropDown';

import { CurrencyInput } from 'components/masks';
import api from 'services/api';

export default function UpdateModal({
  selectedPatrimony,
  setActivePatrimony,
  setModalUdpate,
}) {
  const [id] = useState(selectedPatrimony.id);
  const [name, setName] = useState(selectedPatrimony.name);
  const [classification, setClassification] = useState(
    selectedPatrimony.classification
  );
  const [categorization, setCategorization] = useState(selectedPatrimony.categorization);
  const [liquidity, setLiquidity] = useState(selectedPatrimony.liquidity);
  const [value, setValue] = useState(selectedPatrimony.value);
  const [loading, setLoading] = useState(false);

  const optionsDropdown = [
    { value: 'moradia', label: 'Moradia' },
    { value: 'imóvel de Investimento', label: 'Imóvel de Investimento' },
    { value: 'transporte', label: 'Transporte' },
    { value: 'outros', label: 'Outros' },
  ];

  const optionsliq = [
    { value: 'DO', label: 'DO' },
    { value: 'Até 30 dias', label: 'Até 30 dias' },
    { value: 'De 1 à 6 meses', label: 'De 1 à 6 meses' },
    { value: 'De 6 à 12 meses', label: 'De 6 à 12 meses' },
    { value: 'De 12 à 24 meses', label: 'De 12 à 24 meses' },
    { value: 'Acima de 24 meses', label: 'Acima de 24 meses' },
  ];

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);

      if (!name || !value)
        return toast.error('Os campos nome e valor são obrigatórios');

      let activeToUpdate = {};
      if (selectedPatrimony.type !== 'patrimonial-active') {
        if (!liquidity || !categorization)
          return toast.error('Os campos liquidez e categoria são obrigatórios');
        activeToUpdate = {
          name,
          classification,
          liquidity,
          categorization,
          value,
        }
      } else {
        activeToUpdate = {
          name,
          classification,
          liquidity: null,
          categorization: null,
          value,
        }
      }


      const { data } = await api.put(`/active_patrimonies/${id}`, activeToUpdate);


      setActivePatrimony(patrimonies =>
        patrimonies.map(patrimony => {
          if (patrimony.id !== id) return patrimony;
          return data;
        })
      );
      setLoading(false);
      setModalUdpate(false);
      return toast.success('Ativo editado com sucesso');
    } catch (err) {
      setLoading(false);
      setModalUdpate(false);
      return toast.error('Falha ao editar ativo');
    }
  }

  return (
    <Wrapper>
      <Modal
        color="investments"
        setShowModal={setModalUdpate}
        icon={<AiOutlineEdit />}
        title="Editar Ativo"
      >
        <Form onSubmit={handleSubmit}>
          <Input
            label="Nome Completo:"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          {selectedPatrimony?.type === 'patrimonial-active' && (
            <Dropdown
              label="Tipo de ativo:"
              options={optionsDropdown}
              defaultValue={{
                value: capitalize(classification),
                label: capitalize(classification),
              }}
              onChange={selected => setClassification(selected.value)}
            />
          )}
          {selectedPatrimony?.type !== 'patrimonial-active' && (
            <>
              <Dropdown
                label="Categoria:"
                options={goalsAllocationDefault}
                defaultValue={{
                  value: capitalize(categorization),
                  label: capitalize(categorization),
                }}
                onChange={e => setCategorization(e.value)}
              />
              <Dropdown
                label="Liquidez:"
                defaultValue={{
                  value: capitalize(liquidity),
                  label: capitalize(liquidity),
                }}
                options={optionsliq}

                onChange={e => setLiquidity(e.value)}
              />
            </>
          )}
          <CurrencyInput
            label="Valor:"
            prefix="R$ "
            value={value}
            onChange={cleanValue => setValue(cleanValue)}
          />

          <div className="buttons">
            <Button className="submit" type="submit">
              {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
            </Button>
          </div>
        </Form>
      </Modal>
    </Wrapper>
  );
}

UpdateModal.propTypes = {
  selectedPatrimony: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    classification: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  setActivePatrimony: PropTypes.func.isRequired,
  setModalUdpate: PropTypes.func.isRequired,
};
