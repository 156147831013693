import React from 'react';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import {
  ContentCard,
  Header,
  CardHeader,
  CardBody,
  ProgressBar,

} from '../styles';

import CardComponent from 'components/Cards';

export default function Card({
  withLabel,
  title,
  icon,
  entry,
  estimated,
  color,
}) {
  let percent = (entry / estimated) * 100;

  if (isNaN(percent)) percent = 0;

  return (
    <CardComponent className="card-resize" width="421.6px" height="351.9px">
      {withLabel && <Header budget>{withLabel}</Header>}
      <ContentCard>
        <CardHeader color={color}>
          <div className="icon">{icon}</div>
          <span className="title">{title}</span>
        </CardHeader>

        <CardBody color={color}>
          <div className="realized">
            <small>Estimado</small>
            <div>
              <span className="value">
                {estimated.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </span>
            </div>
          </div>

          <div className="estimated">
            <small>Realizado</small>
            <h4>
              {entry.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </h4>
          </div>

          <div className="countProgress">
            <span>{`${Math.floor(percent)}%`}</span>
          </div>
          <ProgressBar
            width={`${percent > 100 ? 100 : Math.floor(percent)}`}
            color={color}
          >
            <div className="filler" />
          </ProgressBar>
          <div className="detail-button-center">
            <Link className="link" to="/home/detail">
              Detalhes
            </Link>
          </div>
        </CardBody>
      </ContentCard>
    </CardComponent>
  );
}

Card.propTypes = {
  withLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  entry: PropTypes.number,
  estimated: PropTypes.number,
  color: PropTypes.string,
};

Card.defaultProps = {
  withLabel: false,
  entry: 0,
  estimated: 0,
  color: 'receipts',
};
