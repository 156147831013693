import styled from 'styled-components';

export const Container = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
    div.container-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    div.pot-total{
      width: 100%;
      height: 70%;
      border: solid 1px  ${props => props.theme.grafit.hover};
      div.percent{
        display: flex;
        flex-direction: column;
        margin-top: 20%;
        span{
          font-family: Nunito;
          font-size: 1vw;
          font-weight: 600;
          line-height: 1.38;
          letter-spacing: 0px;
          color: ${props => props.theme.grafit.hover};
          &.value{
            font-family: Nunito;
            font-size: 1.8vw;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.35;
            letter-spacing: 0.01px;
            text-align: left;
            color:  ${props => props.theme.investments.default};
          }
        }
      }
    }
    div.container-right-left{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 90%;
      div.container-left{
        display: flex;
        flex-direction: column;
        width: 35%;
        margin-right: 1%;
        height: 90%;
      }
      div.container-right{
        height: 90%;
        display: flex;
        width: 65%;
        .container-table-total{
          display: flex;
          align-items: center !important;
          flex-direction: column !important;
          margin: unset !important;
          .container-total{
            background: #b3b3c24d;
            span{
              color: rgba(0, 0, 0, 0.6) !important;
              font-weight: 600;
            }
          }
          .table-commited-eventual{
            thead{
              tr th{
                padding-bottom: 1% !important;
              }
            }
            tbody{
              .valueCurrency{
                width: auto !important;
              }
              td{
                color: rgba(0, 0, 0, 0.6);
              }
            }
          }
        }
      }
  }
`;

export const Title = styled.h1`
  font-family: Nunito;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${props => props.theme.info.custom};
`;

export const DivChartInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  width: 65%;
  height: 95%;
  position: relative;
  div.describe {
    display: flex;
    flex-direction: column;
    span {
      font-size: 1vw;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 0px;
      text-align: left;
      color: ${props => props.theme.grafit.hover};
      &.value-describe {
        font-size: 1.3vw;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: ${props => props.theme.info.custom};
      }
    }
  }
`;

export const DivChart = styled.div`
  display: flex;
  align-items: flex-end;
  width: 29%;
  height: 95%;
  margin-left: 3%;
  border: 2px solid ${props => props.theme.info.hover};
  border-radius: 12px;
  background: transparent;

  div.filler {
    width: 99.9%;
    height: ${props => props.height}%;
    border-radius: 10px;
    background: ${props => props.theme.investments.default};
  }
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 9%;
  margin-top: 5px;
  padding-left: 4%;
  padding-right: 4%;
  background: ${props => props.theme.investments.default};
  border-radius: 10px;
  span {
    font-family: Nunito;
    font-size: 1.1vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: 0px;
    text-align: left;
    color: #ffffff;
  }
`;


