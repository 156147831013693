import React from 'react';

import Wipe from './Wipe';

export default function Planning() {
  const [, , choosenSubPath] = window.location.pathname.split('/');

  if (choosenSubPath === 'wipe') {
    return <Wipe />;
  }
}
