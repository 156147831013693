import React, { useRef, useContext, useState } from 'react';
import { Container, Footer, Row, Content, DropDown } from './styles';
import Can from 'components/Can';
import { Context } from '../Context';
import api from 'services/api';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import Empty from '../Empty/Search/index';
import ReactTooltip from 'react-tooltip';
import DropdownButton from 'components/Button/Dropdown';
import { IoMdArrowDropdownCircle } from 'react-icons/io';
import { Icon } from '../Extract/index';
import { monthlyPayments } from 'utils/installments';
export default function DetailedExtract({
  color,
  page,
  setPage,
  totalEntries,
  search,
  setReloadItem,
  setReloadCategory,
}) {
  const {
    allEntries,
    setAllEntries,
    setModalUpdate,
    setSelectEntry,
    toggleButton,
    loading,
    setLoading,
    dateStart,
  } = useContext(Context);

  const listInnerRef = useRef();

  const [idItemActive, setIdItemActive] = useState('');

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight + 2 >= scrollHeight && !loading) {
        setPage(page + 1);
        setLoading(true);
      } else {
        if (
          scrollTop + clientHeight + 5 >= scrollHeight &&
          !loading &&
          search
        ) {
          setLoading(true);
          return setPage(page + 1);
        }
      }
    }
  };

  async function handleDeleteEntry(id) {
    try {
      await api.delete(`/entries/${id}`);
      setAllEntries(entries => entries.filter(entry => entry.id !== id));
      toast.success('Excluido com sucesso');
      setReloadCategory(true);
      setReloadItem(true);
    } catch (err) {
      toast.error('Falha ao excluir item');
    }
  }

  return (
    <Container>
      {allEntries.length === 0 ? (
        <Empty />
      ) : (
        <Content onScroll={() => onScroll()} ref={listInnerRef}>
          {allEntries.map(item => (
            <Row
              key={item.id}
              color={item.payment === 'Parcelado' ? 'debts' : color}
            >
              <div className="content-description">
                <div className={`icon-border ${item.payment}`}>
                  <Icon classification={item.classification} />
                </div>

                <div className="description">
                  <span className="title-category">{item.category_name}</span>
                  <span
                    className="title-item"
                    data-tip={item.name || item.description}
                  >
                    {item.name || item.description}
                  </span>
                </div>
              </div>

              <span className="value">
                <span>R$</span>
                <span>
                  {item['value'].toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </span>

              <DropDown
                color={item.payment === 'Parcelado' ? 'debts' : color}
                onClick={() => {
                  if (item.id === idItemActive) {
                    return setIdItemActive(0);
                  }
                  setIdItemActive(item.id);
                }}
              >
                <IoMdArrowDropdownCircle
                  className={idItemActive === item.id ? 'active' : ''}
                />
              </DropDown>

              {toggleButton !== 'installments' && (
                <Can
                  canRoles={[
                    'planner',
                    'adm',
                    'cs',
                    'clientPfg',
                    'assistant-planner',
                  ]}
                >
                  <DropdownButton className="options">
                    <button
                      onMouseDown={() => {
                        setModalUpdate(true);
                        setSelectEntry(item);
                      }}
                    >
                      Editar
                    </button>
                    <button
                      onMouseDown={() => {
                        if (window.confirm('Excluir Item?')) {
                          handleDeleteEntry(item.id);
                        }
                      }}
                    >
                      Excluir
                    </button>
                  </DropdownButton>
                </Can>
              )}
              <ReactTooltip />
              {idItemActive === item.id ? (
                <div class={'content-drop-down'}>
                  <div className="what-is">
                    <span className="what-is">O que é?</span>
                    <span className="description">
                      {item.description || item.name}
                    </span>
                  </div>
                  <span className="date">
                    {format(new Date(item.start), 'dd/MM/yyyy')}
                  </span>

                  <span className="installments">
                    {`${monthlyPayments(item, dateStart).currentInstallment} /
                      ${monthlyPayments(item, dateStart).totalInstallments}`}
                  </span>
                </div>
              ) : (
                ''
              )}
            </Row>
          ))}
        </Content>
      )}
      <Footer color={toggleButton === 'installments' ? 'debts' : toggleButton}>
        <span>Total</span>
        <span>
          {' '}
          {totalEntries?.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}{' '}
        </span>
      </Footer>
    </Container>
  );
}
