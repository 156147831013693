import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
`;

export const UpperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  /* padding: 15px 0; */
  span.title{

    width: 265px;
    font-family: Nunito;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #4d4d70;
  }
`;
export const ContainerTable = styled.div`
  margin-top: 1%;
  display: flex;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0 4px;
  border-collapse: separate;
  &.flexibleMonth {
    thead{
      th:last-child {
        padding-left: unset !important;
      }
    }
  }
  &.flexibleEventual{
    thead{
      th:last-child {
        padding-left: unset !important;
      }
    }
  }
  div.valueCurrency {
      display: flex;
      width: 100px;
      justify-content: space-between;
      margin-right: auto;
    }
`;

export const Thead = styled.thead`
  th {
    padding-left: 30px;
    font-weight: 500;
    color: ${props => props.theme.grafit.hover};
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0px;
  }
`;

export const Tbody = styled.tbody`
  tr {
    td {
      color: #4d4d70;
      font-weight: 600;
      border-top: 1.2px solid #cccccc;
      border-bottom: 1.2px solid #cccccc;
      font-size: 0.9vw;
      font-weight: 600 !important;
      line-height: 1.38;
      padding: 7px 35px;
      background: ${props => props.theme.white.default};

      &:first-child {
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        border-left: 1.2px solid ${props => props.theme.grafit.default};
      }

      &:last-child {
        position: relative;
        border-top-right-radius: 9px;
        border-bottom-right-radius: 9px;
        border-right: 1.2px solid ${props => props.theme.grafit.default};
      }
    }
  }
`;

export const ContainerTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 1.5%;
  padding-top: 1.5%;
  padding-left: 3%;
  padding-right: 9%;
  /* padding: 20px 20px; */
  background: ${props => props.theme.grafit.default};
  border: solid 1px #80809936;
  border-radius: 10px;
  font-weight: 700;
  font-size: 0.9vw;

  @media(max-width: 1600px){
    padding-left: 4%;
    padding-right: 8%;
  }
`;
