import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api from 'services/api';
import { toast } from 'react-toastify';
import {
  FinancialPlanningMapPdfComponent,
  ManagementPdfComponent,
  DirectorCustomerSuccessPdfComponent,
  ManagementEventualPdfComponent,
  MyBudgetPotPdfComponent,
  MoneyFlowPdfComponent,
  OAPIndicatorsPdfComponent,
  BudgetDistributionPdfComponent
} from '../PDF';

const DataGestaoOrcamentaria = {
  headerTitle: 'Gestão <br />Orçamentária',
  footerTitle: 'O Mapa do seu Planejamento Financeiro',
  labels: [
    "Meu Pote Orçamentário",
    "Fluxo do Dinheiro",
    "Distribuição Orçamentária",
    "Gestão de Eventuais",
    "Indicadores do OAP"
  ]
}

const ManagementEventual = {
  headerTitle: 'Gestão<br />de Eventuais',
  footerTitle: 'Gestão Orçamentária'
}

const MyBudgetPot = {
  headerTitle: 'Meu Pote<br />Orçamentário',
  footerTitle: 'Gestão Orçamentária'
}

const DirectorCustomerSuccess = {
  footerTitle: 'O Mapa do seu Planejamento Financeiro'
}

const moneyFlowOptions = {
  headerTitle: 'Fluxo<br />do Dinheiro',
  footerTitle: 'Gestão Orçamentário'
}

const OAPIndicators = {
  headerTitle: 'Indicadores<br />do OAP',
  footerTitle: 'Gestão Orçamentária'
}

const BudgetDistribution = {
  headerTitle: 'Distribuição<br />Orçamentária',
  footerTitle: 'Gestão Orçamentária'
}

const OAPIndicatorsInitialState = {
  savings_index: {
    name: "Índice de Poupança",
    value: 0,
  },
  commitment_index: {
    name: "Índice de Comprometimento",
    value: 0
  },
  installment_purchase_index: {
    name: "Índice de Compras Parceladas",
    value: 0,
    total: 0,
    percentage: 0
  }
}

export default function ActingR1() {

  const { id } = useSelector(state => state.user.profile);
  const [budgetReportData, setBudgetReportData] = useState({})

  const fetchData = async () => {
    try {
      const response = await api.get(`budget/budget_report/${id}?limit_values=20`);
      const { status, data } = response;

      if (status !== 200) {
        toast.error('Não há dados para serem exibidos');
      } else {
        setBudgetReportData(response.data);
      }

    } catch (error) {
      toast.error('Erro ao fazer a requisição');
    }
  };

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <>
      <FinancialPlanningMapPdfComponent data={budgetReportData} />
      <DirectorCustomerSuccessPdfComponent data={DirectorCustomerSuccess} />
      <ManagementPdfComponent data={DataGestaoOrcamentaria} />
      <MyBudgetPotPdfComponent data={{ ...MyBudgetPot, budgetPot: budgetReportData.budget_pot || [] }} />
      <MoneyFlowPdfComponent data={{ ...moneyFlowOptions, moneyFlow: budgetReportData.money_flow || [] }} />
      <BudgetDistributionPdfComponent data={{ ...BudgetDistribution, budgetDistribution: budgetReportData.budget_distribution || [] }} />
      <ManagementEventualPdfComponent data={{ ...ManagementEventual, eventualManagement: budgetReportData.eventual_management || [] }} />
      <OAPIndicatorsPdfComponent data={{ ...OAPIndicators, oapIndicators: budgetReportData.oap_indicators || OAPIndicatorsInitialState }} />
    </>
  );
}
