import styled from 'styled-components';
import TotalTable from 'components/Tables/TotalTable';

export const Table = styled(TotalTable)`

  tbody tr td:first-child {

    width: 150px !important;
    min-width: 150px !important;
    max-width:150px !important;
    /* max-width: 200px !important; */
  }
  tbody tr td:nth-child(2) {
    width: 150px !important;
    min-width: 150px !important;
    max-width:150px !important;
  }

`;

export const Form = styled.form`
  flex: 1;

  div.divGroup {
    display: flex;
    align-items: center;
    & > div + div {
      margin-left: 2%;
    }
    input {
    }
  }

  div.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6%;

    & > button + button {
      margin-left: 1.5%;
    }
  }
`;
