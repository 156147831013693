import styled, { css } from 'styled-components';
import LogoAzulImage from 'assets/logo-azul.png'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170px;
  height: 845px;
  background: #FFF;
  -webkit-print-color-adjust: exact;
  margin: 0 auto;

  ${({ minHeight }) =>
      minHeight !== undefined &&
      css`
        min-height: ${ minHeight + 'px'} ;
      `
  }

  ${({ minHeight }) =>
        minHeight === undefined &&
        css`
          min-height: auto;
        `
    }

  h1, h2, h3, h4, h5, h6, p, span, ul, li, b {
    font-family: 'Poppins', sans-serif !important;
  }

`

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.padding !== undefined ? props.padding: 0};
  width: 100%;
  height: auto;

  & > ul {
    list-style: none;
    font-size: 20px;
    font-weight: 500;

    li {
      position: relative;
      font-weight: 500;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        top: 9px;
        left: -19px;
        width: 11px;
        height: 11px;
        background: #6b87ff;
        border-radius: 11px;
      }
    }

    & > ul {
      list-style: none;
      margin-left: 1.95rem;
      font-weight: 500;
      li {
        position: relative;
        font-weight: 500;
        &:before {
          content: '';
          position: absolute;
          top: 9px;
          left: -19px;
          width: 11px;
          height: 11px;
          background: #FFFFFF;
          border: solid 2px #6b87ff;
          border-radius: 11px;
        }
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.padding !== undefined ? props.padding: 0};
  height: ${props => props.height !== undefined ? props.height : 'auto'};

  > div.half {
    width: 50%;
  }

  > div.d-40w {
    width: 44%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  > div.d-60w {
    width: 56%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-title {
    height: auto;
    min-height: 80px;

    small {
      font-size: 12px !important;
      font-style: italic;
    }
  }

  .p-smaller {
    font-size: 19px;
    line-height: 26px;
    font-weight: 600;
    margin-top: 58px;
  }

  .p-smaller-no-mg {
    font-size: 19px;
    line-height: 26px;
    font-weight: 600;
    margin-top: 19px;
  }

  .half-content {
    height: 100%;
    border: solid 1px #EEE;
  }

  .full-content {
    height: 460px;
    width: 100%;
    border: solid 1px #EEE;
  }

  &.split {
    gap: 6px;
  }

  &.split-2 {
    margin-top: auto;
    gap: 4px;
  }
`;

export const PurpleBar = styled.div`
  width: 378px;
  height: 40px;
  background: #5560ff;

  &.full-height {
    background: #6b87ff;
    height: 100%;
  }
`

export const Title = styled.h1`
  text-transform: uppercase;
  font-size: 58px;
  font-weight: 800;
  line-height: 66px;
  margin-bottom: 0;
  padding: 0;
  margin-top: -2px;
`

export const SubTitle = styled.h2`
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 0;
  padding: 0;

  &.bigger {
    font-size: 40px;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  sup {
    font-size: 20px;
  }
`

export const LogoAzul = styled.div`
  width: 60px;
  height: 54px;
  background: url(${LogoAzulImage});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 15px;
`

export const Footer = styled.div`
  width: 100%;
  height: 130px;
  margin-top: auto;
  border-top: solid 6px #2d2d2d;

  > div {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 23px;
    font-weight: 600;
  }
`
