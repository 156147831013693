import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  width: 100%;

  div.divGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  div.button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
  }
`;

export const SubTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;
  letter-spacing: 0px;
  text-align: left;
  color: ${props => props.theme.darker.disabled};
  margin: 8px 0px;
`;
