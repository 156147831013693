import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  canvas {
    min-height: 300px;
    position: relative;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.darker.disabled};
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;

  button {
    background: none;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.3px;
    text-align: left;
    color: ${({ theme }) => theme.info.default};
  }
`;

export const Information = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  /* height: 80vh; */
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    background: ${({ theme, color }) => theme[color].default};
    width: 12px;
    height: 12px;
    border-radius: 2px;
    transform: translateX(-15px);
  }

  p {
    margin: 0 0 0 4px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.grafit.hover};
  }

  span {
    margin-left: 4px;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: 0px;
    color: ${({ theme, color }) => theme[color].default};
  }
`;
