import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { Form } from '../styles';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Loading from 'components/Loading';

import api from 'services/api';

export default function DeleteModal({
  setShowModalDelete,
  idSelectedAsset,
  setOperations,
}) {
  const [loading, setLoading] = useState(false);

  const { id } = useSelector(state => state.user.profile);

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      setLoading(true);

      await api.delete(`users/${id}/operations/${idSelectedAsset}`);

      setOperations(operations =>
        operations.filter(operation => operation.id !== idSelectedAsset)
      );

      toast.success('Item excluído com sucesso');
    } catch (err) {
      toast.error('Falha ao excluir item');
    } finally {
      setLoading(false);
      setShowModalDelete(false);
    }
  }
  return (
    <Modal
      title="Excluir item?"
      color="debts"
      setShowModal={setShowModalDelete}
    >
      <Form onSubmit={handleSubmit}>
        <div className="button">
          <Button stripped>{loading ? <Loading /> : 'Confirmar'}</Button>
        </div>
      </Form>
    </Modal>
  );
}

DeleteModal.propTypes = {
  idSelectedAsset: PropTypes.number.isRequired,
  setShowModalDelete: PropTypes.func.isRequired,
  setOperations: PropTypes.func.isRequired,
};
