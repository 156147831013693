import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { format, subMonths, addMonths } from 'date-fns';
import { pt } from 'date-fns/locale';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import capitalize from 'utils/capitalizeWords';
import api from 'services/api';
import { ContentCard, ContainerDate, Status } from '../styles';
import Card from 'components/Cards';
import Loading from 'components/Loading';
import Table from '../Table';
import { Container, Title, DivChartInfo, DivChart, Head } from './styles';
import CardProgress from '../CardProgress/index';

export default function CommitedEventual({ theme }) {
  const [commitEventual, setCommitEventual] = useState([]);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [totalEventual, setTotalEventual] = useState(0);
  const [loading, setLoading] = useState(false);
  const [accomplished, setAccomplished] = useState(0);
  const { id } = useSelector(state => state.user.profile);

  const head = [
    'Item',
    'Categoria',
    'Vencimento',
    'Estimado',
    'Realizado',
    'Status',
  ];

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await api.get(
        `/users/${id}/spending_classification_report`,
        {
          params: {
            classification: 'Eventual Comprometido',
            date,
          },
        }
      );

      let eventual = data.reduce((total, current) => total + current.value, 0);
      setTotalEventual(eventual);
      setAccomplished(
        eventual - data.reduce((total, current) => total + current.total, 0)
      );

      setData(data);
      setLoading(false);
    }
    fetchData();
  }, [id, date]);

  useEffect(() => {
    setCommitEventual(
      data.map(item => [
        item.name,
        item.category.name,
        capitalize(
          format(new Date(2020, item.when - 1), 'MMMM', {
            locale: pt,
          })
        ),
        {
          value: item.value.toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          currency: 'R$',
        },
        {
          value: item.total.toLocaleString('pt-br', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          currency: 'R$',
        },
        <Status status_color={handleStatusColor(item.total, item.when)}>
          <span>{handleStatusColor(item.total, item.when, true)}</span>
        </Status>,
      ])
    );
  }, [data]);

  function handlePotValue() {
    // let result = 0;
    // result = (hasTotal * 100) / (totalEventual * 12);
    // if (result > 100) return 100;
    return 0;
  }

  function handleStatusColor(total, when, justLabel) {
    // red -> item.total  === 0 & item.when < new Date()
    // gren -> item.total > 0
    // blue -> item.total & item.when >= new Date()

    if (total === 0 && when < new Date()) {
      if (justLabel) {
        return 'Atrasado';
      }
      return 'danger';
    }

    if (total > 0) {
      if (justLabel) {
        return 'Pago';
      }
      return 'success';
    }

    if (total === 0 && when >= new Date()) {
      if (justLabel) {
        return 'Em aberto';
      }
      return 'secondary';
    }
  }
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <div className="container-header">
            <Title color={theme.info.hover}>Eventual Comprometido</Title>
            <ContainerDate color={theme.darker.default} key={Math.random()}>
              <button
                onClick={() => setDate(date => subMonths(new Date(date), 1))}
              >
                <AiOutlineLeft size={32} />
              </button>
              <div className="date">
                <span>
                  {capitalize(format(date, 'MMMM, yyyy', { locale: pt }))}
                </span>
              </div>
              <button
                onClick={() => setDate(date => addMonths(new Date(date), 1))}
              >
                <AiOutlineRight size={32} />
              </button>
            </ContainerDate>
            <CardProgress
              left={totalEventual.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              right={accomplished.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              fill={100 - (100 * accomplished) / totalEventual}
            />
          </div>
          <div className="container-right-left">
            <div className="container-left">
              <Card className="pot-total" notShadow>
                <ContentCard>
                  <DivChart height={handlePotValue}>
                    <div className="filler" />
                  </DivChart>
                  <DivChartInfo>
                    <div className="total">
                      <div className="describe">
                        <span>Total Eventual Comprometido</span>
                        <span className="value-describe">
                          {Number(totalEventual).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="percent">
                      <span>Quanto você tem</span>
                      <span className="value">R$ 0,00</span>
                    </div>
                  </DivChartInfo>
                </ContentCard>
              </Card>
              <Head>
                <span>Reserva para Gastos Eventuais</span>
                <span>
                  {(totalEventual / 12).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </span>
              </Head>
            </div>

            <div className="container-right">
              <Table
                className="table-commited-eventual"
                color={theme.info.hover}
                head={head}
                body={commitEventual}
                total={data
                  .reduce((total, current) => total + current.total, 0)
                  .toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

CommitedEventual.propTypes = {
  theme: PropTypes.shape({
    info: PropTypes.shape({
      hover: PropTypes.string,
    }),
  }).isRequired,
};
