import React from 'react'
import Input from 'components/Input';
import Button from 'components/Button';
import { Form, DivSwitch, DivDropzone } from '../../../../styles';
import Switch from 'react-switch';
import { FaSpinner } from 'react-icons/fa';
import { MdPhotoCamera } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';
import { CurrencyInput } from 'components/masks';
import { goalsAllocationDefault, liquidityOptions } from 'utils/defaultsDropDown';
import Dropdown from 'components/Input/Dropdown';

export default function Default(
    {
        name,
        acceptedFiles,
        getRootProps,
        theme,
        handleSubmit,
        selectedGoal,
        calcIFP,
        getInputProps,
        setName,
        description,
        setDescription,
        value,
        setValue,
        savedMoney,
        setSavedMoney,
        profitability,
        setProfitability,
        end,
        setEnd,
        thumbnail,
        setThumbnail,
        toggleSwitch,
        setToggleSwitch,
        loading,
        setLoading,
        bpl,
        setBpl,
        plf,
        setPlf,
        allocationStrategy,
        setallocationStrategy,
        patrimonialActiveValue,
        setPatrimonialActiveValue,
        financialActiveValue,
        setFinancialActiveValue,
        liquidity,
        setLiquidity,
        patrimonialBalance,
        financialBalance,
        financialActiveValueBpl,
        setFinancialActiveValueBpl,
        allocationStrategyBpl,
        setallocationStrategyBpl

    }
) {




    return (
        <Form column>
            <div className='left'>
                <Input
                    label="Nome:"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <Input
                    label="O que significa pra você?"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                />
                <DivDropzone thumbnail={thumbnail}>
                    <div {...getRootProps({ className: 'dropzone' })}>
                        {acceptedFiles.length > 0 ? (
                            <h3>{acceptedFiles[0].name}</h3>
                        ) : (
                            <MdPhotoCamera />
                        )}
                        <input {...getInputProps()} />
                    </div>
                </DivDropzone>

                <div className="inputGroup">
                    <Input
                        label="Prazo:"
                        width="160px"
                        value={end}
                        type="date"
                        onChange={e => setEnd(e.target.value)}
                    />
                    <DivSwitch>
                        <label>Sem valor definido</label>
                        <Switch
                            onChange={() => {
                                setToggleSwitch(!toggleSwitch);
                                setValue(0);
                                setProfitability(0);
                                setSavedMoney(0);
                            }}
                            checked={toggleSwitch}
                            offColor={theme.grafit.default}
                            onColor={theme.info.default}
                            onHandleColor={theme.info.hover}
                            handleDiameter={25}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={16}
                            width={45}
                        />
                    </DivSwitch>

                </div>
                <CurrencyInput
                    label="Valor total"
                    prefix="R$ "
                    value={value}
                    onChange={e => setValue(e)}
                />

                {toggleSwitch && (

                    <div className="buttonGroup">
                        <Button onClick={e => handleSubmit(e)}>
                            {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
                        </Button>
                    </div>
                )}
            </div>
            {/* feito */}
            {!toggleSwitch && (
                <div className='right'>

                    <div className="inputGroup">

                        <CurrencyInput
                            label="Patrimônio Imobilizado atual:"
                            data-tip={`Disponível: ${(patrimonialBalance)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}

                            value={patrimonialActiveValue}
                            onChange={setPatrimonialActiveValue}
                        />
                        <CurrencyInput
                            label="Patrimônio Financeiro atual:"
                            data-tip={`Disponível: ${(financialBalance)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`}

                            value={financialActiveValue}
                            onChange={setFinancialActiveValue}
                        />
                    </div>

                    <Input
                        style={{ background: '#e9e9e9' }}
                        label="Valor total atual:"
                        disabled
                        value={savedMoney?.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                        })}
                    />
                    <div className="inputGroup drop">

                        <Dropdown
                            label="Estratégia de alocação:"
                            options={goalsAllocationDefault}
                            defaultValue={{
                                value: allocationStrategy,
                                label: allocationStrategy,
                            }}
                            onChange={selected => setallocationStrategy(selected.value)}
                        />
                        <Dropdown
                            label="Liquidez:"
                            options={liquidityOptions}
                            defaultValue={{
                                value: liquidity,
                                label: liquidity,
                            }}
                            onChange={selected => setLiquidity(selected.value)}
                        />
                    </div>

                    <CurrencyInput
                        suffix="% a.m."
                        label="Rentabilidade:"
                        value={profitability}
                        onChange={e => setProfitability(e)}
                    />

                    <Input
                        style={{ background: '#e9e9e9' }}
                        label="Investimento Mensal:"
                        disabled
                        value={calcIFP()}
                    />
                    <div className="buttonGroup">
                        <Button onClick={e => handleSubmit(e)}>
                            {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
                        </Button>
                    </div>
                </div>
            )}
                  <ReactTooltip />
        </Form>
    )
}
