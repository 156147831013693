import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { addMonths, subMonths, format } from 'date-fns';
import { pt } from 'date-fns/locale';
import capitalize from 'utils/capitalizeWords';
import Table from '../Table';
import api from 'services/api';
import { Wrapper, Status, ContainerDate } from '../styles';
import Loading from 'components/Loading';
import { ContainerSwitch } from './styles';
import CardProgress from '../CardProgress/index';

export default function CommitedMonth({ theme }) {
  const [commitMonths, setCommitMonths] = useState([]);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const { id } = useSelector(state => state.user.profile);
  const [checkedRecurrent, setCheckedRecurrent] = useState('');
  const [estimated , setEstimated] = useState(0);
  const [accomplished, setAccomplished] = useState(0);

  const head = [
    'Item',
    'Categoria',
    'Venc.',
    'Estimado',
    'Realizado',
    'Recorrente',
    'Status',
  ];

  function handleChangeRecurrent() {
    setCheckedRecurrent('clicked');
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      const { data } = await api.get(
        `/users/${id}/spending_classification_report`,
        {
          params: {
            classification: 'Mensal Comprometido',
            date,
          },
        }
      );


      let totalEstimated = data.reduce((accum, curr) => accum + curr.value,0);
      setEstimated(totalEstimated);

      let totalAccomplished = data.reduce((accum, curr) => accum + curr.total,0);
      setAccomplished(totalEstimated - totalAccomplished);

      setCommitMonths(
        data.map(item => [
          item.name,
          item.category.name,
          item.when,
          {
            value: item.value.toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
            currency: 'R$',
          },
          {
            value: item.total.toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
            currency: 'R$',
          },

          <ContainerSwitch>
            <button
              className={checkedRecurrent.toString()}
              onClick={() => {
                handleChangeRecurrent();
              }}
            >
              <p>SIM</p>
            </button>
            <button
              className=""
              onClick={() => {
                handleChangeRecurrent();
              }}
            >
              <p>NÃO</p>
            </button>
          </ContainerSwitch>,

          // <Recurrent status={item}>,
          <Status status_color={handleStatusColor(item.total, item.when)}>
            <span>{handleStatusColor(item.total, item.when, true)}</span>
          </Status>,
        ])
      );
      setLoading(false);
    }
    fetchData();
  }, [checkedRecurrent, date, id]);


  function handleStatusColor(total, when, justLabel) {
    // red -> item.total  === 0 & item.when < new Date()
    // gren -> item.total > 0
    // blue -> item.total & item.when >= new Date()
    // Cor azul: value === 0 e o dia do item é menor que o dia de hj,
    // por ex, eu pago a conta de luz dia 10 e estamos no dia 5, então value === 0 e "dia_item >= dia_hoje"

    if (total === 0 && when < new Date()) {
      if (justLabel) {
        return 'Atrasado';
      }
      return 'danger';
    }

    if (total > 0) {
      if (justLabel) {
        return 'Pago';
      }
      return 'success';
    }

    if (total === 0 && when >= new Date()) {
      if (justLabel) {
        return 'Em aberto';
      }
      return 'secondary';
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Wrapper>
          <Table
            title="Mensal Comprometido"
            head={head}
            body={commitMonths}
            color={theme.darker.default}
            upperCenterContent={
              <ContainerDate color={theme.darker.default} key={Math.random()}>
                <button
                  onClick={() => setDate(date => subMonths(new Date(date), 1))}
                >
                  <AiOutlineLeft size={32} />
                </button>
                <div className="date">
                  <span>
                    {capitalize(format(date, 'MMMM, yyyy', { locale: pt }))}
                  </span>
                </div>
                <button
                  onClick={() => setDate(date => addMonths(new Date(date), 1))}
                >
                  <AiOutlineRight size={32} />
                </button>
              </ContainerDate>
            }
            upperRightContent={
              <CardProgress left={
                estimated.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
                }

              fill={
                (100-(100 * accomplished)/estimated)
              }

              right={
                accomplished.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
                }
              />
              }
          />
        </Wrapper>
      )}
    </>
  );
}

CommitedMonth.propTypes = {
  theme: PropTypes.shape({
    darker: PropTypes.shape({
      hover: PropTypes.string,
      default: PropTypes.string,
    }),
  }).isRequired,
};
