import React, { useState, useEffect } from 'react';
import { toCurrencyNoCents as formatReal } from 'utils/toCurrency';
import {
  Container,
  ContainerColumn,
  PurpleBar,
  ContentWrapper,
  LogoAzul,
  Table,
  Footer,
  Title
} from './styles';

export default function ManagementEventual({ data }) {

  const { headerTitle, footerTitle, eventualManagement } = data;
  const [managementData, setManagementData] = useState({
    pec: 0,
    lec: 0,
    committedEventual: [],
    flexibleEventual: []
  })

  useEffect(() => {
    if (eventualManagement.pec !== undefined) {
      setManagementData({
        pec: eventualManagement.pec,
        lec: eventualManagement.lec,
        committedEventual: eventualManagement.committed_eventual,
        flexibleEventual: eventualManagement.flexible_eventual
      });
    }
  }, [eventualManagement])

  const renderTableEventuaisComprometidos = () => {
    if (managementData.committedEventual.length === 0) return;

    const contentTable = managementData.committedEventual.map((row, index) => {

      return (
        <tr key={`tr-ec-${index}`}>
          <td className="title h-0">{row.name}</td>
          <td className="center h-0">{row.month}</td>
          <td className="center h-0">{formatReal(row.anual_value)}</td>
        </tr>
      )
    })

    return contentTable;
  }

  const renderTableEventuaisFlexiveis = () => {
    if (managementData.flexibleEventual.length === 0) return;

    const contentTable = managementData.flexibleEventual.map((row, index) => {

      return (
        <tr key={`tr-ef-${index}`}>
          <td className="title h-0">{row.name}</td>
          <td className="center h-0">{formatReal(row.anual_value)}</td>
        </tr>
      )
    })

    return contentTable;
  }

  return (
    <ContentWrapper id="printable" minHeight={1682}>
      <Container padding="0 78px 80px 78px">
        <PurpleBar />
      </Container>
      <Container padding="0 78px">
        <ContainerColumn>
          <Title dangerouslySetInnerHTML={{ __html: headerTitle }} />
          <Container style={{ gap: '14px', margin: '58px 0' }}>
            <div className="d-50w">
              <p className="p-smaller-no-mg">Esses são as referências para conseguir gerir os<br />gastos que não acontecem todos os meses;</p>
              <ul>
                <li>PEC: Provisão de Eventuais Comprometidos</li>
                <li>LEF: Limite de Eventuais Flexíveis</li>
              </ul>
            </div>
            <div className="d-25w">
              <div className="d-purple">PEC</div>
              <div className="d-purple">LEF</div>
            </div>
            <div className="d-25w">
              <div className="d-gray">{formatReal(managementData.pec)}</div>
              <div className="d-gray">{formatReal(managementData.lec)}</div>
            </div>
          </Container>
        </ContainerColumn>
      </Container>
      <Container padding="0px 78px 19px 78px">
        <ContainerColumn className="full-content">
          <div className="horizontal-text">
            <h2>Eventuais Comprometidos</h2>
          </div>
          <div className="tabela">
            <Table>
              <thead>
                <tr>
                  <th className="center" style={{ width: '50%' }}>Item</th>
                  <th className="center">Mês</th>
                  <th className="center">Valor Anual</th>
                </tr>
              </thead>
              <tbody>
                {renderTableEventuaisComprometidos()}
              </tbody>
            </Table>
          </div>
        </ContainerColumn>
      </Container>
      <Container padding="0px 78px 19px 78px">
        <ContainerColumn className="full-content">
          <div className="horizontal-text">
            <h2>Eventuais Flexíveis</h2>
          </div>
          <div className="tabela">
            <Table>
              <thead>
                <tr>
                  <th className="center" style={{ width: '50%' }}>Item</th>
                  <th className="center">Valor Anual</th>
                </tr>
              </thead>
              <tbody>
                {renderTableEventuaisFlexiveis()}
              </tbody>
            </Table>
          </div>
        </ContainerColumn>
      </Container>
      <Footer>
        <Container padding="0 78px">
          <p>{footerTitle}</p>
          <LogoAzul />
        </Container>
      </Footer>
    </ContentWrapper>
  );
}
