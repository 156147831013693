import styled from 'styled-components';

export const InfoCardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;

  & > div {
    margin-right: 25px;
  }

  @media (max-width: 1400px) {
    display: grid;
    grid-template-columns: 49% 49%;

    & > div {
      margin-right: 0;
      margin-bottom: 3%;
      
    }
  }
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1%;
  width: 100%;
  justify-content: space-between;
  span {
    padding-bottom: 2%;

    font-size: 16px;
    line-height: 1.33;
    text-align: left;
    color: ${props => props.theme[props.color].default};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 53px;
`;

export const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 98.5%;
  margin-top: 53px;

  & > div + div {
    margin-top: 80px;
  }
`;

export const DivButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  width: 100%;
`;

export const DivNewCategory = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;
