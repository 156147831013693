import styled, { keyframes } from 'styled-components';
import Card from 'components/Cards';

const showDivDropdown = keyframes`
  0% {
    opacity: 0;
    max-height: 0;
  }
  70% {
    max-height: 100%;
    opacity: 1;
  }
`;
const slide = keyframes`
  100% { transform: translateX(0%); }
  0% { transform: translateX(-100%); }
`;

export const Container = styled(Card)`
  display: grid;
  width: 100%;
  overflow-x: unset;
  height: 100%;
  max-height: calc(100% - 50px);
  border: solid 1px ${props => props.theme.grafit.hover};
  box-shadow: unset !important;
  padding: unset;

  .options {
    width: 20px;
    height: 110%;
    font-size: 1.5vh;
  }
`;

export const Content = styled.div`
  /* margin: 2% 2% 5% 2%;
  width:96%; */
  margin-top: 2%;
  margin-right: 2%;
  margin-bottom: 6.7vh;
  margin-left: 2%;
  width: 96%;
  overflow-y: scroll;
  
`;

export const Row = styled.div`
  display: flex;
  position: relative;
  width: 96%;
  height: 12%;
  animation: ${slide} 0.5s backwards;
  /* min-height: 48px;
  max-height: 65px; */
  align-items: center;
  justify-content: space-between;
  margin: 0 3% 0 3%;
  border-bottom: solid 0.4px ${props => props.theme.white.disabled};
  /* background: lightblue; */

  .content-description {
    display: flex;
    width: 60%;

    height: 100%;
    align-items: center;

    /* background: pink; */

    .description {
      display: flex;
      flex-direction: column;
      margin-left: 3%;
      width: 80%;

      .title-category {
        font-family: Nunito;
        font-size: 1.3vh;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0px;
        color: ${props => props.theme[props.color]?.default};
      }
      .title-item {
        white-space: nowrap;
        font-family: Nunito;
        font-size: 0.8vw;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 0px;
        color: #808099;

        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    div.icon-border {
      display: flex;
      padding: 3%;
      width: 4vh;
      height: 4vh;
      align-items: center;
      justify-content: center;
      border: solid 1px ${props => props.theme[props.color]?.default};
      border-radius: 1vh;
      background: ${props => props.theme[props.color]?.disabled};
      svg {
        fill: ${props => props.theme[props.color]?.default};
      }
    }
  }
  span {
    font-family: Nunito;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    &.value {
      display: flex;
      justify-content: space-between;
      /* background: lightgray; */
      text-align: left;
      font-size: 1.5vh;
      font-weight: bold;
      line-height: 1.38;
      color: ${props => props.theme[props.color]?.default}!important;
      width: 6vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  div.buttons {
    svg {
      width: 1.8vh;
      height: 1.8vh;
      &.edit {
        color: ${props => props.theme.receipts.default};
      }
      &.delete {
        color: ${props => props.theme.debts.default};
      }
    }
    button + button {
    }
  }
  .content-drop-down {
    display: flex;
    border-radius: 0.8vw;
    position: absolute;
    transform: translateY(6.8vh);
    align-items: center;
    z-index: 9999;
    width: 100%;
    height: 90%;
    background: ${props => props.theme[props.color]?.disabled};
    /* background: lightskyblue; */
    animation: ${showDivDropdown} forwards 800ms;
    padding-left: 5%;
    padding-right: 5%;
    justify-content: space-between;
    div.what-is {
      display: flex;
      flex-direction: column;

      span.what-is {
        font-size: 1.5vh;
        color: ${props => props.theme[props.color]?.default};
      }
      span.description {
        font-size: 1.8vh;
        color: ${props => props.theme.grafit.hover};
        font-weight: 600;
      }
    }
    span.installments {
      font-size: 1.5vh;
      color: ${props => props.theme.grafit.hover};
    }
    span.date {
      font-size: 1.3vh;
      font-weight: normal;
      line-height: 1.33;
      color: #808099;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 6.5vh;
  max-height: 64px;
  background: ${props => props.theme[props.color].default};
  bottom: 0%;
  align-items: center;
  justify-content: space-between;
  padding: 0 3% 0 3%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  span {
    font-family: Nunito;
    font-size: 2vh;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: 0px;
    text-align: left;
    color: #ffffff;
  }
`;

export const DropDown = styled.button`
  display: flex;
  /* background: lightsalmon; */
  height: 100%;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 2vh;
    fill: ${props => props.theme[props.color].default};
    &.active {
      transform: rotate(180deg);
    }
  }
`;
