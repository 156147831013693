import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function GhostButton({
  children,
  isCustomColor,
  color,
  ...rest
}) {
  return (
    <Container color={color} isCustomColor={isCustomColor} {...rest}>
      {children}
    </Container>
  );
}

GhostButton.propTypes = {
  isCustomColor: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
  ]),
  color: PropTypes.string,
};

GhostButton.defaultProps = {
  isCustomColor: false,
  children: 'Botão',
  color: 'receipts',
};
