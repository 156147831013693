import styled from 'styled-components';

export const ContainerSwitch = styled.div`
  display: flex;
  width: 115px;
  height: 34px;
  justify-content: space-between;
  align-items:center;
  background: #f0f1f7;

  button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 51px;
    height: 24px !important;

    p{
      margin-bottom: unset !important;
      font-family: Nunito;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.15px;
      text-align: left;
      color: #b3b3c2;
    }

    &.clicked{
      margin: 0 16px 0 0;
      padding: 4px 15px;
      border-radius: 2px;
      box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.16);
      background-color: #3ba7ff;
      p{
        font-family: Nunito;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.15px;
        color: #ffffff;
      }
    }

`;


