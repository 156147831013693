import React from 'react';
import { DatePickerCustom } from './styles'
import { RiArrowDownSLine } from 'react-icons/ri';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

export default function DatePicker({
    selected,
    onChange,
    className,
    locale,
    dateFormat,
    text

}) {
    function formatDate(date) {
        const dateAux = format(date, 'MMMM', {
            locale: pt,
        }).toString();
        return dateAux
            .charAt(0)
            .toUpperCase()
            .concat(dateAux.substring(1));
    }
    return (
        <DatePickerCustom
            selected={selected}
            onChange={onChange}
            className={className}
            locale={locale}
            dateFormat={dateFormat}
            customInput={
                <button>
                    <span className="indicator"> {text} </span>
                    <span>{
                        formatDate(selected)
                    }
                    </span>
                
                    <RiArrowDownSLine />
                </button>}
            showMonthYearPicker
        />
    );

}