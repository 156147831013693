import styled from 'styled-components';

export const ContainerBar = styled.div`

  width: 100%;
  .bar{
    width: 200px !important;

    div.info div.left{
        margin: unset !important;
      }
    p{
      margin-bottom: 30px;
    }
    .progress-bar{
      height: 12px;
    }
  }
`;
