import styled from 'styled-components';

export const ContainerProgress = styled.div`

  .card-progress{
    width: 286px;
    height: 70px;
    border-radius: 5px !important;
    border: solid 1px #b3b3c2 !important;
    padding: 13px !important;
    box-shadow: unset !important;
      div.values{
      display: flex;
      justify-content: space-between;
      height: 13px;
      p{
        font-family: Nunito;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0px;
        text-align: left;
        color: #808099;
      }
  }

  div.div-bar{
    div.info{
      height: 19px !important;
      p{
        font-family: Nunito;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0px;
        color: #808099;

      }
      div.left{
          margin-top: unset !important;
          p{
            margin-top: 2px;
          }
      }
      div.right{
        text-align: right;
        margin-top: unset !important;
        p{
          font-family: Nunito;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: 0px;
          text-align: right;
          color: #1399ff;

        }
      }
    }
      div.progress-bar{
        height:10px !important;
      }
    }
  }
`;
