import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ContextColorHeader from 'utils/contexts/ColorHeaderContext';
import { Container,  SubPagesNav } from './styles';
import routesInfo from 'routes/routesInfo';
import { checkSuitabilityUserAuth } from 'services/users'

export default function Header() {

  const { colorHeader } = useContext(ContextColorHeader);

  const { parcial, respondido } = useSelector(state => state.suitability);

  const [, choosenPath, choosenSubPath] = window.location.pathname.split('/');

  const fullPath = window.location.pathname;

  let pageInfo = routesInfo[choosenPath];

  const disableLinkInvestments = () => {
    // Se veio do ADM
    if(!checkSuitabilityUserAuth()) return false;

    if(choosenPath === 'investments') {
      if(respondido && parcial === false) {
        // Respondeu tudo, libera os botões
        return false;
      } else {
        // Não respondeu, libera apenas suitability
        return true;
      }
    } else {
      return false;
    }
  }

  const checkIsNotForbiden = (path, forbidens) => {
    if(forbidens.length === 0) return true;
    const found = forbidens.find(item => item === path);
    return found === undefined;
  }

  return (
    <>
      <Container background={colorHeader}>
        <SubPagesNav inactive_background={colorHeader}>
          {pageInfo.subPages.map(subPage => {
            var forbidden = [];
            if(subPage.path === 'suitability' && !checkSuitabilityUserAuth()) {
              return '';
            }

            // Retira item Patrimonio do submenu
            if(fullPath === "/finances/budget") {
              forbidden = ["patrimony", "investments/suitability"];
            }

            // Retira item Orçamento do submenu
            if(fullPath === "/finances/patrimony") {
              forbidden = ["budget"];
            }

            // Retira item Progresso e Extrato do submenu
            if(fullPath === "/investments/panelInvestments") {
              forbidden = ["progress", "extract", "r1"];
            }

            // Retira item Progresso e Extrato do submenu
            if(fullPath === "/investments/suitability") {
              forbidden = ["progress", "extract", "r1"];
            }

            if(fullPath === "/investments/r1") {
              forbidden = ["panelInvestments", "progress", "extract"];
            }

            if (!subPage.hidden && checkIsNotForbiden(subPage.path, forbidden)) {
              let pathNavigation = ''
              if(subPage.absolutePath) {
                pathNavigation = `../../${subPage.path}`;
              } else {
                pathNavigation = `/${pageInfo.path}/${subPage.path}`;
              }
              return (
                <Link
                  className={
                    choosenSubPath === subPage.path ? 'active' : subPage.path
                  }
                  style={disableLinkInvestments() ? {pointerEvents: 'none' } : null}
                  key={subPage.title}
                  to={pathNavigation}
                >
                  {subPage.title}
                </Link>
              );
            }
            return '';
          })}
        </SubPagesNav>
      </Container>
    </>
  );
}
