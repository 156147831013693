import styled from 'styled-components';

export const Container = styled.div`

  display: ${props => props.showModal === false && 'none'};
  gap:10px;
  input.desabled{
    background: black;
  }
`;
