import React from 'react';

import UnderConstruction from 'components/UnderConstruction';

// import LifeCompass from './LifeCompass';

export default function SelfKnowledge() {
  // const [, , choosenSubPath] = window.location.pathname.split('/');

  // if (choosenSubPath === 'lifeCompass') {
  //   return <LifeCompass />;
  // }

  return <UnderConstruction />;
}
