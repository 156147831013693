import styled from 'styled-components';

export const Form = styled.form`
  width :100%;
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;
  padding: 0.5%;
 
  div.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    & > button + button {
      margin-left: 5%;
    }
  }
  & > div + div {
    margin-top: 2%;
  }
  div.radio-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5%;
    margin-bottom: 1%;
    color: ${props => props.theme.grafit.hover};
    & > div {
      display: flex;
      align-items: center;
      margin-top: 1%;
      input {
        margin-left: 10%;
      }
    }
  }
`;

export const DivGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;