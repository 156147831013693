export function calcSpendingsBar(estimated, accomplished, installments) {
  const percentAccomplished = (
    (accomplished * 100) /
    (estimated || 100)
  ).toFixed(0);
  const percentInstallment = (
    (installments * 100) /
    (estimated || 100)
  ).toFixed(0);
  const percentEstimated = 100;
  const percentSurplus = 0;
  const rest = accomplished + installments > estimated ? 100 : 0;

  //se os gastos forem maiores que o estimado, barra vermelha.

  return {
    percentAccomplished,
    percentInstallment,
    percentEstimated,
    percentSurplus,
    rest,
  };
}
