import React, { useState, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { format } from 'date-fns';
import Organize from './Organize';
import Goal from './Goal';
import WipeContainer from './WipeContainer';
import WipeModal from './Modals/WipeModal';
import ResultModal from './Modals/ResultModal';

import Loading from 'components/Loading';

import api from 'services/api';

import { Container } from './styles';

export default function Wipe() {
  const [page, setPage] = useState(1);
  const [perPage] = useState(5);
  const [goal, setGoal] = useState(null);
  const [wiped, setWiped] = useState([]);
  const [items, setItems] = useState([]);
  const [organize, setOrganize] = useState(true);
  const [wipeContainer, setWipeContainer] = useState(false);
  const [wipeModal, setWipeModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [resultModal, setResultModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const user = useSelector(state => state.user.profile);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      const { data } = await api.post(`users/${user.id}/calc_parameters`, {
        date: format(new Date(), 'yyyy-MM-dd'),
        params: ['liquid'],
      });

      const { liquid } = data;

      if (liquid > 0) {
        setGoal(null);
      } else {
        setGoal(data.liquid * -1);
      }

      const { data: items } = await api.get(`users/${user.id}/items`, {
        params: {
          type: 'spendings',
          all: true,
          category: true,
        },
      });

      const spendingsTotal = items.reduce((total, current) => {
        if (
          current.classification === 'Eventual Flexível' ||
          current.classification === 'Eventual Comprometido'
        ) {
          return total + current.value / 12;
        }
        return total + current.value;
      }, 0);

      const average = spendingsTotal / items.length;

      setItems(
        items.map(item => ({
          ...item,
          quadrant: 1,
          priority: false,
          replaceable: false,
          newValue: 0,
          action: '',
          date: '',
          belowAverage:
            item.classification === 'Eventual Flexível' ||
            item.classification === 'Eventual Comprometido'
              ? item.value / 12 < average
              : item.value < average,
        }))
      );

      return setLoading(false);
    }

    fetchData();
  }, [user.id]);

  const pageItems = useMemo(() => {
    return items.slice((page - 1) * perPage, perPage * page);
  }, [items, page, perPage]);

  const firstPageItem = useMemo(() => {
    return (page - 1) * perPage + 1;
  }, [page, perPage]);

  const lastPageItem = useMemo(() => {
    return (page - 1) * perPage + 1 + pageItems.length - 1;
  }, [page, pageItems.length, perPage]);

  const totalItems = useMemo(() => {
    return items.length;
  }, [items.length]);

  function handlePageNext() {
    if (page === 1) return;
    setPage(page - 1);
  }

  function handlePageBefore() {
    const maxPages = Math.ceil(items.length / perPage);

    if (page === maxPages) return;
    setPage(page + 1);
  }

  return (
    <>
      {wipeModal && (
        <WipeModal
          item={selectedItem}
          setItems={setItems}
          setSelectedItem={setSelectedItem}
          setWipeModal={setWipeModal}
          setWiped={setWiped}
        />
      )}
      {resultModal && (
        <ResultModal
          setLoading={setLoading}
          loading={loading}
          setResultModal={setResultModal}
          success={success}
          wiped={wiped.reduce((total, current) => total + current.wiped, 0)}
          setPage={setPage}
          setWipeContainer={setWipeContainer}
          setOrganize={setOrganize}
          setWiped={setWiped}
          setSuccess={setSuccess}
          estimated={items.reduce((total, current) => {
            if (
              current.classification === 'Eventual Flexível' ||
              current.classification === 'Eventual Comprometido'
            ) {
              return total + current.value / 12;
            }
            return total + current.value;
          }, 0)}
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <h2 className="title">Meta</h2>
          <Goal goal={goal} wiped={wiped} />
          {organize && (
            <Organize
              firstPageItem={firstPageItem}
              lastPageItem={lastPageItem}
              totalItems={totalItems}
              handlePageNext={handlePageNext}
              handlePageBefore={handlePageBefore}
              pageItems={pageItems}
              setItems={setItems}
              items={items}
              setPage={setPage}
              setWipeContainer={setWipeContainer}
              setOrganize={setOrganize}
            />
          )}
          {wipeContainer && (
            <WipeContainer
              firstPageItem={firstPageItem}
              lastPageItem={lastPageItem}
              totalItems={totalItems}
              handlePageNext={handlePageNext}
              handlePageBefore={handlePageBefore}
              pageItems={pageItems}
              setWipeModal={setWipeModal}
              setSelectedItem={setSelectedItem}
              wiped={wiped}
              setResultModal={setResultModal}
              setLoading={setLoading}
              setSuccess={setSuccess}
              setPage={setPage}
              setWipeContainer={setWipeContainer}
              setOrganize={setOrganize}
              setWiped={setWiped}
              items={items}
            />
          )}
        </Container>
      )}
    </>
  );
}
