import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  height: 100%;
  hr {
    border-left: 1.5px solid ${props => props.theme.grafit.default};
    color: transparent;
    height: 99%;
  }
`;

export const Header = styled.span`
  position: absolute;
  color: ${props => props.theme.grafit.hover};
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;

  transform: translateY(-55px) translateX(-15px);
`;

export const ContentChart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;

  div.container {
    display: flex;
    height: 100%;
  }

  div.canvas {
    display: flex;
    flex-direction: column;
    margin-top: 13%;
    height: 81% !important;
    width: 50% !important;
    canvas{
      transform: translateX(-10%);
    }
  }

  div.values {
    display: flex;
    margin-top:13%;
    flex-direction: column;
    span {
      font-size: 12px;
      text-align: left;
      color: ${props => props.theme.grafit.hover};

      &.receipts {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.33;
        color: ${props => props.theme.receipts.default};
      }
      &.investments {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.35;
        color: ${props => props.theme.investments.default};
      }
      &.spendings {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.35;
        color: ${props => props.theme.spendings.default};
      }

      &.debts {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.35;
        color: ${props => props.theme.debts.default};
      }
    }
  }

  div.detail-button {
    display: flex;
    justify-content: flex-end;

    a {
      background: none;
      color: ${props => props.theme.info.default};
      font-weight: bold;
      text-decoration: none;
    }
  }

  @media (max-width: 1550px) {
    div.container {
      div.canvas {

        width: 100px !important;
        canvas{
          width: 140px !important;
          height: 190px !important;
        }
      }

      div.values {
        & > span {
          font-size: 10.5px !important;
        }
        span.resize {
          font-size: 17px !important;
          line-height: 1.33;
        }
        span + span + span{
          &.resize{
            font-size:16px !important;
          }
        }
      }
    }
  }

  @media (max-width:1700px){
    div.container {

      div.canvas {
        height: 80% !important;
        width: 150px !important;
      }

      div.values {
        & > span {
          font-size: 12px;
        }
        span.resize {
          font-size: 22px;
        }
        span + span + span{
          &.resize{
            font-size:18px;
          }
        }
      }
    }
  }

  @media (max-width:1650px){
    div.container {
      div.values{
        margin-top: 14% !important;
      }
      div.canvas {
        margin-top:14% !important;
        height: 80% !important;
        width: 145px !important;
      }

      div.values {
        & > span {
          font-size: 11px;
        }
        span.resize {
          font-size: 21px;
        }
        span + span + span{
          &.resize{
            font-size:17px;
          }
        }
      }
    }
  }

  @media (max-width: 1450px){
    div.container{
      div.canvas {
        margin-top:14% !important;

        width: 140px !important;
        canvas
        {
          width: 135px !important;
          height: 170px !important;
        }
      }
    }
  }

`;
