import styled, { keyframes } from 'styled-components';
const slide = keyframes`
  100% { transform: translateX(0%); }
  0% { transform: translateX(-100%); }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-bottom: 15px;
  span {
    color: ${props => props.theme.darker.disabled};
    font-size: 28px;
    font-weight: bold;
    line-height: 1.36;
    letter-spacing: 0.2px;
    &.accompliched {
      margin: auto;
      color: ${props => props.theme.darker.disabled};
    }
  }

  small {
    color: ${props => props.theme.grafit.hover};
  }
`;

export const Table = styled.table`
  width: ${props => props.width}%;
  height: 10px;
  text-align: left;
  border: none;
  table-layout: fixed;
  border-spacing: 0 10px;
  border-collapse: separate;

  &:first-child {
    tbody td {
      &:first-child {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:last-child {
        background: ${props => props.theme.darker.disabled};
        color: ${props => props.theme.darker.default};
      }
    }
    /* transform: translateY(12px); */
  }

  &:nth-child(2) {
    margin: 0 10px;
  }
  &:last-child {
    thead tr th:last-child {
      color: ${props => props.theme.grafit.hover};
    }
    tbody td {
      text-align: center;
      &:last-child {
        background: ${props => props.theme.grafit.default};
      }
    }
    /* transform: translateY(12px); */
  }
`;

export const THead = styled.thead`
  position: relative;

  tr th {
    height: 36px;
    font-weight: 700;
    text-align: center;
    color: ${props => props.theme.grafit.default};
  }

  tr.date-picker {
    button {
      position: absolute;
      background: transparent;
      top: 2px;
      svg {
        color: ${props => props.theme.grafit.hover};
      }

      &.previous {
        left: -30px;
      }
      &.next {
        right: -30px;
      }
    }
    th {
      position: relative;

      border-top: 1px solid ${props => props.theme.grafit.hover};
      border-bottom: 1px solid ${props => props.theme.grafit.hover};
      background: ${props => props.theme.grafit.disabled};
      color: ${props => props.theme.grafit.hover};
      padding: 5px;

      height: 100%;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-left: 1px solid ${props => props.theme.grafit.hover};
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right: 1px solid ${props => props.theme.grafit.hover};
      }
    }
  }
`;

export const TBody = styled.tbody`
  tr.total {
    td {
      color: ${props => props.theme.white.disabled} !important;
    }
    & > td {
      background: ${props => props.theme.darker.disabled};
      color: ${props => props.theme.white.disabled};
      text-align: center;
      padding-left: 20px;
    }
  }
  td {
    background: ${props => props.theme.grafit.disabled};
    color: ${props => props.theme.grafit.hover};
    border-top: 1px solid ${props => props.theme.grafit.default};
    border-bottom: 1px solid ${props => props.theme.grafit.default};

    text-align: center;

    padding: 12px 8px;
    font-weight: 700;

    &.td-stimated {
      background: ${props => props.theme.spendings.hover};
      color: ${props => props.theme.white.disabled} !important;
    }

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-left: 1px solid ${props => props.theme.grafit.default};
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-right: 1px solid ${props => props.theme.grafit.default};
    }
  }
`;

export const TdHover = styled.td`
  display: flex;
  align-items: center;

  &:hover {
    span {
      display: none;
    }
    button {
      display: flex !important;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      animation: ${slide} 0.5s backwards;
      svg {
        fill: ${props => props.theme.info.default};
      }
    }
  }
  button {
    display: none;
  }
`;

export const DivDataPicker = styled.div`
  /* background: pink;
  width: 822px; */
`;
