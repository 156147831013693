import React, { useState, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Container } from './styles';

import ParamsProvider from './Provider';

import Loading from 'components/Loading';

import api from 'services/api';

import Estimated from './Estimated';
import Accomplished from './Accomplished';
import newApi from 'services/newApi';
import { startOfMonth, endOfMonth } from 'date-fns'

export default function PotDetail() {
  const [date, setDate] = useState(new Date());
  const { token } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);

  const [receipts, setReceipts] = useState({
    estimated: 0,
    entries: 0,
    'Mensal Comprometido': 0,
    'Eventual Comprometido': 0,
    'Mensal Flexível': 0,
    'Eventual Flexível': 0,
  });
  const [spendings, setSpendings] = useState({
    estimated: 0,
    entries: 0,
    'Mensal Comprometido': 0,
    'Eventual Comprometido': 0,
    'Mensal Flexível': 0,
    'Eventual Flexível': 0,
  });
  const [spendingsAccomplished, setSpendingsAccomplished] = useState({
    'Mensal Comprometido': 0,
    'Eventual Comprometido': 0,
    'Mensal Flexível': 0,
    'Eventual Flexível': 0,
  });
  const [investments, setInvestments] = useState({
    estimated: 0,
    entries: 0,
  });
  const [installments, setInstallments] = useState({
    entries: 0,
  });
  const [passivePatrimonies, setPassivePatrimonies] = useState({
    entries: 0,
    estimated: 0,
  });

  const { id } = useSelector(state => state.user.profile);

  const maxEntries = useMemo(() => {
    const inside =
      installments?.entries +
      passivePatrimonies?.entries +
      spendingsAccomplished['Mensal Flexível'] +
      spendingsAccomplished['Mensal Comprometido'] +
      spendingsAccomplished['Eventual Flexível'];

    const outside = receipts.entries;

    if (inside > outside) return inside;

    return outside;
  }, [
    installments,
    passivePatrimonies,
    receipts.entries,
    spendingsAccomplished,
  ]);

  const maxEstimated = useMemo(() => {
    const inside =
      installments.entries +
      passivePatrimonies.entries +
      spendings['Mensal Flexível'] +
      spendings['Mensal Comprometido'] +
      spendings['Eventual Flexível'] +
      investments.estimated +
      spendings['Eventual Comprometido'];

    const outside = receipts.estimated;

    if (inside > outside) return inside;

    return outside;
  }, [
    installments.entries,
    investments.estimated,
    passivePatrimonies.entries,
    receipts.estimated,
    spendings,
  ]);

  const max = useMemo(
    () => (maxEstimated > maxEntries ? maxEstimated : maxEntries),
    [maxEntries, maxEstimated]
  );

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data: response } = await newApi.post(`Auth/AutenticarCompativel`, {
        idUser: id,
        token
      });

      const { data: entriesInvestments } = await newApi.get(`Investimento/total/${id}`, {
        params: {
          Inicio: startOfMonth(date),
          Fim: endOfMonth(date)
        }, headers: {
          'Authorization': `Bearer ${response.token}`
        }
      });
      const { data } = await api.post(`/users/${id}/calc_parameters`, {
        date,
        params: [
          'receipts',
          'spendings',
          'investments',
          'installments',
          'passivePatrimonies',
          'spendingsAccomplished',
        ],
      });

      const {
        receipts,
        spendings,
        investments,
        installments,
        passivePatrimonies,
        spendingsAccomplished,
      } = data;

      const investmentsObject = {
        estimated: investments.estimated,
        entries: entriesInvestments,
      }

      setReceipts(receipts);
      setSpendings(spendings);
      setSpendingsAccomplished(spendingsAccomplished);
      setInvestments(investmentsObject);
      setInstallments(installments);
      setPassivePatrimonies(passivePatrimonies);
      setLoading(false);
    }

    fetchData();
  }, [date, id, token]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <ParamsProvider
            value={{
              receipts,
              investments,
              spendings,
              spendingsAccomplished,
              installments,
              passivePatrimonies,
              date,
              setDate,
            }}
          >
            <Estimated max={max} />
            <Accomplished max={max} />
          </ParamsProvider>
        </Container>
      )}
    </>
  );
}
