import styled from 'styled-components';

export const Container = styled.div`
  min-width: ${props => props.width} !important;
  margin-top: ${props => props.marginTop}px;
  display: flex;
  flex-direction: column;

  div.info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 25px;

    div.left {
      display: flex;
      width: auto;
      height: auto;
      margin-top: 3%;

      color: ${props => props.theme[props.leftColor].default};
      p {
        font-size: ${props => props.fontSize}px;
      }
    }

    div.right {
      margin-top: 3%;
      color: ${props => props.theme[props.rightColor].default};
      p {
        font-size: ${props => props.fontSize}px;
      }
    }
  }

  div.progress-bar {
    background: ${props => props.theme[props.bg].disabled};
    width: 100%;
    height: ${props => props.height || '15'}px;
    border-radius: 10px;
    transition: 200ms ease;

    div.filler {
      background: ${props => props.theme[props.color].default};
      width: ${props => props.fill}%;
      height: 100%;
      border-radius: 10px;
      transition: 200ms ease;
    }
  }
  &.bar-management{
      div.progress-bar{
        background: ${props => props.theme[props.bg].default} !important;
      }
      div.filler{
        background: ${props => props.theme[props.color].disabled} !important;
      }
    }
`;
