import styled from 'styled-components';
export const Form = styled.form`
  width: 100%;
  height: auto;
  overflow: auto;
  padding-right: 20px;

  div.custom__container{
    margin-top: ${props => '0px'};
  }
  .frVONL div.custom__container {
    margin-top: 0px;
  }

  & > div + div {
    margin-top: 10px;
  }

  div.buttons {
    transform:translateY(-3px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    button{
      margin-left:7%;
    }
    & > button + button {
      margin-left: 5%;
    }
  }

  div.radio-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
    width: 50%;
    color: ${props => props.theme.grafit.hover};

    & > div {
      display: flex;
      align-items: center;
      & > input {
        margin-left: 5px;
        margin-bottom: 8px;
      }
    }
  }
`;