import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Loading from 'components/Loading';

import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Container, Header, Information, Options, Title } from './styles';

import Card from 'components/Cards';

import { Pie } from 'react-chartjs-2';

Chart.plugins.register(ChartDataLabels);

export default function CardPIe({ title, values }) {

  const [ data, setData ] = useState({});
  const [ options, setOptions ] = useState(null);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {

    const titles = values.map(item => item.titulo);
    const valores = values.map(item => item.valor);

    const _data = {

      labels: titles,
      datasets: [{
        data: valores,
        backgroundColor: [
          '#00c791',
          '#1de9b6',
          '#00ffbf',
          '#236499',
          '#0f7acc',
          '#1399ff',
          '#89ccff',
          '#bae0ff',
          '#e7f5ff',
        ],
        hoverOffset: 4
      }]
    };

    setData(_data);
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(data !== null) {
      const _options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'right',
          align: 'start',
          labels: {
            usePointStyle: true,
            boxWidth: 20,
          }
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function(tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var meta = dataset._meta[Object.keys(dataset._meta)[0]];
              var total = meta.total;
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = parseFloat((currentValue/total*100).toFixed(1));
              return currentValue + ' (' + percentage + '%)';
            },
            title: function(tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            }
          }
        },
        plugins: {
          datalabels: {
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map(data => {
                    return sum += data;
                });
                let percentage = (value*100 / sum).toFixed(2)+"%";
                return percentage;
              },
              color: '#fff',
              backgroundColor: 'rgba(0,0,0,0.6)',
              borderRadius: 25,
              padding: 8,
              font: {
                weight: 'bold'
              },
              align: 'end',
              offset: 30
          }
          /*
          datalabels: {
            backgroundColor: function(context) {
              return context.dataset.backgroundColor;
            },
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 2,
            color: 'white',
            display: function(context) {
              var dataset = context.dataset;
              var count = dataset.data.length;
              var value = dataset.data[context.dataIndex];
              return value > count * 1.5;
            },
            font: {
              weight: 'bold'
            },
            padding: 6,
            formatter: Math.round
          }*/
        }
      }

      setOptions(_options);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [data])

  return (
    <Card className="card-grapich" width="100%" height="572px">
      <Container>
        <Header>
          <Title>{title}</Title>
        </Header>

        <Information>
          <>
            { loading && options !== null && data !== null ? (
              <Loading />
              ) : (
                <Pie
                  data={data}
                  options={options}
                />
              )
            }
          </>

        </Information>
        <Options>
        </Options>
      </Container>
    </Card>
  );
}

CardPIe.propTypes = {
  title: PropTypes.string,
  values: PropTypes.array
};

CardPIe.defaultProps = {
  title: "",
  values: []
};
