import React, { useState } from 'react';
import { produce } from 'immer';
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import capitalize from 'utils/capitalizeWords';
import api from 'services/api';
import { DivButtons, DivNewCategory, ContentModal } from '../../styles';
import ModalExclude from 'components/Modal';
import TotalTable from 'components/Tables/TotalTable';
import DropdownButton from 'components/Button/Dropdown';
import Button from 'components/Button';
import Can from 'components/Can';

export default function Receipts({
  categories,
  items,
  setCategories,
  setItems,
  loading,
  setLoading,
  setSelectedCategory,
  setEditCategoryModal,
  setCreateItemModal,
  setSelectedItem,
  setEditItemModal,
  setCreateCategoryModal,
}) {
  const [showModal, setShowModal] = useState(false);

  // Armazena se o usuário quer excluir item ou categoria
  const [toggleDelete, setToggleDelete] = useState('');

  // Dado que foi selecionado para ser excluido
  const [selectedData, setSelectedData] = useState({});

  function handleDelete() {
    if (toggleDelete === 'category') {
      return deleteCategory(selectedData);
    }
    return deleteItem(selectedData);
  }

  async function deleteCategory(category) {
    try {
      setLoading(true);
      await api.delete(`categories/${category.id}`);

      const newCategories = produce(categories, async draft => {
        await delete draft[category.id];
      });

      const newItems = produce(items, draft => {
        category.items.forEach(async item => {
          await delete draft[item.id];
        });
      });

      setItems(newItems);
      setCategories(newCategories);
      setLoading(false);
      return setShowModal(false);
    } catch (err) {
      setLoading(false);
      toast.error('Ops, tivemos um erro ao excluir a categoria');
      return setShowModal(false);
    }
  }

  async function deleteItem(item) {
    try {
      setLoading(true);
      await api.delete(`items/${item.id}`);

      const newCategories = produce(categories, draft => {
        draft[item.category_id].items = draft[item.category_id].items.filter(
          id => id !== item.id
        );
      });

      const newItems = produce(items, draft => {
        delete draft[item.id];
      });

      setCategories(newCategories);
      setItems(newItems);

      toast.success('Item excluido com sucesso');

      setLoading(false);
      return setShowModal(false);
    } catch (err) {
      toast.error('Ops, tivemos um erro!');
      setLoading(false);
      return setShowModal(false);
    }
  }

  function tableHead() {
    return [
      <div style={{ textAlign: 'start' }}>Nome</div>,
      'Classificação',
      'Mês / Dia do mês',
      'Valor',
      'Valor Mensal',
      '',
    ];
  }

  function tableBody(category) {
    return category?.items?.map(item => [
      <div
        style={{
          textAlign: 'start',
          marginLeft: '20px',
        }}
      >
        {items[item]?.name}
      </div>,
      items[item]?.classification,
      items[item]?.classification === 'Eventual Comprometido'
        ? capitalize(
            format(new Date(2020, items[item]?.when - 1), 'MMMM', {
              locale: pt,
            })
          )
        : items[item]?.when,
      {
        value: items[item]?.value.toLocaleString('pt-br', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        currency: 'R$',
      },

      items[item]?.classification === 'Eventual Comprometido' ||
      items[item]?.classification === 'Eventual Flexível'
        ? {
            value: (items[item]?.value / 12).toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
            currency: 'R$',
          }
        : {
            value: items[item]?.value.toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
            currency: 'R$',
          },
      <Can
        canRoles={['planner', 'adm', 'cs', 'clientPfg', 'assistant-planner']}
      >
        <DropdownButton className="options">
          <button
            onMouseDown={() => {
              setSelectedItem(items[item]);
              setEditItemModal(true);
            }}
          >
            Editar
          </button>
          <button
            onMouseDown={() => {
              setShowModal(!showModal);
              setToggleDelete('item');

              setSelectedData(items[item]);
            }}
          >
            Excluir
          </button>
        </DropdownButton>
      </Can>,
    ]);
  }

  function handleCalcPercentage(category) {
    const totalCategory = category.items.reduce((total, currentValue) => {
      if (!items[currentValue]) return total;
      if (
        items[currentValue].classification === 'Eventual Flexível' ||
        items[currentValue].classification === 'Eventual Comprometido'
      ) {
        return total + items[currentValue].value / 12;
      }
      return total + items[currentValue].value;
    }, 0);

    const totalReceipts = categories
      ? Object.values(categories)
          .filter(c => c.type === 'receipts')
          .reduce((total, current) => [...total, ...current.items], [])
          .reduce((total, currentValue) => {
            if (!items[currentValue]) return total;
            if (
              items[currentValue].classification === 'Eventual Flexível' ||
              items[currentValue].classification === 'Eventual Comprometido'
            ) {
              return total + items[currentValue].value / 12;
            }
            return total + items[currentValue].value;
          }, 0)
      : 0;

    let result = (totalCategory / totalReceipts) * 100;

    if (!isFinite(result)) {
      result = 0;
    }

    return Math.round(result);
  }

  return (
    <>
      <Can
        canRoles={['planner', 'adm', 'cs', 'clientPfg', 'assistant-planner']}
      />
      <DivNewCategory>
        <Button
          className="newCategoryButton"
          color="receipts"
          onClick={() => setCreateCategoryModal(true)}
        >
          Nova Categoria
        </Button>
      </DivNewCategory>
      {categories &&
        Object.values(categories)
          .filter(category => category.type === 'receipts')
          .map(category => (
            <TotalTable
              key={category.id}
              withPercentage={handleCalcPercentage(category)}
              total={category.items.reduce((total, currentValue) => {
                if (!items[currentValue]) return total;
                if (
                  items[currentValue].classification === 'Eventual Flexível' ||
                  items[currentValue].classification === 'Eventual Comprometido'
                ) {
                  return total + items[currentValue].value / 12;
                }
                return total + items[currentValue].value;
              }, 0)}
              color="darker"
              title={category.name}
              subtitle={category.classification}
              head={tableHead()}
              body={tableBody(category)}
              buttonIcons={[
                <Can
                  canRoles={[
                    'planner',
                    'adm',
                    'cs',
                    'clientPfg',
                    'assistant-planner',
                  ]}
                  key={Math.random()}
                >
                  <button
                    className="icon"
                    key={Math.random()}
                    onClick={() => {
                      setShowModal(!showModal);
                      setToggleDelete('category');
                      setSelectedData(category);
                    }}
                  >
                    <AiOutlineDelete data-tip="Excluir Item" />
                  </button>
                  <button
                    className="icon"
                    onClick={() => {
                      setSelectedCategory(category);
                      setEditCategoryModal(true);
                    }}
                    key={Math.random()}
                  >
                    <AiOutlineEdit data-tip="Editar Item" />
                  </button>
                  <button
                    className="icon"
                    onClick={() => {
                      setSelectedCategory(category);
                      setCreateItemModal(true);
                    }}
                    key={Math.random()}
                  >
                    <AiOutlinePlus data-tip="Novo Item" />
                  </button>
                </Can>,
              ]}
            />
          ))}
      {showModal && (
        <ModalExclude
          color="danger"
          setShowModal={setShowModal}
          title="Confirmar Exclusão? "
          icon={<AiOutlineDelete />}
        >
          <ContentModal color="danger">
            <span>Pode haver lançamentos nesse(s) item(s).</span>
            <DivButtons>
              <Button color="danger" onClick={() => handleDelete()}>
                {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
              </Button>
            </DivButtons>
          </ContentModal>
        </ModalExclude>
      )}
    </>
  );
}

Receipts.propTypes = {
  categories: PropTypes.shape().isRequired,
  items: PropTypes.shape().isRequired,
  setCategories: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  setEditCategoryModal: PropTypes.func.isRequired,
  setCreateItemModal: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  setEditItemModal: PropTypes.func.isRequired,
  setCreateCategoryModal: PropTypes.func.isRequired,
};
