import React from 'react';
import PropTypes from 'prop-types';

import { AiOutlineClose } from 'react-icons/ai';
import { ModalStyled, DivBackground } from './styles';

export default function Modal({
  children,
  color,
  icon,
  title,
  variant,
  height,
  width,
  setShowModal,
}) {
  return (
    <>
      <DivBackground>
        <ModalStyled color={color} variant={variant} height={height} width={width}>
          <div className="head">
            <div className="titleIcon">
            {icon && <div className="icon">{icon}</div>}
            <span>{title}</span>
            </div>
            <div className="close">
              <button onClick={() => setShowModal(false)}>
                <AiOutlineClose />
              </button>
            </div>
          </div>
          <div className="body">{children}</div>
        </ModalStyled>
      </DivBackground>
    </>
  );
}

Modal.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.bool]),
  title: PropTypes.string,
  setShowModal: PropTypes.func,
};

Modal.defaultProps = {
  setShowModal: () => {},
  variant: false,
  children: () => {},
  color: 'debts',
  icon: false,
  title: 'Nova Dívida',
};
