import styled, { keyframes } from 'styled-components';

const cardHeight = 100;
const iconDivWidth = 90;
const iconDivHeigth = 100;

const shake = keyframes`
  0% { transform:translate(0,0) }
  1.78571% { transform:translate(5px,0) }
  3.57143% { transform:translate(0,0) }
  5.35714% { transform:translate(5px,0) }
  7.14286% { transform:translate(0,0) }
  8.92857% { transform:translate(5px,0) }
  10.71429% { transform:translate(0,0) }
  100% { transform:translate(0,0) }
`;

export const Container = styled.div`
  display: flex;
 
  align-items: center;
  flex: 1;
  height: ${cardHeight}%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  cursor: pointer;
  transition: 200ms ease;
  &.shake {
    animation: ${shake} 2.72s ease infinite;
   
  }

  &:hover {
    /* background: ${props => props.theme[props.color].disabled}; */
    transform: scale(1.05);

    /* span {
      color: ${props => props.theme[props.color].default};
    } */
  }

  &.active {
    /* background: ${props => props.theme[props.color].disabled}; */
    transform: scale(1.05);

    /* span {
      color: ${props => props.theme[props.color].default};
    } */
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${iconDivWidth}px;
  height: ${iconDivHeigth}%;
  background: ${props => props.theme[props.color].default};
  border-radius: 10px 0 0 10px;

  svg {
    fill: ${props => props.theme.white.default};
  }
`;

export const Title = styled.span`
  font-size: 24px;
  line-height: 1.33;
  text-align: left;
  color: ${props => props.theme[props.color].default};
`;

export const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 15px 15px;
`;

export const MainInfo = styled.div`
  display: flex;
  flex-direction: column;

  div.subInfo{
    /* background-color: pink; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    strong{
      font-size: 15px;
      margin-left: 2px;
    }
  }

  span {
    margin-top: 5px;
    color: ${props => props.theme.grafit.hover};
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.4px;
    text-align: left;
  }

  strong {
    font-size: 30px;
    font-weight: bold;
    color: ${props => props.theme[props.color].default};
  }
`;