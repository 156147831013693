import React from 'react';
import PropTypes from 'prop-types';

import { FiInfo } from 'react-icons/fi';

import Select from 'react-select/async';

import { Container } from './styles';

export default function Dropdown({
  handleClickSmall,
  small,
  options,
  height,
  width,
  label,
  ...rest
}) {
  return (
    <Container width={width}>
      <div className="legends">
        {label && <label className={`${rest.className}`}>{label}</label>}
        {small && (
          <button type="button" onClick={handleClickSmall}>
            <FiInfo />
            {small}
          </button>
        )}
      </div>
      <Select
        loadOptions={options}
        height={height}
        className="custom__container"
        classNamePrefix="custom"
        {...rest}
      />
    </Container>
  );
}

Dropdown.propTypes = {
  small: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleClickSmall: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  width: PropTypes.string,
  height: PropTypes.number,
  label: PropTypes.string,
};

Dropdown.defaultProps = {
  handleClickSmall: () => {},
  small: false,
  width: '100%',
  height: 35,
  label: undefined,
};
