import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import DashboardLayout from 'pages/_layouts/DashboardNew';
import { loadInitialStateRequest } from 'store/modules/suitability/actions';
import { signOut } from 'store/modules/auth/actions';
import { store } from 'store';

const RouteWrapper = ({
  component: Component,
  isPrivate = false,
  dashboard = false,
  path,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { profile: user } = useSelector(state => state.user);
  const { signed } = useSelector(state => state.auth);
  const { parcial } = useSelector(state => state.suitability);

  const validateSession = () => {
    const { auth } = store.getState();
    const requiredProps = ['type', 'email'];

    if (!auth.signed || path !== '/') {
      return;
    }

    requiredProps.forEach(propName => {
      const isValid = auth.user[propName] === user[propName];

      if (!isValid || (!isValid && path === '/home')) {
        dispatch(signOut());
      }
    });
  };
  validateSession();

  useEffect(() => {
    if (parcial === null && signed && user.type === 'client') {
      dispatch(loadInitialStateRequest(user.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcial, signed, user]);

  if (path === '/external_acess') {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/home" />;
  }

  return (
    <Route
      {...rest}
      render={props =>
        dashboard ? (
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  isPrivate: PropTypes.bool,
  dashboard: PropTypes.bool,
  path: PropTypes.string,
};

export default RouteWrapper;
