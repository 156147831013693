import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { MdArrowForwardIos } from 'react-icons/md';

import ReactTooltip from 'react-tooltip';

import { Link } from 'react-router-dom';

import {
  Container,
  Menu,
  Title,
  Cards,
  Grapichs,
  Group,
  Table,
  TableTitle,
  Content,
  Nav,
} from './styles';

import newApi from 'services/newApi';

import CardMenu from './Cards/CardMenu';
import CardPie from './Cards/CardPie';
import Button from 'components/Button';
import CardBar from './Cards/CardBar';
import ModalProfileStrategy from './Modals/ProfileStrategy';

import Loading from 'components/Loading';

export default function PanelInvestments() {
  const user = useSelector(state => state.user.profile);

  const { respondido } = useSelector(state => state.suitability);

  const [loading, setLoading] = useState(true);
  const [modalProfileStrategy, setModalProfileStrategy] = useState(false);
  const [fullAlocation, setFullAlocation] = useState(null);

  const [valoresAtuais, setValoresAtuais] = useState([]);
  const [valoresFinais, setValoresFinais] = useState([]);

  const [showLiquidez, setShowLiquidez] = useState(false);
  const [liquidezAtual, setLiquidezAtual] = useState([]);
  const [necessidadeLiquidez, setNecessidadeLiquidez] = useState([]);

  const labelToggleButton = ['Ver Estratégia', 'Ver Liquidez'];

  const [dataChartLiquidez, setChartLiquidez] = useState(null);

  useEffect(() => {
    const arLiquidezAtual = [];
    const arNecessidadeLiquidez = [];
    if (liquidezAtual.length > 1 && necessidadeLiquidez.length > 1) {
      //porcentagem absolutas
      for (let i = 0; i < liquidezAtual.length; i++) {
        arLiquidezAtual.push(liquidezAtual[i].porcentagem_absoluta);
        arNecessidadeLiquidez.push(necessidadeLiquidez[i].porcentagem_absoluta);
      }
    } else {
      return;
    }

    const data = {
      datasets: [
        {
          label: 'Liquidez atual',
          data: arLiquidezAtual,
          borderColor: '#3ba7ff',
          backgroundColor: '#bae0ff',
          indexAxis: 'y',
        },
        {
          label: 'Necessidade de liquidez',
          data: arNecessidadeLiquidez,
          borderColor: '#0f7acc',
          backgroundColor: '#1399ff',
          indexAxis: 'y',
        },
      ],
    };

    setChartLiquidez(data);
  }, [liquidezAtual, necessidadeLiquidez]);

  useEffect(() => {
    if (fullAlocation?.estrategia !== undefined) {
      const dadosValoresAtuais = fullAlocation.estrategia.map(dado => {
        return {
          titulo: dado.fundo,
          valor: dado.porcentagem_participacao_atual,
        };
      });
      setValoresAtuais(dadosValoresAtuais);

      const dadosValoresFinais = fullAlocation.estrategia.map(dado => {
        return {
          titulo: dado.fundo,
          valor: dado.porcentagem_full_alocation,
        };
      });
      setValoresFinais(dadosValoresFinais);
    } else {
      setValoresAtuais([
        {
          titulo: 'Nenhum',
          valor: 100,
        },
      ]);

      setValoresFinais([
        {
          titulo: 'Nenhum',
          valor: 100,
        },
      ]);
    }
    //setValoresAtuais
  }, [fullAlocation]);

  useEffect(() => {
    async function loadInvestmentLiquidity() {
      try {
        const response = await newApi.get(
          `estrategia/${user.id}/suitability/liquidez`
        );
        if (response.status !== 200) {
          // toast.info('Nenhum retorno para Liquidez foi encontrado para este usuário.');
        } else {
          setLiquidezAtual(response.data.liquidez_atual);
          setNecessidadeLiquidez(response.data.liquidez_necessaria);
        }
      } catch (error) {
        toast.error('Houve um erro ao tentar buscar os dados.');
      }
    }

    loadInvestmentLiquidity();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function loadFullLocation() {
      setLoading(true);

      try {
        const response = await newApi.get(
          `estrategia/${user.id}/suitability/fullalocation`
        );
        if (response.status !== 200) {
          // toast.info('Nenhum retorno de Allocation foi encontrado para este usuário.');
          setLoading(false);
        } else {
          setFullAlocation(response.data);
          setLoading(false);
        }
      } catch (error) {
        toast.error('Houve um erro ao tentar buscar os dados.');
        setLoading(false);
      }
    }

    loadFullLocation();
    // eslint-disable-next-line
  }, []);

  const getTdClassName = valor => {
    if (valor < 0) {
      return 'danger';
    }

    return 'success';
  };

  const getPercentage = (valor, absoluto) => {
    if (absoluto) return `${Math.floor(Number(valor))} %`;
    return `${parseFloat(Number(valor).toFixed(2))} %`;
  };

  const getRealValue = valor => {
    return valor.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const renderTable = () => {
    if (fullAlocation === null) return;

    const contentTable = fullAlocation.estrategia.map(linha => {
      return (
        <tr key={`linha-${linha.fundo_id}`}>
          <td>{linha.fundo}</td>
          <td className="center">{getRealValue(linha.valor_atual)}</td>
          <td className="center">
            {getPercentage(linha.porcentagem_participacao_atual)}
          </td>
          <td className="center">
            {getPercentage(linha.porcentagem_elp_definida)}
          </td>
          <td className="center">
            {getPercentage(linha.porcentagem_full_alocation)}
          </td>
          <td className="center">{getRealValue(linha.valor_final)}</td>
          <td
            className={`center ${getTdClassName(
              linha.valor_proxima_movimentacao
            )}`}
          >
            {getRealValue(linha.valor_proxima_movimentacao)}
          </td>
        </tr>
      );
    });

    return contentTable;
  };

  const renderTableLiquidezAtual = () => {
    if (liquidezAtual.length < 1) return;

    const contentTable = liquidezAtual.map(linha => {
      return (
        <tr className="green" key={`linha-${linha.prazo}`}>
          <td>{linha.prazo}</td>
          <td className="center">{getRealValue(linha.valor_absoluto)}</td>
          <td className="center">
            {getPercentage(linha.porcentagem_absoluta)}
          </td>
          <td className="center">{getRealValue(linha.valor_acumulado)}</td>
          <td className="center">
            {getPercentage(linha.porcentagem_acumulada)}
          </td>
        </tr>
      );
    });

    return contentTable;
  };

  const renderTableNecessidadeLiquidez = () => {
    if (necessidadeLiquidez.length < 1) return;

    const contentTable = necessidadeLiquidez.map(linha => {
      return (
        <tr className="green" key={`linha-${linha.prazo}`}>
          <td>{linha.prazo}</td>
          <td className="center">{getRealValue(linha.valor_absoluto)}</td>
          <td className="center">
            {getPercentage(linha.porcentagem_absoluta)}
          </td>
          <td className="center">{getRealValue(linha.valor_acumulado)}</td>
          <td className="center">
            {getPercentage(linha.porcentagem_acumulada)}
          </td>
        </tr>
      );
    });

    return contentTable;
  };

  const switchLiquidez = () => {
    setShowLiquidez(!showLiquidez);
  };

  const showModalStrategy = () => {
    if (!respondido) return;
    setModalProfileStrategy(true);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <Menu>
            <Nav>
              <Button
                data-tip
                data-for="noUserData"
                color="primary"
                className={!respondido ? 'disabled' : ''}
                style={{
                  margin: '10px 0',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                }}
                onClick={showModalStrategy}
              >
                Estratégia
              </Button>
              {!respondido && (
                <ReactTooltip id="noUserData">
                  <span>
                    Cliente ainda não respondeu o suitability, não há dados para
                    exibir.
                  </span>
                </ReactTooltip>
              )}

              <Button
                color="spendings"
                className={`switcher ${showLiquidez ? 'on' : 'off'}`}
                onClick={switchLiquidez}
              >
                {showLiquidez ? labelToggleButton[0] : labelToggleButton[1]}
                <MdArrowForwardIos />
              </Button>
            </Nav>
            <Title>Painel de investimentos</Title>
            <Cards>
              <Link
                className="no-underline"
                to={{
                  pathname: '/finances/patrimony',
                  state: { slideTo: 'ativos-financeiros' },
                }}
              >
                <CardMenu
                  value={
                    fullAlocation?.valor_total_carteira
                      ? fullAlocation.valor_total_carteira
                      : 0
                  }
                  type="Patrimônio Financeiro Atual"
                />
              </Link>
              <CardMenu
                value={
                  fullAlocation?.desenquadramento
                    ? getPercentage(fullAlocation.desenquadramento)
                    : getPercentage(0)
                }
                type="Desenquadramento"
              />
            </Cards>
          </Menu>
          {!showLiquidez ? (
            <Content
              key="estrategia"
              className={`${showLiquidez ? 'on' : 'off'}`}
            >
              <Menu>
                <Table>
                  <thead>
                    <tr>
                      <th>Estratégias</th>
                      <th className="center">Valores Atuais</th>
                      <th className="center">Participação Atual</th>
                      <th className="center">Alocação Estratégica</th>
                      <th className="center">Full Alocation</th>
                      <th className="center">Valores Finais</th>
                      <th className="center">Próximas Movimentações</th>
                    </tr>
                  </thead>
                  <tbody>{renderTable()}</tbody>
                </Table>
              </Menu>
              <Grapichs>
                <Group className="card-chart-EP">
                  <CardPie title="Valores Atuais" values={valoresAtuais} />
                </Group>
                <Group className="card-chart-EP">
                  <CardPie title="Valores Finais" values={valoresFinais} />
                </Group>
              </Grapichs>
            </Content>
          ) : (
            <Content
              key="liquidez"
              className={`${showLiquidez ? 'on' : 'off'}`}
            >
              <Menu>
                <TableTitle>Liquidez Atual</TableTitle>
                <Table className="sub-table">
                  <thead>
                    <tr>
                      <th>Prazo</th>
                      <th className="center">Valor Absoluto</th>
                      <th className="center">Porcentagem Absoluta</th>
                      <th className="center">Valor Acumulado</th>
                      <th className="center">Porcentagem Acumulada</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableLiquidezAtual()}</tbody>
                </Table>
              </Menu>
              <Menu>
                <TableTitle>Necessidade de Liquidez (Objetivos)</TableTitle>
                <Table className="sub-table">
                  <thead>
                    <tr>
                      <th>Prazo</th>
                      <th className="center">Valor Absoluto</th>
                      <th className="center">Porcentagem Absoluta</th>
                      <th className="center">Valor Acumulado</th>
                      <th className="center">Porcentagem Acumulada</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableNecessidadeLiquidez()}</tbody>
                </Table>
              </Menu>
              <Menu>
                {dataChartLiquidez !== null && (
                  <CardBar data={dataChartLiquidez} />
                )}
              </Menu>
            </Content>
          )}
        </Container>
      )}
      {modalProfileStrategy && (
        <ModalProfileStrategy close={setModalProfileStrategy} />
      )}
    </>
  );
}
