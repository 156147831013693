import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  width: 100%;

  height: ${props => props.height};
  border-radius: 5px;
  background: ${props => props.theme.grafit.disabled};
`;

export const Filler = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${props => props.filler}%;
  height: 100%;
  border-radius: 1vh;
  background: ${props => props.theme[props.color].default};
  transition: 1s ease;
  &.darker {
    background: ${props => props.theme[props.color].hover};
  }
  &.custom{
    background: ${props => props.theme[props.color].custom};

  }

  span {
    height: 100%;
    width: 300px;
    justify-content: center;
    /* position: absolute; */
    font-size: 1.2vh;
    font-weight: 600;
    line-height: 1.38;
    letter-spacing: 0px;
    color: ${props => props.theme[props.color].custom};
    margin-left: 2rem;
    &.value {
      font-size: 1.7vh;
    }
  }
`;


