import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, WrapperChart } from './styles';

import Button from 'components/Button';
import GhostButton from 'components/Button/GhostButton';

import ActivePatrimony from './ActivePatrimony';
import PassivePatrimony from './PassivePatrimony';
import { useContext } from 'react';
import ContextHeaderColor from 'utils/contexts/ColorHeaderContext';

// import ChartBar from './Chart/ChartBar';
// import ChartBarPatrimony from './Chart/ChartBarPatrimony';

import Loading from 'components/Loading';

import api from 'services/api';

function Patrimony(props) {
  // Patrimônios ativos vindos da api
  const [activePatrimony, setActivePatrimony] = useState([]);

  // Patrimônios passivos vindos da api
  const [passivePatrimony, setPassivePatrimony] = useState([]);

  const [togglePatrimony, setTogglePatrimony] = useState('Active');

  const [loading, setLoading] = useState(true);

  const { id } = useSelector(state => state.user.profile);

  const { setColorHeader } = useContext(ContextHeaderColor);

  useEffect(() => {
    if(activePatrimony.length !== 0) {
      const pathTo = props?.location?.state?.slideTo;
      if(pathTo) props.location.state = null;
      if (pathTo === undefined) {
        window.scrollTo(0, 0);
      }
      else {
        setTimeout(() => {
          const element = document.getElementById(pathTo);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
          }
        }, 50);
      }
    }
    // eslint-disable-next-line
  }, [activePatrimony]);

  useEffect(() => {
    setLoading(true);
    async function fetchActive() {
      const { data: dataActive } = await api.get(
        `/users/${id}/active_patrimonies`
      );

      setActivePatrimony(dataActive);

      const { data: dataPassive } = await api.get(
        `/users/${id}/passive_patrimony `
      );
      setPassivePatrimony(dataPassive);
    }
    fetchActive();
    setLoading(false);
  }, [id]);

  return (
    <Container>
      <div className="select-patrimony">
        {togglePatrimony === 'Active' ? (
          <Button
            color="investments"
            className="button-active"
            onClick={
              () =>{
                setTogglePatrimony('Active');
                setColorHeader('investments');
          }}
          >
            Ativo
          </Button>
        ) : (
          <GhostButton
            color="investments"
            className="button-active"
            onClick={
              () => {
              setTogglePatrimony('Active');
              setColorHeader('investments');
            }}
          >
            Ativo
          </GhostButton>
        )}
        {togglePatrimony === 'Passive' ? (
          <Button color="debts" onClick={() => {
            setTogglePatrimony('Passive');
            setColorHeader('debts');
            }
          }>
            Passivo
          </Button>
        ) : (
          <GhostButton
            color="debts"
            onClick={() => {
              setTogglePatrimony('Passive');
              setColorHeader('debts');
              }
            }
          >
            Passivo
          </GhostButton>
        )}
      </div>
      {loading ? (
        <Loading />
      ) : (
        <WrapperChart>
          {/* <WrapperCard>
            <ChartBar
              dataActive={activePatrimony}
              dataPassive={passivePatrimony}
            />
            <ChartBarPatrimony
              dataActive={activePatrimony}
              dataPassive={passivePatrimony}
            />
          </WrapperCard> */}
          {togglePatrimony === 'Active' ? (
              <ActivePatrimony
                dataActive={activePatrimony}
                setDataActive={setActivePatrimony}
              />
          ) : (
            <PassivePatrimony
              dataPassive={passivePatrimony}
              setDataPassive={setPassivePatrimony}
            />
          )}
        </WrapperChart>
      )}
    </Container>
  );
}

export default withRouter(Patrimony);
