import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { produce } from 'immer';

import {
  AiOutlineShoppingCart,
  AiOutlineCreditCard,
  AiOutlineLineChart,
  AiOutlineWallet,
} from 'react-icons/ai';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Dropdown from 'components/Input/Dropdown';

import api from 'services/api';

import { Body } from './styles';

const options = [
  { value: 'Recebimento', label: 'Recebimento', type: 'receipts' },
  { value: 'Dívida', label: 'Dívida', type: 'debts' },
  { value: 'Parcelados', label: 'Parcelados', type: 'debts' },
  { value: 'Sonho', label: 'Sonho', type: 'investments' },
  { value: 'Pessoal', label: 'Pessoal', type: 'spendings' },
  {
    value: 'Alimentação Fora de Casa',
    label: 'Alimentação Fora de Casa',
    type: 'spendings',
  },
  { value: 'Lazer', label: 'Lazer', type: 'spendings' },
  { value: 'Vida e Saúde', label: 'Vida e Saúde', type: 'spendings' },
  { value: 'Moradia', label: 'Moradia', type: 'spendings' },
  { value: 'Transporte', label: 'Transporte', type: 'spendings' },
  { value: 'Dependente', label: 'Dependente', type: 'spendings' },
  { value: 'Outro', label: 'Outro', type: 'spendings' },
];

export default function EditCategoryModal({
  type,
  category,
  categories,
  setCategories,
  setEditCategoryModal,
  setSelectedCategory,
}) {
  const [name, setName] = useState(category.name);
  const [classification, setClassification] = useState(category.classification);
  const [loading, setLoading] = useState(false);

  let icon = null;

  switch (type) {
    case 'receipts':
      icon = <AiOutlineWallet />;
      break;
    case 'spendings':
      icon = <AiOutlineShoppingCart />;
      break;
    case 'debts':
      icon = <AiOutlineCreditCard />;
      break;
    case 'investments':
      icon = <AiOutlineLineChart />;
      break;
    default:
  }

  async function handleSubmit(event) {
    try {
      setLoading(true);
      event.preventDefault();

      if (!name) {
        return toast.error('Preencha todos dados');
      }

      const { data } = await api.put(`categories/${category.id}`, {
        name,
        classification,
      });

      const newCategories = produce(categories, draft => {
        draft[category.id] = {
          ...data,
          items: draft[category.id].items,
        };
      });

      toast.success('Edição realizada com sucesso');

      setCategories(newCategories);
      setSelectedCategory({});
      setLoading(false);
      return setEditCategoryModal(false);
    } catch (err) {
      setLoading(false);
      return toast.error('Ops, tivemos um erro!');
    }
  }

  return (
    <Modal
      title="Editar Categoria"
      setShowModal={setEditCategoryModal}
      color={type}
      icon={icon}
    >
      {loading ? (
        <Loading />
      ) : (
        <Body>
          <form onSubmit={handleSubmit}>
            <Input
              label="Nome da Categoria"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <Dropdown
              label="Tipo"
              defaultValue={{
                label: classification,
                value: classification,
              }}
              options={options.filter(option => option.type === type)}
              onChange={e => setClassification(e.value)}
            />
            <div className="buttons">
              <Button type="submit">Confirmar</Button>
            </div>
          </form>
        </Body>
      )}
    </Modal>
  );
}

EditCategoryModal.propTypes = {
  type: PropTypes.string,
  category: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    classification: PropTypes.string,
  }).isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  setEditCategoryModal: PropTypes.func.isRequired,
  setCategories: PropTypes.func.isRequired,
  categories: PropTypes.objectOf(PropTypes.object),
};

EditCategoryModal.defaultProps = {
  type: 'receipts',
  categories: {},
};
