import styled from 'styled-components';

export const TdStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70px;

  border-radius: 5px;
  border: solid 1px ${props => props.theme[props.color].default};
  color: ${props => props.theme[props.color].default};
`;
