import styled from 'styled-components';

export const WrapperGray = styled.div`
  height: 100%;
  width: 100%;
  background-color: #1E1E1E;
  padding: 16px;
  display: flex;
  justify-content: center;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h1 {
    display: flex;
    justify-content: center;
    font-size: 55px;
    color: #1f1f66;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: -0.97px;
    margin-bottom: 31px;
  }

  h4 {
    color: ${props => props.theme.grafit.hover};
    width: 300px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 5%;
    span {
      color: ${props => props.theme.primary.default};
      font-weight: 700;
      margin-left: 4px;
    }
  }

  span {
    width: 212px;
    height: 44px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.15px;
    text-align: center;
    color: #cccccc;
    margin-bottom: 60px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 315px;

  label {
    margin-bottom: 6px;
    color: ${props => props.theme.grafit.hover};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.15px;
    font-family: 'Nunito', sans-serif;
  }

  a {
    color: ${props => props.theme.secondary.default};
    font-size: 14px;
    margin-left: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.15px;
    margin-bottom: 20px;
    margin-top: 15px;
    align-self: center;
    justify-self: center;
  }
`;

export const InputGroup = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background: #ffffff;
  border: 1px solid ${props => props.theme.secondary.default};
  border-radius: 5px;

  svg {
    color: ${props => props.theme.secondary.default};
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    height: 39px;
    border: 0;
    outline: 0;
    padding: 3.5% 2% 3.5% 0%;
    margin-left: 4%;
    width: 100%;
    border-radius: 5px;

    &::placeholder {
      color: ${props => props.theme.grafit.hover};
      /* font-weight: bold; */
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: 0.26px;
    }
  }
`;
