import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-11px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  transform: translateY(-10px);

  .no-underline {
    &:hover {
      text-decoration: none !important;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: 24px;

  width: 100%;
`;

export const TableTitle = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 0;
  color: #FFFFFF;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 4px 0;
  background: ${props => props.theme.primary.hover};
  border: solid 2px ${props => props.theme.primary.hover};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`

export const Table = styled.table`
  border: solid 2px ${props => props.theme.primary.hover};
  padding: 2px;
  width: 100%;
  border-collapse:separate;

  &:not(.sub-table) {
    border-radius: 4px;
  }
  &.sub-table {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  thead th, tbody td {
    padding: 4px 8px;
    font-weight: 600;

    &.center {
      text-align: center;
    }
  }

  tbody tr td {

    &.success {
      background: #8febd4 !important;
    }

    &.danger {
      background: ${props => props.theme.danger.disabled} !important;
    }
  }

  tbody tr.green:nth-child(even) td:first-child {
    background: ${props => props.theme.grafit.disabled} !important;
    color: ${props => props.theme.black.default};
  }

  tbody tr.green:nth-child(odd) td:first-child {
    background: ${props => props.theme.receipts.disabled} !important;
    color: ${props => props.theme.black.default};
  }

  tbody tr.green:nth-child(even) td {
    background: ${props => props.theme.investments.default};
    color: ${props => props.theme.black.default};
  }

  tbody tr.green:nth-child(odd) td {
    background: #8febd4;
    color: ${props => props.theme.black.default};
  }

  tbody tr:nth-child(odd) td {
    background: ${props => props.theme.receipts.disabled};
    color: ${props => props.theme.black.default};
  }

  tbody tr:nth-child(even) td {
    background: ${props => props.theme.grafit.disabled};
    color: ${props => props.theme.black.default};
  }

  thead {
    background: ${props => props.theme.primary.hover};
    color: ${props => props.theme.white.default};
  }
`

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.darker.disabled};
`;

export const Cards = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;

  div.card-menu {
    padding: 0 !important;
    flex: 1;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const Grapichs = styled.div`
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
  gap: 50px;

  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 20px 0;

  &.on {
    opacity: 0;
    animation: ${FadeIn} .7s ease forwards 0.1s;
  }
  &.off {
    opacity: 0;
    animation: ${FadeIn} .7s ease forwards 0.1s;
  }
`

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  button.switcher {
    position: relative;
    margin: 10px 0;
    padding-left: 30px;
    padding-right: 60px;

    &.on {
      opacity: 0;
      animation: ${slideIn} .7s ease forwards 0.1s;
    }
    &.off {
      opacity: 0;
      animation: ${slideOut} .7s ease forwards 0.1s;
    }

    svg {
      position: absolute;
      right: 26px;
      height: 20px;
      width: auto;
    }
  }
`

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    padding-right: 10px;

    > button {
      font-size: 13px;

      color: ${({ theme }) => theme.info.default};

      transition: 200ms ease-out;

      &:hover {
        transform: scale(1.02);
      }
    }
  }

  @media (max-width: 1542px) {
    &.card-price {
      margin-left: 0 !important;
    }
    div.container-cards-price {
      display: flex;
      align-items: flex-start;

      div.container-card-price {
        flex-shrink: 3;
      }

      & > div.container-card-price + div.container-card-price {
        margin-left: 14px;
      }
    }
  }

  div.container-cards-price {
    width: 100%;

    > div {
      margin-top: 14px;
    }
  }

  &.card-chart-EP {
    width: 50%;
  }

  &.card-price {
    width: auto;
  }

  @media (max-width: 1598px) {
    &.card-chart-EP {
      flex-grow: 3;
    }

    &.card-chart-AC {
      flex-grow: 3;
      margin-left: 15px;
    }

    &.card-price {
      flex-grow: 5;

      margin-left: 15px;
    }
  }
`;
