import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;

  form {
    display: flex;
    flex-direction: column;

  }
  div.custom__container{
    margin-top: ${props=> '0px'};
  }

  form > div + div {
    margin-top: 20px;
  }

  div.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;

    button + button {
      margin-left: 10px;
    }
  }
`;
