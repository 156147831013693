import styled from 'styled-components';


export const Display = styled.div`
  display: flex;
  width: 100%;
  /* background-color: red; */
  flex-direction: column;

  div.header{
    display: flex;

    width: 650px !important;
    gap: 20px;
    height: 100px;
    margin-bottom: 20px;
    div.custom-card-info{
      
      height: 100px !important;
      span{
        
        font-size: 15px ;
       
      }
      strong{
        font-size: 20px;
      }
    }
  }
`;

export const Container = styled.div`
  flex: 1;
  width: 100%;
  display: grid;

  grid-template-rows: repeat(auto-fit, 340px);
  row-gap: 2.8%;
  column-gap: 1.4%;
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 1350px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .card-goal {
  box-shadow: ${props => '2px 2px 3px 0 rgba(0, 0, 0, 0.16)'};
  width: 100%;
  height: auto;
  min-height: 250px;
  max-height: 340px;
  img {
    height: 115%;
  }

  div.content {
    align-items: center;

    div.top-info {
      width: 100%;

      .title {
        font-size: 1.2rem;
        width: 100%;
        word-break: break-word;
        font-weight: 600;
        color: ${props => props.theme.grafit.hover}
      }
      .value {
        font-size: 1.4rem;
      }
      small {
        font-size: 0.8rem;
      }
    }

    div.info {
      height: 60%;
      align-items: center;
      p {
        font-size: 0.69vw !important;
        margin-bottom: unset !important;
        font-weight: 800;
      }
    }

    div.progress-bar {
      height: 0.8vw;
    }
  }

  span.goal-done-text-left {
    width: 66px;
    height: 19px;
    font-family: Nunito;
    font-size: 0.7vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0px;
    text-align: center;
    color: #1de9b6;
  }
}

 
 
`;

export const CreateDreamCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  min-height: 350px;
  height: 100%;
  margin-bottom: 1.5%;
  transition: 200ms ease;

  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    color: ${props => props.theme.info.default};
  }

  div.icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    

    img {
     
      width: 70px;
      height: 70px;
      color: ${props => props.theme.white.default};
    }

    span {
      
      color: ${props => props.theme.grafit.hover};
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.1;
      letter-spacing: 0px;
      text-align: center;

      & > span {
        color: ${props => props.theme.darker.custom};
      }
    }
  }

  div.add-goal {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    button {
      background: none;
      font-size: 0.8vw;
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: 0.33px;
      text-align: left;
      color: ${props => props.theme.info.default};
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  transition: 200ms ease;


  div.background-finished {
    height: 100%;
    background-color:  ${props => props.theme.layout.default};
    border-radius: 10px;
    img {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 100% !important;
      object-fit: cover !important;
	/* background-image: url(https://placecage.com/100/180); */
      
    }
    &.freedom-financial{
      img{
        mask-image: linear-gradient(to top, transparent 10%, green 90%);

      }
    }
    .dropdown-button {
      width: 12% !important;
      height: 12% !important;
      top: 1% !important;
      right: 6% !important;
    }
  }
  .background {
    height: 43.5%;
  }

  div.background,
  .background-finished {
    position: absolute;
    top: 0;
    width: 100%;
    transition: 500ms ease;

    img {
      width: 100%;
      height: 100%;
      transition: 500ms ease;
      &:hover {
        filter: blur(1px) brightness(70%);
        -webkit-filter: blur(1px) brightness(70%);
      }
    }

    .dropdown-button {
      position: absolute;
      top: 10px;
      right: 5px;
      width: 20%;
      height: 20%;
    }

    .done-icon {
      position: absolute;
      top: 10px;
      left: 10px;
      color: ${props => props.theme.primary.default};
    }
  }

  div.content {
    position: absolute;
    top: 43.5%;
    /* background-color: transparent; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56.5%;

    padding-top: 30px;
    padding-bottom: 15px;
    padding-right: 20px;
    padding-left: 20px;

    div.top-info {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title-finished {
        font-size: 1.1vw;
        font-weight: 600;
        line-height: 1.05;
        letter-spacing: 0.26px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        color: ${props => props.theme.white.default};
        margin-top: 35px;
      }
    }

    span {
      &.title {
        text-align: center;
        color: ${props => props.theme.grafit.hover};
        font-size: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        /* margin-top: 1%; */
      }

      &.value {
        color: ${props =>
    props.done
      ? props.theme.primary.default
      : props.theme.investments.default};
        font-size: 22px;
        font-weight: bold;
      }
    }

    small {
      color: ${props => props.theme.grafit.default};
      font-size: 15px;
      font-weight: 700;
    }
  }
`;

export const DetailGoal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  div.header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    height: 10%;

    svg {
      color: ${props => props.theme.grafit.hover};
      cursor: pointer;
    }
  }

  div.body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 90%;

    div.totalGoal {
      display: flex;

      justify-content: flex-end;

      width: 30%;
      height: 75%;

      border-radius: 12px;
      background: ${props => props.theme.grafit.disabled};
      margin-left: 10%;

      div.percentGoal {
        width: 100%;
        height: 60%;
        background: ${props => props.theme.investments.default};
        border-radius: 12px;
      }
    }
  }
`;

export const ProgressBar = styled.div`
  background: ${props => props.theme.grafit.disabled};
  width: 100%;
  height: ${props => props.height || '15'}px;
  border-radius: 10px;

  div.filler {
    background: ${props => props.theme[props.color].default};
    width: ${props => props.width}%;
    height: 100%;
    border-radius: 10px;
  }
`;

export const Form = styled.form`
  display: flex;
  /* flex-direction: ${props => props.column ? 'row' : 'column'} ; */
  align-items: flex-start; 
  justify-content: space-between;
  max-height: 800px;
  /* height: 600px; */
  /* overflow: auto; */
  padding-right: 15px;
  gap: 50px;
  width: 100%;
  & > div + div {
    margin-top: 5%;
  }
  div.left, div.right{
    width: 100%;
    height: 100%;
    
   
  }  

  div.right{
    margin-top: 10px
  }

  div.inputGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap:8px;
    &.drop{
      margin-top: 8px;
    }
  }

  div.buttonGroup {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
`;

export const DivSwitch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 100%;

  label {
    font-size: 14px;
    color: ${props => props.theme.grafit.hover};
    margin-top: -5%;
    margin-bottom: 10%;
  }
`;

export const DivDropzone = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  border-radius: 8px;
  margin-top: 15px;
  border: 1px solid ${props => props.theme.info.default};
  transition: 200ms ease;


  svg {
    color: ${props => props.theme.info.default};
  }

  cursor: pointer;
  /* &:hover {
    background: ${props => props.theme.white.default};
    border-color: ${props => props.theme.info.default};
  } */

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: url(${props => props.thumbnail}) center;
    border-radius: 8px;
    width: 98%;
    height: 92px;
    transition: 200ms ease;

    h3 {
      color: ${props => props.theme.info.default};
      /** Limitar caracteres */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }

    &:hover {
      color: ${props => props.theme.white.default};
      background: ${props => !props.thumbnail && props.theme.info.default};
      border-color: ${props => props.theme.info.default};
      font-size: 15px;

      svg {
        color: ${props => props.theme.white.default};
      }

      h3 {
        color: ${props => props.theme.info.default};
      }
    }
  }

  div.without-file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-size: 12px;
      font-style: italic;
      line-height: 1.33;
      letter-spacing: 0px;
      text-align: left;
      color: ${props => props.theme.grafit.default};
    }
  }
`;

export const DivButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;
