import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Container, Filler } from './styles';
import { Context } from '../../Context';
import ReactTooltip from 'react-tooltip';
export default function ProgressBar({
  installments,
  // valueAccomplished,
  // valueInstallments,
  // valueEstimated,
  accomplished,
  surplus,
  height,
  color,
  className,
}) {
  const { toggleButton } = useContext(Context);
  return (
    <Container height={height} className={className}>
      {toggleButton === 'receipts' ? (
        <Filler
          className={`${className}`}
          height={height}
          color={accomplished > 100 ? 'investments' : 'receipts'}
          filler={accomplished}
        ></Filler>
      ) : (
        <>
          <Filler
            className={`${className} custom`}
            height={height}
            color={'debts'}
            data-for="path"
            // data-tip={`Parcelados: ${valueInstallments || 'R$ 0,00'}`}
            data-tip="Parcelados"
            filler={
              Number(accomplished) + Number(installments) > 100
                ? 0
                : installments
            }
          ></Filler>
          <Filler
            className={className}
            height={height}
            color={color}
            data-for="path"
            // data-tip={`À vista e recorrentes: ${valueAccomplished ||
            //   'R$ 0,00'}`}
            data-tip="À vista e recorrentes"
            filler={
              Number(accomplished) + Number(installments) > 100
                ? 0
                : accomplished
            }
          ></Filler>

          <Filler
            className={className}
            height={height}
            data-for="path"
            // data-tip="hello <br /> world"
            data-tip="Gastos parcelados, à vista e recorrentes"
            // data-tip={`À vista e recorrentes: ${valueAccomplished ||
            //   'R$ 0,00'} <br /> Parcelados: ${valueInstallments ||
            //   'R$ 0,00'} <br /> Excedente: ${surplus || 'R$ 0,00'}`}
            color={'debts'}
            filler={Number(accomplished) + Number(installments) > 100 ? 100 : 0}
          ></Filler>
        </>
      )}
      <ReactTooltip html={true} id="path" />
    </Container>
  );
}

ProgressBar.propTypes = {
  filler: PropTypes.number,
  height: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  color: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

ProgressBar.defaultProps = {
  value: false,
  label: false,
  color: '#1de9b6',
  height: '91px',
  filler: 25,
};
