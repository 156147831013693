import React from 'react';

import PropTypes from 'prop-types';

import { Container, Filler } from './styles';

export default function ProgressBar({ height, color, filler }) {
  return (
    <Container height={height}>
      <Filler height={height} color={color} filler={filler}>
        {filler <= 5 ? <span /> : <span>{`${filler}%`}</span>}
      </Filler>
    </Container>
  );
}

ProgressBar.propTypes = {
  filler: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

ProgressBar.defaultProps = {
  color: '#1de9b6',
  height: 91,
  filler: 25,
};
