import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { format, addMonths, subMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { AiOutlineWallet, AiOutlineShoppingCart } from 'react-icons/ai';
import { Container, Title, DivButton, DivOptions } from './styles';

import UpdateModal from './UpdateModal';

import Table from './Table';

import api from 'services/api';

import capitalizeWords from 'utils/capitalizeWords';

import Loading from 'components/Loading';

import { BsGraphUp } from 'react-icons/bs';
import newApi from 'services/newApi';

export default function Month() {
  const [showModal, setShowModal] = useState(false);

  const [type, setType] = useState('spendings');

  const [categories, setCategories] = useState([]);

  const [selectedItem, setSelectedItem] = useState({});

  const [loading, setLoading] = useState(true);

  const { id } = useSelector(state => state.user.profile);

  const { token } = useSelector(state => state.auth);

  const [investments, setInvestments] = useState({
    estimated: 0,
    investments: [],
  });

  useEffect(() => {
    async function fetchInvestments() {
      setLoading(true);
      const { data } = await newApi.post(`Auth/AutenticarCompativel`, {
        idUser: id,
        token,
      });

      const { data: response } = await newApi.get(
        `Investimento/ultimos_seis_meses/${id}/${new Date().toISOString()}`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      const { data: params } = await api.post(`users/${id}/calc_parameters`, {
        date: format(new Date(), 'yyyy-MM-dd'),
        params: ['investments'],
      });
      setInvestments({
        estimated: params.investments.estimated,
        investments: response,
      });
      setLoading(false);
    }
    async function fetchData() {
      setLoading(true);
      const { data } = await api.get(`users/${id}/spending_report`, {
        params: {
          type,
        },
      });

      setCategories(data);
      setLoading(false);
    }
    if (type === 'investments') {
      fetchInvestments();
    } else {
      fetchData();
    }
  }, [id, type, token]);

  function returnSixMonth() {
    const monthsFunction = [];
    const today = new Date();
    const start = subMonths(today, 7);

    let count = 1;

    while (count <= 7) {
      const month = format(addMonths(start, count), 'MMMM', { locale: ptBR });

      monthsFunction.push(capitalizeWords(month));
      count++;
    }

    return monthsFunction.slice(1);
  }
  function buildBodyInvestments(investments) {
    const sixMonthValues = investments.investments.map(item =>
      item.valor.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    );
    const sum = investments.investments.reduce(
      (accumulator, currentValue) => accumulator + currentValue.valor,
      0
    );
    const avg = sum / 6;
    const estimated = investments.estimated.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    const result = [
      [
        'Investimentos',
        {
          className: 'td-stimated',
          value: estimated,
        },
        ...sixMonthValues,
        avg.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        sum.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        estimated,
      ],
    ];

    return result;
  }

  function bodyTable(items) {
    if (!items) return [[]];

    return items.map(item => {
      const { report } = item;

      const valuesArray = Object.values(report)
        .map(data => data.total)
        .slice(1);

      // Valor por mês
      const currencyValues = valuesArray.map(data => {
        if (data === 0) return '-';

        return Math.ceil(data).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
        });
      });

      let recurrency = 0;

      // Soma dos valores
      const sum = Math.ceil(
        valuesArray.reduce((total, current) => {
          if (!current) return total;
          recurrency++;
          return total + current;
        }, 0)
      );

      if (recurrency === 0) recurrency = 1;

      // Média dos gastos por mês
      const average = sum / recurrency;

      let estimated = 0;

      if (item.classification.includes('Eventual')) {
        estimated = Math.ceil(item.value / 12).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      } else {
        estimated = Math.ceil(item.value).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      }

      const result = [
        item.name,
        {
          className: 'td-stimated',
          value: estimated,
          item_id: item.id,
          category_id: item.category_id,
        },
        // Meses do ano
        ...currencyValues,
        // Média dos gastos
        average.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        // item.classification === 'Mensal Flexível' ||
        // item.classification === 'Mensal Comprometido'
        //   ? average.toLocaleString('pt-br', {
        //       style: 'currency',
        //       currency: 'BRL',
        //       minimumFractionDigits: 0,
        //       maximumFractionDigits: 0,
        //     })
        //   : '-',
        // Soma dos gastos
        sum.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      ];

      return result;
    });
  }
  const title = {
    spendings: 'Gastos',
    receipts: 'Recebimentos',
    investments: 'Investimentos',
  };

  const RenderTable = type => {
    if (type === 'investments') {
      return (
        <Table
          title={'Investimentos'}
          head={['Item', 'Estimado', ...returnSixMonth(), 'Média', 'Soma']}
          body={buildBodyInvestments(investments)}
        />
      );
    }
    return categories.map(category => {
      return (
        <Table
          key={category.id}
          title={category.name}
          head={['Item', 'Estimado', ...returnSixMonth(), 'Média', 'Soma']}
          body={bodyTable(category.items)}
          category={category}
          setShowModal={setShowModal}
          setSelectedItem={setSelectedItem}
        />
      );
    });
  };

  return (
    <>
      <Title>Relatório 6 meses - {title[type]}</Title>
      <DivOptions>
        <DivButton
          data-tip="Gastos"
          selected={type === 'spendings'}
          onClick={() => setType('spendings')}
        >
          <AiOutlineShoppingCart size={25} />
        </DivButton>
        <DivButton
          data-tip="Recebimentos"
          selected={type === 'receipts'}
          onClick={() => setType('receipts')}
        >
          <AiOutlineWallet size={25} />
        </DivButton>
        <DivButton
          data-tip="Investimentos"
          selected={type === 'investments'}
          onClick={() => setType('investments')}
        >
          <BsGraphUp size={25} />
        </DivButton>
      </DivOptions>
      {loading ? (
        <Loading className="teste" />
      ) : (
        <Container>{RenderTable(type)}</Container>
      )}
      {showModal && (
        <UpdateModal
          setShowModal={setShowModal}
          selectedItem={selectedItem}
          setCategories={setCategories}
          setSelectedItem={setSelectedItem}
        />
      )}
    </>
  );
}
