import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { normalize, schema } from 'normalizr';
import { AiOutlineShoppingCart, AiOutlineCreditCard } from 'react-icons/ai';

import { MdTrendingUp } from 'react-icons/md';

import ContextHeaderColor from 'utils/contexts/ColorHeaderContext';

import api from 'services/api';

import InfoCard from 'components/Cards/Info';

import CreateCategoryModal from './Modals/CreateCategoryModal';
import EditCategoryModal from './Modals/EditCategoryModal';
import CreateItemModal from './Modals/CreateItemModel';
import EditItemModal from './Modals/EditItemModal';
import CreateInstallmentModal from './Modals/CreateInstallmentModal';
import EditInstallmentModal from './Modals/EditInstallmentModal';

import { InfoCardsContainer, TablesContainer } from './styles';

import Receipts from './SubScreens/Receipts';
import Spendings from './SubScreens/Spendings';
import Debts from './SubScreens/Debts';
import Investments from './SubScreens/Investments';
import Loading from 'components/Loading';

export default function Budget() {
  const [type, setType] = useState('receipts');
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState({});
  const [items, setItems] = useState({});
  const [installments, setInstallments] = useState([]);
  const [passivePatrimonies, setPassivePatrimonies] = useState([]);
  const [goals, setGoals] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedInstallment, setSelectedInstallment] = useState({});
  const [createCategoryModal, setCreateCategoryModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [editItemModal, setEditItemModal] = useState(false);
  const [createItemModal, setCreateItemModal] = useState(false);
  const [createInstallmentModal, setCreateInstallmentModal] = useState(false);
  const [editInstallmentModal, setEditInstallmentModal] = useState(false);
  const { setColorHeader } = useContext(ContextHeaderColor);
  const user = useSelector(state => state.user.profile);

  useEffect(() => {
    async function fecthData() {
      setLoading(true);
      const { data: categories } = await api.get(
        `/users/${user.id}/categories`,
        {
          params: {
            items: true,
          },
        }
      );

      const itemSchema = new schema.Entity('items');
      const categorySchema = new schema.Entity('categories', {
        items: [itemSchema],
      });
      const categoryListSchema = [categorySchema];
      const { entities } = normalize(categories, categoryListSchema);

      const { data: goals } = await api.get(`/users/${user.id}/goals`, {
        params: {
          all: true,
        },
      });

      const { data: installments } = await api.get(
        `/users/${user.id}/installments`,
        {
          params: {
            withCategory: true,
            withItem: true,
            all: true,
          },
        }
      );

      const { data: passivePatrimonies } = await api.get(
        `/users/${user.id}/passive_patrimony`
      );

      setItems(entities.items);
      setCategories(entities.categories || []); // com o || o valor padrão dos cards muda de 12k pra 0
      setGoals(goals);
      setInstallments(installments);
      setPassivePatrimonies(passivePatrimonies);
      setLoading(false);
    }

    fecthData();
  }, [user, user.id]);



  const creditCardValue = useMemo(() => {
    return categories &&
      Object.values(categories)
        .filter(c => c.type === 'spendings')
        .reduce((total, current) => [...total, ...current.items], [])
        .reduce((total, currentValue) => {
          if (!items[currentValue] || !items[currentValue].creditCard) return total;
          if (
            items[currentValue].classification === 'Eventual Flexível' ||
            items[currentValue].classification === 'Eventual Comprometido'
          ) {
            return total + items[currentValue].value / 12;
          }
          return total + items[currentValue].value;
        }
          , 0)
        .toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
  }, [categories, items]);

  return (
    <>
      {createCategoryModal && (
        <CreateCategoryModal
          type={type}
          categories={categories}
          items={items}
          setCategories={setCategories}
          setItems={setItems}
          setCreateCategoryModal={setCreateCategoryModal}
        />
      )}
      {editCategoryModal && (
        <EditCategoryModal
          type={type}
          categories={categories}
          category={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setCategories={setCategories}
          setEditCategoryModal={setEditCategoryModal}
        />
      )}
      {createItemModal && (
        <CreateItemModal
          type={type}
          category={selectedCategory}
          categories={categories}
          items={items}
          setCategories={setCategories}
          setItems={setItems}
          setCreateItemModal={setCreateItemModal}
        />
      )}
      {editItemModal && (
        <EditItemModal
          type={type}
          selectedItem={selectedItem}
          items={items}
          setItems={setItems}
          setEditItemModal={setEditItemModal}
          setSelectedItem={setSelectedItem}
        />
      )}
      {createInstallmentModal && (
        <CreateInstallmentModal
          type={type}
          setInstallments={setInstallments}
          categories={Object.values(categories)
            .filter(c => c.type === 'spendings')
            .map(c => ({
              ...c,
              items: c.items.map(i => items[i]),
            }))}
          setCreateInstallmentModal={setCreateInstallmentModal}
        />
      )}
      {editInstallmentModal && (
        <EditInstallmentModal
          categories={Object.values(categories)
            .filter(c => c.type === 'spendings')
            .map(c => ({
              ...c,
              items: c.items.map(i => items[i]),
            }))}
          setInstallments={setInstallments}
          selectedInstallment={selectedInstallment}
          setEditInstallmentModal={setEditInstallmentModal}
          items={items}
        />
      )}
      <InfoCardsContainer>
        <InfoCard
          onClick={() => {
            setType('receipts');
            setColorHeader('receipts');
          }}
          active={type === 'receipts'}
          mainInfo={
            categories &&
            Object.values(categories)
              .filter(c => c.type === 'receipts')
              .reduce((total, current) => [...total, ...current.items], [])
              .reduce((total, currentValue) => {
                if (!items[currentValue]) return total;
                if (
                  items[currentValue].classification === 'Eventual Flexível' ||
                  items[currentValue].classification === 'Eventual Comprometido'
                ) {
                  return total + items[currentValue].value / 12;
                }
                return total + items[currentValue].value;
              }, 0)
              .toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
          }
        />
        <InfoCard
          color="investments"
          CardIcon={MdTrendingUp}
          title="Investimentos"
          onClick={() => {
            setType('investments');
            setColorHeader('investments');
          }}
          active={type === 'investments'}
          mainInfo={goals
            .reduce((total, currentValue) => {
              if (currentValue.done_at) return total;
              return total + currentValue.ifp;
            }, 0)
            .toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
        />
        <InfoCard
          color="spendings"
          onClick={() => {
            setType('spendings');
            setColorHeader('spendings');
          }}
          CardIcon={AiOutlineShoppingCart}
          title="Gastos"
          active={type === 'spendings'}
          subInfo={creditCardValue}
          mainInfo={
            categories &&
            Object.values(categories)
              .filter(c => c.type === 'spendings')
              .reduce((total, current) => [...total, ...current.items], [])
              .reduce((total, currentValue) => {
                if (!items[currentValue]) return total;
                if (
                  items[currentValue].classification === 'Eventual Flexível' ||
                  items[currentValue].classification === 'Eventual Comprometido'
                ) {
                  return total + items[currentValue].value / 12;
                }
                return total + items[currentValue].value;
              }, 0)
              .toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
          }
        />
        <InfoCard
          color="debts"
          CardIcon={AiOutlineCreditCard}
          title="Dívidas"
          onClick={() => {
            setType('debts');
            setColorHeader('debts');
          }}
          active={type === 'debts'}
          mainInfo={(
            passivePatrimonies.reduce((total, currentValue) => {
              if (currentValue.settled_at) return total;
              return total + currentValue.installment;
            }, 0) +
            installments.reduce(
              (total, currentValue) => total + currentValue.value,
              0
            )
          ).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        />
      </InfoCardsContainer>

      <TablesContainer>
        {type === 'receipts' && (
          <Receipts
            categories={categories}
            items={items}
            setCategories={setCategories}
            setItems={setItems}
            loading={loading}
            setLoading={setLoading}
            setSelectedCategory={setSelectedCategory}
            setEditCategoryModal={setEditCategoryModal}
            setCreateItemModal={setCreateItemModal}
            setSelectedItem={setSelectedItem}
            setEditItemModal={setEditItemModal}
            setCreateCategoryModal={setCreateCategoryModal}
          />
        )}
        {type === 'spendings' && (
          <Spendings
            categories={categories}
            items={items}
            setCategories={setCategories}
            setItems={setItems}
            loading={loading}
            setLoading={setLoading}
            setSelectedCategory={setSelectedCategory}
            setEditCategoryModal={setEditCategoryModal}
            setCreateItemModal={setCreateItemModal}
            setSelectedItem={setSelectedItem}
            setEditItemModal={setEditItemModal}
            setCreateCategoryModal={setCreateCategoryModal}
          />
        )}
        {type === 'debts' && (
          <Debts
            loading={loading}
            setLoading={setLoading}
            installments={installments}
            passivePatrimonies={passivePatrimonies}
            setInstallments={setInstallments}
            setEditInstallmentModal={setEditInstallmentModal}
            setSelectedInstallment={setSelectedInstallment}
            setCreateInstallmentModal={setCreateInstallmentModal}
            setCreateCategoryModal={setCreateCategoryModal}
          />
        )}
        {type === 'investments' && goals.length > 0 && <Investments goals={goals} />}
      </TablesContainer>
      {loading ? <Loading></Loading> : <></>}
    </>
  );
}
