import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;

  transform: translateY(20px) translateX(-16px);

  & > div + div {
    margin-top: 80px;
  }
  padding: 0 1.5%;
`;

export const Title = styled.span`
  font-size: 34px;
  font-weight: bold;
  line-height: 1.35;
  letter-spacing: 0.24px;

  color: ${props => props.theme.darker.disabled};

  padding-left: 5px;
  margin-bottom: 25px;
`;

export const DivOptions = styled.div`
  display: flex;

  & div + div {
    margin-left: 15px;
  }
`;

export const DivButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${props => props.theme.grafit.hover};
  border-radius: 10px;

  padding: 7px;

  ${props =>
    props.selected
      ? css`
          background: ${props.theme.info.default};
          border: 0;
          & > svg {
            fill: ${props.theme.white.default};
          }
        `
      : css`
          & > svg {
            fill: ${props => props.theme.grafit.hover};
          }
        `}

  &:hover {
    cursor: pointer;
  }
`;
