import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Input from 'components/Input';
import Button from 'components/Button';
import { CurrencyInput } from 'components/masks';
import { Form } from './styles';
import newApi from 'services/newApi';
import format from 'date-fns/format';
import { Context } from '../Context';

export default function Edit() {
  const { setPage, selectedItem } = useContext(Context);
  const [dateStart, setDateStart] = useState(
    format(new Date(selectedItem.data), 'yyyy-MM-dd')
  );
  const [value, setValue] = useState(selectedItem.valor);
  const [loading, setLoading] = useState(false);
  const { id } = useSelector(state => state.user.profile);
  const { token } = useSelector(state => state.auth);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await newApi.post(`Auth/AutenticarCompativel`, {
        idUser: id,
        token,
      });
      await newApi.put(
        `Investimento`,
        {
          id: selectedItem.id,
          data: new Date(dateStart).toISOString(),
          valor: value,
          idUsuario: id,
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      setValue(0);
      toast.success('Lançamento editado com sucesso');
      setLoading(false);
      setPage('list');
    } catch (error) {
      setLoading(false);
      toast.error('Houve um erro com o lançamento');
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <CurrencyInput
        label="Valor"
        prefix="R$ "
        value={value}
        onChange={cleanValue => setValue(cleanValue)}
      />

      <Input
        label="Data"
        type="date"
        value={dateStart}
        onChange={e => setDateStart(e.target.value)}
      />

      <div className="buttons">
        <Button
          onClick={() => setPage('list')}
          type="submit"
          stripped
          color="info"
        >
          Voltar
        </Button>
        <Button type="submit" stripped color="info">
          {loading ? 'Carregando...' : 'Salvar transação'}
        </Button>
      </div>
    </Form>
  );
}
