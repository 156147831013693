import { all, takeLatest, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import { loadInitialStateSuccess, loadInitialStateFailure } from './actions';

import newApi from 'services/newApi';

export function* loadSuitabilityData( { payload }) {

  const id = payload;
  try {

    const response = yield call(newApi.get, `Estrategia/${id}/statussuitability`);

    const { parcial, perguntas, respondido, descricao, perfil } = response.data;

    yield put(loadInitialStateSuccess({ parcial, perguntas, respondido, descricao, perfil }));

  } catch (err) {
    toast.error(
      'Falha ao resgatar dados de preenchimento Suitability.'
    );
    yield put(loadInitialStateFailure());
  }
}

export default all([
  takeLatest('@suitability/LOAD_INITIAL_DATA_REQUEST', loadSuitabilityData),
]);
