import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Loading from 'components/Loading';

import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Container, Information } from './styles';

import { Pie } from 'react-chartjs-2';

Chart.plugins.register(ChartDataLabels);

export default function CardPIe({ values, height }) {

  const [ data, setData ] = useState({});
  const [ options, setOptions ] = useState(null);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {

    const titles = values.map(item => item.titulo);
    const valores = values.map(item => item.valor);

    const _data = {

      labels: titles,
      datasets: [{
        data: valores,
        backgroundColor: [
          '#1c11a7',
          '#4140c0',
          '#6268d7',
          '#8490ec',
          '#aab9ff',
          '#a288cb',
          '#b688cb',
          '#9669ab',
        ],
        hoverOffset: 4
      }]
    };

    setData(_data);
  // eslint-disable-next-line
  }, [values])

  useEffect(() => {
    if(data !== null) {
      const _options = {
        responsive: true,
        // responsive: false,
        // width: 800,
        // height: 600,
        maintainAspectRatio: false,
        devicepixelratio: 8,
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
            boxWidth: 6,
          }
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function(tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var meta = dataset._meta[Object.keys(dataset._meta)[0]];
              var total = meta.total;
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = parseFloat((currentValue/total*100).toFixed(1));
              return currentValue + ' (' + percentage + '%)';
            },
            title: function(tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            }
          }
        },
        plugins: {
          datalabels: {
              formatter: (value) => {
                let percentage = (value).toFixed(2)+"%";
                return value !== 0 ? percentage : '';
              },
              color: '#FFF',
              textStrokeColor: '#000',
              textStrokeWidth: 5,
              /*backgroundColor: (value) => {
                return value !== 0 ? "rgba(0,0,0,0.6)" : "transparent";
              },*/
              borderRadius: 25,
              padding: 4,
              font: {
                family: 'Roboto Condensed',
                weight: 'bold',
                size: 13,
              },
              align: 'end',
              offset: [0, 20, 0, 20, 0, 20, 0, 20],
          }
          /*
          datalabels: {
            backgroundColor: function(context) {
              return context.dataset.backgroundColor;
            },
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 2,
            color: 'white',
            display: function(context) {
              var dataset = context.dataset;
              var count = dataset.data.length;
              var value = dataset.data[context.dataIndex];
              return value > count * 1.5;
            },
            font: {
              weight: 'bold'
            },
            padding: 6,
            formatter: Math.round
          }*/
        }
      }

      setOptions(_options);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [data])


  return (
    <Container>
      <Information>
        <>
          { loading && options !== null && data !== null ? (
            <Loading />
            ) : (
              <Pie
                height={height}
                data={data}
                options={options}
              />
            )
          }
        </>
      </Information>
    </Container>
  );
}

CardPIe.propTypes = {
  values: PropTypes.array,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CardPIe.defaultProps = {
  values: [],
  height: '100%'
};
