import React from 'react';
import PropTypes from 'prop-types';

import { ModalStyled, DivBackground } from './styles';

export default function BlankModal({ children, color }) {
  return (
    <>
      <DivBackground>
        <ModalStyled color={color}>
          <div className="body">{children}</div>
        </ModalStyled>
      </DivBackground>
    </>
  );
}

BlankModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  color: PropTypes.string,
};

BlankModal.defaultProps = {
  children: () => {},
  color: 'debts',
};
