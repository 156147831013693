import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { produce } from 'immer';

import {
  AiOutlineShoppingCart,
  AiOutlineCreditCard,
  AiOutlineLineChart,
  AiOutlineWallet,
} from 'react-icons/ai';

import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { dayOptions, monthsOfYear } from 'utils/dates';

import Modal from 'components/Modal';
import Input from 'components/Input';
import CurrencyInput from 'components/masks/CurrencyInput';
import Select from 'components/Input/Dropdown';
import Button from 'components/Button';
import Loading from 'components/Loading';

import api from 'services/api';

import { Body } from './styles';
import capitalizeWords from 'utils/capitalizeWords';

export default function EditItemModal({
  type,
  selectedItem,
  items,
  setItems,
  setEditItemModal,
  setSelectedItem,
}) {
  const [name, setName] = useState(selectedItem.name);
  const [value, setValue] = useState(selectedItem.value);
  const [classification, setClassification] = useState(
    selectedItem.classification
  );
  const [when, setWhen] = useState(selectedItem.when);
  const [loading, setLoading] = useState(false);

  let icon = null;

  switch (type) {
    case 'receipts':
      icon = <AiOutlineWallet />;
      break;
    case 'spendings':
      icon = <AiOutlineShoppingCart />;
      break;
    case 'debts':
      icon = <AiOutlineCreditCard />;
      break;
    case 'investments':
      icon = <AiOutlineLineChart />;
      break;
    default:
  }

  const options = [
    { value: 'Mensal Flexível', label: 'Mensal Flexível' },
    { value: 'Mensal Comprometido', label: 'Mensal Comprometido' },
    { value: 'Eventual Flexível', label: 'Eventual Flexível' },
    { value: 'Eventual Comprometido', label: 'Eventual Comprometido' },
  ];

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setLoading(true);

      const { data } = await api.put(`items/${selectedItem.id}`, {
        name,
        value,
        classification,
        when:
          classification === 'Eventual Comprometido' ||
            classification === 'Mensal Comprometido'
            ? when
            : null,
      });

      const newItems = produce(items, draft => {
        draft[data.id] = data;
      });

      setItems(newItems);
      toast.success('Item editado com sucesso!');
      setLoading(false);
      return setEditItemModal(false);
    } catch (err) {
      setLoading(false);
      return toast.error('Ops, tivemos um erro!');
    }
  }

  function checkWhen(e) {
    const { value: whenValue } = e.target;

    if (whenValue > 31) {
      return toast.error('O valor de "Quando" deve ser um dia do mês válido');
    }

    return setWhen(whenValue);
  }

  return (
    <Modal
      title="Editar item"
      setShowModal={() => {
        setEditItemModal(false);
        setSelectedItem({});
      }}
      color={type}
      icon={icon}
    >
      {loading ? (
        <Loading />
      ) : (
        <Body>
          <form onSubmit={handleSubmit}>
            <Input
              value={name}
              onChange={e => setName(e.target.value)}
              label="Nome do item"
            />
            <Select
              onChange={e => setClassification(e.value)}
              options={options}
              placeholder=""
              defaultValue={options.filter(o => o.value === classification)[0]}
              label="Classificação"
            />
            {!classification === 'Mensal Flexível' ||
              (!classification === 'Eventual Flexível' && (
                <Input
                  value={when}
                  onChange={checkWhen}
                  type="number"
                  label="Quando"
                />
              ))}

            {classification.includes('Mensal Comprometido') && (
              <Select
                label="Dia do Mês"
                options={dayOptions()}
                defaultValue={{
                  value: selectedItem?.when,
                  label: selectedItem?.when,
                }}
                onChange={selected => setWhen(selected.value)}
              />
            )}

            {classification.includes('Eventual Comprometido') && (
              <Select
                label="Mês do Ano"
                options={monthsOfYear()}
                defaultValue={{
                  value: selectedItem?.when,
                  label: capitalizeWords(
                    format(new Date(), 'MMMM', {
                      locale: pt,
                    })
                  ),
                }}
                onChange={selected => setWhen(selected.value)}
              />
            )}

            <CurrencyInput
              value={value}
              prefix="R$ "
              onChange={e => setValue(e)}
              label="Valor"
            />

            <div className="buttons">
              <Button type="submit">Confirmar</Button>
            </div>
          </form>
        </Body>
      )}
    </Modal>
  );
}

EditItemModal.propTypes = {
  type: PropTypes.string.isRequired,
  setEditItemModal: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    classification: PropTypes.string,
    when: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  items: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  setItems: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
};
