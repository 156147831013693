import React, { useContext } from 'react';
import { Container } from './styles';
import Can from 'components/Can';
import { AiFillEdit, AiTwotoneDelete } from 'react-icons/ai';
import { Context } from '../../Context';
import { format, differenceInCalendarMonths } from 'date-fns';
import dateUTC from 'utils/dateUTC';

export default function ExtractTable({
  show,
  reloadCategory,
  setReloadCategory,
  reloadItem,
  setReloadItem,
}) {
  const {
    entries,
    setModalUpdate,
    setSelectEntry,
    toggleButton,
    setShowModalDelete,
    dateStart,
  } = useContext(Context);

  const Row = ({ item }) => {

    return (
      <tr key={item.id} className={entries.length > 1 ? 'hasMany' : 'hasOne'}>
        <td>
          <div className="content">
            <span>
              {toggleButton === 'installments' ? item.name : item.description}
            </span>
          </div>{' '}
        </td>
        <td>
          <div className="content">
            <span>
              {item.value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </span>
          </div>{' '}
        </td>
        <td>
          <div className="content">
            <span>
              {item.payment === undefined
                ? item.recurrent
                  ? 'sim'
                  : 'não'
                : item.payment}
            </span>
          </div>{' '}
        </td>
        <td>
          <div className="content">
            <span>{`${item.installment} / ${differenceInCalendarMonths(dateUTC(item.end), dateUTC(item.start)) + 1}`}</span>
          </div>{' '}
        </td>
        <td className={toggleButton === 'installments' ? toggleButton : ''}>
          <div className="content">
            <span>{format(new Date(item.created_at), 'dd/MM/yyyy')}</span>
          </div>{' '}
        </td>
        <td>
          <div className="content">
            {item.userCanEdit && toggleButton !== 'installments' && (
              <>
                <button
                  onClick={async () => {
                    setModalUpdate(true);
                    setSelectEntry(item);
                  }}
                >
                  <AiFillEdit className="edit" />
                </button>
                <button
                  onClick={() => {
                    setSelectEntry(item);
                    setShowModalDelete(true);
                  }}
                >
                  <AiTwotoneDelete className="delete" />
                </button>
              </>
            )}
          </div>
        </td>
      </tr>
    );
  };

  return (
    <Container show={show}>
      <table>
        <thead>
          <tr>
            <th>
              <div className="content-th">Item</div>
            </th>
            <th>
              <div className="content-th">Valor</div>
            </th>
            <th>
              <div className="content-th">Pagamento</div>
            </th>
            <th>
              <div className="content-th">Parcelas</div>
            </th>
            <th>
              <div className="content-th">Data</div>
            </th>
            {toggleButton !== 'installments' && (
              <th>
                <div className="content-th">Ações</div>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {entries.map((item, key) => (
            <Row key={key} item={item} />
          ))}
        </tbody>
      </table>
    </Container>

  );
}
