
import React, {useContext} from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Container } from './styles';
import { Context } from '../../Context';

export default function Empty() {
   
    const {toggleButton } = useContext(
        Context
      );
     
    return (
        <Container color={toggleButton === 'installments'? 'debts' : toggleButton} >
            <AiOutlineSearch />
            <p>Desculpe...</p>
            <span>Nenhuma pesquisa</span>
            <span>foi encontrada</span>
        </Container>
    );

}
