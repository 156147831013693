export default function toCurrency(value) {
  let result = value;

  if (typeof value === 'undefined') {
    result = 0;
  }

  return result.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
}

export const toCurrencyNoCents = (value) => {
  let result = value;

  if (typeof value === 'undefined') {
    result = 0;
  }

  return result.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}
