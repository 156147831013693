import styled, { keyframes, css } from 'styled-components';

const showDivDropdown = keyframes`
  0% {
    opacity: 0;
    max-height: 0;
  }
  70% {
    max-height: 100%;
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  padding-top: 1vw;
  width: 100%;
  height: 100%;
  ${props =>
    props.show &&
    css`
      animation: ${showDivDropdown} forwards 3000ms;
    `};
}

  table{
    width: 100%;
    height:100%;
  }
 
  table thead th{
    align-items: center;
    padding-bottom:.4vw;
    
    .content-th{
      font-family: Nunito;
      font-size: 0.8vw;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: 0px;
      color: #808099;
    }
  }
  table thead th{
    text-align: center;
  }
  table tbody td{
    text-align: center;
    /* background: lightgreen; */
  }
  table tbody tr.hasMany:first-child td:first-child{
    border-radius: 10px 0 0 0;
  }
  table tbody tr.hasMany:first-child td:last-child{
    border-radius: 0 10px 0 0;
  }
  table tbody tr.hasMany:last-child td:first-child{
    border-radius: 0 0 0 10px;
  }

  table tbody tr.hasMany:last-child td:last-child{
    border-radius: 0 0  10px 0 ;
  }
  
  table tbody :not(tr.hasMany) td:first-child{
    border-radius: 10px 0 0 10px;
  }
  table tbody :not(tr.hasMany)  td:last-child{
    border-radius: 0 10px 10px 0;
  }

  table tbody td.installments{
    border-radius: 0 10px 10px 0;
    .content{
      border-radius: 0 10px 10px 0;
    }
  }

  table tbody tr:first-child .content{
    height: 5vh;
    button svg{
      margin-top: 1.3vh
    }
   
  }
  
 
  table tbody tr.hasMany:last-child .content{
    height: 5.9vh;
   
    span{
      align-items: center;
      margin-bottom: 1vh;
    }
    button{
      align-items: center;
      margin-bottom: 1vh;
      svg{
        margin-bottom: .7vh
      }
    }
  }

  table tbody tr.hasOne{
    .content{
      span{
        align-items: center;
      }
      button svg{
        margin: 0;
      }
    }
  }
  table tbody tr {
    height: 4vh;
    .content{
      display: flex;
      align-items: center;
      justify-content: center;
      height:100%;
      border-bottom: 1px solid #b3b3c294;
     
      span{
        display: flex;
        align-items: flex-end;
        font-family: Nunito;
        font-size: 0.8vw;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: 0px;
        
        height: 78%;
        /* background: lightblue; */
        color: #808099;
      }
      button{
        display: flex;
        align-items:center;
        height: 78%;
        svg.delete {
        width: 1.1vw;
        height: 1.1vw;
        
        color: ${props => props.theme.debts.default};
        }
        svg.edit {
        width: 1.1vw;
        height: 1.1vw;
        color: ${props => props.theme.receipts.default};
        }
      }
    }
  }
  table tbody{
    box-shadow: 0 0 0 1px ${props => props.theme.grafit.default};
    border-radius: 10px;
    background: ${props => props.theme.white.disabled}
  }

  table tbody tr:last-child{
    .content{
      border-bottom: unset;
    }
  }

  table thead th:first-child{
    padding-left: 5%;
    div.content-th{
      text-align: initial;
    }

  }
  table thead th:last-child{
    padding-right: 5%;
  }

  table tbody td:first-child{
    padding-left: 5%;
    div.content{
      justify-content: initial !important;
    }

  }
  table tbody td:last-child{
    padding-right: 5%;
  }
`;
