import React, { useState, useEffect, useContext, useCallback } from 'react';

import { ThemeContext } from 'styled-components';

import {
  AiOutlinePlus,
  AiOutlineMinus,
  AiOutlineDown,
  AiOutlineUp,
} from 'react-icons/ai';

import { useSelector } from 'react-redux';

import {
  Container,
  InvestGroup,
  Header,
  DivOptions,
  ButtonDropdown,
  DivMoreActives,
  Title,
  DivTableOperation,
  MessageInitial,
} from './styles';

import icon_investments from 'assets/icon_investments.svg';
import icon_withoutOperations from 'assets/investments/without_operations.svg';

import Card from 'components/Cards';
import Button from 'components/Button';
import Loading from 'components/Loading';

import Table from './Table';
import ProgressBar from './ProgressBar';

import CreateModal from './Modals/CreateModal';
import DeleteModal from './Modals/DeleteModal';
import UpdateModal from './Modals/UpdateModal';

import api from 'services/api';

const default_types_operation = {
  asset: 'Ações',
  fii: 'FII',
  tp: 'Títulos Públicos',
  others: 'Outros',
};

export default function Investments() {
  const [operations, setOperations] = useState([]);

  const [assetsPrice, setAssetsPrice] = useState([]);
  const [typeOperations, setTypeOperations] = useState([]);
  const [symbolOperations, setSymbolOperations] = useState([]);

  const [idOpenTableOperation, setIdOpenTableOperation] = useState('');

  const [typeSelected, setTypeSelected] = useState('');
  const [selectedAsset, setSelectedAsset] = useState('');

  const [loading, setLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);

  const [dropdown, setDropdown] = useState(false);
  const [typeModal, setTypeModal] = useState('');
  const [showTableOperation, setShowTableOperation] = useState(false);

  const { id } = useSelector(state => state.user.profile);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    async function loadAssets() {
      setLoading(true);

      const { data: operations } = await api.get(`users/${id}/operations`);

      setOperations(operations);

      setLoading(false);
    }
    loadAssets();
  }, [id]);

  useEffect(() => {
    async function updateAssetsPrice() {
      setLoading(true);

      const symbolOps = [...new Set(operations.map(op => op.symbol))];

      setSymbolOperations(symbolOps);

      const typeAssets = [
        ...new Set(operations.map(operation => operation.type_asset)),
      ];

      setTypeOperations(typeAssets);

      const assetsArray = [
        ...new Set(operations.map(operation => operation.symbol)),
      ];

      const tempAssetsPrices = {};

      const promises = assetsArray.map(async asset => {
        const response = await api.get(`assets/${asset}/prices`);

        if (response.data.err) {
          tempAssetsPrices[asset] = { err: true };
        } else {
          tempAssetsPrices[asset] = response.data;
        }
      });

      await Promise.all(promises);

      setAssetsPrice(tempAssetsPrices);

      setLoading(false);
    }
    updateAssetsPrice();
  }, [operations]);

  const toCurrency = useCallback(value => {
    let result = value;

    if (typeof value === 'undefined' || isNaN(result)) {
      result = 0;
    }

    return result.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }, []);

  function filterOperationWhereSymbol(symbol) {
    return operations.filter(op => op.symbol === symbol);
  }

  function fillerOperation(type_operation) {
    const totalValueOperations = operations.reduce((total, currentValue) => {
      return total + currentValue.value;
    }, 0);

    const totalValueAsset = operations.reduce((total, currentValue) => {
      if (currentValue.type_asset !== type_operation) return total;
      return total + currentValue.value;
    }, 0);

    let percent = (totalValueAsset / totalValueOperations) * 100;

    if (percent > 100) {
      percent = 100;
    }

    return Math.round(percent);
  }

  function fillerOneOperation(operation) {
    const totalValueWhereTypeOperation = operations.reduce(
      (total, currentValue) => {
        if (currentValue.type_asset !== operation.type_asset) return total;
        return total + currentValue.value;
      },
      0
    );

    const totalValueOneOperation = operations.reduce((total, currentValue) => {
      if (currentValue.id !== operation.id) return total;
      return total + currentValue.value;
    }, 0);

    let percent = (totalValueOneOperation / totalValueWhereTypeOperation) * 100;

    if (percent > 100) {
      percent = 100;
    }

    return Math.round(percent);
  }

  function removeDuplicateOperations() {
    return symbolOperations.map(symbol =>
      operations.find(op => op.symbol === symbol)
    );
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <Header>
            <span>Meus Investimentos</span>
            <DivOptions>
              <img src={icon_investments} alt="Icon" />
              <div className="options">
                <span>
                  Lembre-se! Depois de investir você precisa selar cada
                  <span> Investimento </span>
                  nos seus <span> Sonhos.</span>
                </span>
                <div className="buttons">
                  <Button
                    color="receipts"
                    onClick={() => {
                      setShowModal(true);
                      setTypeModal('sell');
                    }}
                  >
                    Vender <AiOutlineMinus />
                  </Button>
                  <Button
                    color="investments"
                    onClick={() => {
                      setShowModal(true);
                      setTypeModal('buy');
                    }}
                  >
                    Comprar <AiOutlinePlus />
                  </Button>
                </div>
              </div>
            </DivOptions>
          </Header>
          {operations.length === 0 ? (
            <MessageInitial>
              <img src={icon_withoutOperations} alt="Ícone de investimentos" />
              Inclua novas operações
            </MessageInitial>
          ) : (
            <Card width="100%" height="auto">
              {typeOperations.map(typeOp => (
                <InvestGroup key={typeOp}>
                  <Title fontSize={32} marginBottom={12}>
                    {default_types_operation[typeOp]}
                  </Title>
                  <div className="main">
                    <ProgressBar
                      color={theme.investments.default}
                      filler={fillerOperation(typeOp)}
                    />
                    <ButtonDropdown
                      onClick={() => {
                        setDropdown(!dropdown);
                        setTypeSelected(typeOp);
                      }}
                      active={dropdown && typeSelected === typeOp}
                    >
                      {dropdown && typeSelected === typeOp ? (
                        <AiOutlineUp />
                      ) : (
                        <AiOutlineDown />
                      )}
                    </ButtonDropdown>
                  </div>
                  <DivMoreActives visible={dropdown && typeSelected === typeOp}>
                    {removeDuplicateOperations()
                      .filter(op => op?.type_asset === typeOp)
                      .map(operation => (
                        <React.Fragment key={operation.id}>
                          <div className="quotation">
                            <div className="line">
                              <Title asSubTitle>{operation.symbol}</Title>
                              <div className="progress-bar">
                                <ProgressBar
                                  height={52}
                                  color={theme.investments.hover}
                                  filler={fillerOneOperation(operation)}
                                />
                                <ButtonDropdown
                                  onClick={() => {
                                    setShowTableOperation(!showTableOperation);
                                    setIdOpenTableOperation(operation.id);
                                  }}
                                  customHeight
                                  active={
                                    showTableOperation &&
                                    idOpenTableOperation === operation.id &&
                                    dropdown
                                  }
                                >
                                  {showTableOperation ? (
                                    <AiOutlineUp />
                                  ) : (
                                    <AiOutlineDown />
                                  )}
                                </ButtonDropdown>
                              </div>
                            </div>
                            <div className="information">
                              <div className="group">
                                <span className="title">Preço Médio</span>
                                <span className="value">
                                  {toCurrency(
                                    (assetsPrice[operation.symbol]?.high +
                                      assetsPrice[operation.symbol]?.low) /
                                      2
                                  )}
                                </span>
                              </div>
                              <div className="group">
                                <span className="title">Valor atual</span>
                                <span className="value">
                                  {toCurrency(
                                    assetsPrice[operation.symbol]
                                      ?.previous_close
                                  )}
                                </span>
                              </div>
                              <div className="group">
                                <span className="title">
                                  Rentabilidade Total
                                </span>
                                <span className="value">R$ -</span>
                              </div>
                              <div className="options">
                                <button>
                                  <AiOutlineMinus />
                                </button>
                                <button>
                                  <AiOutlinePlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          <DivTableOperation
                            showTable={
                              showTableOperation &&
                              idOpenTableOperation === operation.id
                            }
                          >
                            <Table
                              body={filterOperationWhereSymbol(
                                operation.symbol
                              )}
                              setShowModalDelete={setShowModalDelete}
                              setSelectedAsset={setSelectedAsset}
                              setShowModalUpdate={setShowModalUpdate}
                            />
                          </DivTableOperation>
                        </React.Fragment>
                      ))}
                  </DivMoreActives>
                </InvestGroup>
              ))}
            </Card>
          )}
        </Container>
      )}
      {showModal && (
        <CreateModal
          typeModal={typeModal}
          setOperations={setOperations}
          setShowModal={setShowModal}
        />
      )}
      {showModalDelete && (
        <DeleteModal
          setShowModalDelete={setShowModalDelete}
          idSelectedAsset={selectedAsset.id}
          setOperations={setOperations}
        />
      )}
      {showModalUpdate && (
        <UpdateModal
          setShowModalUpdate={setShowModalUpdate}
          selectedAsset={selectedAsset}
          setOperations={setOperations}
        />
      )}
    </>
  );
}
