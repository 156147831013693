import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import { Form } from '../styles';

import { Wrapper } from '../../styles';

import Modal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';

import {AiOutlineEdit} from 'react-icons/ai';
import { CurrencyInput } from 'components/masks';

import api from 'services/api';

export default function UpdateModal({
  selectedPatrimony,
  setPassivePatrimony,
  calcDiscount,
  calcTotalValue,
  setShowModalUpdate,
}) {
  const [id] = useState(selectedPatrimony.id);
  const [name, setName] = useState(selectedPatrimony.name);
  const [type] = useState(selectedPatrimony.type);
  const [institution, setInstitution] = useState(selectedPatrimony.institution);
  const [installment, setInstallment] = useState(selectedPatrimony.installment);
  const [remainingInstallments, setRemainingInstallments] = useState(
    selectedPatrimony.remainingInstallments
  );
  const [rate, setRate] = useState(selectedPatrimony.rate);
  const [inCash, setInCash] = useState(selectedPatrimony.inCash);

  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);
      const { data } = await api.put(`/passive_patrimony/${id}`, {
        name,
        type,
        institution,
        installment,
        remainingInstallments,
        rate,
        inCash,
      });

      setPassivePatrimony(patrimonies =>
        patrimonies.map(patrimony => {
          if (patrimony.id !== id) return patrimony;
          return data;
        })
      );
      setLoading(false);
      toast.success('Editado com sucesso');
    } catch (err) {
      setLoading(false);
      toast.error('Falha ao editar passivo');
    }
    setShowModalUpdate(false);

    setName('');
    setInstitution('');
    setInstallment('');
    setRemainingInstallments('');
    setRate('');
    setInCash('');
  }

  return (
    <Wrapper>
      <Modal
        color="debts"
        setShowModal={setShowModalUpdate}
        title="Editar Passivo"
        icon={<AiOutlineEdit/>}
      >
        <Form onSubmit={handleSubmit}>
          <Input
            label="Nome do Passivo"
            width="100%"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <Input
            label="Instituição"
            width="100%"
            value={institution}
            onChange={e => setInstitution(e.target.value)}
          />

          <div className="divGroup">
            <Input
              label="Parcelas restantes"
              value={remainingInstallments}
              onChange={e => setRemainingInstallments(e.target.value)}
              type="number"
              min="0"
            />
            <CurrencyInput
              label="Taxa"
              suffix="% a.m."
              value={rate}
              onChange={setRate}
            />
          </div>

          <div className="divGroup">
            <CurrencyInput
              label="Valor da Parcela:"
              prefix="R$ "
              value={installment}
              onChange={cleanValue => setInstallment(cleanValue)}
            />
            <CurrencyInput
              label="Valor à vista:"
              prefix="R$ "
              value={inCash}
              onChange={cleanValue => setInCash(cleanValue)}
            />
          </div>

          <div className="divGroup">
            <Input
              label="Saldo Atual:"
              width="50%"
              value={calcTotalValue(remainingInstallments, installment)}
              disabled
              style={{'background' : 'rgb(233, 233, 233)'}}
            />
            <Input
              label="Desconto:"
              width="50%"
              value={calcDiscount(inCash, remainingInstallments, installment)}
              disabled
              style={{'background' : 'rgb(233, 233, 233)'}}
            />
          </div>

          <div className="buttons">
            <Button type="submit">
              {loading ? <FaSpinner calssName="spin" /> : 'Confirmar'}
            </Button>
          </div>
        </Form>
      </Modal>
    </Wrapper>
  );
}

UpdateModal.propTypes = {
  selectedPatrimony: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    institution: PropTypes.string,
    installment: PropTypes.number,
    remainingInstallments: PropTypes.number,
    rate: PropTypes.number,
    inCash: PropTypes.number,
  }).isRequired,
  setShowModalUpdate: PropTypes.func.isRequired,
  setPassivePatrimony: PropTypes.func.isRequired,
  calcDiscount: PropTypes.func.isRequired,
  calcTotalValue: PropTypes.func.isRequired,
};
