import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 95%;
  height: ${props => props.height}px;

  border-radius: 5px;

  background: ${props => props.theme.grafit.disabled};
`;

export const Filler = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: ${props => props.filler}%;
  height: 100%;

  border-radius: 5px;
  background: ${props => props.color};

  span {
    font-size: ${props => (props.height === 52 ? 24 : 60)}px;
    font-weight: 300;
    line-height: 1.37;
    letter-spacing: 0.03px;

    color: ${props => props.theme.white.default};

    margin-right: 5px;
  }
`;
