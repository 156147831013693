import styled, { css } from 'styled-components';
import OctagonImage from 'assets/octagon.png'
import BgPatrimonio from 'assets/bg-gestao-patrimonial.png'
import LogoAzulImage from 'assets/logo-azul.png'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170px;
  height: 100%;
  background: url(${BgPatrimonio});
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-print-color-adjust: exact;
  margin: 0 auto;

  ${({ minHeight }) =>
      minHeight !== undefined &&
      css`
        min-height: ${ minHeight + 'px'} ;
      `
  }

  ${({ minHeight }) =>
        minHeight === undefined &&
        css`
          min-height: auto;
        `
    }

  h1, h2, h3, h4, h5, h6, p, span {
    font-family: 'Poppins', sans-serif !important;
  }

`

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.padding !== undefined ? props.padding: 0};
  height: ${props => props.height !== undefined ? props.height : 'auto'};
`;

export const PurpleBar = styled.div`
  width: 378px;
  height: 40px;
  background: #5560ff;
`

export const Octagon = styled.div`
  width: 132px;
  height: 115px;
  background: url(${OctagonImage});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 28px;
`

export const Title = styled.h1`
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 800;
  line-height: 66px;
  margin-bottom: 0;
  padding: 0;
  margin-top: -4px;
`
export const Labels = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`

export const Label = styled.li`
  display: inline-flex;
  align-items: center;
  width: 50%;
  gap: 26px;
  height: 58px;

  span {
    font-size: 30px;
  }

  svg {
    width: 38px;
    height: auto;

    ${({ rotateSvg }) =>
      rotateSvg &&
      css`
        transform: rotate(22.5deg);
      `}

    path {
      color: #5560ff;
    }
  }
`
export const LogoAzul = styled.div`
  width: 60px;
  height: 54px;
  background: url(${LogoAzulImage});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 15px;
`

export const Footer = styled.div`
  width: 100%;
  height: 130px;
  margin-top: auto;
  border-top: solid 6px #2d2d2d;

  > div {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 23px;
    font-weight: 600;
  }
`
