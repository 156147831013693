import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 840px;
  height: 100%;
  max-height: calc(100vh - 230px);
  overflow-y: scroll;
`;

export const Table = styled.table`
  border: solid 2px ${props => props.theme.primary.hover};
  padding: 2px;
  border-collapse:separate;
  border-radius: 4px;

  thead th, tbody td {
    padding: 4px 8px;
    font-weight: 600;

    &.center {
      text-align: center;
    }
  }

  tbody tr:nth-child(odd) td {
    background: ${props => props.theme.receipts.disabled};
    color: ${props => props.theme.black.default};
  }

  tbody tr:nth-child(even) td {
    background: ${props => props.theme.grafit.disabled};
    color: ${props => props.theme.black.default};
  }

  thead {
    background: ${props => props.theme.primary.hover};
    color: ${props => props.theme.white.default};
  }
`

export const PerfilInvestidor = styled.h5`
  span {
    display: inline-block;
    background: ${props => props.theme.primary.hover};
    color: ${props => props.theme.white.default};
    padding: 4px 20px;
    border-radius: 4px;
    font-weight: bold;
  }
`
