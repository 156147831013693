import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

export default function ProgressBar({
  color,
  fill,
  bg,
  left,
  right,
  leftColor,
  fontSize,
  rightColor,
  width,
  marginTop,
  className,
}) {
  return (
    <Container
      fill={`${fill > 100 ? 100 : Math.floor(fill)}`}
      color={color}
      marginTop={marginTop}
      bg={bg}
      rightColor={rightColor}
      leftColor={leftColor}
      width={width}
      fontSize={fontSize}
      className={className}
    >
      <div className="info">
        <div className="left">
          <p>{left}</p>
        </div>
        <div className="right">
          <p>{right}</p>
        </div>
      </div>
      <div className="progress-bar">
        <div className="filler" />
      </div>
    </Container>
  );
}

ProgressBar.propTypes = {
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  fill: PropTypes.number,
  bg: PropTypes.string,
  width: PropTypes.string,
  leftColor: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rightColor: PropTypes.string,
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

ProgressBar.defaultProps = {
  marginTop: 0,
  color: 'receipts',
  fill: 50,
  bg: 'grafit',
  leftColor: 'grafit',
  fontSize: '20px',
  rightColor: 'grafit',
  left: undefined,
  right: undefined,
  width: '100%',
  className: '',
};
