import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Cards({
  notPadding,
  withRow,
  notShadow,
  children,
  active,
  width,
  height,
  transparent,
  className,
  id,
}) {
  return (
    <Container
      id={id}
      className={`${className} ${active ? 'active' : ''}`}
      width={width}
      height={height}
      transparent={transparent}
      notShadow={notShadow}
      withRow={withRow}
      notPadding={notPadding}
    >
      {children}
    </Container>
  );
}

Cards.propTypes = {
  notPadding: PropTypes.bool,
  withRow: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  notShadow: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.array,
    PropTypes.node,
  ]).isRequired,
  active: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  transparent: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
};

Cards.defaultProps = {
  notPadding: false,
  withRow: false,
  notShadow: false,
  active: false,
  width: '410px',
  height: null,
  transparent: false,
  className: '',
  id: '',
};
