import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { FaSpinner } from 'react-icons/fa';

import { MdModeEdit } from 'react-icons/md';

import { toast } from 'react-toastify';
import { Form, DivGroup } from './styles';

import Modal from 'components/Modal';
import Input from 'components/Input';
import { CurrencyInput } from 'components/masks';
import Button from 'components/Button';

import api from 'services/api';

export default function UpdateModal({
  selectedItem,
  setSelectedItem,
  setCategories,
  setShowModal,
}) {
  const [name, setName] = useState(selectedItem[0]);

  const [value, setValue] = useState(selectedItem[1].value.split('R$')[1]);

  const [loading, setLoading] = useState(false);

  const { id } = useSelector(state => state.user.profile);

  const { item_id, category_id } = selectedItem[1];

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      setLoading(true);

      const { data } = await api.put(`items/${item_id}`, {
        value,
      });

      const oldValue = selectedItem[1].value.split('R$')[1].trim();

      await api.post(`users/${id}/items/${item_id}/spendings_management`, {
        value,
        oldValue,
      });

      setCategories(categories =>
        categories.map(c => {
          if (c.id !== category_id) return c;
          return {
            ...c,
            items: c.items.map(i => {
              if (i.id !== item_id) return i;
              return {
                ...i,
                value: data.value,
              };
            }),
          };
        })
      );
      setValue('');
      setName('');
      setShowModal(false);
      setSelectedItem({});
      toast.success('Item editado com sucesso');
      setShowModal(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error('Falha ao editar item, verifique os dados e tente novamente');
    }
  }

  return (
    <Modal
      title="Editar Estimado"
      color="darker"
      setShowModal={setShowModal}
      icon={<MdModeEdit />}
      variant="hover"
    >
      <Form onSubmit={handleSubmit}>
        <Input
          label="Nome do item"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <CurrencyInput
          label="Valor estimado"
          prefix="R$ "
          value={value}
          onChange={cleanValue => setValue(cleanValue)}
        />
        <DivGroup>
          <Button type="submit" color="info">
            {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
          </Button>
        </DivGroup>
      </Form>
    </Modal>
  );
}

UpdateModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  setCategories: PropTypes.func.isRequired,
  selectedItem: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setSelectedItem: PropTypes.func.isRequired,
};
