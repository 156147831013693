import styled, { css } from 'styled-components';
import LogoAzulImage from 'assets/logo-azul.png'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170px;
  height: auto;
  background: #FFF;
  -webkit-print-color-adjust: exact;
  margin: 0 auto;

  ${({ minHeight }) =>
      minHeight !== undefined &&
      css`
        min-height: ${ minHeight + 'px'} ;
      `
  }

  ${({ minHeight }) =>
        minHeight === undefined &&
        css`
          min-height: auto;
        `
    }

  h1, h2, h3, h4, h5, h6, p, span {
    font-family: 'Poppins', sans-serif !important;
  }

`

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.padding !== undefined ? props.padding: 0};
  height: ${props => props.height !== undefined ? props.height : 'auto'};

  > div.half {
    width: 50%;
  }

  .header-title {
    height: auto;
    min-height: 80px;

    small {
      font-size: 12px !important;
      font-style: italic;
    }

    .p-smaller {
      font-size: 19px;
      line-height: 20px;
      font-weight: 600;
      margin-top: 58px;
    }
  }

  .half-content {
    height: 450px;
    min-height: 450px;

    &.flex-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .value {
        display: flex;
        justify-content: center;

        &.full {
          margin: 19px 37px;
          border-top: solid 2px #000;
          align-items: center;
          padding: 38px 0;

          span {
            &:not(:first-child) {
              font-size: 38px;
              font-weight: 600;

            }
            &:first-child {
              display: inline-block;
              width: auto;
              min-width: 195px;
              padding-left: 0px;
              line-height: 34px;
            }
          }
        }

        & > span {
          &:not(:first-child) {
            font-size: 30px;
            font-weight: 600;
          }

          &:first-child {
            position: relative;
            display: inline-block;
            width: auto;
            min-width: 195px;
            padding-left: 38px;
            line-height: 46px;

            &.ativo:before {
              content: '';
              position:absolute;
              top: 17px;
              left: 7px;
              width: 11px;
              height: 11px;
              border-radius: 11px;
              background: #5960ff;
            }

            &.passivo:before {
              content: '';
              position:absolute;
              top: 17px;
              left: 7px;
              width: 11px;
              height: 11px;
              border-radius: 11px;
              background: #ff0043;
            }
          }
        }
      }
    }
  }

  .full-content {
    height: 590px;
    width: 100%;
    border: solid 2px #EEE;
  }
`;

export const PurpleBar = styled.div`
  width: 378px;
  height: 40px;
  background: #5560ff;
`

export const Title = styled.h1`
  text-transform: uppercase;
  font-size: 58px;
  font-weight: 800;
  line-height: 66px;
  margin-bottom: 0;
  padding: 0;
  margin-top: -2px;
`

export const SubTitle = styled.h2`
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 0;
  padding: 0;

  &.bigger {
    font-size: 40px;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  sup {
    font-size: 20px;
  }
`

export const LogoAzul = styled.div`
  width: 60px;
  height: 54px;
  background: url(${LogoAzulImage});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 15px;
`

export const Footer = styled.div`
  width: 100%;
  height: 130px;
  margin-top: auto;
  border-top: solid 6px #2d2d2d;

  > div {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 23px;
    font-weight: 600;
  }
`
