import React, { useState } from 'react';
import { BsGraphUp } from 'react-icons/bs';
import Modal from '../../../../../components/Modal';
import ListModal from './ListModal';
import Edit from './Edit'
import Delete from './Delete'
import { Context } from './Context';

export default function Investments({ setShowInvestments }) {
    const [page, setPage] = useState('list');
    const [selectedItem, setSelectedItem] = useState({});
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const components = {
        list: {
            component: <ListModal />,
            width: '700px',
            title: "Investimentos"
        },
        edit: {
            component: <Edit />,
            width: '400px',
            title: "Editar investimento"
        },
        delete: {
            component: <Delete />,
            width: '400px',
            title: "Confirmar exclusão?"
        }
    }
    return (
        <Context.Provider
            value={{ setShowInvestments, selectedItem, setSelectedItem, setPage, dateStart, setDateStart, dateEnd, setDateEnd }}>
            <Modal width={components[page].width} setShowModal={setShowInvestments} color='investments' title={components[page].title} icon={<BsGraphUp />}>
                {components[page].component}
            </Modal>
        </Context.Provider >

    )
}