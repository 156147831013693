import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Input from 'components/Input';
import Button from 'components/Button';
import { CurrencyInput } from 'components/masks';
import { Form } from './styles';
import newApi from 'services/newApi';
import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';

export default function Investments() {
  const [dateStart, setDateStart] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const { id } = useSelector(state => state.user.profile);
  const { token } = useSelector(state => state.auth);
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const today = new Date(dateStart);
    const day = today.getDate();
    const nameMonth = format(today, 'LLLL', { locale: ptBR });

    try {
      const { data } = await newApi.post(`Auth/AutenticarCompativel`, {
        idUser: id,
        token,
      });

      await newApi.post(
        `Investimento/${id}`,
        {
          dateStart: new Date(dateStart).toISOString(),
          value,
          name: `Investimento do dia ${day} de ${nameMonth}`,
          type: 'financial-active',
          classification: null,
          sealable: true,
          liquidity: 'A definir',
          categorization: 'A definir',
        },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
      setValue(0);
      toast.success('Lançamento realizado com sucesso');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Houve um erro com o lançamento');
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <CurrencyInput
        label="Valor"
        prefix="R$ "
        value={value}
        onChange={cleanValue => setValue(cleanValue)}
      />

      <Input
        label="Data"
        type="date"
        value={dateStart}
        onChange={e => setDateStart(e.target.value)}
      />

      <div className="buttons">
        <Button type="submit" stripped color="info">
          {loading ? 'Carregando...' : 'Salvar transação'}
        </Button>
      </div>
    </Form>
  );
}
