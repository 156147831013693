import styled from 'styled-components';

export const Container = styled.div`
  input {
    width: 15px;
    height: 15px;
    border-radius: 5px;
    border: solid 2px #b3b3c2;
    background-color: #ffffff;
  }
`;
