import styled from 'styled-components';

export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  div.body {
    display: flex;
    background: #fff;
    border-radius: 10px;
    padding: 30px;
  }

  -webkit-box-shadow: 3px 3px 154px -40px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 154px -40px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 154px -40px rgba(0, 0, 0, 0.75);
`;

export const DivBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  max-height: 100%;
  min-height: 100%;
  min-width: 100%;
  background: rgba(167, 167, 167, 0.6);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
