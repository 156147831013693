import styled, { keyframes } from 'styled-components';

export const openInput = keyframes`
  0% {
    width:0%;
    opacity: 0;
  }
  100%{
    width: 100%;
    opacity: 1;

  }

`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  div.containers-date-picker {
    display: flex;
    flex-direction: row;
    width: 500px;
    justify-content: space-evenly;
    
    .date-picker {
    
      width: 150px;
      height: 40px;
      animation: ${openInput} forwards 500ms;
      svg {
        height: 1.5vw;
        width: 1.5vw;
      }
      span {
        font-size: 16px;
        margin-left: 5px
      }
    }
  }
`;
export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${props => props.theme.darker.disabled};
`;

export const Legend = styled.p`

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0px;
  text-align: left;
  color: #b3b3c2;
  &.all {
    float: right;
  }
`;


export const Actions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  gap: 10px;
  svg{
   
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.theme.investments.disabled};
    &.edit{
      fill: ${props => props.theme.investments.darker};
 
    }
    &.delete{
      fill: ${props => props.theme.debts.custom};

    }
  }
`;
export const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  animation: ${openInput} forwards 500ms;
  height: 50vh;
  /* background-color: pink; */
   
   
  
  overflow-y: scroll;
`;
export const Line = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  width: 90%;
  height: 46px;
  border-bottom: 1px solid ${props => props.theme.grafit.disabled};
`;

export const TextAssets = styled.p`
  width: 67px;
  height: 22px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0px;
  text-align: right;
  color:${props => props.theme.grafit.hover};
  margin-top: 12px;
  &.name {
    width: 44px;
    height: 22px;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0px;
    text-align: left;
    color:${props => props.theme.investments.hover};
  }
`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;

  background-color: ${props => props.theme.investments.default};
  margin-top: 10px;
  border-radius: 5px;
  /* width: 50%; */
  height: 50px;
  align-items: center;
  animation: ${openInput} forwards 500ms;
  span{
    font-size: 18px;
    font-weight: 800;
    
    color: ${props => props.theme.white.default};
  }
`;
