import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { Container, Header, Information, Title } from './styles';

import Card from 'components/Cards';

export default function CardBar({ data }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderWidth: 2,
      },
      scales: {
        y: {
          type: 'category',
          axis: 'x',
        },
      },
    },
    tooltips: {
      enabled: true,
      position: 'average',
      callbacks: {
        label: function(tooltipItem, data) {
          const title = data.datasets[tooltipItem.datasetIndex].label;
          return (
            title + ' - ' + tooltipItem.label + ' (' + tooltipItem.xLabel + '%)'
          );
        },
        title: function(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            return (sum += data);
          });
          let percentage = ((value * 100) / sum).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,0.6)',
        padding: 2,
        left: 50,
        font: {
          weight: 'bold',
        },
        align: 'end',
      },
    },
  };

  const labels = [
    'DO',
    'Até 30 dias',
    'De 1 à 6 meses',
    'De 6 à 12 meses',
    'De 12 à 24 meses',
    'Acima de 24 meses',
  ];

  const newData = {
    ...data,
    labels,
  };

  return (
    <Card className="card-grapich" width="100%" height="auto">
      <Container>
        <Header>
          <Title>Liquidez</Title>
        </Header>

        <Information>
          <HorizontalBar options={options} data={newData} />
        </Information>
      </Container>
    </Card>
  );
}
