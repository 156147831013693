import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  List, Line, Legend, TextAssets,
  Total, Actions
} from './styles';
import DatePicker from '../../DatePickerCustom/index';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import Loading from 'components/Loading';
import newApi from 'services/newApi';
import { Context } from '../Context';
import { format, startOfMonth, endOfMonth } from 'date-fns'
export default function ListModal() {

  const { token } = useSelector(state => state.auth);
  const { id } = useSelector(state => state.user.profile);
  const [investments, setInvestments] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const { setSelectedItem, setPage, dateStart, setDateStart, dateEnd, setDateEnd } = useContext(Context);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      const { data: response } = await newApi.post(`Auth/AutenticarCompativel`, {
        idUser: id,
        token
      });

      const { data } = await newApi.get(`Investimento/periodo/${id}`, {
        params: {
          Inicio: startOfMonth(dateStart),
          Fim: endOfMonth(dateEnd)
        }, headers: {
          'Authorization': `Bearer ${response.token}`
        }
      });
      setInvestments(data.investimentos)
      setTotal(data.total);
      setLoading(false);

    }
    fetchData();
  }, [dateStart, dateEnd, id, token])

  return (
    <Container>
      <div className="containers-date-picker">
        <div>
          <DatePicker
            selected={dateStart}
            onChange={date => setDateStart(date)}
            className="date-picker"
            locale="pt-br"
            dateFormat="MMMM"
            showMonthYearPicker
            text="De"
          />
        </div>
        <div>
          <DatePicker
            selected={dateEnd}
            onChange={date => setDateEnd(date)}
            className="date-picker"
            locale="pt-br"
            dateFormat="MMMM"
            showMonthYearPicker
            text="Até"
          />
        </div>
      </div>
      {
        loading ? <Loading /> :
          <>
            <Line className="header">
              <Legend>Data</Legend>
              <Legend>Valor</Legend>
              <Legend>Ações</Legend>
            </Line>
            <List>
              {investments?.map(investment => (
                <Line key={Math.random().toString()}>
                  <TextAssets className="name">{format(new Date(investment.data), 'dd/MM/yyyy')}</TextAssets>
                  <TextAssets>{investment.valor.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}</TextAssets>
                  <Actions>

                    <AiFillEdit className='edit' onClick={() => {
                      setSelectedItem(investment)
                      setPage('edit')
                    }} />
                    <AiFillDelete className='delete' onClick={() => {
                      setSelectedItem(investment)
                      setPage('delete')
                    }} />
                  </Actions>

                </Line>
              ))}

            </List>
            <Total>
              <span>
                Total
              </span>
              <span>
                {total.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </Total>
          </>
      }
    </Container>
  );
}
