/**
 * Opções de personalização usadas no gráficos - react-chartjs-2
 */

export function optionsBarDisplayOff(max) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    cornerRadius: 10,
    legend: {
      display: false,
    },
    tooltips: {
      showAllTooltips: false,
      enabled: false, // Desativa hover do canvas
      mode: 'index',
      bodySpacing: 12,
      bodyFontSize: 15,
    },
    plugins: {
      // Change options for ALL labels of THIS CHART
      datalabels: {
        color: 'rgba(0,0,0,0)'
      }
    },
    scales: {
      xAxes: [
        {
          display: false,
          stacked: true,
        },
      ],

      yAxes: [
        {
          display: false,
          stacked: true,
          ticks: {
            beginAtZero: true,
            max,
          },
        },
        {
          id: 'right',
          display: false,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            max,
          },
        },
      ],
    },
  };
}

export const optionsBar = {
  responsive: true,

  maintainAspectRatio: false,
  cornerRadius: 5,
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      label({ datasetIndex, yLabel }, { datasets }) {
        return `${datasets[datasetIndex].label}: ${yLabel.toLocaleString(
          'pt-br',
          {
            style: 'currency',
            currency: 'BRL',
          }
        )}`;
      },
    },
  },
  scales: {
    xAxes: [
      {
        // categoryPercentage: 0.9,
        // barPercentage: 0.7,
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback(value) {
            return value.toLocaleString('pt-BR');
          },
        },
      },
    ],
  },
};

export function optionsBarPatrimony(max, active, passive, liquid) {
  return {
    legendCallback: ({ legend }) => {
      legend.legendItems[0].text = `P. Ativo: ${active.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`;
      legend.legendItems[1].text = `P. Passivo: ${passive.toLocaleString(
        'pt-br',
        {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      )}`;
      legend.legendItems[2].text = `P. Líquido: ${liquid.toLocaleString(
        'pt-br',
        {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }
      )}`;
    },
    cornerRadius: 5,
    legend: {
      display: true,
    },
    responsive: true,
    tooltips: {
      showAllTooltips: false,
      enabled: false, // Desativa o hover do canvas
      mode: 'index',
      bodySpacing: 12,
      bodyFontSize: 15,
    },

    scales: {
      xAxes: [
        {
          display: true,
          stacked: true,
          // categoryPercentage: 0.9,
          // barPercentage: 0.7,
        },
        {
          id: 'left',
          display: false,
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            max,
          },
        },
      ],

      yAxes: [
        {
          display: false,
          // stacked: true,
          ticks: {
            max,
            beginAtZero: true,
          },
        },
        {
          id: 'right',
          display: false,
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            max,
          },
        },
        {
          id: 'left',
          display: true,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            max,
            callback(value) {
              return value.toLocaleString('pt-BR');
            },
          },
        },
      ],
    },
  };
}
