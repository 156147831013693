import React from 'react';
import PropTypes from 'prop-types';

import { Container, UpperContainer, TableContainer, DivTotalValue } from './styles';

export default function TotalTable({
  upperCenterContent,
  withPercentage,
  defaultFontSize,
  dontTitle,
  isCustomColor,
  color,
  title,
  head,
  body,
  subtitle,
  buttonIcons,
  total,
  className,
}) {

  return (
    <Container color={color}>
      <UpperContainer color={color} isCustomColor={isCustomColor} className="container-titles">
        <div className="titles">
          {dontTitle && <h2>{title}</h2>}
          {subtitle && <h3>{subtitle}</h3>}
        </div>
        {upperCenterContent && <div>{upperCenterContent}</div>}
        <div className="icons">{buttonIcons.map(buttonIcon => buttonIcon)}</div>
      </UpperContainer>
      <TableContainer
        color={color}
        isCustomColor={isCustomColor}
        defaultFontSize={defaultFontSize}
      >
        <table className={className}>
          <thead>
            <tr>
              {head.map(content => (
                <th key={Math.random()}>{content}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {body.map(row => (
              <tr key={Math.random()}>
                {row.map(column => {
                  if (
                    typeof column === 'object' &&
                    column !== null &&
                    Object.keys(column).length === 2 &&
                    column.currency !== undefined &&
                    column.currency !== null
                  ) {
                    return (
                      <td key={Math.random()}>
                        <div className="valueCurrency">
                          <div className="currency">{column.currency}</div>
                          <div className="value">{column.value}</div>
                        </div>
                      </td>
                    );
                  }
                  return <td key={Math.random()}>{column}</td>;
                })}
              </tr>
            ))}
          </tbody>
        </table>
        {total !== 0 && (
          <div className="total">
            <div className={color + " labels"}  >
              <span>Total</span>
              {withPercentage && <span className="percent">Percentual</span>}
            </div>
            <DivTotalValue type={
              {
                color: color,
                title: title
              }
            }
              className={`values ${window.location.pathname.substring(10, 20)}`}>
              <span>
                {total.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </span>
              {withPercentage && (
                <span className="percent">{withPercentage}%</span>
              )}
            </DivTotalValue>
          </div>
        )}
      </TableContainer>
    </Container>
  );
}

TotalTable.propTypes = {
  upperCenterContent: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  withPercentage: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  defaultFontSize: PropTypes.bool,
  dontTitle: PropTypes.bool,
  isCustomColor: PropTypes.bool,
  color: PropTypes.string,
  title: PropTypes.string,
  head: PropTypes.arrayOf(PropTypes.string),
  body: PropTypes.arrayOf(PropTypes.array),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  buttonIcons: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.node,
      PropTypes.func,
    ])
  ),
  total: PropTypes.number,
};

TotalTable.defaultProps = {
  upperCenterContent: false,
  withPercentage: false,
  defaultFontSize: false,
  dontTitle: true,
  isCustomColor: false,
  color: 'receipts',
  title: 'Título',
  head: ['head', 'head', 'head'],
  body: [['linha', 'linha', 'linha']],
  subtitle: false,
  buttonIcons: [],
  total: 0,
};
