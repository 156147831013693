import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { optionsBarDisplayOff } from 'utils/optionsBar';
import newApi from 'services/newApi';
import Modal from '../../../../../components/Modal';
import { ThemeContext } from 'styled-components';
import Dropdown from 'components/Input/Dropdown';
import format from 'date-fns/format';
import {
  CardContent,
  Body,
  AreaDetail,
  DivDetail,
  DivChart,
  DivTotal,
} from '../styles';
import Loading from 'components/Loading';

export default function List({ setShowPots }) {
  const { id: userId } = useSelector(state => state.user.profile);
  const { token } = useSelector(state => state.auth);
  const theme = useContext(ThemeContext);
  const [selected, setSelected] = useState([]);
  const [balance, setBalance] = useState(0);
  const [pots, setPots] = useState([]);
  const [dates, setDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [pot, setPot] = useState({
    investments: 0,
    debts: 0,
    eventualFlexible: 0,
    committedMonthly: 0,
    monthlyFlexible: 0,
    pec: 0,
    receipts: 0,
  });

  useEffect(() => {
    async function fetchPots() {
      setLoading(true);

      try {
        const { data } = await newApi.post('/Auth/AutenticarCompativel', {
          idUser: userId,
          token: token,
        });

        const { data: pots } = await newApi.get(`/Orcamento/pote/${userId}`, {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        });

        setPots(pots);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
    fetchPots();
  }, [token, userId]);

  useEffect(() => {
    const existPots = pots.length;
    if (existPots) {
      const listDates = pots.map(pot => ({
        value: pot.dataCriacao,
        label: format(new Date(pot.dataCriacao), 'dd/MM/yyyy'),
      }));
      setDates(listDates);
      setSelectedDate(listDates[0]);
    }
  }, [setPots, pots]);

  useEffect(() => {
    const result = pots.find(pot => pot.dataCriacao === selectedDate.value);
    if (result) {
      const difference =
        result.recebimento -
        (result.comprasParceladas +
          result.divida +
          result.eventualFlexivel +
          result.investimento +
          result.mensalComprometido +
          result.mensalFlexivel +
          result.pec);
      setBalance(difference);

      setPot({
        installments: 0,
        debts: result.divida,
        eventualFlexible: result.eventualFlexivel,
        investments: result.investimento,
        committedMonthly: result.mensalComprometido,
        monthlyFlexible: result.mensalFlexivel,
        pec: result.pec,
        receipts: result.recebimento,
      });
      setSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  function onOff(label) {
    let index;
    if (selected.includes(label)) {
      index = selected.indexOf(label);

      setSelected(selected.filter((_, i) => i !== index));

      switch (label) {
        case 'PGE-C':
          setBalance(balance - pot.pec);
          break;

        case 'Recebimentos':
          setBalance(balance + pot.receipts);
          break;

        case 'Investimentos':
          setBalance(balance - pot.investments);
          break;

        case 'Eventuais Flexíveis':
          setBalance(balance - pot.eventualFlexible);
          break;

        case 'Mensais Flexíveis':
          setBalance(balance - pot.monthlyFlexible);
          break;

        case 'Mensais Comprometidos':
          setBalance(balance - pot.committedMonthly);
          break;

        case 'Dívidas':
          setBalance(balance - pot.debts);
          break;

        default:
          break;
      }
    } else {
      setSelected([...selected, label]);

      switch (label) {
        case 'PGE-C':
          setBalance(balance + pot.pec);
          break;

        case 'Recebimentos':
          setBalance(balance - pot.receipts);
          break;

        case 'Investimentos':
          setBalance(balance + pot.investments);
          break;

        case 'Eventuais Flexíveis':
          setBalance(balance + pot.eventualFlexible);
          break;

        case 'Mensais Flexíveis':
          setBalance(balance + pot.monthlyFlexible);
          break;

        case 'Mensais Comprometidos':
          setBalance(balance + pot.committedMonthly);
          break;

        case 'Dívidas':
          setBalance(balance + pot.debts);
          break;
        default:
          break;
      }
    }
  }

  const dataChart = {
    labels: [''],
    datasets: [
      {
        label: 'Receitas',
        data: [selected.includes('Recebimentos') ? 0 : pot.receipts],
        backgroundColor: 'transparent',
        borderColor: theme.receipts.default,
        borderSkipped: 'none',
        borderWidth: 3,
        yAxisID: 'right',
      },
      {
        label: 'Dívidas',
        data: [selected.includes('Dívidas') ? 0 : pot.debts],
        backgroundColor: theme.debts.default,
      },
      {
        label: 'Patrimônio pássivo',
        data: [0],
        backgroundColor: theme.debts.hover,
        borderWidth: 0,
      },
      {
        label: 'Gastos mensais comprometidos',
        data: [
          selected.includes('Mensais Comprometidos') ? 0 : pot.committedMonthly,
        ],
        backgroundColor: theme.spendings.hover,
        borderWidth: 0,
      },
      {
        label: 'Gastos mensais flexíveis',
        data: [
          selected.includes('Mensais Flexíveis') ? 0 : pot.monthlyFlexible,
        ],
        backgroundColor: theme.spendings.default,
        borderWidth: 0,
      },
      {
        label: 'Gastos eventuais flexíveis',
        data: [
          selected.includes('Eventuais Flexíveis') ? 0 : pot.eventualFlexible,
        ],
        backgroundColor: theme.spendings.custom,
        borderWidth: 0,
      },
      {
        label: 'PGE-C',
        data: [selected.includes('PGE-C') ? 0 : pot.pec],
        backgroundColor: theme.pgec.default,
        borderWidth: 0,
      },
      {
        label: 'Investimentos',
        data: [selected.includes('Investimentos') ? 0 : pot.investments],
        backgroundColor: theme.investments.default,
        borderWidth: 0,
      },
    ],
  };

  const Content = () => (
    <CardContent>
      <Dropdown
        label="Potes registrados"
        placeholder="Data..."
        width="200px"
        options={dates}
        value={selectedDate}
        onChange={select => setSelectedDate(select)}
      />
      <Body className="modal-pot">
        <AreaDetail>
          {/* Recebimentos */}
          <DivDetail
            color={theme.receipts.default}
            onClick={() => onOff('Recebimentos')}
          >
            <div className="labels">
              <div className="circle" />
              <span
                className={selected.includes('Recebimentos') ? 'selected' : ''}
              >
                Recebimentos
              </span>
            </div>
            <div className="values">
              <span>
                {selected.includes('Recebimentos')
                  ? Number(0).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : pot.receipts.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
              </span>
            </div>
          </DivDetail>
          {/* Investimentos */}
          <DivDetail
            color={theme.investments.default}
            onClick={() => onOff('Investimentos')}
          >
            <div className="labels">
              <div className="circle" />
              <span
                className={selected.includes('Investimentos') ? 'selected' : ''}
              >
                Investimentos
              </span>
            </div>
            <div className="values">
              <span>
                {selected.includes('Investimentos')
                  ? Number(0).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : pot.investments.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
              </span>
            </div>
          </DivDetail>
          {/* PGE-C */}
          <DivDetail color={theme.pgec.default} onClick={() => onOff('PGE-C')}>
            <div className="labels">
              <div className="circle" />
              <span className={selected.includes('PGE-C') ? 'selected' : ''}>
                PEC
              </span>
            </div>
            <div className="values">
              <span>
                {selected.includes('PGE-C')
                  ? Number(0).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : pot.pec.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
              </span>
            </div>
          </DivDetail>
          {/* Eventual Flexível */}
          <DivDetail
            color={theme.spendings.custom}
            onClick={() => onOff('Eventuais Flexíveis')}
          >
            <div className="labels">
              <div className="circle" />
              <span
                className={
                  selected.includes('Eventuais Flexíveis') ? 'selected' : ''
                }
              >
                Eventuais Flexíveis
              </span>
            </div>
            <div className="values">
              <span>
                {selected.includes('Eventuais Flexíveis')
                  ? Number(0).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : pot.eventualFlexible.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
              </span>
            </div>
          </DivDetail>
          {/* Mensal Flexível */}
          <DivDetail
            color={theme.spendings.default}
            onClick={() => onOff('Mensais Flexíveis')}
          >
            <div className="labels">
              <div className="circle" />
              <span
                className={
                  selected.includes('Mensais Flexíveis') ? 'selected' : ''
                }
              >
                Mensais Flexíveis
              </span>
            </div>
            <div className="values">
              <span>
                {selected.includes('Mensais Flexíveis')
                  ? Number(0).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : pot.monthlyFlexible.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
              </span>
            </div>
          </DivDetail>
          {/* Mensal Comprometido */}
          <DivDetail
            color={theme.spendings.hover}
            onClick={() => onOff('Mensais Comprometidos')}
          >
            <div className="labels">
              <div className="circle" />
              <span
                className={
                  selected.includes('Mensais Comprometidos') ? 'selected' : ''
                }
              >
                Mensais Comprometidos
              </span>
            </div>
            <div className="values">
              <span>
                {selected.includes('Mensais Comprometidos')
                  ? Number(0).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : pot.committedMonthly.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
              </span>
            </div>
          </DivDetail>
          {/* Compras Parceladas */}
          <DivDetail color={theme.debts.hover}>
            <div className="labels">
              <div className="circle" />
              <span className={'selected'}>Compras Parceladas</span>
            </div>
            <div className="values">
              <span>R$ 0</span>
            </div>
          </DivDetail>
          {/* Dívidas */}
          <DivDetail
            color={theme.debts.default}
            onClick={() => onOff('Dívidas')}
          >
            <div className="labels">
              <div className="circle" />
              <span className={selected.includes('Dívidas') ? 'selected' : ''}>
                Dívidas
              </span>
            </div>
            <div className="values">
              <span>
                {selected.includes('Dívidas')
                  ? Number(0).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : pot.debts.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
              </span>
            </div>
          </DivDetail>
          <DivTotal>
            <span className="total">Saldo</span>
            <span className="value">
              {balance?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
          </DivTotal>
        </AreaDetail>
        <DivChart>
          <Bar
            data={dataChart}
            options={optionsBarDisplayOff(
              Math.abs(pot.receipts) + Math.abs(balance)
            )}
          />
        </DivChart>
      </Body>
    </CardContent>
  );

  return (
    <Modal
      title="Histórico"
      color="investments"
      setShowModal={setShowPots}
      width="60vw"
      height="80vh"
    >
      {loading ? <Loading /> : <Content />}
    </Modal>
  );
}
