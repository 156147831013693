const light = {
  white: {
    default: '#fff',
    disabled: '#F1F1FF',
  },
  primary: {
    hover: '#00c791',
    default: '#00f9b5',
    disabled: '#ccfff2',
  },
  secondary: {
    hover: '#000033',
    default: '#4545e6',
    disabled: ' #ccccff',
  },
  info: {
    hover: '#236499',
    default: '#3ba7ff',
    disabled: '#bae0ff',
    custom: '#77869E',
  },
  success: {
    hover: '#2a8038',
    default: '#4be364',
    disabled: '#ccffd4',
  },
  warning: {
    hover: '#d9bc2b',
    default: '#f6cd00',
    disabled: '#fffbcc',
  },
  danger: {
    hover: '#ab1136',
    default: '#f7194e',
    disabled: '#ffccd8',
  },
  grafit: {
    hover: '#808099',
    default: '#b3b3c2',
    disabled: '#f0f1f7',
    custom: '#e6e6eb',
  },
  gray: {
    hover: '#E2E2E2',
    default: '#8C8B8C',
    disabled: '#f0f1f7',
    custom: '#282728',
  },
  black: {
    default: '#111111',
  },
  receipts: {
    hover: '#3b288f',
    default: '#6342ee',
    disabled: '#efecfd',
    custom: '#b1a1f7',

    layout_light: '#927bf3',
    layout_dark: '#6342ee',
    darker: '#3b288f',
  },
  spendings: {
    hover: '#0f7acc',
    default: '#1399ff',
    disabled: '#e7f5ff',
    custom: '#89ccff',

    layout_light: '#1399ff',
    layout_dark: '#0f7acc',
    darker: '#33335c',
  },
  debts: {
    hover: '#c4143e',
    default: '#f7192f',
    disabled: '#ffccd8',
    custom: '#ff809e',

    layout_light: '#f7194e',
    layout_dark: '#c4143e',
    darker: '#991030',
  },
  investments: {
    hover: '#00c791',
    default: '#1de9b6',
    disabled: '#e8fdf8',
    custom: '#e6fef8',

    layout_light: '#00ffbf',
    layout_dark: '#19cc9f',
    darker: '#17ba92',
  },
  darker: {
    hover: '#000033',
    default: '#1a1a47',
    disabled: '#4d4d70',
    custom: '#33335c',
  },
  blueDarker: {
    hover: '#000033',
    default: '#140361',
    disabled: '#4d4d70',
    custom: '#33335c',
  },
  layout: {
    layout_light: '#7386FF',
    layout_dark: '#140361',
    default: "#1C11A7",
    disabled: '#5960FF',
    custom: '#4739EB',
  },
  newLayout: {
    default: "#140361",
    open: '#25156c',
    disabled: '#5960FF',
    custom: '#4739EB',
  },
  blue: {
    default: "#4739EB",
    disabled: '#5960FF',
    custom: '#4739EB',
  },
  pgec: {
    default: '#17ba92',
  },
  eventualFlexible: {
    default: '#45edc3', // cor no zeplin -> #8ef4db
  },
};

export default light;
