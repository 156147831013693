import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { ContentModal, DivGroup, Form } from './styles';
import { Context } from '../Context';
import api from 'services/api';
import dateUTC from 'utils/dateUTC';
import Modal from 'components/Modal';
import Input from 'components/Input';
import Select from 'components/Input/Dropdown';
import Button from 'components/Button';
import { CurrencyInput } from 'components/masks';
import Loading from 'components/Loading';
import { monthlyPayments } from 'utils/installments';

export default function ModalUpdate({ setReloadCategory, setReloadItem }) {
  const {
    toggleButton,
    setEntries,
    selectEntry,
    setModalUpdate,
    setSelectEntry,
    setAllEntries,
  } = useContext(Context);

  const [categories, setCategories] = useState([]);
  const [selectCategory, setSelectCategory] = useState({});
  const [selectItem, setSelectItem] = useState({});
  const [start, setStart] = useState(
    format(new Date(selectEntry?.start), 'yyyy-MM-dd')
  );
  const [end, setEnd] = useState(
    format(new Date(selectEntry?.end), 'yyyy-MM-dd')
  );
  const [description, setDescription] = useState(
    selectEntry?.description || selectEntry?.name
  );
  const [recurrent, setRecurrent] = useState(selectEntry?.recurrent);
  const [value, setValue] = useState(selectEntry?.value);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState();
  const { id } = useSelector(state => state.user.profile);
  const [currentInstallment, setCurrentInstallment] = useState(
    selectEntry.processedFrontendValue
      ? selectEntry.installment
      : monthlyPayments(selectEntry).currentInstallment
  );
  const [totalInstallments, setTotalInstallments] = useState(
    monthlyPayments(selectEntry).totalInstallments
  );

  //buscar os itens da categoria default
  useEffect(() => {
    async function fetchItems() {
      try {
        setLoading(true);
        const { data: itemsApi } = await api.get(
          `items/category/${selectCategory.id}`
        );
        itemsApi.forEach(item => {
          if (item.id === selectEntry.item_id)
            return setSelectItem({
              value: item?.name,
              label: item?.name,
              id: item?.id,
            });
        });
        setItems(
          itemsApi.map(item => ({
            value: item?.name,
            label: item?.name,
            id: item?.id,
          }))
        );

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    if (selectCategory.id) fetchItems();
  }, [selectCategory, selectEntry.item_id]);

  useEffect(() => {
    async function fetchCategories() {
      setLoading(true);

      try {
        const { data: dataCategory } = await api.get(
          `/users/${id}/categories`,
          {
            params: {
              type: toggleButton,
              items: false,
            },
          }
        );

        setCategories(
          dataCategory.map(category => ({
            value: category?.name,
            label: category?.name,
            id: category?.id,
            ...category,
          }))
        );
        dataCategory.forEach(category => {
          if (category.id === selectEntry.category_id)
            return setSelectCategory({
              value: category?.name,
              label: category?.name,
              id: category?.id,
              ...category,
            });
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);

        toast.error('Falha ao carregar lançamentos');
      }
    }
    fetchCategories();
  }, [id, toggleButton, selectEntry.category_id]);

  async function handleSubmit(e) {
    try {
      setLoading(true);
      e.preventDefault();
      setReloadCategory(true);
      setReloadItem(true);
      if (selectEntry.payment === 'Parcelado') {
        const { data } = await api.put(`/installments/${selectEntry?.id}`, {
          name: description,
          value,
          category_id: selectCategory.id,
          item_id: selectItem.id,
          currentInstallment,
          totalInstallments,
        });
        setEntries(entries =>
          entries?.map(entry => {
            if (entry.id !== selectEntry?.id) return entry;
            return data;
          })
        );

        setAllEntries(entries =>
          entries?.map(entry => {
            if (entry.id !== selectEntry?.id) return entry;
            return data;
          })
        );
      } else {
        const { data } = await api.put(`/entries/${selectEntry?.id}`, {
          item_id: selectItem.id,
          description,
          start: dateUTC(start),
          end: dateUTC(end),
          value,
          recurrent,
        });
        setEntries(entries =>
          entries?.map(entry => {
            if (entry.id !== selectEntry?.id) return entry;
            return data;
          })
        );

        setAllEntries(entries =>
          entries?.map(entry => {
            if (entry.id !== selectEntry?.id) return entry;
            return data;
          })
        );
      }

      setStart('');
      setEnd('');
      setDescription('');
      setRecurrent('');
      setValue('');
      setModalUpdate(false);
      setSelectEntry({});
      setReloadCategory(false);
      setReloadItem(true);
      toast.success('Editado com sucesso');
      return setLoading(false);
    } catch (err) {
      toast.error('Falha ao editar item');
      setReloadCategory(false);
      setReloadItem(true);
      return setLoading(false);
    }
  }

  return (
    <Modal
      color="spendings"
      setShowModal={setModalUpdate}
      title="Editar item"
      icon={null}
    >
      {loading ? (
        <Loading />
      ) : (
        <Form onSubmit={handleSubmit}>
          <ContentModal>
            <Select
              label="Nome da categoria"
              options={categories}
              defaultValue={selectCategory}
              onChange={selected => setSelectCategory(selected)}
            />
            {items ? (
              <Select
                label="Nome do item"
                options={items}
                defaultValue={selectItem}
                onChange={selected => setSelectItem(selected)}
              />
            ) : (
              <></>
            )}
            {selectEntry.payment !== 'Parcelado' ? (
              <Input
                label="Data de inicio"
                type="date"
                value={start}
                onChange={e => {
                  if (!recurrent) {
                    setEnd(e.target.value);
                  }
                  setStart(e.target.value);
                }}
              />
            ) : (
              <></>
            )}

            <Input
              label="O que é?"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
            <DivGroup>
              {selectEntry.payment !== 'Parcelado' ? (
                <div className="radio-buttons">
                  <label htmlFor="">É recorrente?</label>
                  {recurrent === undefined
                    ? selectEntry.payment === 'Recorrente'
                      ? setRecurrent(true)
                      : setRecurrent(false)
                    : ''}

                  <div>
                    <label htmlFor="true">Sim</label>
                    <input
                      type="radio"
                      name="recurrent"
                      id="true"
                      checked={recurrent}
                      onChange={() => {
                        setRecurrent(true);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="false">Não</label>
                    <input
                      type="radio"
                      name="recurrent"
                      id="false"
                      checked={!recurrent}
                      onChange={() => {
                        setEnd(start);
                        setRecurrent(false);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <Input
                    label="Parcela atual"
                    type="number"
                    width="45%"
                    value={currentInstallment}
                    onChange={e => setCurrentInstallment(e.target.value)}
                  />
                  <Input
                    label="Total de parcelas"
                    type="number"
                    width="45%"
                    value={totalInstallments}
                    onChange={e => setTotalInstallments(e.target.value)}
                  />
                </>
              )}
              {recurrent && (
                <Input
                  label="Data de término"
                  type="date"
                  width="45%"
                  value={end}
                  onChange={e => setEnd(e.target.value)}
                />
              )}
            </DivGroup>
            <CurrencyInput
              label="Valor"
              prefix="R$ "
              value={value}
              onChange={cleanValue => setValue(cleanValue)}
            />

            <div className="buttons">
              <Button type="submit" color="info">
                Confirmar
              </Button>
            </div>
          </ContentModal>
        </Form>
      )}
    </Modal>
  );
}
