import styled, { css } from 'styled-components';
import BgPatrimonio from 'assets/bg-gestao-patrimonialflip.png'

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 1170px;
  height: 100%;
  background: url(${BgPatrimonio});
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-print-color-adjust: exact;
  margin: 0 auto;

  ${({ minHeight }) =>
      minHeight !== undefined &&
      css`
        min-height: ${ minHeight + 'px'} ;
      `
  }

  ${({ minHeight }) =>
        minHeight === undefined &&
        css`
          min-height: auto;
        `
    }

  h1, h2, h3, h4, h5, h6, p, span {
    font-family: 'Poppins', sans-serif !important;
  }

`

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.padding !== undefined ? props.padding: 0};
  height: ${props => props.height !== undefined ? props.height : 'auto'};
`;

export const Title = styled.h1`
  position: relative;
  text-align: right;
  text-transform: uppercase;
  font-size: 86px;
  font-weight: 800;
  line-height: 90px;
  margin-bottom: 20px;
  padding: 0;
  margin-top: 460px;
  color: #5560ff;

  span {
    display: block;
    font-size: 58px;
    font-weight: 600;
  }

  &:after {
    position: absolute;
    content: '';
    width: 156px;
    height: 9px;
    top: 332px;
    right: 1px;
    background: #000000;
  }
`

export const ClientName = styled.div`
  position: absolute;
  top: 900px;
  right: 76px;

  h2 {
    color: #FFFFFF;
    font-size: 37px;
    font-weight: 600;
  }
`
