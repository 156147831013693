import React from 'react';

// import { Container, Title, Cards, Menu } from './styles';
// import CardMenu from './Cards/CardMenu';

import UnderConstruction from 'components/UnderConstruction';

// import EarningsMonth from './Grapichs/ EarningsMonth/index';
// import EarningsAsset from './Grapichs/EarningsAsset';
// import EarningsHistory from './Grapichs/EarningsHistory';

export default function Extract() {
  return (
    <UnderConstruction />
    // <Container>
    //   <Menu>
    //     <Title>Relatório de Proventos</Title>
    //     <Cards>
    //       <CardMenu type="Total Recebidos" />
    //       <CardMenu type="Rendimento de Dividendos" />
    //       <CardMenu type="Média de Proventos Mensais" />
    //     </Cards>
    //   </Menu>

    //   <EarningsMonth />
    //   <EarningsAsset />
    //   <EarningsHistory />
    // </Container>
  );
}
