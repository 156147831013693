import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'styled-components';

import { Link } from 'react-router-dom';

import { Bar } from 'react-chartjs-2';

import { optionsBarDisplayOff } from 'utils/optionsBar';

import { ContentChart, Header } from '../styles';

import { Context } from '../../ContextChart';

export default function ChartEstimated({ max }) {
  const theme = useContext(ThemeContext);

  const {
    receipts,
    passivePatrimonies,
    // installments,
    spendings,
    investments,
  } = useContext(Context);

  const dataChart = {
    labels: [''],
    datasets: [
      {
        label: 'Receipts',
        data: [receipts?.estimated],
        backgroundColor: 'transparent',
        borderColor: theme.receipts.default,
        borderSkipped: 'none',
        borderWidth: 2.5,
        yAxisID: 'right',
      },
      {
        label: 'Debts',
        data: [passivePatrimonies?.entries],
        backgroundColor: theme.debts.default,
        borderWidth: 0,
      },
      {
        label: 'Spendings',
        data: [
          spendings['Mensal Flexível'] +
          spendings['Mensal Comprometido'] +
          spendings['Eventual Flexível'],
        ],
        backgroundColor: theme.spendings.default,
        borderWidth: 0,
      },
      {
        label: 'Investments',
        data: [spendings['Eventual Comprometido'] + investments.estimated],
        backgroundColor: theme.investments.default,
        borderWidth: 0,
      },
    ],
  };

  return (
    <ContentChart>
      <Header>Pote Estimado</Header>
      <div className="container">
        <div className="canvas">
          <Bar
            data={dataChart}
            options={optionsBarDisplayOff(max)}
          />
        </div>
        <div className="values">
          <span>Recebimentos</span>
          <span className="receipts resize">
            {receipts?.estimated?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </span>
          <span>Investimentos</span>
          <span className="investments resize">
            {investments?.estimated?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </span>
          <span>Gastos</span>
          <span className="spendings resize">
            {spendings?.estimated?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </span>
          <span>Dívidas</span>
          <span className="debts resize">
            {(
              passivePatrimonies?.entries
            ).toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </span>
        </div>
      </div>
      <div className="detail-button">
        <Link to="home/detail">Detalhes</Link>
      </div>
    </ContentChart>
  );
}

ChartEstimated.propTypes = {
  max: PropTypes.number.isRequired,
};
