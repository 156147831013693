import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { addMonths, format } from 'date-fns';
import { toast } from 'react-toastify';
import {
  AiOutlineWallet,
  AiOutlineLineChart,
  AiOutlineShoppingCart,
  AiOutlineCreditCard,
} from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import { pt } from 'date-fns/locale';
import api from 'services/api';
import newApi from 'services/newApi';
import { startOfMonth, endOfMonth } from 'date-fns';
import {
  Container,
  Body,
  LoadingScreen,
  Title,
  ContentTitle,
  DivTitleMonths,
  ContainerCards,
} from './styles';
import InfoCards from './Card';
import Goals from './Goals';
import Chart from './Chart';
import DropDown from 'components/Input/Dropdown/';

export default function Home() {
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(new Date());
  const [goals, setGoals] = useState([]);
  const { id } = useSelector(state => state.user.profile);
  const { token } = useSelector(state => state.auth);
  const [receipts, setReceipts] = useState({
    estimated: 0,
    entries: 0,
    'Mensal Comprometido': 0,
    'Eventual Comprometido': 0,
    'Mensal Flexível': 0,
    'Eventual Flexível': 0,
  });
  const [spendings, setSpendings] = useState({
    estimated: 0,
    entries: 0,
    'Mensal Comprometido': 0,
    'Eventual Comprometido': 0,
    'Mensal Flexível': 0,
    'Eventual Flexível': 0,
  });
  const [investments, setInvestments] = useState({
    estimated: 0,
    entries: 0,
  });
  const [installments, setInstallments] = useState({
    entries: 0,
  });
  const [passivePatrimonies, setPassivePatrimonies] = useState({
    entries: 0,
    estimated: 0,
  });
  const user = useSelector(state => state.user.profile);

  function formatDate(date) {
    const dateAux = format(date, 'MMMM / yyyy', {
      locale: pt,
    }).toString();
    return dateAux
      .charAt(0)
      .toUpperCase()
      .concat(dateAux.substring(1));
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data: fetchedGoals } = await api.get(`users/${user.id}/goals`, {
        params: {
          all: true,
        },
      });

      let entriesInvestmentsData = 0;

      try {
        const { data: entriesInvestments } = await newApi.get(`Investimento/total/${id}`, {
          params: {
            Inicio: startOfMonth(date),
            Fim: endOfMonth(date)
          }
        });

        if (entriesInvestments !== undefined) {
          entriesInvestmentsData = entriesInvestments;
        }
      } catch (err) {
        toast.error('Erro ao tentar carregar dados de investimentos para esse usuário');
      }

      const { data: params } = await api.post(
        `users/${user.id}/calc_parameters`,
        {
          date: format(date, 'yyyy-MM-dd'),
          params: [
            'receipts',
            'spendings',
            'investments',
            'installments',
            'passivePatrimonies',
            'spendingsAccomplished',
          ]
        }
      );

      /*const {
        receipts,
        spendings,
        investments,
        installments,
        passivePatrimonies,
      } = params;*/

      const investmentsObject = {
        estimated: params?.investments?.estimated,
        entries: entriesInvestmentsData,
      }

      if (params.receipts) setReceipts(params.receipts);
      if (params.spendings) setSpendings(params.spendings);
      if (investmentsObject) setInvestments(investmentsObject);
      if (params.installments) setInstallments(params.installments);
      if (params.passivePatrimonies) setPassivePatrimonies(params.passivePatrimonies);
      if (typeof (fetchedGoals) !== "string") setGoals(fetchedGoals);
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line
  }, [date, user.id, id, token]);

  const optionsSelect = () => {
    const initialDate = new Date(user.created_at);
    const currentDate = new Date();
    const intervalMonths = (currentDate.getMonth() - initialDate.getMonth() + (12 * (currentDate.getFullYear() - initialDate.getFullYear())));
    let auxMonth = 0;
    const listMonths = [];

    while (auxMonth <= intervalMonths) {
      const data_index = addMonths(initialDate, auxMonth);
      listMonths.push({
        value: data_index,
        label: formatDate(data_index),
      });
      auxMonth++;
    }

    auxMonth = 1;
    while (auxMonth <= 5) {
      const data_index = addMonths(currentDate, auxMonth);
      listMonths.push({
        value: data_index,
        label: formatDate(data_index),
      });
      auxMonth++;
    }
    return listMonths;
  };

  const handleChange = e => setDate(new Date(e.value));

  return (
    <Container>
      {loading ? (
        <LoadingScreen>
          <FaSpinner className="spin" />
        </LoadingScreen>
      ) : (
        <>
          <Body>
            <DivTitleMonths>
              <ContentTitle>
                <Title>Dashboard</Title>
              </ContentTitle>
              <div className="divData">
                <DropDown
                  className="dropMonths"
                  onChange={handleChange}
                  options={optionsSelect()}
                  height="43px"
                  width="128px"
                  defaultValue={{
                    label: formatDate(date),
                    value: format(date, 'MMMM / yyyy', {
                      locale: pt,
                    }),
                  }}
                />
              </div>
            </DivTitleMonths>
            <ContainerCards>
              <InfoCards
                title="Recebimentos"
                withLabel="Orçamento"
                color="receipts"
                icon={<AiOutlineWallet />}
                entry={receipts.entries}
                estimated={receipts.estimated}
              />

              <InfoCards
                title="Investimentos"
                color="investments"
                icon={<AiOutlineLineChart />}
                entry={investments.entries}
                estimated={investments.estimated}
              />

              <Chart
                receipts={receipts}
                spendings={spendings}
                investments={investments}
                installments={installments}
                passivePatrimonies={passivePatrimonies}
              />

              <Chart
                receipts={receipts}
                spendings={spendings}
                investments={investments}
                installments={installments}
                passivePatrimonies={passivePatrimonies}
                accomplished
              />

              <InfoCards
                color="spendings"
                title="Gastos"
                entry={spendings.entries}
                estimated={spendings.estimated}
                icon={<AiOutlineShoppingCart />}
              />

              <InfoCards
                title="Dívidas"
                color="debts"
                icon={<AiOutlineCreditCard />}
                entry={installments.entries + passivePatrimonies.entries}
                estimated={passivePatrimonies.entries}
              />
              <Goals goals={goals} setGoals={setGoals} />
            </ContainerCards>
          </Body>
        </>
      )}
    </Container>
  );
}
