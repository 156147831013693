import React from 'react';

import PropTypes from 'prop-types';

import ProgressBar from 'components/ProgressBar';
import Card from 'components/Cards';
import {DivText} from './styles';

export default function Goal({ goal, wiped }) {
  const wipedValue = wiped.reduce((total, current) => {
    if (current.classification.toLowerCase().includes('eventual')) {
      return total + current.wiped / 12;
    }
    return total + current.wiped;
  }, 0);

  return (
    <Card className="goal" width="100%">
      <DivText>
        <p >Estimado</p>
        <p >Quanto falta</p>
      </DivText>
      <ProgressBar
        rightColor="receipts"
        color="spendings"
        fill={goal ? (wipedValue * 100) / goal : 100}
        left={wipedValue.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
        right={
          goal
            ? goal.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            : 'Você já está no azul!'
        }
      />
    </Card>
  );
}

Goal.propTypes = {
  goal: PropTypes.number,
  wiped: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Goal.defaultProps = {
  goal: null,
};
