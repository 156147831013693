import React from 'react';

import Spendings from './Spendings';
import Entries from './Entries';


export default function Investments() {
  const [, , choosenSubPath] = window.location.pathname.split('/');

  if (choosenSubPath === 'spendings') {
    return <Spendings />;
  }

  if (choosenSubPath === 'extract') {
    return <Entries />;
  }
}
