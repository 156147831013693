import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { AiFillCheckCircle, AiOutlinePlus } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import DropdownButton from 'components/Button/Dropdown';
import { Table } from './styles';
import api from 'services/api';
import { DivButtons } from '../../styles';
import ModalExclude from 'components/Modal';
import Button from 'components/Button';
import Can from 'components/Can';
import { AiOutlineDelete } from 'react-icons/ai';

export default function Debts({
  loading,
  setLoading,
  installments,
  setInstallments,
  passivePatrimonies,
  setCreateInstallmentModal,
  setSelectedInstallment,
  setEditInstallmentModal,
}) {
  const [showModal, setShowModal] = useState(false);

  const [selectedData, setSelectedData] = useState({});

  const theme = useContext(ThemeContext);

  async function handleDelete(installment) {
    try {
      setLoading(true);

      await api.delete(`installments/${installment.id}`);
      setInstallments(installments.filter(i => i.id !== installment.id));

      setLoading(false);
      toast.success('Item excluido com sucesso');
      setShowModal(false);
    } catch (err) {
      setLoading(false);
      toast.error('Ops, tivemos um erro!');
      setShowModal(false);
    }
  }

  function handleInstallmentPercentage() {
    const totalDebts =
      passivePatrimonies.reduce((total, currentValue) => {
        if (currentValue.settled_at) return total;
        return total + currentValue.installment;
      }, 0) +
      installments.reduce(
        (total, currentValue) => total + currentValue.value,
        0
      );

    const totalInstallments = installments.reduce(
      (total, currentValue) => total + currentValue.value,
      0
    );

    let result = (totalInstallments / totalDebts) * 100;

    if (!isFinite(result)) {
      result = 0;
    }
    return Math.round(result);
  }

  function handleCalcPassivePercentage() {
    const totalDebts =
      passivePatrimonies.reduce((total, currentValue) => {
        if (currentValue.settled_at) return total;
        return total + currentValue.installment;
      }, 0) +
      installments.reduce(
        (total, currentValue) => total + currentValue.value,
        0
      );

    const totalPassive = passivePatrimonies.reduce((total, currentValue) => {
      if (currentValue.type !== 'patrimonial-passive') return total;
      if (currentValue.settled_at) return total;
      return total + currentValue.installment;
    }, 0);

    let result = (totalPassive / totalDebts) * 100;

    if (!isFinite(result)) {
      result = 0;
    }

    return Math.round(result);
  }

  function handleCalcFinPassives() {
    const totalDebts =
      passivePatrimonies.reduce((total, currentValue) => {
        if (currentValue.settled_at) return total;
        return total + currentValue.installment;
      }, 0) +
      installments.reduce(
        (total, currentValue) => total + currentValue.value,
        0
      );

    const totalPassive = passivePatrimonies.reduce((total, currentValue) => {
      if (currentValue.type !== 'passive-financial') return total;
      if (currentValue.settled_at) return total;
      return total + currentValue.installment;
    }, 0);

    let result = (totalPassive / totalDebts) * 100;

    if (!isFinite(result)) {
      result = 0;
    }

    return Math.round(result);
  }

  return (
    <>
      <Table
        title="Compras Parceladas"
        color="debts"
        withPercentage={handleInstallmentPercentage()}
        head={[
          <div style={{ textAlign: 'start' }}>Nome</div>,
          'Categoria',
          'Item',
          'Parcela Atual',
          'Total de Parcelas',
          'Valor da Parcela',
          'Valor Restante',
          '',
        ]}
        body={installments.map(i => [
          <div
            style={{
              textAlign: 'start',
              marginLeft: '20px',
            }}
          >
            {i?.name}
          </div>,
          i?.category?.name,
          i?.item?.name,
          i.currentInstallment,
          i.totalInstallments,
          {
            value: i.value.toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
            currency: 'R$',
          },
          {
            value: i.remainingValue.toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
            currency: 'R$',
          },

          <Can canRoles={['planner', 'adm', 'cs', 'clientPfg', 'assistant-planner']}>
            <DropdownButton>
              <button
                onMouseDown={() => {
                  setSelectedInstallment(i);
                  setEditInstallmentModal(true);
                }}
              >
                Editar
              </button>
              <button
                onMouseDown={() => {
                  setShowModal(!showModal);
                  setSelectedData(i);
                }}
              >
                Excluir
              </button>
            </DropdownButton>
          </Can>,
        ])}
        total={installments.reduce(
          (total, currentValue) => total + currentValue.value,
          0
        )}
        buttonIcons={[
          <Can
            canRoles={['planner', 'adm', 'cs', 'clientPfg', 'assistant-planner']}
            key={Math.random()}
          >
            <button
              className="icon"
              onClick={() => {
                setCreateInstallmentModal(true);
              }}
              key={Math.random()}
            >
              <AiOutlinePlus data-tip="Novo Item" />
            </button>
          </Can>,
        ]}
      />
      <Table
        title="Passivos Patrimoniais"
        color="debts"
        className="passivos-patrimoniais"
        withPercentage={handleCalcPassivePercentage()}
        head={[
          <div style={{ textAlign: 'start' }}>Passivo</div>,
          'PMT',
          'Nº Parcelas',
          'Valor Total',
          'Quitado',
        ]}
        total={passivePatrimonies.reduce((total, currentValue) => {
          if (currentValue.type !== 'patrimonial-passive') return total;
          if (currentValue.settled_at) return total;
          return total + currentValue.installment;
        }, 0)}
        body={passivePatrimonies
          .filter(pp => pp.type === 'patrimonial-passive')
          .map(pp => [
            <div
              style={{
                textAlign: 'start',
                marginLeft: '20px',
              }}
            >
              {pp.name}
            </div>,
            {
              value: pp.installment.toLocaleString('pt-br', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              currency: 'R$',
            },

            pp.remainingInstallments,
            {
              value: pp.remainingValue.toLocaleString('pt-br', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              currency: 'R$',
            },

            pp.settled_at ? (
              <AiFillCheckCircle size={28} color={theme.success.default} />
            ) : (
              ''
            ),
          ])}
      />
      <Table
        head={[
          <div style={{ textAlign: 'start' }}>Passivo</div>,
          'PMT',
          'Nº Parcelas',
          'Valor Total',
          'Quitado',
        ]}
        withPercentage={handleCalcFinPassives()}
        total={passivePatrimonies.reduce((total, currentValue) => {
          if (currentValue.type !== 'passive-financial') return total;
          if (currentValue.settled_at) return total;
          return total + currentValue.installment;
        }, 0)}
        body={passivePatrimonies
          .filter(pp => pp.type === 'passive-financial')
          .map(pp => [
            <div
              style={{
                textAlign: 'start',
                marginLeft: '20px',
              }}
            >
              {pp.name}
            </div>,
            {
              value: pp.installment.toLocaleString('pt-br', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              currency: 'R$',
            },

            pp.remainingInstallments,
            {
              value: pp.remainingValue.toLocaleString('pt-br', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
              currency: 'R$',
            },

            pp.settled_at ? (
              <AiFillCheckCircle size={28} color={theme.success.default} />
            ) : (
              ''
            ),
          ])}
        title="Passivos Financeiros"
        className="passivos-financeiros"
        color="debts"
      />
      {showModal && (
        <ModalExclude
          color="danger"
          setShowModal={setShowModal}
          title="Confirmar Exclusão? "
          icon={<AiOutlineDelete />}
        >
          <DivButtons>
            <Button color="danger" onClick={() => handleDelete(selectedData)}>
              {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
            </Button>
          </DivButtons>
        </ModalExclude>
      )}
      <ReactTooltip />
    </>
  );
}

Debts.propTypes = {
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  installments: PropTypes.arrayOf(PropTypes.object).isRequired,
  setCreateInstallmentModal: PropTypes.func.isRequired,
  passivePatrimonies: PropTypes.arrayOf(PropTypes.object).isRequired,
  setInstallments: PropTypes.func.isRequired,
  setSelectedInstallment: PropTypes.func.isRequired,
  setEditInstallmentModal: PropTypes.func.isRequired,
};
