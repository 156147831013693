import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { produce } from 'immer';
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import ReactTooltip from 'react-tooltip';
import capitalize from 'utils/capitalizeWords';
import api from 'services/api';
import { DivButtons, DivNewCategory, ContentModal } from '../../styles';
import Checkbox from 'components/Input/Checkbox';
import ModalExclude from 'components/Modal';
import DropdownButton from 'components/Button/Dropdown';
import Button from 'components/Button';
import Can from 'components/Can';
import Switch from 'react-switch';
import { Table, DivSwitch } from './styles.js';
import Loading from 'components/Loading';

export default function Spendings({
  categories,
  items,
  setCategories,
  setItems,
  loading,
  setLoading,
  setSelectedCategory,
  setEditCategoryModal,
  setCreateItemModal,
  setSelectedItem,
  setEditItemModal,
  setCreateCategoryModal,
}) {
  const theme = useContext(ThemeContext);
  const [showModal, setShowModal] = useState(false);
  const [toggleDelete, setToggleDelete] = useState('');
  const [selectedData, setSelectedData] = useState({});
  const [checked, setChecked] = useState(false);
  const [listItems, setListItems] = useState([]);
  const [titleDelete, setTitleDelete] = useState('Confirmar Exclusão? ');
  const [selectedItemId, setSelectedItemId] = useState('');

  async function handleCreditCard(id, creditCard) {
    try {
      setLoading(true);

      const { data } = await api.put(`items/${id}`, {
        credit_card: creditCard,
      });

      const newItems = produce(items, draft => {
        draft[data.id] = data;
      });

      setItems(newItems);
      toast.success('Item editado com sucesso!');
      setLoading(false);
      return setEditItemModal(false);
    } catch (err) {
      setLoading(false);
      return toast.error('Ops, tivemos um erro!');
    }
  }

  function deleteItemOrCategory(category) {
    setShowModal(!showModal);
    setSelectedData(category);
    if (listItems.length >= 1) {
      setTitleDelete('Confirmar Exclusão dos Itens Selecionados?');
      return setToggleDelete('item');
    }
    setToggleDelete('category');
  }

  async function handleDelete() {
    if (toggleDelete === 'category') {
      return deleteCategory(selectedData);
    }
    if (toggleDelete === 'item' && listItems.length <= 0) {
      return deleteItem(selectedData);
    }
    const itemsDelete = [];
    Object.values(categories)
      .filter(category => category.type === 'spendings')
      .map(category =>
        category.items
          .map(item => items[item])
          .map(element => {
            return listItems.map(idItem => {
              if (idItem === element.id) return itemsDelete.push(element);
              return 0;
            });
          })
      );

    let newCategories = {};
    let newItems = {};

    const promises = itemsDelete.map(async (item, index) => {
      try {
        await api.delete(`items/${item.id}`);
      } catch (err) {
        toast.error('Ops, tivemos um erro!');
        console.error('\nErro ao excluir -> ', err);
      }

      if (index === 0) {
        newCategories = produce(categories, draft => {
          if (draft[item.category_id].items !== undefined)
            draft[item.category_id].items = draft[
              item.category_id
            ].items.filter(id => id !== item.id);
        });

        newItems = produce(items, draft => {
          delete draft[item.id];
        });

        return 0;
      }

      newCategories = produce(newCategories, draft => {
        draft[item.category_id].items = draft[item.category_id].items.filter(
          id => id !== item.id
        );
      });

      newItems = produce(newItems, draft => {
        delete draft[item.id];
      });
    });

    await Promise.all(promises);

    setCategories(newCategories);

    setItems(newItems);

    toast.success('Items excluido com sucesso');

    setLoading(false);
    setShowModal(false);
    setChecked(false);
  }

  async function deleteCategory(category) {
    try {
      setLoading(true);
      await api.delete(`categories/${category.id}`);

      const newCategories = produce(categories, draft => {
        delete draft[category.id];
      });

      const newItems = produce(items, draft => {
        category.items.forEach(item => {
          delete draft[item.id];
        });
      });

      setItems(newItems);
      setCategories(newCategories);
      setShowModal(false);
      return setLoading(false);
    } catch (err) {
      setLoading(false);
      setShowModal(false);
      return toast.error('Ops, tivemos um erro ao excluir a categoria');
    }
  }

  async function deleteItem(item) {
    try {
      setLoading(true);
      await api.delete(`items/${item.id}`);

      const newCategories = produce(categories, draft => {
        draft[item.category_id].items = draft[item.category_id].items.filter(
          id => id !== item.id
        );
      });

      const newItems = produce(items, draft => {
        delete draft[item.id];
      });

      setCategories(newCategories);
      setItems(newItems);

      toast.success('Item excluido com sucesso');
      setLoading(false);
      return setShowModal(false);
    } catch (err) {
      toast.error('Ops, tivemos um erro!');
      setLoading(false);
      return setShowModal(false);
    }
  }

  const handleChangeSelectAll = ({ target }) => {
    const { value: idCategoryChecked } = target;
    selectAll(idCategoryChecked);
  };

  function selectAll(idCategory) {
    const allItemsId = [];

    if (Number(checked) === Number(idCategory)) {
      setTitleDelete('Confirmar Exclusão? ');
      setListItems([]);
      setChecked(null);
      return;
    }
    Object.values(categories)
      .filter(
        category =>
          category.type === 'spendings' && category.id === Number(idCategory)
      )
      .map(category =>
        category.items.map(item => allItemsId.push(items[item]?.id))
      );
    setChecked(idCategory);
    setListItems(allItemsId);
  }

  const handleChange = ({ target }) => {
    const { value: idChecked } = target;
    const inclui = listItems.includes(Number(idChecked));

    if (inclui) {
      listItems.splice(listItems.indexOf(Number(idChecked)), 1);
      return;
    }
    setListItems(oldState => [...oldState, Number(idChecked)]);
  };

  function tableHead(category) {
    return [
      <Checkbox
        defaultChecked={Number(checked) === Number(category?.id)}
        onChange={handleChangeSelectAll}
        value={Number(category?.id)}
      />,
      <div style={{ textAlign: 'start' }}>Nome</div>,
      'Classificação',
      'Mês / Dia do mês',
      'Valor',
      'Gasto no Cartão',
      'Valor Mensal',

      '',
    ];
  }

  function tableBody(category) {
    return category?.items?.map(item => [
      <Checkbox
        defaultChecked={listItems.includes(Number(items[item]?.id))}
        value={items[item]?.id}
        onChange={handleChange}
      />,

      <div
        style={{
          textAlign: 'start',
          marginLeft: '20px',
        }}
      >
        {items[item]?.name}
      </div>,

      items[item]?.classification,
      items[item]?.classification === 'Eventual Comprometido'
        ? capitalize(
            format(new Date(2020, items[item]?.when - 1), 'MMMM', {
              locale: pt,
            })
          )
        : items[item]?.when,
      {
        value: items[item]?.value.toLocaleString('pt-br', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        currency: 'R$',
      },
      <DivSwitch>
        {loading && selectedItemId === items[item]?.id ? (
          <Loading />
        ) : (
          <Switch
            onChange={async () => {
              setSelectedItemId(items[item]?.id);
              await handleCreditCard(items[item]?.id, !items[item]?.creditCard);
            }}
            checked={items[item]?.creditCard}
            offColor={theme.grafit.default}
            onColor={theme.info.default}
            onHandleColor={theme.info.hover}
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={16}
            width={45}
          />
        )}
      </DivSwitch>,
      items[item]?.classification === 'Eventual Comprometido' ||
      items[item]?.classification === 'Eventual Flexível'
        ? {
            value: (items[item]?.value / 12).toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
            currency: 'R$',
          }
        : {
            value: items[item]?.value.toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
            currency: 'R$',
          },

      <Can
        canRoles={['planner', 'adm', 'cs', 'clientPfg', 'assistant-planner']}
      >
        <DropdownButton>
          <button
            onMouseDown={() => {
              setSelectedItem(items[item]);
              setEditItemModal(true);
            }}
          >
            Editar
          </button>
          <button
            onMouseDown={() => {
              setShowModal(!showModal);
              setToggleDelete('item');
              setSelectedData(items[item]);
            }}
          >
            Excluir
          </button>
        </DropdownButton>
      </Can>,
    ]);
  }

  function handleCalcPercentage(category) {
    const totalCategory = category.items.reduce((total, currentValue) => {
      if (!items[currentValue]) return total;
      if (
        items[currentValue].classification === 'Eventual Flexível' ||
        items[currentValue].classification === 'Eventual Comprometido'
      ) {
        return total + items[currentValue].value / 12;
      }
      return total + items[currentValue].value;
    }, 0);

    const totalReceipts = categories
      ? Object.values(categories)
          .filter(c => c.type === 'spendings')
          .reduce((total, current) => [...total, ...current.items], [])
          .reduce((total, currentValue) => {
            if (!items[currentValue]) return total;
            if (
              items[currentValue].classification === 'Eventual Flexível' ||
              items[currentValue].classification === 'Eventual Comprometido'
            ) {
              return total + items[currentValue].value / 12;
            }
            return total + items[currentValue].value;
          }, 0)
      : 0;

    let result = (totalCategory / totalReceipts) * 100;

    if (!isFinite(result)) {
      result = 0;
    }

    return Math.round(result);
  }

  return (
    <>
      <Can
        canRoles={['planner', 'adm', 'cs', 'clientPfg', 'assistant-planner']}
      >
        <DivNewCategory>
          <Button
            color="spendings"
            onClick={() => setCreateCategoryModal(true)}
          >
            Nova Categoria
          </Button>
        </DivNewCategory>
      </Can>
      {categories &&
        Object.values(categories)
          .filter(category => category.type === 'spendings')
          .map(category => (
            <Table
              className="tableSpendings"
              key={category.id}
              withPercentage={handleCalcPercentage(category)}
              total={category.items.reduce((total, currentValue) => {
                if (!items[currentValue]) return total;
                if (
                  items[currentValue].classification === 'Eventual Flexível' ||
                  items[currentValue].classification === 'Eventual Comprometido'
                ) {
                  return total + items[currentValue].value / 12;
                }
                return total + items[currentValue].value;
              }, 0)}
              color="spendings"
              title={category.name}
              subtitle={category.classification}
              head={tableHead(category)}
              body={tableBody(category)}
              buttonIcons={[
                <Can
                  canRoles={[
                    'planner',
                    'adm',
                    'cs',
                    'clientPfg',
                    'assistant-planner',
                  ]}
                  key={Math.random()}
                >
                  <button
                    className="icon"
                    key={Math.random()}
                    onClick={() => {
                      deleteItemOrCategory(category);
                    }}
                  >
                    <AiOutlineDelete data-tip="Excluir Item" />
                  </button>

                  <button
                    className="icon"
                    key={Math.random()}
                    onClick={() => {
                      setSelectedCategory(category);
                      setEditCategoryModal(true);
                    }}
                  >
                    <AiOutlineEdit data-tip="Editar Item" />
                  </button>

                  <button
                    className="icon"
                    key={Math.random()}
                    onClick={() => {
                      setSelectedCategory(category);
                      setCreateItemModal(true);
                    }}
                  >
                    <AiOutlinePlus data-tip="Novo Item" />
                  </button>
                </Can>,
              ]}
            />
          ))}
      {showModal && (
        <ModalExclude
          color="danger"
          setShowModal={setShowModal}
          title={titleDelete}
          icon={<AiOutlineDelete />}
        >
          <ContentModal color="danger">
            <span>Pode haver lançamentos nesse(s) item(s).</span>
            <DivButtons>
              <Button color="danger" onClick={() => handleDelete()}>
                {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
              </Button>
            </DivButtons>
          </ContentModal>
        </ModalExclude>
      )}
      <ReactTooltip />
    </>
  );
}

Spendings.propTypes = {
  categories: PropTypes.shape().isRequired,
  items: PropTypes.shape().isRequired,
  setCategories: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  setEditCategoryModal: PropTypes.func.isRequired,
  setCreateItemModal: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  setEditItemModal: PropTypes.func.isRequired,
  setCreateCategoryModal: PropTypes.func.isRequired,
};
