import React from 'react';
import {
  Container,
  ContentWrapper,
  ClientName,
  Title
} from './styles';

export default function FinancialPlanningMap({ data }) {

  const clientName = data.client;

  const stylesTop = {
    minHeight: '430px',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  }

  const stylesBottom = {
    minHeight: '430px',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  }

  return (
    <ContentWrapper id="printable" minHeight={1620}>
      <Container style={stylesTop} padding="0 76px 44px 40px">
        <Title>
          <span>O mapa do seu</span>
          Planejamento<br />Financeiro
        </Title>
        <ClientName><h2>{clientName}</h2></ClientName>
      </Container>
      <Container style={stylesBottom} padding="160px 0 70px 0">
      </Container>
    </ContentWrapper>
  );
}
