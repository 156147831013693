import React from 'react';
import PropTypes from 'prop-types';

import { ButtonStyled } from './styles';
import Loading from '../Loading';
export default function Button({
  color,
  stripped,
  children,
  loading,
  ...rest
}) {
  return (
    <ButtonStyled color={color} {...rest} stripped={stripped}>
      {loading ? <Loading></Loading> : children}
    </ButtonStyled>
  );
}

Button.propTypes = {
  color: PropTypes.string,
  stripped: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(),
    PropTypes.string,
    PropTypes.node,
  ]),
};

Button.defaultProps = {
  children: 'Botão',
  stripped: false,
  color: 'info',
};
