import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { Container } from './styles';
import dateUTC from 'utils/dateUTC';
import {MdShowChart } from 'react-icons/md';
import Modal from 'components/Modal';
import Input from 'components/Input';
import CurrencyInput from 'components/masks/CurrencyInput';
import Button from 'components/Button';
import Loading from 'components/Loading';

export default function WipeModal({
  item,
  setItems,
  setSelectedItem,
  setWipeModal,
  setWiped,
}) {
  const [newValue, setNewValue] = useState(item.newValue);
  const [action, setAction] = useState(item.action);
  const [date, setDate] = useState(
    format(item.date ? new Date(item.date) : new Date(), 'yyyy-MM-dd')
  );

  const [loading, setLoading] = useState(false);

  function handleClick() {
    setLoading(true);
    if (!newValue || !action || !date) {
      toast.error('Preencha todos valores');
      return setLoading(false);
    }

    setWiped(wiped => [
      ...wiped,
      {
        item_id: item.id,
        value: newValue,
        oldValue: item.value,
        classification: item.classification,
        wiped:
          item.type === 'Eventual Flexível' ||
          item.type === 'Eventual Comprometido'
            ? item.value - newValue / 12
            : item.value - newValue,
        action,
        date: dateUTC(date),
      },
    ]);

    setItems(items =>
      items.map(i => {
        if (i.id !== item.id) return i;
        return {
          ...i,
          newValue,
          action,
          date: dateUTC(date),
        };
      })
    );
    setSelectedItem(null);
    setWipeModal(false);
    setLoading(false);
  }

  return (
    <Modal
      title="Enxugar"
      setShowModal={() => {
        setSelectedItem(null);
        setWipeModal(false);
      }}
      color="spendings"
      icon={<MdShowChart/>}
    >
      <Container>
        <CurrencyInput
          value={newValue}
          onChange={e => setNewValue(e)}
          prefix="R$ "
          label="Novo Valor"
        />
        <Input
          label="Ação"
          value={action}
          onChange={e => setAction(e.target.value)}
        />
        <Input
          label="Data"
          type="date"
          value={date}
          onChange={e => setDate(e.target.value)}
        />

        <div className="buttons">
          <Button onClick={handleClick} color="spendings">
            {loading ? <Loading /> : 'Salvar'}
          </Button>
        </div>
      </Container>
    </Modal>
  );
}

WipeModal.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.number,
    type: PropTypes.string,
    date: PropTypes.string,
    action: PropTypes.string,
    newValue: PropTypes.number,
    classification: PropTypes.string,
  }).isRequired,
  setItems: PropTypes.func.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  setWipeModal: PropTypes.func.isRequired,
  setWiped: PropTypes.func.isRequired,
  classification: PropTypes.string.isRequired,
};
