import styled from 'styled-components';

export const Form = styled.form`
  height: 100% !important;
  width: 100% !important;

  div.custom__container{
    margin-top: ${props => '0px'};
  }

  align-items:center;
  .buttons {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 25px;
    margin-left: 115px;
  }
  

`;

export const DivButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100% !important;
  div.custom__container{
    margin-top: ${props => '0px'};
  }
  .buttons {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 25px;
    margin-left: 115px;
  }
`;

export const DivSwitch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  svg{
    height: 25px;
    padding-top: 0 !important;
  }
`;
