import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { Form } from '../Edit/styles';
import newApi from 'services/newApi';
import PropTypes from 'prop-types';
import { Context } from '../Context';
import { toast } from 'react-toastify';

export default function Delete() {
  const [loading, setLoading] = useState(false);
  const { id } = useSelector(state => state.user.profile);
  const { token } = useSelector(state => state.auth);
  const { selectedItem, setPage } = useContext(Context);
  const close = () => {
    setPage('list');
  };
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await newApi.post(`Auth/AutenticarCompativel`, {
        idUser: id,
        token,
      });

      await newApi.delete(`Investimento/${selectedItem.id}`, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });
      toast.success('Lançamento excluído com sucesso');
      setLoading(false);
      setPage('list');
    } catch (error) {
      setLoading(false);
      toast.error('Houve um erro com o lançamento');
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="buttons">
        <Button onClick={close} color="investments">
          {'Cancelar'}
        </Button>
        <Button color="debts" type="submit">
          {loading ? 'Carregando...' : 'Excluir'}
        </Button>
      </div>
    </Form>
  );
}
Delete.propTypes = {
  setShowInvestments: PropTypes.func,
  itemId: PropTypes.number,
};
