import styled from 'styled-components'
export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: 200ms ease;


  div.freedom-financial {
    height: 100%;
    background-color:  ${props => props.theme.layout.default};
    border-radius: 10px;
    img {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 100% ;
      object-fit: cover ;
      mask-image: linear-gradient(to top, transparent 10%, green 90%);
    }
    .dropdown-button {
      width: 40px;
      height: 40px;
      top: 1%;
      right: 6%;
    }
  }

  .freedom-financial {
    position: absolute;
    top: 0;
    width: 100%;
    transition: 500ms ease;

    img {
      width: 100%;
      height: 100%;
      transition: 500ms ease;
      &:hover {
        filter: blur(1px) brightness(70%);
        -webkit-filter: blur(1px) brightness(70%);
      }
    }

    .dropdown-button {
      position: absolute;
      top: 10px;
      right: 5px;
      width: 20%;
      height: 20%;
    }

    .done-icon {
      position: absolute;
      top: 10px;
      left: 10px;
      color: ${props => props.theme.primary.default};
    }
  }

  div.content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
    
    padding-top: 30px;
    padding-bottom: 15px;
    padding-right: 20px;
    padding-left: 20px;

    div.top-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top:100px;
      /* background-color: pink; */
      /* justify-content: space-between; */
      height: 100%;

      .title-finished {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.05;
        letter-spacing: 0.26px;
        text-align: center;
        /* white-space: nowrap; */
        /* overflow: hidden; */
        /* text-overflow: ellipsis; */
        width: 100%;
        color: ${props => props.theme.white.default};
        margin-top: 35px;
      }
    }

    span {
      &.title {
        text-align: center;
        color: ${props => props.theme.white.default} !important;
        font-size: 22px !important;
        font-weight: 800;
        /* white-space: nowrap; */
        /* overflow: hidden; */
        /* text-overflow: ellipsis; */
        max-width: 200px;
        
      }

      &.value {
        color: ${props => props.theme.white.default} !important;

        font-size: 22px;
        font-weight: bold;
      }
    }

    small {
      color: ${props => props.theme.white.default};
      font-size: 15px;
      font-weight: 700;
    }
  }
`;