import produce from 'immer';

const INITIAL_STATE = {
  parcial: null,
  respondido: null,
  perguntas: [],
  loading: false,
  perfil: null,
  descricao: null
};

export default function suitability(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@suitability/LOAD_INITIAL_DATA_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@suitability/LOAD_INITIAL_DATA_SUCCESS': {
        draft.perfil = action.payload.perfil;
        draft.descricao = action.payload.descricao;
        draft.parcial = action.payload.parcial;
        draft.respondido = action.payload.respondido;
        draft.perguntas = action.payload.perguntas;
        draft.loading = false;
        break;
      }
      case '@suitability/LOAD_INITIAL_DATA_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.perfil = null;
        draft.descricao = null;
        draft.parcial = null;
        draft.respondido = null;
        draft.perguntas = [];
        draft.loading = false;
        break;
      }
      default:
        return state;
    }
  });
}
