import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { Form, SubTitle } from '../styles';

import Modal from 'components/Modal';
import Input from 'components/Input';
import CurrencyInput from 'components/masks/CurrencyInput';
import Button from 'components/Button';
import Loading from 'components/Loading';

import api from 'services/api';

export default function UpdateModal({
  setShowModalUpdate,
  selectedAsset,
  setOperations,
}) {
  const [date, setDate] = useState(new Date(selectedAsset.date));
  const [stockBroker, setStockBroker] = useState('');
  const [quantity, setQuantity] = useState(selectedAsset.quantity);
  const [value, setValue] = useState(selectedAsset.value);

  const [loading, setLoading] = useState(false);

  const typeAsset = selectedAsset.type;

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);
      const { data } = await api.put(`operations/${selectedAsset.id}`, {
        stock_broker: stockBroker,
        date,
        quantity,
        value,
      });

      setOperations(operations =>
        operations.map(operation => {
          if (operation.id !== data.id) return operation;
          return data;
        })
      );

      toast.success('Item editado com sucesso');
    } catch (err) {
      toast.error('Falha ao editar item! Verifique os dados e tente novamente');
    } finally {
      setLoading(false);
      setShowModalUpdate(false);
    }
  }

  return (
    <Modal title="Editar item" color="info" setShowModal={setShowModalUpdate}>
      <Form onSubmit={handleSubmit}>
        <SubTitle>Onde você negociou?</SubTitle>
        <Input
          label="Em qual Instituição Financeira?"
          value={stockBroker}
          onChange={e => setStockBroker(e.target.value)}
        />
        <Input
          label={typeAsset === 'sell' ? 'Data de venda?' : 'Data de venda'}
          type="date"
          value={format(date, 'yyyy-MM-dd')}
          onChange={e => setDate(e.target.value)}
        />
        <SubTitle>Quanto você resgatou?</SubTitle>
        <div className="divGroup">
          <Input
            label="Quantidade?"
            width="45%"
            value={quantity}
            onChange={e => setQuantity(e.target.value)}
          />
          <CurrencyInput
            label={
              typeAsset === 'sell' ? 'Preço de venda?' : 'Preço de compra?'
            }
            prefix="R$ "
            value={value}
            onChange={e => setValue(e)}
            width="45%"
          />
        </div>

        <div className="button">
          <Button>{loading ? <Loading /> : 'Confirmar edição'}</Button>
        </div>
      </Form>
    </Modal>
  );
}

UpdateModal.propTypes = {
  setShowModalUpdate: PropTypes.func.isRequired,
  setOperations: PropTypes.func.isRequired,
  selectedAsset: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    quantity: PropTypes.number,
    value: PropTypes.number,
    type: PropTypes.string,
  }).isRequired,
};
