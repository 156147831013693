import styled, { keyframes } from 'styled-components';

import { measures } from '../styles';

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const FadeInSlide = keyframes`
  0% {
    margin-left: -80px;
    opacity: 0;
  }

  100% {
    margin-left: 0px;
    opacity: 1;
  }
`;

const {
  sidebarWidth,
  sidebarHomeIconSize,
} = measures;

export const BackgrondMenu = styled.div`
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0px 24px 24px 0px;
  width: ${props => props.open ? '250px': '48px'};
  height: 100vh;
  transition: width 300ms ease;
  background: ${props => props.theme.newLayout.open};
`

export const Container = styled.aside`
  position: fixed;
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0px 24px 24px 0px;
  width: ${sidebarWidth}px;
  height: 100vh;
  min-height: 300px;
  background: ${props => props.theme.newLayout.default};
  /*&:hover {
    width: 300px;
    transition: width 600ms ease;
    .link-text {
      display: flex;
      color: ${props => props.theme.white.disabled};
      //margin-left: 1rem;
      font-size: 14px;
      font-weight: 600;
      transition: width 600ms ease;
    }
  }*/

`;

export const SideNav = styled.ul`
  position: relative;
  list-style: none;
  overflow-y: auto;
  /* border: solid 1px red; */
  /* width:  ${props => props.open ? '240px': '100%'}; */
  width: 246px;
  height: 100%;
  min-height: 100px;
  max-height: calc(100vh - 96px);
  /* pointer-events: none; */
  pointer-events: ${props => props.open ? 'all': 'none'};

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    height: 0;
  }

  &:before {
    content: '';
    position: absolute;
    width: 48px;
    height: ${props => props.currentHeight + 'px'};
    pointer-events: all;
    z-index: -1;
  }

  li {
    /* border: solid 1px red; */
    margin-top: 24px;
    margin-left: 0px;
    font-family: 'Inter', sans-serif !important;
    font-weight: 600;
    font-size: 12px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    pointer-events: all;
    padding-left: 2px;
    /* border: solid 1px red; */

    &:last-child {
      margin-bottom: 24px;
    }

    &.off {
      width: 48px;
    }
  }
`;

export const ContainerLancamento = styled.div`
    font-family: 'Inter', sans-serif !important;
    font-weight: 600;
    font-size: 12px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    pointer-events: all;
    padding-left: ${props => props.paddingLeft !== undefined ? props.paddingLeft + 'px' :  '10px'};
    align-self: flex-start;
    width: 220px;
    height: 32px;

    span.client-name {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.off {
      width: 48px;
    }
`

export const SideMenu = styled.div`
  position: relative;
  top: 0;
  left: 0;
  padding-left: 10px;
  width: calc(100% - 60px);
  /* border: solid 1px orangered; */

  &.open {
    width: calc(100% - 40px);
  }
`

export const TooltipMenu = styled.div`
  position: absolute;
  top: 0;
  left: 46px;
  padding-left: 10px;
  height: ${props => `${(props.totalButtons * props.spaceY) + 10}px`};
  width: calc(100% - 60px);
  /* border: solid 1px orangered; */
  z-index: 2;
`

export const WrapperMenuToolTip = styled.div`
  display: flex;
  width: ${props => props.width !== undefined ? props.width + 'px': ''};
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
`

export const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 12px;
  width: auto;
  cursor: ${props => props.clickable ? 'pointer' : 'default'};
  height: 32px;

  color: #FFFFFF;
  opacity: 0;
  animation: ${FadeIn} .7s ease forwards 0.1s;
  white-space: nowrap;
  transition: all 0.3s ease;

  &.simple-button {
    display: inline-flex;
    width: 194px;
    justify-content: space-between;
    padding: 8px 12px;
    background: transparent;

    &:hover {
      background: #140361;
    }
  }


  &.has-sub {
    cursor: pointer;
    &:hover {
      background: #140361;
    }
  }

  &.sub-open {
    background: #140361;

    svg {
      transform: scale(-1);
    }
  }

  svg {
    height: 20px;
    width: auto;
    transform: scale(1);
    cursor: pointer;
  }
`

export const ButtonToolTip = styled.span`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  width: auto;
  cursor: ${props => props.clickable ? 'pointer' : 'default'};
  height: 32px;
  background: ${ props => props.type === "subbutton" ? 'rgba(20, 3, 97, 0.8)' : '#140361'};
  color: #FFFFFF;
  opacity: 0;
  animation: ${FadeIn} .7s ease forwards 0.1s;
  white-space: nowrap;
  transition: all 0.3s ease;

  &.justify-between {
    justify-content: space-between;
  }

  &.sub-open {
    background: #453883;

    &:hover {
      background: #372d69;
    }
  }

  svg {
    height: 20px;
    width: auto;
    margin-right: 10px;
  }

  &.no-mg {
    svg {
      margin-right: 0;
      cursor: pointer;
    }
  }

  &:hover {
    background: ${ props => props.type === "subbutton" ? 'rgba(10, 3, 60, 0.8)' : '#140361'};
  }
`

export const HomeIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  width: 100%;
  margin-bottom: 25px;

  hr {
    width: 100%;
    background: ${props => props.theme.layout.disabled};
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    height: ${sidebarHomeIconSize}px;
    width: ${sidebarHomeIconSize}px;
    padding: 5px;
    border-radius: 10px;

    &:hover {
      background: rgba(0, 0, 0, 0.25);
      svg {
        fill: ${props => props.theme.primary.default};
      }
    }
    svg {
      width: 25px;
      height: 25px;
      fill: ${props => props.theme.layout.disabled};
      display: flex;
      align-items: flex-end;
      justify-content: center;
      transition: 500ms ease;
    }
  }
`;

export const MenuHeaderClosed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 48px;
  height: 110px;
  min-height: 110px;
  gap: 17px;
  opacity: 0;
  animation: ${FadeIn} .7s ease forwards 0.1s;
`
export const MenuHeaderOpened = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 23px 8px 0 22px;
  width: 250px;
  height: 110px;
  min-height: 110px;
  gap: 19px;
  opacity: 0;
  animation: ${FadeInSlide} .3s ease-out forwards;

  div {
    width: 17px;
    height: 17px;
  }
`

export const Avatar = styled.img`
  width: 30px;
  height:34px;

  &.opened {
    width: 65px;
    height:28px;
  }
`;

export const ButtonOpen = styled.button`
  color: #FFFFFF;

  svg {
    transform-origin: center;
    transform: rotate(-90deg);
    width: 24px;
    height: 24px;
  }
`

export const ButtonClose = styled.button`
  color: #FFFFFF;
  height: 32px;

  svg {
    transform-origin: center;
    transform: rotate(90deg);
    width: 24px;
    height: 24px;
  }
`

export const Separator = styled.hr`
  border-top: solid 2px #7386FF;
  width: 38px;
`

export const MenuActionBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48px;
  padding-bottom: 24px;

  button.launchModal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: rgba(115, 134, 255, 0.32);
    border-radius: 4px;

    svg {
      width: 22px;
      height: 22px;
      fill: ${props => props.theme.white.default};
      &:hover {
        fill: ${props => props.theme.white.default} !important;
      }
    }
  }
`

export const IconButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 32px;
  background: transparent;
  transition: all 0.3s ease;
  border-radius: 4px;
  cursor: pointer;

  & > a {
    padding: 0;
    margin: 0;
    line-height: 0;
  }

  &.react-icons {
    svg {
      width: 24px;
      height: auto;
      color: #FFFFFF;
    }
  }

  &:not(.react-icons) {
    a {
      color: #FFFFFF;
    }
    color: #FFFFFF;
    svg path {
      fill: currentColor;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }

  &.active {
    background: #7386FF;
    &:not(.react-icons) {
      a {
        color: #140361;
      }
      color: #140361;
    }

    &.react-icons {
      svg {
        color: #140361;
      }
      a {
        color: #140361;
      }
      color: #140361;
    }
  }

  &:not(.disabled):hover {
    background: #7386FF;
    &:not(.react-icons) {
      a {
        color: #140361;
      }
      color: #140361;
    }

    &.react-icons {
      svg {
        color: #140361;
      }
      a {
        color: #140361;
      }
      color: #140361;
    }
  }


`
