import produce from 'immer';

const INITIAL_STATE = {
  user: null,
  token: null,
  roles: [],
  permissions: [],
  signed: false,
  loading: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/EXTERNAL_ACESS': {
        draft.user = action.payload.external_user.profile;
        draft.token = action.payload.external_user.token;
        draft.roles = action.payload.external_user.roles;
        draft.permissions = action.payload.external_user.permissions;
        draft.signed = true;
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.user = action.payload.user;
        draft.token = action.payload.token;
        draft.roles = action.payload.roles;
        draft.permissions = action.payload.permissions;
        draft.signed = true;
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.signed = false;
        draft.roles = [];
        draft.permissions = [];
        break;
      }
      default:
        return state;
    }
  });
}
