import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > div + div {
    margin-top: 25px;
  }

  div.buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    button + button {
      margin-left: 20px;
    }
  }
`;
