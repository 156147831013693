import React from 'react';

import PropTypes from 'prop-types';

import { FaSpinner } from 'react-icons/fa';
import Modal from 'components/Modal/BlankModal';

import Button from 'components/Button/GhostButton';

import { Container } from './styles';

export default function ResultModal({
  loading,
  estimated,
  wiped,
  setResultModal,
  setPage,
  setWipeContainer,
  setOrganize,
  setWiped,
  setSuccess,
}) {
  return (
    <Modal>
      <Container>
        {loading ? (
          <FaSpinner className="spin" />
        ) : (
          <>
            <h2>Parabéns</h2>
            <div className="info">
              <span>
                {Math.ceil(wiped / estimated).toLocaleString('pt-br')}%
              </span>
              <small>
                {wiped.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </small>
            </div>
            <span className="text">
              Você enxugou{' '}
              <small className="blue">
                {Math.ceil(wiped / estimated).toLocaleString('pt-br')}%
              </small>{' '}
              do seu orçamento atual
            </span>
            <Button
              onClick={() => {
                setResultModal(false);
                setPage(1);
                setWiped([]);
                setWipeContainer(false);
                setSuccess(false);
                return setOrganize(true);
              }}
              color="grafit"
            >
              Ok
            </Button>
          </>
        )}
      </Container>
    </Modal>
  );
}

ResultModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  estimated: PropTypes.number.isRequired,
  wiped: PropTypes.number.isRequired,
  setResultModal: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setWipeContainer: PropTypes.func.isRequired,
  setOrganize: PropTypes.func.isRequired,
  setWiped: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
};
