import React, { useState, useEffect } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import {
  Container,
  ContainerColumn,
  PurpleBar,
  ContentWrapper,
  LogoAzul,
  Footer,
  Title,
} from './styles';

export default function PortfolioLiquidity({ data }) {
  const { headerTitle, footerTitle, liquidities, requiredLiquidities } = data;
  const [dataGR, setDataGR] = useState({
    labels: [
      'D O',
      'Até 30 dias',
      '1 à 6 meses',
      '6 à 12 meses',
      '12 à 24 meses',
      'Acima de 24 meses',
    ],
    datasets: [
      {
        label: 'Necessidade de Liquidez',
        backgroundColor: '#7386ff',
        borderColor: '#7386ff',
        data: [],
      },
      {
        label: 'Liquidez Atual',
        backgroundColor: '#4739eb',
        borderColor: '#14739eb',
        data: [],
      },
    ],
  });

  useEffect(() => {
    if (liquidities?.length > 0) {
      const dataLiqAtual = liquidities.map(
        liquidity => liquidity.absolutePercentage
      );
      const requiredLiquiditiesData = requiredLiquidities.map(
        liquidity => liquidity.absolutePercentage
      );
      setDataGR({
        labels: [
          'D O',
          'Até 30 dias',
          '1 à 6 meses',
          '6 à 12 meses',
          '12 à 24 meses',
          'Acima de 24 meses',
        ],
        datasets: [
          {
            label: 'Necessidade de Liquidez',
            backgroundColor: '#7386ff',
            borderColor: '#7386ff',
            data: requiredLiquiditiesData,
          },
          {
            label: 'Liquidez Atual',
            backgroundColor: '#4739eb',
            borderColor: '#14739eb',
            data: dataLiqAtual,
          },
        ],
      });
    }
  }, [liquidities, requiredLiquidities]);

  const options = {
    legend: {
      position: 'top',
      align: 'start',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 6,
      },
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = dataset.data[tooltipItem.index];
          return dataset.label + ': ' + currentValue + '%';
        },
        title: function(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        },
      },
    },
    plugins: {
      datalabels: {
        formatter: value => {
          let percentage = value.toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,0.6)',
        borderRadius: 4,
        padding: 4,
        left: 50,
        font: {
          family: 'Roboto Condensed',
          weight: 'bold',
          size: 13,
        },
        align: 'end',
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            callback: function(value) {
              return value + '%';
            },
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  return (
    <ContentWrapper id="printable" style={{ minHeight: '1682px' }}>
      <Container padding="0 78px 82px 78px">
        <PurpleBar />
      </Container>
      <Container padding="0 78px">
        <ContainerColumn>
          <Title dangerouslySetInnerHTML={{ __html: headerTitle }}></Title>
          <p className="p-smaller">
            A liquidez requerida da sua carteira mede o prazo <br />
            necessário para transformar seus ativos em dinheiro, <br />
            sem risco de perda
          </p>
        </ContainerColumn>
      </Container>
      <Container padding="0px 78px 0px 78px">
        <ContainerColumn className="full-content">
          <HorizontalBar height={700} data={dataGR} options={options} />
        </ContainerColumn>
      </Container>
      <Container padding="56px 78px 56px 78px" height="auto" className="split">
        <Container style={{ marginTop: '10px' }} className="split-2">
          <div className="d-purple">Balanceamento da Carteira</div>
          <div className="d-gray">
            A carteira será rebalanceada daqui 6 meses.
          </div>
        </Container>
      </Container>
      <Footer>
        <Container padding="0 78px">
          <p>{footerTitle}</p>
          <LogoAzul />
        </Container>
      </Footer>
    </ContentWrapper>
  );
}
